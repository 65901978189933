<template>
  <v-container style="max-width: 1450px">
    <PageTitle :title="'Todas as notificações'" />
    <v-list dense class="mx-auto">
      <template v-for="(item, index) in notificacoes">
        <NotificationCard
          :notificacao="item"
          @onRead="listarNotificacoes"
          v-bind:key="'id' + item.id"
        />
        <v-divider
          v-if="index < notificacoes.length - 1"
          :key="'idx' + index"
        ></v-divider>
      </template>
    </v-list>
    <p
      v-if="!notificacoes.length"
      style="text-align: center; font-family: Raleway"
    >
      Nenhuma notificação
    </p>
  </v-container>
</template>

<script>
import PageTitle from "../components/PageTitle";
import NotificationCard from "../components/NotificationCard";
import { listarNotificacoes } from "../services/providers/notificacoesProvider";
import { EventBus } from "../services/event-bus.js";

export default {
  name: "Notifications",
  components: {
    PageTitle,
    NotificationCard,
  },
  data: () => ({
    notificacoes: [],
  }),
  created() {
    EventBus.$on("read-notification", async () => {
      await this.listarNotificacoes();
    });
  },
  async mounted() {
    await this.listarNotificacoes();
  },
  methods: {
    async listarNotificacoes() {
      try {
        this.notificacoes = await listarNotificacoes();
        Array.isArray(this.notificacoes) &&
          this.notificacoes.sort((a, b) => (a.lido < b.lido ? -1 : 1));
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro interno!",
          text: error.message || error,
          type: "error",
          duration: 15000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
