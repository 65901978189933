import api from "../api";

export const listarEntregaItem = async () => {
  const res = await api.get(`/entrega-item`);
  return res.data;
};

export const listarEntregaItemPorItem = async (itemId) => {
  const res = await api.get(`/entrega-item/search?itens=${itemId}`);
  return res.data;
};

export const buscaPegarItem = async (idEntrega) => {
  const res = await api.get(`/entrega-item/search?processosEntrega=${idEntrega}`);
  return res.data;
};

export const cadastrarEntregaItem = async (payload) => {
  const res = await api.post(`/entrega-item`, payload);
  return res.data;
};

export const editarEntregaItem = async (idEntrega, payload) => {
  const res = await api.patch(`/entrega-item/${idEntrega}`, payload);
  return res.data;
};

export const editarRequisicao = async (IdRequisicao, payload) => {
  const res = await api.patch(`/requisicoes/${IdRequisicao}`,payload);
  return res.data;
};

export const adicionarRequisicao = async ( payload) => {
  const res = await api.post(`/processo-entrega-item/adicionar-requisicao`,payload);
  return res.data;
};

export const removerRequisicao = async ( payload) => {
  const res = await api.patch(`/processo-entrega-item/remover-requisicao`,payload);
  return res.data;
};

export const adicionarEmpenho = async ( payload) => {
  const res = await api.post(`/processo-entrega-item/adicionar-empenho`,payload);
  return res.data;
};

export const removerEmpenho = async ( payload) => {
  const res = await api.patch(`/processo-entrega-item/remover-empenho`,payload);
  return res.data;
};

export const editarEmpenho = async (idEmpenho, payload) => {
  const res = await api.patch(`/empenhos/${idEmpenho}`,payload);
  return res.data;
};

export const listarEntregaIntermediaria = async (id) => {
  const res = await api.get(`/processo-entrega-item/${id}`);
  return res.data;
};

export const listarEntregaItemIntermediariaPorItem = async (itemId) => {
  const res = await api.get(`/processo-entrega-item/search?itens=${itemId}`);
  return res.data;
};

export const excluirProcessoComum = async (id) => {
  const res = await api.delete(`/processo-entrega-item/${id}`);
  return res.data;
};