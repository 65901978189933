<template>
  <div>
    <div class="container-main">
      <v-row>
        <v-col md="1" style="display: flex; align-items: center">
          <v-btn
            depressed
            text
            color="white"
            class="btn-editar"
            @click="prevParcela()"
            :disabled="indiceParcelaAtual < 1"
          >
            <v-icon color="#F39C12" x-large
              >mdi-arrow-left-circle-outline</v-icon
            >
          </v-btn>
        </v-col>
        <v-col class="container-out" :key="indiceParcelaAtual">
          <div md="10">
            <div class="container-header">
              <p class="session-title">
                {{ parcelas[indiceParcelaAtual].numero }}ª PARCELA
              </p>
              <v-btn
                depressed
                class="btn-editar"
                width="290"
                color="#037F8C"
                @click="modalEditarParcelaOpen = !modalEditarParcelaOpen"
                >EDITAR PARCELA PREVISTA</v-btn
              >
            </div>
            <br />
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="title-tab"></th>
                    <th class="title-tab">VALOR</th>
                    <th class="title-tab">DATA</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-tab-main">PREVISAO:</td>
                    <td class="text-tab">
                      {{
                        parcelas[indiceParcelaAtual].valorAutorizado | currency
                      }}
                    </td>
                    <td class="text-tab">
                      {{
                        new Date(parcelas[indiceParcelaAtual].dataPrevisao)
                          | moment("DD/MM/YYYY")
                      }}
                    </td>
                  </tr>
                  <tr
                    v-for="(desembolso, idx) in desembolsosPorParcela"
                    v-bind:key="idx"
                  >
                    <td class="text-tab-main">{{ desembolso.descricao }}</td>
                    <td class="text-tab">{{ desembolso.valor | currency }}</td>
                    <td class="text-tab">
                      {{
                        new Date(desembolso.dataExecucao) | moment("DD/MM/YYYY")
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td class="text-tab-main">TOTAL</td>
                    <td class="text-tab">
                      {{ totalDesembolsoParcela | currency }}
                    </td>
                    <td class="text-tab"></td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <br />
            <br />
            <p v-if="observacoes.length" class="session-title">OBSERVAÇÃO</p>
            <div v-for="(observacao, idx) in observacoes" v-bind:key="idx">
              <div class="container-obs">
                <div
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                  "
                >
                  <div style="display: flex; flex-direction: row">
                    <p
                      v-if="observacao.usuarioCriacao"
                      class="comment-header-label"
                    >
                      <v-icon style="margin-right: 10px" color="#037F8C"
                        >mdi-account</v-icon
                      >
                      {{ observacao.usuarioCriacao.login }}
                    </p>
                    <p
                      v-if="observacao.dataEdicao"
                      class="comment-header-label"
                      style="margin-left: 10px"
                    >
                      <v-icon style="margin-right: 10px" color="#037F8C"
                        >mdi-calendar</v-icon
                      >
                      {{
                        new Date(observacao.dataEdicao)
                          | moment("DD/MM/YYYY HH:mm")
                      }}
                    </p>
                    <p
                      v-else
                      class="comment-header-label"
                      style="margin-left: 10px"
                    >
                      <v-icon style="margin-right: 10px" color="#037F8C"
                        >mdi-calendar</v-icon
                      >
                      {{
                        new Date(observacao.dataCriacao)
                          | moment("DD/MM/YYYY HH:mm")
                      }}
                    </p>
                  </div>
                  <div
                    style="
                      display: flex;
                      flex-direction: row;
                      margin-top: -10px;
                    "
                  >
                    <v-btn
                      text
                      @click="
                        (observacaoSelecionada = observacao) &
                          (modalObsOpen = !modalObsOpen)
                      "
                      :disabled="usuario.id != observacao.usuarioCriacao.id"
                    >
                      <v-icon color="#F39C12">mdi-pencil-box-outline</v-icon>
                    </v-btn>
                    <v-btn
                      text
                      @click="
                        (observacaoSelecionada = observacao) &
                          (modalExcluirObservacao = !modalExcluirObservacao)
                      "
                      :disabled="usuario.id != observacao.usuarioCriacao.id"
                    >
                      <v-icon color="#F39C12">mdi-delete</v-icon>
                    </v-btn>
                  </div>
                </div>
                <div class="comment-text">{{ observacao.conteudo }}</div>
              </div>
            </div>
            <br />
            <div style="display: flex; justify-content: center">
              <v-btn
                depressed
                width="220"
                color="#FF8D3F"
                class="btn-editar"
                @click="modalObsOpen = !modalObsOpen"
                >ADICIONAR OBSERVAÇÃO</v-btn
              >
            </div>
          </div>
        </v-col>
        <v-col md="1" style="display: flex; align-items: center">
          <v-btn
            depressed
            text
            color="white"
            class="btn-editar"
            @click="nextParcela()"
            :disabled="indiceParcelaAtual == parcelas.length - 1"
          >
            <v-icon color="#F39C12" x-large
              >mdi-arrow-right-circle-outline</v-icon
            >
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <ModalAddObservacaoParcela
      :key="String(indiceParcelaAtual + modalObsOpen)"
      :observacao="observacaoSelecionada"
      :modalVisible="modalObsOpen"
      :parcela="parcelas[indiceParcelaAtual]"
      @close="
        (modalObsOpen = !modalObsOpen) &
          (observacaoSelecionada = null) &
          loadData()
      "
    />
    <ModalEditarParcela
      v-if="parcelas[indiceParcelaAtual] && modalEditarParcelaOpen"
      :modalVisible="modalEditarParcelaOpen"
      :parcela="parcelas[indiceParcelaAtual]"
      :desembolsos="desembolsosPorParcela"
      @close="(modalEditarParcelaOpen = !modalEditarParcelaOpen) & loadData()"
    />
    <ModalConfirmacao
      v-if="observacaoSelecionada"
      :modalVisible="modalExcluirObservacao"
      :titulo="'Você realmente deseja excluir a observação'"
      textoBotaoDireito="Confirmar"
      :onConfirm="excluirObservacaoParcela"
      @close="
        (modalExcluirObservacao = !modalExcluirObservacao) &
          (observacaoSelecionada = null)
      "
    />
  </div>
</template>

<script>
import { buscarDesembolsoPorParcela } from "../services/providers/desembolsoProvider";
import {
  buscarObservacoesPorParcela,
  excluirObservacaoParcela,
} from "../services/providers/parcelaProvider";
import ModalAddObservacaoParcela from "./modals/ModalAddObservacaoParcela";
import ModalEditarParcela from "./modals/ModalEditarParcela";
import ModalConfirmacao from "./modals/ModalConfirmacao";
import Auth from "../services/auth";

export default {
  name: "ParcelaSession",
  props: {
    projeto: Object,
  },
  components: {
    ModalAddObservacaoParcela,
    ModalEditarParcela,
    ModalConfirmacao,
  },
  data: () => ({
    parcelas: [],
    indiceParcelaAtual: 0,
    desembolsosPorParcela: [],
    totalDesembolsoParcela: 0,
    modalObsOpen: false,
    modalEditarParcelaOpen: false,
    modalExcluirObservacao: false,
    observacoes: [],
    observacaoSelecionada: null,
    usuario: Auth.getUser(),
  }),
  mounted() {
    if (this.projeto && this.projeto.parcelas && this.projeto.parcelas.length) {
      this.parcelas = this.projeto.parcelas.sort((a, b) =>
        a.numero > b.numero ? 1 : -1
      );
      this.loadData();
    }
  },
  methods: {
    async loadData() {
      try {
        const desembolsos = await buscarDesembolsoPorParcela(
          this.parcelas[this.indiceParcelaAtual].id
        );
        this.observacoes = await buscarObservacoesPorParcela(
          this.parcelas[this.indiceParcelaAtual].id
        );
        this.desembolsosPorParcela = desembolsos
          .sort((a, b) =>
            new Date(a.dataExecucao).getTime() >
            new Date(b.dataExecucao).getTime()
              ? 1
              : -1
          )
          .map((d, i) => ({
            ...d,
            dataExecucao: d.dataExecucao + " 00:00:00",
            descricao: i + 1 + "/" + desembolsos.length + " Recebido em",
          }));
        this.totalDesembolsoParcela = this.desembolsosPorParcela.reduce(
          (total, desembolso) => (total += desembolso.valor),
          0
        );
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro interno!",
          text: error.message || error,
          type: "error",
          duration: 15000,
        });
      }
    },
    async nextParcela() {
      if (this.indiceParcelaAtual < this.parcelas.length - 1) {
        this.indiceParcelaAtual += 1;
        await this.loadData();
      }
    },
    async prevParcela() {
      if (this.indiceParcelaAtual > 0) {
        this.indiceParcelaAtual -= 1;
        await this.loadData();
      }
    },
    async excluirObservacaoParcela() {
      if (this.observacaoSelecionada) {
        try {
          await excluirObservacaoParcela(this.observacaoSelecionada.id);
          this.$notify({
            title: "Obervação excluída!",
            text: "A obervação foi excluída com sucesso!",
            type: "success",
            duration: 5000,
          });
          await this.loadData();
        } catch (error) {
          console.error(error);
          this.$notify({
            title: "Erro ao excluir a observação!",
            text: error.message,
            type: "error",
            duration: 15000,
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.session-title {
  margin: 20px;
  margin-top: 0px;
  text-transform: uppercase;
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #037f8c;
}
.container-main {
  border-radius: 10px;
  border: 0.5px solid #55b4c2;
  padding-bottom: 30px;
  margin-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}
.container-obs {
  border-radius: 10px;
  background-color: #ffffff;
  padding: 20px;
}
.container-header {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}
.container-out {
  width: 100%;
  min-height: 400px;
}
.btn-editar {
  border-radius: 8px;
  color: #fff;
}
.title-tab {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 13px;
  text-align: center !important;
  color: #616161 !important;
  border-bottom: 0.899715px solid rgba(97, 97, 97, 0.3);
}
.text-tab {
  font-family: Roboto;
  font-style: normal;
  text-transform: uppercase;
  font-size: 15px;
  line-height: 15px;
  text-align: center;
  color: #037f8c;
  padding-top: 25px;
  padding-bottom: 25px;
  border-bottom: 0.899715px solid rgba(97, 97, 97, 0.3);
}
.text-tab-main {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 15px;
  line-height: 15px;
  text-align: center;
  color: #037f8c;
  padding-top: 25px;
  padding-bottom: 25px;
  border-bottom: 0.899715px solid rgba(97, 97, 97, 0.3);
}
.comment-header-label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: justify;
  text-transform: uppercase;
  color: #037f8c;
}
.comment-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-align: justify;
  color: #616161;
}
</style>
