<template>
  <div ref="chart" class="vue-plotly" />
</template>

<script>
import Plotly from "plotly.js-dist";
import debounce from "lodash/debounce";
const events = [
  "click",
  "hover",
  "unhover",
  "selecting",
  "selected",
  "restyle",
  "relayout",
  "autosize",
  "deselect",
  "doubleclick",
  "redraw",
  "animated",
];
const functions = [
  "restyle",
  "relayout",
  "update",
  "addTraces",
  "deleteTraces",
  "moveTraces",
  "extendTraces",
  "prependTraces",
  "purge",
];
const methods = functions.reduce((all, funcName) => {
  all[funcName] = function(...args) {
    return Plotly[funcName].apply(Plotly, [this.$refs.chart].concat(args));
  };
  return all;
}, {});
export default {
  name: "VuePlotly",
  props: {
    autoResize: {
      type: Boolean,
      default: true,
    },
    watchShallow: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
    },
    layout: {
      type: Object,
    },
    traces: {
      type: Array,
    },
    title: String,
    yTitle: String,
    xTitle: String,
    width: String,
    height: String,
  },
  data() {
    const baseLayout = {
      width: this.width,
      height: this.height,
      responsive: true,
      drag_mode: false,
      paper_bgcolor: "rgba(0,0,0,0)",
      plot_bgcolor: "#E6F4F1",
      separators: ".,",
      title: {
        text: this.title,
        font: {
          family: "Raleway",
          color: "#004F57",
          size: 18,
          weight: "bold",
        },
      },
      xaxis: {
        title: this.xTitle,
        font: {
          family: "Raleway",
        },
        fixedrange: true,
      },
      yaxis: {
        title: this.yTitle,
        font: {
          family: "Raleway",
        },
        zeroline: false,
        fixedrange: true,
        hoverformat: ",.2f",
        tickprefix: "R$ ",
      },
      legend: {
        orientation: "h",
        xanchor: "left",
        font: {
          family: "Raleway",
          size: window.innerWidth <= 1366 ? 10 : 14,
        },
      },
    };
    return {
      el: {},
      internalOptions: {
        responsive: true,
        displayModeBar: false,
        ...this.options,
      },
      internalLayout: { ...baseLayout, ...this.layout } || baseLayout,
    };
  },
  mounted() {
    this.newPlot();
    this.initEvents();
    this.$watch("traces", this.newPlot, { deep: !this.watchShallow });
    this.$watch("options", this.newPlot, { deep: !this.watchShallow });
    this.$watch("layout", this.relayout, { deep: !this.watchShallow });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.__resizeListener);
    this.__generalListeners.forEach((obj) =>
      this.$refs.chart.removeAllListeners(obj.fullName)
    );
    Plotly.purge(this.$refs.chart);
  },
  methods: {
    initEvents() {
      if (this.autoResize) {
        this.__resizeListener = debounce(this.newPlot, 200);
        window.addEventListener("resize", this.__resizeListener);
      }
      this.__generalListeners = events.map((eventName) => {
        return {
          fullName: "plotly_" + eventName,
          handler: (...args) => {
            this.$emit.apply(this, [eventName].concat(args));
          },
        };
      });
      this.__generalListeners.forEach((obj) => {
        this.$refs.chart.on(obj.fullName, obj.handler);
      });
    },
    ...methods,
    updateStyle(opts) {
      var update = {
        mode: opts,
      };
      Plotly.restyle(this.$refs.chart, update);
    },
    plot() {
      return Plotly.react(
        this.$refs.chart,
        this.traces,
        this.internalLayout,
        this.internalOptions
      );
    },
    newPlot() {
      return Plotly.newPlot(
        this.$refs.chart,
        this.traces,
        this.internalLayout,
        this.internalOptions,
        { responsive: true, displayModeBar: false }
      ).then((gd) => {
        gd.on("plotly_legendclick", () => false);
      });
    },
    react() {
      return Plotly.newPlot(
        this.$refs.chart,
        this.traces,
        this.internalLayout,
        this.internalOptions
      );
    },
  },
};
</script>
