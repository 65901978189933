<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        outlined
        dense
        v-model="dateFormatted"
        append-icon="mdi-calendar"
        readonly
        hide-details
        v-bind="attrs"
        v-on="on"
        :disabled="disabled"
        @click:append="menu = !menu"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      no-title
      locale="pt-br"
      @input="menu = false"
      @change="emitChange"
    />
  </v-menu>
</template>

<script>
export default {
  props: ["value", "disabled"],
  data: (vm) => ({
    date: new Date(vm.value || new Date()).toISOString().substr(0, 10),
    dateFormatted: vm.formatDate(
      new Date(vm.value || new Date()).toISOString().substr(0, 10)
    ),
    menu: false,
  }),
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },
  },
  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(val);
      this.$emit("input", val);
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    emitChange(val) {
      this.$emit("change", val);
    },
  },
};
</script>