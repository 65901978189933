<template>
  <div v-if="meta" class="container-div">
    <div class="header">
      <v-btn class="header-button" color="white" text @click="$router.go(-1)">
        <v-icon large color="#037F8C">mdi-arrow-left-circle-outline</v-icon>
        <PageTitle
          :title="`<b>METAS/${meta.subprojeto.codigo}</b>/${formatNomeMeta()}`"
          size="18"
        />
      </v-btn>
      <div>
        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              text
              large
              @click="modalExcluirOpen = !modalExcluirOpen"
            >
              <v-icon large color="#ff8d3f">mdi-delete-outline</v-icon>
            </v-btn>
          </template>
          <span>
            Excluir meta
          </span>
        </v-tooltip>
        <v-btn
          depressed
          color="#037F8C"
          width="240"
          class="btn-controls"
          style=" margin-right: 15px"
          @click="modalCrudMetasOpen = !modalCrudMetasOpen"
          >EDITAR META</v-btn
        >
      </div>
    </div>
    <br />
    <br />
    <div>
      <p class="session-title">RESPONSÁVEIS</p>
      <EquipeTable :participantes="responsaveis" />
    </div>
    <br />
    <br />
    <div>
      <div style="display: flex; justify-content: space-between">
        <p class="session-title">ATIVIDADES</p>
        <v-btn
          depressed
          color="#037F8C"
          width="240"
          class="btn-controls"
          style=" margin-right: 15px"
          @click="modalCrudAtividadesOpen = !modalCrudAtividadesOpen"
          >CADASTRAR ATIVIDADE</v-btn
        >
      </div>
      <AtividadesTable :atividades="atividades" />
    </div>
    <br />
    <br />
    <ModalCrudMetas
      :key="String(modalCrudMetasOpen) + 'meta'"
      :modalVisible="modalCrudMetasOpen"
      :meta="meta"
      @close="modalCrudMetasOpen = !modalCrudMetasOpen"
    />
    <ModalConfirmacao
      :modalVisible="modalExcluirOpen"
      :titulo="'Você realmente deseja excluir a meta'"
      textoBotaoDireito="Excluir"
      :onConfirm="deletarMeta"
      @close="modalExcluirOpen = !modalExcluirOpen"
    />
    <ModalCrudAtividades
      :key="String(modalCrudAtividadesOpen) + 'atividade'"
      :modalVisible="modalCrudAtividadesOpen"
      :meta="meta"
      @close="modalCrudAtividadesOpen = !modalCrudAtividadesOpen"
    />
  </div>
</template>

<script>
import { EventBus } from "../../services/event-bus";
import { searchAtividades } from "../../services/providers/atividadesProvider";
import {
  excluirMeta,
  getMetaById,
} from "../../services/providers/metasProvider";
import { filtrarParticipantes } from "../../services/providers/participantesProvider";
import ModalConfirmacao from "../modals/ModalConfirmacao.vue";
import ModalCrudAtividades from "../modals/ModalCrudAtividades.vue";
import ModalCrudMetas from "../modals/ModalCrudMetas.vue";
import PageTitle from "../PageTitle.vue";
import AtividadesTable from "../tables/AtividadesTable.vue";
import EquipeTable from "../tables/EquipeTable.vue";

export default {
  name: "VerMeta",
  components: {
    PageTitle,
    EquipeTable,
    ModalCrudMetas,
    ModalConfirmacao,
    ModalCrudAtividades,
    AtividadesTable,
  },
  data: () => ({
    meta: null,
    responsaveis: [],
    atividades: [],
    modalCrudMetasOpen: false,
    modalExcluirOpen: false,
    modalCrudAtividadesOpen: false,
  }),
  created() {
    this.getData();
    this.getEquipe();
    this.getAtividades();
    EventBus.$on("participante-updated", async () => {
      await this.getEquipe();
    });
    EventBus.$on("atividade-updated", async () => {
      await this.getAtividades();
    });
  },
  methods: {
    async getData() {
      try {
        const idMeta = this.$route.params.idmeta;
        this.meta = await getMetaById(idMeta);
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro interno!",
          text: error.message || error,
          type: "error",
          duration: 15000,
        });
      }
    },
    async getEquipe() {
      try {
        const idMeta = this.$route.params.idmeta;
        this.responsaveis = await filtrarParticipantes({ metas: [idMeta] });
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro interno!",
          text: error.message || error,
          type: "error",
          duration: 15000,
        });
      }
    },
    async getAtividades() {
      try {
        const idMeta = this.$route.params.idmeta;
        this.atividades = await searchAtividades({ metas: [idMeta] });
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro interno!",
          text: error.message || error,
          type: "error",
          duration: 15000,
        });
      }
    },
    async deletarMeta() {
      try {
        await excluirMeta(this.meta.id);
        this.$notify({
          title: "Meta exlcuída!",
          text: `Meta exlcuída com sucesso!`,
          type: "success",
          duration: 5000,
        });
        EventBus.$emit("meta-updated");
        this.modalExcluirOpen = false;
        this.$router.go(-1);
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro!",
          text: error.message,
          type: "error",
          duration: 15000,
        });
      }
    },
    formatNomeMeta: function() {
      return this.meta.descricao.slice(0, 40) + "...";
    },
  },
};
</script>

<style lang="scss" scoped>
.session-title {
  text-align: left !important;
  font-size: 24px !important;
  text-transform: uppercase;
  color: $primary;
  font-weight: 500;
}
</style>
