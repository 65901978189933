<template>
  <v-app>
    <NavBar v-if="loggedIn"></NavBar>
    <Drawer v-if="loggedIn"></Drawer>
    <v-main
      :class="$store.drawerOpen ? 'main-with-drawer' : 'main-without-drawer'"
    >
      <router-view></router-view>
      <notifications position="bottom right" />
    </v-main>
  </v-app>
</template>

<script>
import Drawer from "./components/Drawer";
import NavBar from "./components/NavBar";
import auth from "./services/auth";
export default {
  name: "App",
  components: {
    Drawer,
    NavBar,
  },
  data: () => ({
    loggedIn: auth.loggedIn(),
  }),
  created() {
    auth.onChange = (loggedIn) => {
      this.loggedIn = loggedIn;
    };
  },
};
</script>
<style lang="scss">
@import "./config/variables.scss";
.v-app-bar {
  width: 100% !important;
  left: 0% !important;
  height: 73px !important;
}
.v-navigation-drawer {
  top: 72px !important;
}
.v-toolbar {
  z-index: 999999999 !important;
}
.v-toolbar__content {
  padding-left: 0px !important;
  height: 72px !important;
}
.container {
  max-width: 1600px !important;
  @media (max-width: 1919px) {
    max-width: 1400px !important;
  }
}
.main-with-drawer {
  @media (max-width: 1400px) {
    padding: 64px 0px 0px 6% !important;
  }
}
.main-without-drawer {
  padding: 64px 0px 0px 0px !important;
}
.menu-header .v-list {
  display: flex;
}
.menu-header .v-list-item {
  display: flex;
  height: 73px !important;
  color: white;
}
.menu-header .v-list-item__title {
  font-family: Raleway;
  text-transform: uppercase;
  font-weight: bold !important;
  font-size: 13px !important;
}
.menu-header .v-list-item--link:before {
  background-color: #026873 !important;
}
.v-slide-group__content {
  border-bottom: 1px solid $primary !important;
}
.no-line .v-slide-group__content {
  border-bottom: none !important;
}
.v-text-field--outlined fieldset {
  border: 3px solid #e6f4f1 !important;
  border-radius: 8px !important;
}
.v-select__selections {
  line-height: 24px !important;
}
.v-select__selection--comma {
  font-size: 20px !important;
}
.select-form .v-select__selections {
  line-height: 14px !important;
}
.select-form .v-select__selection--comma {
  font-size: 14px !important;
}
.select-form {
  margin-top: 0px !important;
}
.small {
  max-width: 220px;
}
.vue-notification {
  font-size: 16px !important;
}
.v-expansion-panel::before {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.v-btn--outlined {
  border-width: medium !important;
  border-radius: 8px;
}
.highlight {
  color: none !important;
}
.v-expansion-panel .v-expansion-panel-header .theme--light.v-icon {
  color: #037f8c !important;
}
.v-select__slot .theme--light.v-icon {
  color: #037f8c !important;
}
.v-input .theme--light {
  color: #037f8c !important;
}
.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.v-text-field.v-text-field--solo .v-label {
  top: calc(50% - 15px);
  color: #037f8c !important;
  background: #e0e0e0;
  text-align: center;
  border-radius: 16px;
  font-size: 13px;
  height: 30px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding-left: 7px;
  padding-right: 7px;
}
.v-navigation-drawer {
  min-width: 100px !important;
}
.drawer .v-list {
  max-height: 75vh;
  overflow-y: auto;
}
.drawer .v-list-item {
  padding-top: 6px;
  padding-bottom: 6px;
}
.v-navigation-drawer--mini-variant .v-list-item {
  display: flex;
  justify-content: center;
}
.v-navigation-drawer--mini-variant .subitem {
  display: none;
}
.drawer .v-list-group .subitem .v-list-item {
  padding: 0px !important;
}
.drawer .v-list-group .subitem .v-list-item__icon {
  min-width: 0px !important;
}
.drawer .v-list-group .subitem .v-list-item__title {
  margin-left: 10px;
}
.drawer .v-list-group__header__append-icon {
  color: white !important;

  .theme--light.v-icon {
    color: white !important;
  }
}
tbody {
  tr:hover {
    background-color: transparent !important;
  }
}
.container-div {
  margin-left: 2%;
  margin-right: 2%;
}
.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;

  .header-button {
    padding-left: 0px !important;
  }
}
.btn-controls {
  border-radius: 8px;
  color: #fff !important;
}
.search-item-input {
  margin-top: 10px;
}
.search-item-input fieldset {
  border: 2px solid #037f8c !important;
  border-radius: 8px !important;
}
.modal-footer {
  display: flex;
  justify-content: space-evenly;
  padding-bottom: 20px;
}
.modal-title {
  display: flex;
  font-size: 20px !important;
  text-transform: uppercase;
  font-weight: bold !important;
  font-family: Raleway;
  color: $primary;
  justify-content: center;
  margin: 10px;
  border-radius: 10px;
}
.v-tab {
  color: $primary !important;
  font-weight: bold !important;
}
.v-tab--active {
  color: #ff8d3f !important;
}
.v-expansion-panels--accordion {
  z-index: 99 !important;
}
.v-dialog__content {
  top: 50px;
  max-height: 95%;
}
.label {
  margin: 0px !important;
  height: 40px;
  color: $primary;
  font-weight: 500;
  font-size: 18px;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: center;
}
.label-col {
  display: flex;
  justify-content: flex-end;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: #ffff;
}
::-webkit-scrollbar-thumb {
  background: #026873;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #026873;
}
</style>
