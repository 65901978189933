<template>
  <div style="padding-left: 20px; padding-right: 20px">
    <br />
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="title-tab" style="width: 10%">Código</th>
            <th class="title-tab" style="width: 35%">
              Grupos/Elementos de Despesa
            </th>
            <th class="title-tab" style="width: 14%">1ª Parcela</th>
            <th class="title-tab" style="width: 14%">2ª Parcela</th>
            <th class="title-tab" style="width: 14%">3ª Parcela</th>
            <th class="title-tab" style="width: 20%">TOTAL</th>
          </tr>
        </thead>
      </template>
    </v-simple-table>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <td class="subtitle-tab" style="width: 10%"></td>
            <td class="subtitle-tab" style="width: 35%">
              {{ despesasCorrentes.nome }}
            </td>
            <td
              v-if="despesasCorrentes.valorParcela1"
              class="subtitle-tab"
              style="width: 14%"
            >
              {{ despesasCorrentes.valorParcela1 | currency }}
            </td>
            <td v-else class="subtitle-tab" style="width: 14%">-</td>
            <td
              v-if="despesasCorrentes.valorParcela2"
              class="subtitle-tab"
              style="width: 14%"
            >
              {{ despesasCorrentes.valorParcela2 | currency }}
            </td>
            <td v-else class="subtitle-tab" style="width: 14%">-</td>
            <td
              v-if="despesasCorrentes.valorParcela3"
              class="subtitle-tab"
              style="width: 14%"
            >
              {{ despesasCorrentes.valorParcela3 | currency }}
            </td>
            <td v-else class="subtitle-tab" style="width: 14%">-</td>
            <td class="subtitle-tab" style="width: 20%">
              {{ despesasCorrentes.valorTotal | currency }}
            </td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(metrica, idx) in despesasCorrentes.data" v-bind:key="idx">
            <td class="text-tab">{{ metrica.codigo }}</td>
            <td class="text-tab">{{ metrica.descricao }}</td>
            <td v-if="metrica.parcela1" class="text-tab">
              {{ metrica.parcela1 | currency }}
            </td>
            <td v-else class="text-tab">-</td>
            <td v-if="metrica.parcela2" class="text-tab">
              {{ metrica.parcela2 | currency }}
            </td>
            <td v-else class="text-tab">-</td>
            <td v-if="metrica.parcela3" class="text-tab">
              {{ metrica.parcela3 | currency }}
            </td>
            <td v-else class="text-tab">-</td>
            <td class="text-tab">{{ metrica.total | currency }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <br />
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <td class="subtitle-tab" style="width: 10%"></td>
            <td class="subtitle-tab" style="width: 35%">
              {{ despesasCapital.nome }}
            </td>
            <td
              v-if="despesasCapital.valorParcela1"
              class="subtitle-tab"
              style="width: 14%"
            >
              {{ despesasCapital.valorParcela1 | currency }}
            </td>
            <td v-else class="subtitle-tab" style="width: 14%">-</td>
            <td
              v-if="despesasCapital.valorParcela2"
              class="subtitle-tab"
              style="width: 14%"
            >
              {{ despesasCapital.valorParcela2 | currency }}
            </td>
            <td v-else class="subtitle-tab" style="width: 14%">-</td>
            <td
              v-if="despesasCapital.valorParcela3"
              class="subtitle-tab"
              style="width: 14%"
            >
              {{ despesasCapital.valorParcela3 | currency }}
            </td>
            <td v-else class="subtitle-tab" style="width: 14%">-</td>
            <td class="subtitle-tab" style="width: 20%">
              {{ despesasCapital.valorTotal | currency }}
            </td>
          </tr>
        </thead>
        <tbody>
          <tr></tr>
          <tr v-for="(metrica, idx) in despesasCapital.data" v-bind:key="idx">
            <td class="text-tab">{{ metrica.codigo }}</td>
            <td class="text-tab">{{ metrica.descricao }}</td>
            <td v-if="metrica.parcela1" class="text-tab">
              {{ metrica.parcela1 | currency }}
            </td>
            <td v-else class="text-tab">-</td>
            <td v-if="metrica.parcela2" class="text-tab">
              {{ metrica.parcela2 | currency }}
            </td>
            <td v-else class="text-tab">-</td>
            <td v-if="metrica.parcela3" class="text-tab">
              {{ metrica.parcela3 | currency }}
            </td>
            <td v-else class="text-tab">-</td>
            <td class="text-tab">{{ metrica.total | currency }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <br />
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <td class="subtitle-tab" style="width: 10%"></td>
            <td class="subtitle-tab" style="width: 35%">Total Geral</td>
            <td class="subtitle-tab" style="width: 14%">
              {{
                (despesasCorrentes.valorParcela1 +
                  despesasCapital.valorParcela1)
                  | currency
              }}
            </td>
            <td class="subtitle-tab" style="width: 14%">
              {{
                (despesasCorrentes.valorParcela2 +
                  despesasCapital.valorParcela2)
                  | currency
              }}
            </td>
            <td class="subtitle-tab" style="width: 14%">
              {{
                (despesasCorrentes.valorParcela3 +
                  despesasCapital.valorParcela3)
                  | currency
              }}
            </td>
            <td class="subtitle-tab" style="width: 20%">
              {{
                (despesasCorrentes.valorTotal + despesasCapital.valorTotal)
                  | currency
              }}
            </td>
          </tr>
        </thead>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  name: "CronogramaDesembolsoTable",
  props: {
    metricas: Array,
  },
  data: () => ({
    rubricas: [],
    despesasCorrentes: {},
    despesasCapital: {},
  }),
  async mounted() {
    if (this.metricas) {
      const metricasCronograma = this.metricas.filter((m) =>
        m.fonte ? m.fonte.codigo == "F" : true
      );

      const rubricasPorCodigo = [];

      metricasCronograma.forEach(
        (metrica) =>
          (metrica.rubrica.codigoImportacao =
            metrica.rubrica.codigo + (metrica.rubrica.importado ? "-I" : "-N"))
      );

      metricasCronograma.forEach((metrica) => {
        if (
          !rubricasPorCodigo.some(
            (r) => r.codigoImportacao == metrica.rubrica.codigoImportacao
          )
        ) {
          rubricasPorCodigo.push({
            codigo: metrica.rubrica.codigo,
            codigoImportacao: metrica.rubrica.codigoImportacao,
            descricao: metrica.rubrica.descricaoCompleta,
          });
        }
      });

      rubricasPorCodigo.forEach((rubrica) => {
        rubrica.parcela1 = metricasCronograma
          .filter(
            (m) =>
              m.rubrica.codigoImportacao == rubrica.codigoImportacao &&
              m.parcela.numero == 1
          )
          .reduce((total, metrica) => (total += metrica.valorAlocado), 0);
        rubrica.parcela2 = metricasCronograma
          .filter(
            (m) =>
              m.rubrica.codigoImportacao == rubrica.codigoImportacao &&
              m.parcela.numero == 2
          )
          .reduce((total, metrica) => (total += metrica.valorAlocado), 0);
        rubrica.parcela3 = metricasCronograma
          .filter(
            (m) =>
              m.rubrica.codigoImportacao == rubrica.codigoImportacao &&
              m.parcela.numero == 3
          )
          .reduce((total, metrica) => (total += metrica.valorAlocado), 0);
        rubrica.total = rubrica.parcela1 + rubrica.parcela2 + rubrica.parcela3;
      });

      const despesasCorrentesData = rubricasPorCodigo.filter(
        (rubrica) => rubrica.codigoImportacao.split(".")[0] == "33"
      );

      this.despesasCorrentes = {
        nome: "DESPESAS CORRENTES",
        data: despesasCorrentesData,
        valorParcela1: despesasCorrentesData
          .map((r) => r.parcela1)
          .reduce((total, valor) => (total += valor), 0),
        valorParcela2: despesasCorrentesData
          .map((r) => r.parcela2)
          .reduce((total, valor) => (total += valor), 0),
        valorParcela3: despesasCorrentesData
          .map((r) => r.parcela3)
          .reduce((total, valor) => (total += valor), 0),
      };
      this.despesasCorrentes.valorTotal =
        this.despesasCorrentes.valorParcela1 +
        this.despesasCorrentes.valorParcela2 +
        this.despesasCorrentes.valorParcela3;

      const despesasCapitalData = rubricasPorCodigo.filter(
        (rubrica) => rubrica.codigo.split(".")[0] == "44"
      );

      this.despesasCapital = {
        nome: "DESPESAS DE CAPITAL",
        data: despesasCapitalData,
        valorParcela1: despesasCapitalData
          .map((r) => r.parcela1)
          .reduce((total, valor) => (total += valor), 0),
        valorParcela2: despesasCapitalData
          .map((r) => r.parcela2)
          .reduce((total, valor) => (total += valor), 0),
        valorParcela3: despesasCapitalData
          .map((r) => r.parcela3)
          .reduce((total, valor) => (total += valor), 0),
      };
      this.despesasCapital.valorTotal =
        this.despesasCapital.valorParcela1 +
        this.despesasCapital.valorParcela2 +
        this.despesasCapital.valorParcela3;
    }
  },
};
</script>

<style lang="scss" scoped>
.title-tab {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 16px;
  text-align: center !important;
  color: #616161 !important;
  border: 0.899715px solid rgba(97, 97, 97, 0.3);
}
.subtitle-tab {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 16px;
  text-align: center !important;
  color: #037f8c !important;
  background-color: #e6f4f1;
  border: 0.899715px solid rgba(97, 97, 97, 0.3);
  margin-bottom: 5px;
}
.text-tab {
  font-family: Roboto;
  font-style: normal;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 13px;
  text-align: center;
  color: #616161;
  border: 0.899715px solid rgba(97, 97, 97, 0.3);
  box-sizing: border-b;
}
</style>
