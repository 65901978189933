<template >

  <div class="container-div">
    <div class="header">
      <v-btn class="header-button" color="white" text @click="$router.go(-1)">
        <v-icon large color="#037F8C">mdi-arrow-left-circle-outline</v-icon>
        <PageTitle
          :title="`EQUIPE/<b>INFORMAÇÕES DO PARTICIPANTE</b>`"
          size="18"
        />
      </v-btn>
      <div>
        <v-btn class="btn-controls"
          color="#037F8C"
          depressed
          width="260"
          @click.prevent="
            (modalEditarOpen = !modalEditarOpen) &
              (participanteSelecionado = participante)
          "
        >
          <p>Editar Informações</p>
        </v-btn>
        <br>
        <v-btn class="mt-3"
          outlined
          color="#037F8C"
          width="260"
          @click="
            (modalDesassociarOpen = !modalDesassociarOpen) &
              (participanteSelecionado = participante)
          "
        >
          <p>Desassociar participante</p>
        </v-btn>
      </div>
    </div>
    <br />
    <br />
    <div>
      <!-- Dados Gerais -->
      <div>
        <div class="session">DADOS GERAIS</div>
        <br />
        <div style="margin-left: 20px">
          <v-row>
            <v-col class="t-label-col" md="2">
              <p class="t-label">Tipo do participante:</p>
            </v-col>
            <v-col>
              <p
                v-if="participante && participante.tipoParticipante"
                class="participante-t-label"
              >
                {{ participante.tipoParticipante }}
              </p>
              <p v-else>-</p>
            </v-col>
          </v-row>
          <hr />
          <v-row>
            <v-col class="t-label-col" md="1">
              <p class="t-label">Nome:</p>
              <br>
              <p class="t-label">CPF:</p>
            </v-col>
            <v-col class="t-label-col" md="3">
              <p
                v-if="participante && participante.pessoa && participante.pessoa.nome"
                class="participante-t-label"
              >
                {{ participante.pessoa.nome }}
              </p>
              <p v-else>-</p>
              <br>
              <p
                v-if="participante && participante.pessoa && participante.pessoa.cpf"
                class="participante-t-label"
              >
                {{ participante.pessoa.cpf }}
              </p>
              <p v-else>-</p>
            </v-col>
            <v-col class="t-label-col" md="1">
              <p class="t-label">Telefone:</p>
              <br>
              <p class="t-label">Telefone:</p>
            </v-col>
            <v-col class="t-label-col" md="3">
              <p
                v-if="participante && participante.pessoa && participante.pessoa.telefonePrincipal"
                class="participante-t-label"
              >
                {{ participante.pessoa.telefonePrincipal }}
              </p>
              <p v-else>-</p>
              <br>
              <p
                v-if="participante && participante.pessoa && participante.pessoa.telefoneSecundario"
                class="participante-t-label"
              >
                {{ participante.pessoa.telefoneSecundario }}
              </p>
              <p v-else>-</p>
            </v-col>
            <v-col class="t-label-col" md="1">
              <p class="t-label">Email:</p>
            </v-col>
            <v-col class="t-label-col" md="3">
            <p
                v-if="participante && participante.pessoa && participante.pessoa.email"
                class="participante-t-label"
              >
                {{ participante.pessoa.email }}
              </p>
              <p v-else>-</p>
            </v-col>
          </v-row>
          <hr />
          <v-row>
            <v-col class="t-label-col" md="1">
              <p class="t-label">Matrícula:</p>
            </v-col>
            <v-col class="t-label-col" md="3">
              <p
                v-if="participante && participante.pessoa && participante.pessoa.matricula"
                class="participante-t-label"
              >
                {{ participante.pessoa.matricula }}
              </p>
              <p v-else>-</p>
            </v-col>
            <v-col class="t-label-col" md="1">
              <p class="t-label">Departamento Vinculado:</p>
            </v-col>
            <v-col class="t-label-col" md="5">
              <p
                v-if="participante && participante.pessoa && participante.pessoa.departamento"
                class="participante-t-label"
              >
                {{ participante.pessoa.departamento }}
              </p>
              <p v-else>-</p>
            </v-col>
          </v-row>
          <hr>
          <br />
        </div>
      </div>
      <br />
      <!-- Informações da função -->
      <div>
        <div class="session">INFORMAÇÕES DA FUNÇÃO</div>
        <br />
        <div style="margin-left: 20px">
          <v-row>
            <v-col class="t-label-col" md="1">
              <p class="t-label">Subprojeto:</p>
            </v-col>
            <v-col md="3">
              <p
                v-if="participante && participante.subprojeto"
                class="participante-t-label"
              >
                {{ participante.subprojeto.codigo }}
              </p>
              <p v-else>-</p>
            </v-col>
            <v-col class="t-label-col" md="2">
              <p class="t-label">Tipo de equipe:</p>
            </v-col>
            <v-col md="4">
              <p
                v-if="participante && participante.tipoEquipe"
                class="participante-t-label"
              >
                {{ participante.tipoEquipe }}
              </p>
              <p v-else>-</p>
            </v-col>
          </v-row>
          <hr />
          <v-row>
            <v-col class="t-label-col" md="1">
              <p class="t-label">Função:</p>
            </v-col>
            <v-col md="3">
              <p
                v-if="participante && participante.funcao"
                class="participante-t-label"
              >
                {{ funcaoFormatted }}
              </p>
              <p v-else>-</p>
            </v-col>
            <v-col class="t-label-col" md="2">
              <p class="t-label">Horas/Semana</p>
            </v-col>
            <v-col md="4">
              <p
                v-if="participante && participante.horaSemana"
                class="participante-t-label"
              >
                {{ participante.horaSemana }}
              </p>
              <p v-else>-</p>
            </v-col>
          </v-row>
          <hr />
          <br />
        </div>
      </div>
      <br />
      <div class="session">METAS</div>
      <div>
        <MetasTable
          v-if="participante && participante.metas && participante.metas.length && forceReload"
          :metas="participante.metas"
          :showActions="false"
          :key="forceReload"
        />
        <p v-else>
          Ainda não há metas associadas a esse participante
        </p>
      </div>
      <br />
      <br />
      <div class="session">ITENS</div>
      <br />
      <div>
        <itens-table
          v-if="participante && participante.itens.length && forceReload"
          :itens="participante.itens"
          :showActions="false"
          :key="forceReload"
        />
      </div>
      <br />
      <br />
    </div>
    <ModalConfirmacao
      :modalVisible="modalDesassociarOpen"
      :titulo="'Você realmente deseja dissociar este participante da equipe? O participante não será mais responsável pelos itens em que estava associado'"
      textoBotaoDireito="Desassociar"
      :onConfirm="desassociarParticipante"
      @close="modalDesassociarOpen = !modalDesassociarOpen"
    />
    <ModalCrudParticipante
      v-if="participanteSelecionado"
      :modalVisible="modalEditarOpen"
      :participante="participanteSelecionado"
      @close="forceRender"
      @participante-updated="forceRender"
    />
  </div>

</template>

<script>
import { getParticipantePorId } from "../../services/providers/participantesProvider";
import PageTitle from "../PageTitle.vue";
import ItensTable from "../tables/ItensTable.vue";
import MetasTable from "../tables/MetasTable.vue";
import ModalConfirmacao from "../modals/ModalConfirmacao.vue";
import { removerParticipante } from "../../services/providers/participantesProvider";
import { EventBus } from "../../services/event-bus";
import ModalCrudParticipante from "../modals/ModalCrudParticipante.vue";


export default {
  name: "VerParticipante",
  components: {
    "itens-table": ItensTable, // Evita bug de dependencia recursiva
    MetasTable,
    PageTitle,
    ModalConfirmacao,
    ModalCrudParticipante,
  },
  data: () => ({
    participante: null,
    modalDesassociarOpen: false,
    participanteSelecionado: null,
    modalEditarOpen: false,
    forceReload: true,
  }),

  async mounted() {
    window.scroll(0,0);
    try {
      await this.getData();
    } catch (error) {
      console.error(error);
      this.$notify({
        title: "Erro interno!",
        text: error.message || error,
        type: "error",
        duration: 15000,
      });
    }
  },
  methods: {
    async desassociarParticipante() {
      try {
        await removerParticipante(this.participante.id);
        this.$notify({
          title: "Participante desassociado!",
          text: `Participante desassociado com sucesso!`,
          type: "success",
          duration: 5000,
        });
        EventBus.$emit("participante-updated");
        this.$router.go(-1);
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro!",
          text: error.message,
          type: "error",
          duration: 15000,
        });
      }
    },
    async getData() {

      const test = await getParticipantePorId(
        this.$route.params.idparticipante
      );
      console.log(test);

      this.participante = test;
    },
    async forceRender() {
      console.log("Antes de atualizar");
      this.modalEditarOpen = !this.modalEditarOpen;
      this.participante = await getParticipantePorId(
        this.$route.params.idparticipante
      );

      this.forceReload = false;
      this.participanteSelecionado = null;
      this.$nextTick(() => {
        this.participanteSelecionado = this.participante;
        this.forceReload = true;
      });
      console.log("Depois de atualizar");
    }
  },
  computed: {
    funcaoFormatted() {
      if (this.participante && this.participante.funcao)
        if ( this.participante.funcao  ==  'COORDENADOR_GERAL')
          return 'COORDENADOR GERAL'
        else if (this.participante.funcao  === 'APOIO_ADMINISTRATIVO')
          return 'APOIO ADMINISTRATIVO';
        else if (this.participante.funcao  === 'COORDENADOR_SUBPROJETO')
          return 'COORDENADOR DE SUBPROJETO';
        else if (this.participante.funcao  === 'APOIO_TECNICO')
          return 'APOIO TÉCNICO';
        else
          return 'PESQUISADOR';
      else 
        return '-';
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-title {
  font-size: 24px !important;
  text-transform: uppercase;
  color: $primary;
  font-weight: 500;
}
.session-title {
  text-align: center !important;
  font-size: 24px !important;
  text-transform: uppercase;
  color: $primary;
  font-weight: 500;
}
.session {
  width: 100%;
  padding: 15px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  display: flex;
  color: #037f8c;
}
.t-label {
  color: #616161;
  font-size: 18px;
  line-height: 23px;
  text-align: left;
  font-weight: bold;
  margin: 0px;
  margin-right: 10px;
}
.participante-t-label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  color: #616161;
  text-align: justify;
  white-space: pre-line;
}
hr {
  background-color: #c4c4c4 !important;
}
p {
  margin: 0px;
}
.title-tab {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 16px !important;
  line-height: 23px;
  color: $primary !important;
  background-color: #e6f4f1;
  border: 0.899715px solid rgba(97, 97, 97, 0.3);
  height: 45px !important;
}
.text-tab {
  font-family: Roboto;
  font-style: normal;
  font-size: 13px;
  line-height: 13px;
  color: #616161;
  border: 0.899715px solid rgba(97, 97, 97, 0.3);
  height: 45px !important;
}
</style>
