<template>
  <div>
    <br />
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="title-tab" style="width: 5%">Nº</th>
            <th class="title-tab" style="width: 30%">NOME</th>
            <th class="title-tab" style="width: 15%">TELEFONE</th>
            <th class="title-tab">E-MAIL</th>
            <th class="title-tab" style="width: 15%">FUNÇÃO</th>
            <th class="title-tab">HORA/SEMANA</th>
            <th class="title-tab"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, idx) in participantes" v-bind:key="item.id">
            <td v-if="item.ativo" class="text-tab">
              {{ idx + 1 }}
            </td>
            <td v-else class="text-tab desassociado">
              {{ idx + 1 }}
            </td>

            <td v-if="item.pessoa && item.ativo" class="text-tab">
              {{ item.pessoa.nome }}
            </td>
            <td v-else-if="item.pessoa && !(item.ativo)" class="text-tab desassociado">
              {{ item.pessoa.nome }}
            </td>
            <td v-else class="text-tab">
              -
            </td>

            <td v-if="item.pessoa && item.ativo" class="text-tab">
              {{ item.pessoa.telefonePrincipal | VMask("(##) #####-####") }}
            </td>
            <td v-else-if="item.pessoa && !(item.ativo)" class="text-tab desassociado">
              {{ item.pessoa.telefonePrincipal | VMask("(##) #####-####") }}
            </td>
            <td v-else class="text-tab">
              -
            </td>

            <td v-if="item.pessoa && item.ativo" class="text-tab-email">
              {{ item.pessoa.email }}
            </td>
            <td v-else-if="item.pessoa && !(item.ativo)" class="text-tab-email desassociado">
              {{ item.pessoa.email }}
            </td>
            <td v-else class="text-tab">
              -
            </td>

            <td v-if="item.funcao && item.ativo" class="text-tab">
              {{ FUNCAO_PARTICIPANTE[item.funcao] }}
            </td>
            <td v-else-if="item.funcao && !(item.ativo)" class="text-tab desassociado">
              {{ FUNCAO_PARTICIPANTE[item.funcao]}}
            </td>
            <td v-else class="text-tab">
              -
            </td>

            <td v-if="item.horaSemana && item.ativo" class="text-tab">
              {{ item.horaSemana }}
            </td>
            <td v-else-if="item.horaSemana && !(item.ativo)" class="text-tab desassociado">
              {{  item.horaSemana }}
            </td>

            <td
              class="text-tab"
              style="width: 10%; padding-left: 0px; padding-right: 0px"
            >
              <div style="display: flex; flex-direction: row">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      small
                      text
                      @click="goToParticipante(item)"
                      class="option-hover"
                      style="margin: auto;"
                    >
                      <v-icon color="#ff8d3f"
                        >mdi-arrow-right-bold-circle</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>
                    Visualizar
                  </span>
                </v-tooltip>
                <v-tooltip v-if="showActionButtons && item.ativo" top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      text
                      small
                      class="option-hover"
                      @click="
                        (modalDesassociarOpen = !modalDesassociarOpen) &
                          (participanteSelecionado = item)
                      "
                    >
                      <v-icon color="#ff8d3f">mdi-account-minus-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>
                    Desassociar
                  </span>
                </v-tooltip>
                <v-tooltip v-if="showActionButtons && !item.ativo" top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      text
                      small
                      class="option-hover"
                      @click="
                        (modalReativar = !modalReativar) &
                          (participanteSelecionado = item)
                      "
                    >
                      <v-icon color="#ff8d3f">mdi-account-convert-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>
                    REATIVAR
                  </span>
                </v-tooltip>
                <v-tooltip v-if="showActionButtons" top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      text
                      small
                      class="option-hover"
                      @click="
                        (modalExcluirOpen = !modalExcluirOpen) &
                          (participanteSelecionado = item)
                      "
                    >
                      <v-icon color="#ff8d3f">mdi-delete-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>
                    Excluir
                  </span>
                </v-tooltip>
                <v-tooltip v-if="showDesassociarButton" top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      text
                      small
                      class="option-hover"
                      @click="$emit('desassociarClicked', item)"
                    >
                      <v-icon color="#ff8d3f">mdi-account-minus</v-icon>
                    </v-btn>
                  </template>
                  <span>
                    Desassociar do item
                  </span>
                </v-tooltip>
              </div>
            </td>
          </tr>
          <td colspan="7" class="div-body">
            <div
              class="text-body"
              style="border-right: 0.899715px solid rgba(97, 97, 97, 0.3)"
            >
              TOTAL DE MEMBROS: {{ participantes.length }}
            </div>
          </td>
        </tbody>
      </template>
    </v-simple-table>
    <ModalCrudParticipante
      :key="String(modalCrudOpen) + 'modalCrud'"
      :modalVisible="modalCrudOpen"
      :participante="participanteSelecionado"
      @close="modalCrudOpen = !modalCrudOpen"
    />
    <ModalConfirmacao
      v-if="participanteSelecionado"
      :modalVisible="modalDesassociarOpen"
      :titulo="
        'Você realmente deseja dissociar este participante da equipe? O participante não será mais responsável pelos itens em que estava associado'
      "
      textoBotaoDireito="Desassociar"
      :onConfirm="desassociarParticipante"
      @close="modalDesassociarOpen = !modalDesassociarOpen"
    />
    <ModalConfirmacao
      v-if="participanteSelecionado"
      :modalVisible="modalExcluirOpen"
      :titulo="
        'Ao excluir um participante desassociado ele será completamente desligado do projeto. Deseja excluir'
      "
      textoBotaoDireito="EXCLUIR"
      :onConfirm="excluirParticipante"
      @close="modalExcluirOpen = !modalExcluirOpen"
    />
    <ModalConfirmacao
      v-if="participanteSelecionado"
      :modalVisible="modalReativar"
      :titulo="
        'Voce gostaria de reativar esse participante'
      "
      textoBotaoDireito="reativar"
      :onConfirm="reativarParticipante"
      @close="modalReativar = !modalReativar"
    />
  </div>
</template>

<script>
import ModalCrudParticipante from "../modals/ModalCrudParticipante.vue";
import { FUNCAO_PARTICIPANTE } from "../../utils/enums";
import ModalConfirmacao from "../modals/ModalConfirmacao.vue";
import { EventBus } from "../../services/event-bus";
import { removerParticipante, excluirParticipante, reativarParticipante } from "../../services/providers/participantesProvider";

export default {
  name: "EquipeTable",
  components: {
    ModalCrudParticipante,
    ModalConfirmacao,
  },
  props: {
    participantes: Array,
    showActionButtons: {
      type: Boolean,
      default: true,
    },
    showDesassociarButton: Boolean,
  },
  data: () => ({
    participanteSelecionado: null,
    modalExcluirOpen: false,
    modalVerOpen: false,
    modalCrudOpen: false,
    modalReativar: false,
    modalDesassociarOpen: false,
    FUNCAO_PARTICIPANTE: FUNCAO_PARTICIPANTE,
  }),
  async mounted(){
  },
  methods: {
    goToParticipante(participante) {
      if (participante) {
        this.$router.push({
          path: `/projetos-vigentes/${this.$store.projeto.id}/equipe/${participante.id}/`,
        });
      }
    },
    async desassociarParticipante() {
      try {
        await removerParticipante(this.participanteSelecionado.id);
        this.$notify({
          title: "Participante desassociado!",
          text: `Participante desassociado com sucesso!`,
          type: "success",
          duration: 5000,
        });
        EventBus.$emit("participante-updated");
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro!",
          text: error.message,
          type: "error",
          duration: 15000,
        });
      }
    },
    async excluirParticipante() {
      try {
        await excluirParticipante(this.participanteSelecionado.id);
        this.$notify({
          title: "Participante excluído!",
          text: `Participante excluído com sucesso!`,
          type: "success",
          duration: 5000,
        });
        this.$emit('update');
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro!",
          text: error.message,
          type: "error",
          duration: 15000,
        });
      }
    },
    async reativarParticipante() {
      try {
        await reativarParticipante(this.participanteSelecionado.id);
        this.$notify({
          title: "Participante reativado!",
          text: `Participante reativado com sucesso!`,
          type: "success",
          duration: 5000,
        });
        this.$emit('update');
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro!",
          text: error.message,
          type: "error",
          duration: 15000,
        });
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.title-tab {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 16px;
  text-align: center !important;
  color: $primary !important;
  background-color: #e6f4f1;
  border: 0.899715px solid rgba(97, 97, 97, 0.3);
  height: 45px !important;
}
.text-tab-email {
  font-family: Roboto;
  font-style: normal;
  font-size: 13px;
  line-height: 13px;
  text-align: center;
  color: #616161;
  border: 0.899715px solid rgba(97, 97, 97, 0.3);
  height: 45px !important;
}

.text-tab {
  font-family: Roboto;
  font-style: normal;
  text-transform: uppercase;
  line-height: 1.2em;
  font-size: 13px;
  text-align: center;
  color: #616161;
  border: 0.899715px solid rgba(97, 97, 97, 0.3);
  height: 45px !important;
}
.div-body {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 16px;
  text-align: center !important;
  color: $primary !important;
  padding: 0px;
}
.text-body {
  display: flex;
  height: 45px;
  padding: 10px;
  width: 100%;
  background-color: #e6f4f1;
  text-align: center;
  border: 0.899715px solid rgba(97, 97, 97, 0.3);
  border-left: none;
  border-right: none;
}
.desassociado {
  color:#B8B8B8;
}
</style>
