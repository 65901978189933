<template>
  <v-dialog v-model="modalVisible" persistent max-width="800">
    <v-card style="border-radius: 10px">
      <br />
      <v-card-title class="modal-title">ATUALIZAR RENDIMENTO</v-card-title>
      <v-card-text>
        <br />
        <v-row>
          <v-col class="label-col" md="2">
            <p class="label" style="margin-top: 0px">Rendimento até o dia:</p>
          </v-col>
          <v-col md="4">
            <DatePicker v-model="data" />
          </v-col>
          <v-col class="label-col" md="1">
            <p class="label">R$:</p>
          </v-col>
          <v-col md="5">
            <v-currency-field
              prefix="R$"
              v-model="price"
              outlined
              dense
            ></v-currency-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions
        style="
          display: flex;
          justify-content: space-evenly;
          padding-bottom: 20px;
        "
      >
        <v-btn outlined width="190" color="#FF8D3F" @click="closeModal(false)"
          >Cancelar</v-btn
        >
        <v-btn
          depressed
          width="190"
          class="btn-controls"
          color="#FF8D3F"
          @click="atualizarRendimento"
          >Salvar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DatePicker from "../DatePicker";
import {
  atualizarRendimentosProjeto,
  getProjetoById,
} from "../../services/providers/projetosProvider";
export default {
  name: "ModalAttRendimento",
  props: {
    projeto: Object,
    modalVisible: Boolean,
  },
  components: {
    DatePicker,
  },
  data: () => ({
    price: 0,
    data: null,
  }),
  mounted() {
    if (this.projeto) {
      this.price = this.projeto.totalDeRendimentos;
      this.data = new Date().toISOString().slice(0, 10);
    }
  },
  methods: {
    closeModal() {
      this.price = 0;
      this.data = null;
      this.$emit("close");
    },
    async atualizarRendimento() {
      try {
        const payload = {
          projetoId: this.projeto.id,
          valor: this.price,
          data: this.data + " 00:00:00",
        };
        await atualizarRendimentosProjeto(payload);
        const projeto = await getProjetoById(this.projeto.id);
        if (projeto) {
          this.$actions.setProjeto(projeto);
        }
        this.$notify({
          title: "Rendimento atualizado!",
          text: "Rendimento atualizado com sucesso!",
          type: "success",
          duration: 5000,
        });
        this.closeModal(true);
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro ao atualizar rendimento!",
          text: error.message,
          type: "error",
          duration: 15000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.add-recebido-label {
  background: #e6f4f1;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #037f8c;
  padding: 5px;
  border-radius: 10px;
  margin-right: 5px;
  margin-top: 10px;
}
</style>
