var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',{staticClass:"session-title"},[_vm._v("DESEMBOLSO FNDCT")]),_c('v-row',_vm._l((_vm.parcelas),function(parcela){return _c('v-col',{key:parcela.id,attrs:{"cols":4}},[_c('div',{staticClass:"box-chart",staticStyle:{"height":"10px"}},[_c('h1',{staticClass:"box-header"},[_vm._v(_vm._s(parcela.parcela.numero)+"ª PARCELA")]),_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('h2',{staticClass:"chart-label"},[_vm._v("PREVISTO")]),_c('p',{staticClass:"value-label"},[_vm._v(" "+_vm._s(_vm._f("currency")(parcela.valorAutorizado))+" ")])]),_c('v-col',{attrs:{"md":"6"}},[_c('h2',{staticClass:"chart-label"},[_vm._v("DESEMBOLSADO")]),_c('p',{staticClass:"value-label"},[_vm._v(" "+_vm._s(_vm._f("currency")(parcela.valorDesembolsado))+" ")])])],1),_c('v-row',[_c('v-col',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({staticClass:"progress-bar",attrs:{"id":'progress-line-' + parcela.id}},on)),_c('div',{staticStyle:{"display":"flex","justify-content":"flex-end"}},[_c('p',{staticClass:"chart-label"},[_vm._v(" "+_vm._s(_vm._f("percent")(parcela.percentualDesembolsado))+" ")])])]}}],null,true)},[_c('span',{staticStyle:{"text-align":"left","text-transform":"uppercase","font-size":"13px","font-weight":"bold"}},[_vm._v(" Desembolso: "+_vm._s(_vm._f("percent")(parcela.percentualDesembolsado))+" ")])])],1)],1)],1)])}),1),_c('v-row',[_c('v-col',{staticStyle:{"width":"25% !important"}},[_c('div',{staticClass:"box-chart-main"},[_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('v-row',[_c('v-col',[_c('h1',{staticClass:"box-header-main"},[_vm._v("TOTAL")])])],1),_c('v-row',[_c('v-col',{attrs:{"md":"6"}},[_c('h2',{staticClass:"col-header-main"},[_vm._v("PREVISTO")]),_c('p',{staticClass:"content-main-box"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.projeto.valorAutorizado))+" ")])]),_c('v-col',{attrs:{"md":"6"}},[_c('h2',{staticClass:"col-header-main"},[_vm._v("DESEMBOLSADO")]),_c('p',{staticClass:"content-main-box"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.projeto.valorDesembolsado))+" ")])])],1),_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"btn-controls mt-6",staticStyle:{"margin-right":"10px"},attrs:{"depressed":"","color":"#FF8D3F","width":"250"},on:{"click":function($event){_vm.modalSolicitarDesembolsoOpen = !_vm.modalSolicitarDesembolsoOpen}}},[_c('v-icon',{staticStyle:{"margin-right":"10px"}},[_vm._v("mdi-cash-multiple")]),_vm._v(" REGISTRAR DESEMBOLSO ")],1)],1)],1)],1),_c('v-col',{staticClass:"ma-0 pa-0",staticStyle:{"height":"300px","position":"relative"},attrs:{"md":"6"}},[_c('v-tooltip',{attrs:{"top":"","nudge-left":"200","nudge-bottom":"60"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',{attrs:{"id":"total-desembolsado"}}),_c('div',_vm._g(_vm._b({staticClass:"total-desembolso-div",attrs:{"align-center":""}},'div',attrs,false),on),[(_vm.projeto && _vm.projeto.valorDesembolsado)?_c('p',{staticClass:"total-desembolso-label"},[_vm._v(" "+_vm._s(_vm._f("percent")(((_vm.projeto.valorDesembolsado / _vm.projeto.valorAutorizado) * 100)))+" ")]):_c('p',{staticClass:"total-desembolso-label"},[_vm._v(" 0% ")])])]}}])},[(
                  100 -
                    (_vm.projeto.valorDesembolsado / _vm.projeto.valorAutorizado) *
                      100 !=
                    0
                )?_c('div',[_c('span',{staticStyle:{"text-align":"left","text-transform":"uppercase","font-size":"13px","font-weight":"bold"}},[_vm._v(" À desembolsar: "+_vm._s(_vm._f("percent")((100 - (_vm.projeto.valorDesembolsado / _vm.projeto.valorAutorizado) * 100)))+" ")])]):_c('div',{staticStyle:{"text-align":"left","text-transform":"uppercase","font-size":"13px","font-weight":"bold"}},[_c('span',[_vm._v(" À desembolsar: 0% ")])])])],1)],1)],1)])],1),_c('ModalSolicitacaoDesembolso',{attrs:{"modalVisible":_vm.modalSolicitarDesembolsoOpen},on:{"close":function($event){_vm.modalSolicitarDesembolsoOpen = !_vm.modalSolicitarDesembolsoOpen}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }