<template>
  <div>
    <div v-if="isExactActive" class="container-div">
      <div
        v-if="this.$route.query.isCadastro"
        style="
          margin-top: 12px;
          margin-bottom: 12px;
          display: flex;
          justify-content: flex-end;
        "
      >
        <div class="reformulacao-div">
          <v-row>
            <v-col md="8">
              <p class="reformulacao-title">
                Cadastro de Projetos - Etapa 5: Equipes
              </p>
              <p class="reformulacao-sub-title">
                Ao concluir o cadastro de Equipes clique no botão para retornar
                o cadastro de projeto.
              </p>
            </v-col>
            <v-col
              md="4"
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <v-btn
                class="btn-controls"
                depressed
                color="#FF8D3F"
                width="250"
                large
                :to="{
                  path: `/cadastro-projetos/${$store.projeto.id}/editar-cadastro/`,
                  query: {currentTab: 4}
                }"
              >
                RETORNAR CADASTRO</v-btn
              >
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="header">
        <v-btn
          class="header-button"
          large
          color="white"
          text
          @click="decidirDestino"
        >
          <v-icon large color="#037F8C">mdi-arrow-left-circle-outline</v-icon>
          <PageTitle :title="'<b>EQUIPE</b>'" size="18" />
        </v-btn>
        <span>
          <v-btn
            depressed
            color="#026873"
            class="btn-controls"
            style="margin-right: 15px"
            @click="modalCrudParticipanteOpen = !modalCrudParticipanteOpen"
            >CADASTRAR PARTICIPANTE</v-btn
          >
        </span>
      </div>
      <br />
      <v-row>
        <v-col md="4">
          <v-text-field
            v-model="camposTextuais"
            class="search-item-input"
            outlined
            dense
            clearable
            label="Pesquisar..."
            append-icon="mdi-magnify"
            color="#037F8C"
            v-on:keyup.enter="filtrarParticipantes"
            @click:clear="limparFiltros"
            @click:append="filtrarParticipantes"
          >
          </v-text-field>
        </v-col>
        <v-col
          md="8"
          :style="{ marginTop: opcoesSelecionadas.length ? '-20px' : '-12px' }"
          class="filter"
        >
          <v-expansion-panels v-model="panel" accordion>
            <v-expansion-panel>
              <v-expansion-panel-header
                style="
                  border-bottom: 1px solid #037f8c;
                  padding-bottom: 8px;
                  justify-content: flex-start;
                "
              >
                <p class="filter-title">Filtros:</p>
                <div class="chips-content">
                  <div v-for="op in opcoesSelecionadas" :key="op">
                    <div class="chip">
                      <span>{{ op }}</span>
                    </div>
                  </div>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col class="label-col label" md="2">Subprojeto:</v-col>
                  <v-col>
                    <v-select
                      class="select-form"
                      outlined
                      hide-details
                      clearable
                      small-chips
                      multiple
                      dense
                      color="#037F8C"
                      item-color="#037F8C"
                      :items="subprojetos"
                      item-text="label"
                      item-value="value"
                      label="Todos os subprojetos"
                      solo
                      v-model="subProjetoSelecionados"
                      ><template v-slot:prepend-item>
                        <v-list-item
                          ripple
                          @click="
                            subProjetoSelecionados = selecionarTodasOpcoes(
                              subProjetoSelecionados,
                              subprojetos
                            )
                          "
                        >
                          <v-list-item-action>
                            <v-icon>
                              {{
                                subProjetoSelecionados.length ==
                                subprojetos.length
                                  ? "mdi-close-box"
                                  : "mdi-checkbox-blank-outline"
                              }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              Selecionar todos
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="5"></v-col>
                  <v-col
                    style="
                      display: flex;
                      flex-direction: row;
                      justify-content: space-between;
                    "
                    md="7"
                  >
                    <v-btn
                      small
                      outlined
                      width="190"
                      color="#037f8c"
                      @click="limparFiltros"
                      >Limpar filtros</v-btn
                    >
                    <v-btn
                      small
                      depressed
                      width="190"
                      class="btn-controls"
                      color="#037f8c"
                      @click="filtrarParticipantes"
                      >Aplicar filtros</v-btn
                    >
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <v-row v-if="!Object.keys(participantesPorTipo).length">
        <v-col style="display: flex; justify-content: center">
          <div>
            <img src="../../assets/ilustracao_home.png" height="500" />
            <PageTitle
              title="Filtre equipes por subprojetos e visualize os membros e interessados"
            />
          </div>
        </v-col>
      </v-row>
      <v-row v-for="(tipo, i) in Object.keys(participantesPorTipo)" :key="i">
        <v-col>
          <v-expansion-panels :value="0">
            <v-expansion-panel>
              <v-expansion-panel-header
                style="border-bottom: 1px solid #037f8c; padding-bottom: 8px"
              >
                <p class="filter-title">{{ TIPO_PARTICIPANTE[tipo] }}</p>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="participantesPorTipo[tipo]">
                <div
                  v-for="(tipoEquipe, j) in Object.keys(
                    participantesPorTipo[tipo]
                  )"
                  :key="j"
                >
                  <br />
                  <p class="filter-title">
                    Equipe {{ TIPO_EQUIPE[tipoEquipe] }}
                  </p>
                  <EquipeTable
                    :participantes="participantesPorTipo[tipo][tipoEquipe]" @update="getData"
                  />
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <br />
      <ModalCrudParticipante
        :key="String(modalCrudParticipanteOpen) + 'CrudParticipante'"
        :modalVisible="modalCrudParticipanteOpen"
        @close="modalCrudParticipanteOpen = !modalCrudParticipanteOpen"
      />
    </div>
    <router-view ref="rvp"></router-view>
  </div>
</template>

<script>
import ModalCrudParticipante from "../../components/modals/ModalCrudParticipante.vue";
import PageTitle from "../../components/PageTitle.vue";
import EquipeTable from "../../components/tables/EquipeTable.vue";
import { EventBus } from "../../services/event-bus";
import { filtrarParticipantes } from "../../services/providers/participantesProvider";
import { getSubprojetosByProjetoApi } from "../../services/providers/subProjetosProvider";
import { TIPO_PARTICIPANTE, TIPO_EQUIPE } from "../../utils/enums";

export default {
  name: "Equipe",
  components: {
    PageTitle,
    ModalCrudParticipante,
    EquipeTable,
  },
  data: () => ({
    panel: [0],
    opcoesSelecionadas: [],
    camposTextuais: "",
    subprojetos: [],
    subProjetoSelecionados: [],
    modalCrudParticipanteOpen: false,
    participantesPorTipo: {},
    TIPO_PARTICIPANTE: TIPO_PARTICIPANTE,
    TIPO_EQUIPE: TIPO_EQUIPE,
    isExactActive: true,
  }),
  updated() {
    this.isExactActive = typeof this.$refs.rvp === "undefined";
  },
  created() {
    this.isExactActive = typeof this.$refs.rvp === "undefined";
    this.$watch(
      (vm) => [vm.subProjetoSelecionados],
      () => {
        this.opcoesSelecionadas = this.getSelecionados();
      }
    );
    if (this.$route.query.isCadastro)
      this.$actions.setDrawerOpen(false);
  },
  mounted() {
    this.getData();
    window.scroll(0,0);
    EventBus.$on("participante-updated", async () => {
      await this.getData();
    });
    if (this.$route.query.isCadastro)
      this.$actions.setDrawerOpen(false);
  },
  methods: {
    async getData() {
      const participantes = await filtrarParticipantes({
        projetos: [this.$store.projeto.id],
      });
      this.groupByTipo(participantes);
      let subprojetos = await getSubprojetosByProjetoApi(
        this.$store.projeto.id
      );
      this.subprojetos = subprojetos.map((o) => ({
        label: o.codigo,
        value: o.id,
      }));
    },
    groupByTipo(participantes) {
      const tipos = {};
      participantes.sort((a, b) => (a.tipoEquipe < b.tipoEquipe ? -1 : 1));
      participantes.forEach((participante) => {
        tipos[participante.tipoParticipante] = {
          ...tipos[participante.tipoParticipante],
          [participante.tipoEquipe]: [
            ...((tipos[participante.tipoParticipante] &&
              tipos[participante.tipoParticipante][participante.tipoEquipe]) ||
              []),
            participante,
          ],
        };
        tipos[participante.tipoParticipante][participante.tipoEquipe].sort(
          (a, b) => (a.pessoa.nome < b.pessoa.nome ? -1 : 1)
        );
      });
      this.participantesPorTipo = tipos;
    },
    async filtrarParticipantes() {
      const participantes = await filtrarParticipantes({
        projetos: [this.$store.projeto.id],
        subprojetos: [this.subProjetoSelecionados],
        nome: this.camposTextuais,
      });
      this.groupByTipo(participantes);
    },
    selecionarTodasOpcoes(arrModel, arrComparacao) {
      if (arrModel.length == arrComparacao.length) {
        arrModel = [];
      } else {
        arrModel = arrComparacao;
      }
      return arrModel;
    },
    getSelecionados() {
      const selecteds = [...this.subProjetoSelecionados];
      return selecteds.map((v) => v.label.slice(0, 15));
    },
    limparCamposFiltro() {
      this.panel = [0];
      this.camposTextuais = "";
      this.subProjetoSelecionados = [];
    },
    limparFiltros() {
      this.limparCamposFiltro();
      this.getData();
    },
    decidirDestino () {
      if (this.$route.query)
        if (this.$route.query.isCadastro)
          this.$router.push({
            path: `/cadastro-projetos/${this.$store.projeto.id}/editar-cadastro/`,
            query: {currentTab: 4}
          });
      else
        this.$router.go(-1);
    }
  },
};
</script>

<style lang="scss" scoped>
.filter-title {
  font-family: Roboto;
  font-style: normal;
  text-transform: uppercase;
  margin: 0%;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: #037f8c;
}
.filter {
  .v-expansion-panel ::after {
    position: absolute;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  .v-expansion-panel--active {
    position: absolute;
    width: 100%;
    -webkit-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
  }
}
.v-text-field--outlined fieldset {
  border: 3px solid #037f8c !important;
  border-radius: 8px !important;
}
.label {
  margin-top: 8px !important;
}
.chips-content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.chip {
  display: flex;
  align-items: center;
  width: 100px;
  height: 30px;
  padding: 5px;
  margin: 5px;
  margin-left: 10px;
  border: 1.5px solid #ff8d3f;
  border-radius: 10px;
  text-overflow: ellipsis;
  overflow: hidden;

  span {
    width: 100%;
    font-family: Roboto;
    font-size: 14px;
    color: #ff8d3f;
    text-align: center;
  }
}
.reformulacao-div {
  background-color: #1da7a3;
  width: 100%;
  display: flex;
  padding: 40px;
  border-radius: 20px;
}
.reformulacao-title {
  font-family: Raleway;
  font-weight: bold;
  font-size: 28px;
  color: #f2f2f2;
}
.reformulacao-sub-title {
  font-family: Raleway;
  font-weight: bold;
  font-size: 19px;
  color: #f2f2f2;
}
</style>
