var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"md":"4"}},[_c('v-text-field',{staticClass:"search-item-input",attrs:{"outlined":"","dense":"","clearable":"","label":"Pesquisar...","append-icon":"mdi-magnify","color":"#037F8C"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.buscarItemPorCamposTextuais($event)},"click:clear":_vm.limparFiltros,"click:append":_vm.buscarItemPorCamposTextuais},model:{value:(_vm.camposTextuais),callback:function ($$v) {_vm.camposTextuais=$$v},expression:"camposTextuais"}})],1),_c('v-col',{style:({ marginTop: _vm.opcoesSelecionadas.length ? '-20px' : '-12px' }),attrs:{"md":"8"}},[_c('v-expansion-panels',{attrs:{"accordion":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel',[_c('v-expansion-panel-header',{staticStyle:{"border-bottom":"1px solid #037f8c","padding-bottom":"8px","justify-content":"flex-start"}},[_c('p',{staticClass:"filter-title"},[_vm._v("Filtros:")]),_c('div',{staticClass:"chips-content"},_vm._l((_vm.opcoesSelecionadas),function(op){return _c('div',{key:op},[_c('div',{staticClass:"chip"},[_c('span',[_vm._v(_vm._s(op))])])])}),0)]),_c('v-expansion-panel-content',[(_vm.context == 'projeto')?_c('v-row',[_c('v-col',{staticClass:"label-col label",attrs:{"md":"2"}},[_vm._v("Subprojeto:")]),_c('v-col',[_c('v-select',{staticClass:"select-form",attrs:{"outlined":"","hide-details":"","clearable":"","small-chips":"","multiple":"","dense":"","color":"#037F8C","item-color":"#037F8C","items":_vm.subprojetos,"item-text":"label","item-value":"value","label":"Todos os subprojetos","solo":"","return-object":""},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":function($event){_vm.subProjetoSelecionados = _vm.selecionarTodasOpcoes(
                          _vm.subProjetoSelecionados,
                          _vm.subprojetos
                        )}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v(" "+_vm._s(_vm.subProjetoSelecionados.length == _vm.subprojetos.length ? "mdi-close-box" : "mdi-checkbox-blank-outline")+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Selecionar todos ")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true}],null,false,1570066554),model:{value:(_vm.subProjetoSelecionados),callback:function ($$v) {_vm.subProjetoSelecionados=$$v},expression:"subProjetoSelecionados"}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"label-col label",attrs:{"md":"2"}},[_vm._v("Rubrica:")]),_c('v-col',[_c('v-select',{staticClass:"select-form",attrs:{"outlined":"","hide-details":"","clearable":"","small-chips":"","multiple":"","dense":"","label":"Todas as rubricas","solo":"","color":"#037F8C","item-color":"#037F8C","items":_vm.rubricasFilter,"item-text":"label","item-value":"value","return-object":""},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":function($event){_vm.rubricaFilterSelecionada = _vm.selecionarTodasOpcoes(
                          _vm.rubricaFilterSelecionada,
                          _vm.rubricasFilter
                        )}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v(" "+_vm._s(_vm.rubricaFilterSelecionada.length == _vm.rubricasFilter.length ? "mdi-close-box" : "mdi-checkbox-blank-outline")+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Selecionar todas ")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true}]),model:{value:(_vm.rubricaFilterSelecionada),callback:function ($$v) {_vm.rubricaFilterSelecionada=$$v},expression:"rubricaFilterSelecionada"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"label-col label",attrs:{"md":"2"}},[_vm._v("Status:")]),_c('v-col',[_c('v-select',{staticClass:"select-form",attrs:{"outlined":"","hide-details":"","clearable":"","chips":"","multiple":"","dense":"","label":"Todos os status","solo":"","color":"#037F8C","item-color":"#037F8C","items":_vm.status,"item-text":"label","item-value":"value","return-object":""},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":function($event){_vm.statusSelecionado = _vm.selecionarTodasOpcoes(
                          _vm.statusSelecionado,
                          _vm.status
                        )}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v(" "+_vm._s(_vm.statusSelecionado.length == _vm.status.length ? "mdi-close-box" : "mdi-checkbox-blank-outline")+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Selecionar todos ")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true}]),model:{value:(_vm.statusSelecionado),callback:function ($$v) {_vm.statusSelecionado=$$v},expression:"statusSelecionado"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"label-col label",attrs:{"md":"2"}},[_vm._v("Parcela:")]),_c('v-col',[_c('v-select',{attrs:{"outlined":"","chips":"","hide-details":"","clearable":"","multiple":"","dense":"","label":"Todas as parcelas","solo":"","items":_vm.parcelas,"color":"#037F8C","item-color":"#037F8C","item-text":"label","item-value":"value","return-object":""},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":function($event){_vm.parcelaSelecionada = _vm.selecionarTodasOpcoes(
                          _vm.parcelaSelecionada,
                          _vm.parcelas
                        )}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v(" "+_vm._s(_vm.parcelaSelecionada.length == _vm.parcelas.length ? "mdi-close-box" : "mdi-checkbox-blank-outline")+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Selecionar todas ")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true}]),model:{value:(_vm.parcelaSelecionada),callback:function ($$v) {_vm.parcelaSelecionada=$$v},expression:"parcelaSelecionada"}})],1),_c('v-col',{staticClass:"label-col label",attrs:{"md":"2"}},[_vm._v("Recurso:")]),_c('v-col',[_c('v-select',{staticClass:"select-form",attrs:{"outlined":"","hide-details":"","clearable":"","chips":"","multiple":"","dense":"","label":"Todos os recursos","solo":"","color":"#037F8C","item-color":"#037F8C","items":_vm.recursos,"item-text":"label","item-value":"value","return-object":""},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":function($event){_vm.recursoSelecionado = _vm.selecionarTodasOpcoes(
                          _vm.recursoSelecionado,
                          _vm.recursos
                        )}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v(" "+_vm._s(_vm.recursoSelecionado.length == _vm.recursos.length ? "mdi-close-box" : "mdi-checkbox-blank-outline")+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Selecionar todos ")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true}]),model:{value:(_vm.recursoSelecionado),callback:function ($$v) {_vm.recursoSelecionado=$$v},expression:"recursoSelecionado"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"label-col label",attrs:{"md":"2"}},[_vm._v("Saldo:")]),_c('v-col',[_c('v-select',{staticClass:"select-form",attrs:{"outlined":"","hide-details":"","clearable":"","chips":"","multiple":"","dense":"","label":"Todos os saldos","solo":"","color":"#037F8C","item-color":"#037F8C","items":_vm.tiposSaldos,"item-text":"label","item-value":"value","return-object":""},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":function($event){_vm.tiposSaldoSelecionado = _vm.selecionarTodasOpcoes(
                          _vm.tiposSaldoSelecionado,
                          _vm.tiposSaldos
                        )}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v(" "+_vm._s(_vm.tiposSaldoSelecionado.length == _vm.tiposSaldos.length ? "mdi-close-box" : "mdi-checkbox-blank-outline")+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Selecionar todos ")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true}]),model:{value:(_vm.tiposSaldoSelecionado),callback:function ($$v) {_vm.tiposSaldoSelecionado=$$v},expression:"tiposSaldoSelecionado"}})],1),_c('v-col',{staticClass:"label-col label",attrs:{"md":"2"}},[_vm._v("Execução:")]),_c('v-col',[_c('v-select',{staticClass:"select-form",attrs:{"outlined":"","hide-details":"","clearable":"","dense":"","chips":"","multiple":"","label":"Todas as execuções","solo":"","color":"#037F8C","item-color":"#037F8C","items":_vm.tipoExecucaos,"item-text":"label","item-value":"value","return-object":""},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":function($event){_vm.tipoExecucaoSelecionada = _vm.selecionarTodasOpcoes(
                          _vm.tipoExecucaoSelecionada,
                          _vm.tipoExecucaos
                        )}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v(" "+_vm._s(_vm.tipoExecucaoSelecionada.length == _vm.tipoExecucaos.length ? "mdi-close-box" : "mdi-checkbox-blank-outline")+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Selecionar todas ")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true}]),model:{value:(_vm.tipoExecucaoSelecionada),callback:function ($$v) {_vm.tipoExecucaoSelecionada=$$v},expression:"tipoExecucaoSelecionada"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"md":"5"}}),_c('v-col',{staticStyle:{"display":"flex","flex-direction":"row","justify-content":"space-between"},attrs:{"md":"7"}},[_c('v-btn',{attrs:{"small":"","outlined":"","width":"190","color":"#037f8c"},on:{"click":_vm.limparFiltros}},[_vm._v("Limpar filtros")]),_c('v-btn',{staticClass:"btn-controls",attrs:{"small":"","depressed":"","width":"190","color":"#037f8c"},on:{"click":function($event){return _vm.filtrarItens()}}},[_vm._v("Aplicar filtros")])],1)],1)],1)],1)],1)],1)],1),(_vm.rubricas.length)?_c('div',[_c('div',{key:_vm.rubricas.length + 'ItensTable'},_vm._l((_vm.rubricas),function(rubrica,idx){return _c('div',{key:idx},[(rubrica)?_c('p',{staticClass:"session-title"},[_vm._v(" "+_vm._s(rubrica.codigo)+" - "+_vm._s(rubrica.descricaoCompleta)+" ")]):_vm._e(),(rubrica && rubrica.itens.length)?_c('ItensTable',{attrs:{"context":_vm.context,"itens":rubrica.itens}}):_vm._e()],1)}),0)]):_c('p',{staticStyle:{"font-family":"Raleway","font-size":"20px"}},[_vm._v(" Nenhum item encontrado! ")]),_c('br'),_c('br')],1)}
var staticRenderFns = []

export { render, staticRenderFns }