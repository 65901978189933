<template>
  <div>
    <v-row class="container-ammount">
      <v-col class="box-ammount">
        <p class="title-ammount" style="margin-bottom: 0px">TOTAL FNDCT</p>
        <p class="title-ammount-label">(alocado)</p>
        <p class="text-ammount" v-if="subProjeto && subProjeto.metricas">
          {{ subProjeto.metricas.valorAlocado | currency }}
        </p>
        <p v-else>-</p>
      </v-col>
      <v-col class="box-ammount">
        <p class="title-ammount">TOTAL EXECUTADO FNDCT</p>
        <p class="text-ammount" v-if="subProjeto && subProjeto.metricas">
          {{ subProjeto.metricas.valorExecutado | currency }}
        </p>
        <p v-else>-</p>
      </v-col>
      <v-col class="box-ammount">
        <p class="title-ammount">TOTAL EXECUTADO RENDIMENTO</p>
        <p class="text-ammount" v-if="subProjeto && subProjeto.metricas">
          {{ subProjeto.metricasRendimento.valorExecutado | currency }}
        </p>
        <p v-else>-</p>
      </v-col>
      <v-col class="box-ammount">
        <p class="title-ammount" style="margin-bottom: 0px">SALDO FNDCT</p>
        <p class="title-ammount-label">(alocado não executado)</p>
        <p class="text-ammount" v-if="subProjeto && subProjeto.metricas">
          {{ subProjeto.metricas.saldoNaoExecutado | currency }}
        </p>
        <p v-else>-</p>
      </v-col>
      <v-col class="box-ammount">
        <p class="title-ammount" style="margin-bottom: 0px">
          SALDO RENDIMENTO
        </p>
        <p class="title-ammount-label">(alocado não executado)</p>
        <p
          class="text-ammount"
          v-if="subProjeto && subProjeto.metricasRendimento"
        >
          {{ subProjeto.metricasRendimento.saldoNaoExecutado | currency }}
        </p>
        <p v-else>-</p>
      </v-col>
    </v-row>
    <br />
    <br />
    <div
      v-if="rubricas.length && (availableRubricasBar || availableRubricasPie)"
    >
      <p class="session-title">Rubricas</p>
      <v-row>
        <v-col md="8" v-if="tracesRubricaBar && availableRubricasBar">
          <div class="box-chart">
            <div class="download-chart">
              <v-btn
                depressed
                text
                color="#FF8D3F"
                @click="downloadChart('plotRubricaBar')"
              >
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </div>
            <Chart
              ref="plotRubricaBar"
              v-if="tracesRubricaBar"
              :traces="tracesRubricaBar"
              title="<b>TOTAL EXECUTADO POR RUBRICA</b>"
              width="800"
              height="400"
            />
            <v-progress-circular
              v-else
              indeterminate
              color="#037F8C"
            ></v-progress-circular>
          </div>
        </v-col>
        <v-col md="4">
          <div class="box-chart">
            <div class="download-chart">
              <v-btn
                depressed
                text
                color="#FF8D3F"
                @click="downloadChart('plotRubricaPie')"
              >
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </div>
            <Chart
              ref="plotRubricaPie"
              v-if="tracesRubricaPie"
              :traces="tracesRubricaPie"
              title="<b>PERCENTUAL EXECUTADO <br> POR RUBRICA</b>"
              width="450"
              height="400"
            />
            <v-progress-circular
              v-else
              indeterminate
              color="#037F8C"
            ></v-progress-circular>
          </div>
        </v-col>
      </v-row>
    </div>
    <br />
    <div
      v-if="parcelas.length && (availableParcelasBar || availableParcelasPie)"
    >
      <p class="session-title">Parcelas</p>
      <v-row>
        <v-col md="8" v-if="availableParcelasBar">
          <div class="box-chart">
            <div class="download-chart">
              <v-btn
                depressed
                text
                color="#FF8D3F"
                @click="downloadChart('plotParcelaBar')"
              >
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </div>
            <Chart
              ref="plotParcelaBar"
              v-if="tracesParcelaBar"
              :traces="tracesParcelaBar"
              title="<b>TOTAL EXECUTADO POR PARCELA</b>"
              width="800"
              height="400"
            />
            <v-progress-circular
              v-else
              indeterminate
              color="#037F8C"
            ></v-progress-circular>
          </div>
        </v-col>
        <v-col md="4" v-if="availableParcelasPie">
          <div class="box-chart">
            <div class="download-chart">
              <v-btn
                depressed
                text
                color="#FF8D3F"
                @click="downloadChart('plotParcelaPie')"
              >
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </div>
            <Chart
              ref="plotParcelaPie"
              v-if="tracesParcelaPie"
              :traces="tracesParcelaPie"
              title="<b>PERCENTUAL EXECUTADO <br> POR PARCELA</b>"
              width="450"
              height="400"
            />
            <v-progress-circular
              v-else
              indeterminate
              color="#037F8C"
            ></v-progress-circular>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Chart from "../Chart";
import Plotly from "plotly.js-dist";
import { calcularExecucaoRubricaPorSubProjeto } from "../../services/providers/subProjetosProvider";

export default {
  name: "GraficosFinanceirosSubprojeto",
  components: {
    Chart,
  },
  data: () => ({
    rubricas: [],
    parcelas: [],
    itensHomologacao: [],
    subProjeto: null,
    tracesParcelaBar: null,
    tracesParcelaPie: null,
    tracesRubricaBar: null,
    tracesRubricaPie: null,
    tracesFontePie: null,
    availableRubricasBar: false,
    availableParcelasBar: false,
    availableRubricasPie: false,
    availableParcelasPie: false,
    modalGerarRelatorioProjeto: false,
    modalHomologacaoItensOpen: false,
  }),
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.subProjeto = this.$store.subProjeto;
      if (this.subProjeto) {
        try {
          const rubricas = await calcularExecucaoRubricaPorSubProjeto(
            this.subProjeto.id
          );

          this.rubricas = rubricas;

          const parcelas = this.subProjeto.metricasParcela.map((k) => ({
            ...k,
            id: k.parcela.numero,
          }));

          this.parcelas = parcelas;

          if (rubricas) {
            //RUBRICA
            this.tracesRubricaBar = [
              {
                x: rubricas.map((r) => r.codigo),
                y: rubricas.map((r) => parseFloat(r.valorAlocado)),
                type: "bar",
                text: this.formatLabelsBarChart(
                  rubricas.map((r) => parseFloat(r.valorAlocado))
                ),
                textposition: "auto",
                hovertemplate: "%{label} <br> R$ %{value:,.2f}",
                marker: {
                  color: "#037F8C",
                },
                name: "Total Previsto",
              },
              {
                x: rubricas.map((r) => r.codigo),
                y: rubricas.map((r) => parseFloat(r.valorExecutado)),
                type: "bar",
                text: this.formatLabelsBarChart(
                  rubricas.map((r) => parseFloat(r.valorExecutado))
                ),
                textposition: "auto",
                hovertemplate: "%{label} <br> R$ %{value:,.2f}",
                marker: {
                  color: "#1DA7A3",
                },
                name: "Total Executado",
              },
            ];

            this.availableRubricasBar =
              rubricas.map((r) => parseFloat(r.valorAlocado))[0] ||
              rubricas.map((r) => parseFloat(r.valorExecutado))[0];

            this.availableRubricasPie = !!rubricas.map((r) =>
              parseFloat(r.valorExecutado)
            )[0];

            this.tracesRubricaPie = [
              {
                values: rubricas
                  .filter((r) => r.valorExecutado > 0)
                  .map((r) => parseFloat(r.valorExecutado)),
                labels: rubricas
                  .filter((r) => r.valorExecutado > 0)
                  .map((r) => r.descricao),
                type: "pie",
                hole: 0.6,
                marker: {
                  colors: [
                    "#BCDBDF",
                    "#9A2C80",
                    "#EE7777",
                    "#CC44CC",
                    "#037F8C",
                    "#1DA7A3",
                    "#60C1C8",
                    "#53CDE2",
                  ],
                },
                name: "",
                hovertemplate: "%{label} <br> R$ %{value:,.2f} <br> %{percent}",
              },
            ];
          }

          //PARCELA
          if (parcelas) {
            let labels = parcelas.map((p) => "Parcela " + p.id);
            let valoresPrevistos = parcelas.map((p) => p.valorAutorizado);
            let valoresExecutados = parcelas.map((p) => p.valorExecutado);

            this.availableParcelasBar =
              valoresExecutados[0] || valoresPrevistos[0];

            this.availableParcelasPie = !!valoresExecutados[0];

            this.tracesParcelaBar = [
              {
                x: labels,
                y: valoresPrevistos,
                type: "bar",
                text: this.formatLabelsBarChart(valoresPrevistos),
                textposition: "auto",
                hovertemplate: "%{label} <br> R$ %{value:,.2f}",
                marker: {
                  color: "#037F8C",
                },
                name: "Total Previsto",
              },
              {
                x: labels,
                y: valoresExecutados,
                type: "bar",
                text: this.formatLabelsBarChart(valoresExecutados),
                textposition: "auto",
                hovertemplate: "%{label} <br> R$ %{value:,.2f}",
                marker: {
                  color: "#1DA7A3",
                },
                name: "Total Executado",
              },
            ];
            this.tracesParcelaPie = [
              {
                values: valoresExecutados,
                labels: labels,
                type: "pie",
                hole: 0.6,
                marker: {
                  colors: ["#037F8C", "#1DA7A3", "#60C1C8", "#53CDE2"],
                },
                name: "",
                hovertemplate: "%{label} <br> R$ %{value:,.2f} <br> %{percent}",
              },
            ];
          }
        } catch (error) {
          console.error(error);
          this.$notify({
            title: "Erro interno!",
            text: error.message || error,
            type: "error",
            duration: 15000,
          });
        }
      }
    },
    formatLabelsBarChart(arr) {
      return arr.map((value) =>
        new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(value)
      );
    },
    downloadChart(refStr) {
      let ref = this.$refs[refStr].$refs.chart;
      Plotly.downloadImage(ref, {
        format: "png",
        width: 800,
        height: 600,
        filename: Date.now(),
      });
    },
  },
  watch: {
    "$store.subProjeto": async function(value) {
      await this.loadData(value);
    },
  },
};
</script>

<style lang="scss" scoped>
.box-ammount {
  background-color: $card-background;
  margin: 5px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 15px;
}
.container-ammount {
  padding-left: 10px;
  padding-right: 10px;
}
.session-title {
  margin: 20px;
  text-transform: uppercase;
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #037f8c;
}
.box-chart {
  background-color: $card-background;
  max-height: 410px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  > .vue-plotly {
    position: relative;
    top: -26px;
  }
}
.box-progress {
  background-color: $card-background;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.title-ammount {
  font-family: $title;
  text-transform: uppercase;
  text-align: center;
  font-size: 14px;
  color: $title-color;
  margin-bottom: 10px;
}
.title-ammount-label {
  font-family: $title;
  text-transform: uppercase;
  text-align: center;
  font-size: 10px;
  color: $title-color;
  margin-bottom: 5px;
}
.text-ammount {
  font-family: $subtitle;
  text-transform: uppercase;
  text-align: center;
  color: $title-color;
  font-size: 24px;
}
p {
  margin: 0px;
}
.container-ammount-active {
  background-color: $primary;

  .title-ammount {
    color: white;
  }

  .text-ammount {
    color: white;
  }
}
.progress_bar_desc {
  font-size: 16px;
  position: absolute;
  top: 80%;
  left: 38%;
  text-align: center;
  color: $secondary;
}

.download-chart {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  position: relative;
  top: 35px;
  z-index: 2;
}

@media only screen and (max-width: 1900px) {
  .container-resumo {
    margin-left: 0%;
    margin-right: 0%;
  }
}
</style>
