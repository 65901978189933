import moment from "moment";

function makeHeaderTable(subprojeto) {
    return (!subprojeto ? "SP;" : "Nº;") +
        "DESCRIÇÃO;" +
        "PARCELA;" +
        "QTD;" +
        "VALOR UNIT. PREVISTO;" +
        "TOTAL PREVISTO;" +
        "TOTAL EXECUTADO;" +
        "RECURSO FNDCT;" +
        "RECURSO RENDIMENTO;" +
        "SALDO DISPONÍVEL;" +
        "STATUS" + "\n";
}

function makeTable(subprojeto, rubrica) {
    let bodyStr = "";
    let totalItens = {
        quantidade: 0,
        valorUnitario: 0,
        valorAlocado: 0,
        valorTotalRealizado: 0,
        valorExecutado: 0,
        saldoNaoExecutado: 0,
        totalRendimento: 0,
        totalFndct: 0,
    };

    bodyStr += (rubrica.descricaoCompleta || "-") + "\n";
    bodyStr += makeHeaderTable(subprojeto);

    rubrica.itens.forEach((item, idx) => {
        item.totalRendimento = item.recursos
            .filter(r => (r.fonte ? r.fonte.codigo == "R" : true))
            .reduce((total, recurso) => (total += recurso.valorAlocado), 0);

        item.totalFndct = item.recursos
            .filter(r => (r.fonte ? r.fonte.codigo == "F" : true))
            .reduce((total, recurso) => (total += recurso.valorAlocado), 0);

        totalItens.quantidade += item.quantidade;
        totalItens.valorUnitario += item.valorUnitario;
        totalItens.valorAlocado += item.valorAlocado;
        totalItens.valorTotalRealizado += item.valorTotalRealizado;
        totalItens.valorExecutado += item.valorExecutado;
        totalItens.saldoNaoExecutado += item.saldoNaoExecutado;
        totalItens.totalFndct += item.totalFndct;
        totalItens.totalRendimento += item.totalRendimento;

        let bodyItem = [
            !subprojeto ? item.subprojeto.codigo || "-" : idx + 1,
            String(item.descricao).replace(";", " ").replace(";", " ").replace(";", " ") || "-",
            item.parcela ? item.parcela.numero : "-",
            item.quantidade || "-",
            String(item.valorUnitario ? + item.valorUnitario : "-").replace(".", ","),
            String(item.valorAlocado ? + item.valorAlocado : "-").replace(".", ","),
            String(item.valorExecutado ? + item.valorExecutado : "-").replace(".", ","),
            String(item.totalFndct ? + item.totalFndct : "-").replace(".", ","),
            String(item.totalRendimento ? + item.totalRendimento : "-").replace(".", ","),
            String(item.saldoNaoExecutado ? + item.saldoNaoExecutado : "-").replace(".", ","),
            String(item.situacaoItem.codigo).replace(".", ",")
        ];
        bodyStr += bodyItem.join(";");
        bodyStr += "\n";
    });
    let footerItem = [
        "",
        "",
        "TOTAL",
        String(totalItens.quantidade ? totalItens.quantidade : "-").replace(".", ","),
        String(totalItens.valorUnitario ? + totalItens.valorUnitario : "-").replace(".", ","),
        String(totalItens.valorAlocado ? + totalItens.valorAlocado : "-").replace(".", ","),
        String(totalItens.valorExecutado ? + totalItens.valorExecutado : "-").replace(".", ","),
        String(totalItens.totalFndct ? + totalItens.totalFndct : "-").replace(".", ","),
        String(totalItens.totalRendimento ? + totalItens.totalRendimento : "-").replace(".", ","),
        String(totalItens.saldoNaoExecutado ? + totalItens.saldoNaoExecutado : "-").replace(".", ","),
        ""
    ];

    bodyStr += footerItem.join(";");
    bodyStr += "\n\n";

    return bodyStr;
}

function makeTableFooter(rubricasItens) {
    let bodyStr = "\n\n";

    bodyStr += "RUBRICA;" +
        "VALOR UNIT. PREVISTO;" +
        "TOTAL PREVISTO;" +
        "TOTAL EXECUTADO;" +
        "RECURSO FNDCT;" +
        "RECURSO RENDIMENTO;" +
        "SALDO DISPONÍVEL\n";

    let totalRubricas = {
        valorUnitario: 0,
        valorAlocado: 0,
        valorExecutado: 0,
        saldoNaoExecutado: 0,
        totalRendimento: 0,
        totalFndct: 0,
    };

    rubricasItens.forEach(rubrica => {
        let totalItens = {
            valorUnitario: 0,
            valorAlocado: 0,
            valorExecutado: 0,
            saldoNaoExecutado: 0,
            totalRendimento: 0,
            totalFndct: 0,
        };
        rubrica.itens.forEach((item) => {
            item.totalRendimento = item.recursos
                .filter(r => (r.fonte ? r.fonte.codigo == "R" : true))
                .reduce((total, recurso) => (total += recurso.valorAlocado), 0);
            item.totalFndct = item.recursos
                .filter(r => (r.fonte ? r.fonte.codigo == "F" : true))
                .reduce((total, recurso) => (total += recurso.valorAlocado), 0);
            totalItens.valorUnitario += item.valorUnitario;
            totalItens.valorAlocado += item.valorAlocado;
            totalItens.valorExecutado += item.valorExecutado;
            totalItens.saldoNaoExecutado += item.saldoNaoExecutado;
            totalItens.totalFndct += item.totalFndct;
            totalItens.totalRendimento += item.totalRendimento;
        });

        totalRubricas.valorUnitario += totalItens.valorUnitario;
        totalRubricas.valorAlocado += totalItens.valorAlocado;
        totalRubricas.valorExecutado += totalItens.valorExecutado;
        totalRubricas.saldoNaoExecutado += totalItens.saldoNaoExecutado;
        totalRubricas.totalFndct += totalItens.totalFndct;
        totalRubricas.totalRendimento += totalItens.totalRendimento;

        let bodyItem = [
            rubrica.descricaoCompleta || "-",
            String(totalItens.valorUnitario ? + totalItens.valorUnitario : "-").replace(".", ","),
            String(totalItens.valorAlocado ? + totalItens.valorAlocado : "-").replace(".", ","),
            String(totalItens.valorExecutado ? + totalItens.valorExecutado : "-").replace(".", ","),
            String(totalItens.totalFndct ? + totalItens.totalFndct : "-").replace(".", ","),
            String(totalItens.totalRendimento ? + totalItens.totalRendimento : "-").replace(".", ","),
            String(totalItens.saldoNaoExecutado ? + totalItens.saldoNaoExecutado : "-").replace(".", ","),
        ];
        bodyStr += bodyItem.join(";");
        bodyStr += "\n";
    });

    let footerItem = [
        "TOTAL",
        String(totalRubricas.valorUnitario ? + totalRubricas.valorUnitario : "-").replace(".", ","),
        String(totalRubricas.valorAlocado ? + totalRubricas.valorAlocado : "-").replace(".", ","),
        String(totalRubricas.valorExecutado ? + totalRubricas.valorExecutado : "-").replace(".", ","),
        String(totalRubricas.totalFndct ? + totalRubricas.totalFndct : "-").replace(".", ","),
        String(totalRubricas.totalRendimento ? + totalRubricas.totalRendimento : "-").replace(".", ","),
        String(totalRubricas.saldoNaoExecutado ? + totalRubricas.saldoNaoExecutado : "-").replace(".", ",")
    ];

    bodyStr += footerItem.join(";");
    bodyStr += "\n\n";

    return bodyStr;
}

function makeHeader(projeto, dataRef) {
    return "Demanda;" + (projeto.demanda || "-") + "\n" +
        "Projeto;" + (projeto.codigo + " - " + projeto.descricao || "-") + "\n" +
        "Convênio;" + (projeto.convenio || "-") + "\n" +
        "Ref.;" + (projeto.referencia || "-") + "\n" +
        dataRef ? "Relatório referente a; " + moment(dataRef).format("DD/MM/YYYY") : "" + "\n" +
        "Relatório emitido em;" + new Date().toLocaleDateString() + "\n\n\n" +
        "RELATÓRIO" + "\n\n\n";
}

export const makeCSV = (projeto, subprojeto, rubricasItens, dataRef) => {
    let csvContent = "data:text/csv;charset=utf-8,";
    csvContent += makeHeader(projeto, subprojeto, dataRef);
    rubricasItens.forEach(rubrica => {
        csvContent += makeTable(subprojeto, rubrica);
    });
    csvContent += makeTableFooter(rubricasItens);
    csvContent.replace(/(^\[)|(\]$)/gm, "");
    return csvContent;
}