import api from "../api";

export const cadastrarRelatorioPrestacaoContas = async (payload) => {
  const res = await api.post("/relatorios-prestacao-contas", payload);
  return res.data;
};

export const getRelatorio = async (projetoId) => {
  const res = await api.get(`/relatorios-prestacao-contas/search?projetos=${projetoId}`);
  return res.data;
};

export const excluirRelatorio = async (idRelatorio) => {
  const res = await api.delete(`/relatorios-prestacao-contas/${idRelatorio}`);
  return res.data;
};

export const editarRelatorio = async (idItem,payload) => {
  const res = await api.patch(`/relatorios-prestacao-contas/${idItem}`, payload);
  return res.data;
};


