var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.historicos.length)?_c('p',{staticStyle:{"font-family":"Raleway","font-size":"18px"}},[_vm._v(" Ainda não há nenhuma modificação registrada nesse item. ")]):_c('div',[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',{staticStyle:{"border":"1px solid black"}},[_c('tr',[_c('th',{staticClass:"title-tab",staticStyle:{"width":"10%"}},[_vm._v("STATUS")]),_c('th',{staticClass:"title-tab",staticStyle:{"width":"30%"}},[_vm._v("ALTERAÇÃO")]),_c('th',{staticClass:"title-tab"},[_vm._v("JUSTIFICATIVA")]),_c('th',{staticClass:"title-tab",staticStyle:{"width":"15%"}},[_vm._v(" ALTERADO POR ")]),_c('th',{staticClass:"title-tab"},[_vm._v("DATA")])])]),_vm._l((_vm.historicos),function(historico,idx){return _c('tbody',{key:idx},[_c('tr',[(historico.depois.situacaoItem)?_c('td',{staticClass:"text-tab"},[_vm._v(" "+_vm._s(String(historico.depois.situacaoItem).toUpperCase())+" ")]):_c('td',{staticClass:"text-tab"},[_vm._v("-")]),_c('td',{staticClass:"text-tab",staticStyle:{"width":"40%"}},_vm._l((Object.keys(historico.depois)),function(key,idx){return _c('div',{key:idx},_vm._l((_vm.renderAlteracoesHistorico(
                    key,
                    historico.depois
                  )),function(str){return _c('p',{key:str,staticStyle:{"margin-bottom":"10px","text-align":"left"}},[_vm._v(" "+_vm._s(str)+" ")])}),0)}),0),_c('td',{staticClass:"text-tab",staticStyle:{"width":"30%"}},[_c('p',{staticClass:"justificativa-text"},[_vm._v(_vm._s(historico.justificativa))]),(historico.justificativa.length > 200)?_c('div',{staticStyle:{"width":"100%","display":"flex","margin-top":"10px"}},[_c('v-btn',{attrs:{"depressed":"","text":"","x-small":""},on:{"click":function($event){(_vm.modalOpen = !_vm.modalOpen) &
                      (_vm.justificativaSelecionada = historico.justificativa)}}},[_c('p',{staticClass:"read-more-btn"},[_vm._v("Continuar lendo")])])],1):_vm._e()]),_c('td',{staticClass:"text-tab"},[_vm._v(" "+_vm._s(String(historico.usuario.login) .charAt(0) .toUpperCase() + String(historico.usuario.login).slice(1))+" ")]),_c('td',{staticClass:"text-tab"},[_vm._v(" "+_vm._s(_vm._f("moment")(new Date(historico.data),"DD/MM/YYYY HH:mm"))+" ")])])])}),(_vm.historicos[_vm.historicos.length - 1])?_c('tbody',[_c('tr',[(_vm.historicos[_vm.historicos.length - 1].antes.situacaoItem)?_c('td',{staticClass:"text-tab"},[_vm._v(" "+_vm._s(String( _vm.historicos[_vm.historicos.length - 1].antes.situacaoItem ).toUpperCase())+" ")]):_c('td',{staticClass:"text-tab"},[_vm._v("-")]),_c('td',{staticClass:"text-tab",staticStyle:{"width":"40%"}},_vm._l((Object.keys(
                  _vm.historicos[_vm.historicos.length - 1].antes
                )),function(key,idx){return _c('div',{key:idx},_vm._l((_vm.renderAlteracoesHistorico(
                    key,
                    _vm.historicos[_vm.historicos.length - 1].antes
                  )),function(str){return _c('p',{key:str,staticStyle:{"margin-bottom":"10px","text-align":"left"}},[_vm._v(" "+_vm._s(str)+" ")])}),0)}),0),_c('td',{staticClass:"text-tab",staticStyle:{"width":"30%"}}),_c('td',{staticClass:"text-tab"}),_c('td',{staticClass:"text-tab"})])]):_vm._e()]},proxy:true}])})],1),_c('ModalLerJustificativaInteira',{attrs:{"modalVisible":_vm.modalOpen,"justificativa":_vm.justificativaSelecionada},on:{"close":function($event){_vm.modalOpen = !_vm.modalOpen & (_vm.justificativaSelecionada = null)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }