<template>
  <v-dialog v-model="modalVisible" persistent max-width="950">
    <v-card style="border-radius: 10px">
      <br />
      <v-card-title v-if="isEdit" class="modal-title"
        >EDITAR OCORRÊNCIA</v-card-title
      >
      <v-card-title v-else class="modal-title"
        >CADASTRAR OCORRÊNCIA</v-card-title
      >
      <v-card-text>
        <v-form ref="formOcorrencia">
          <v-row>
            <v-col class="label-col" md="3">
              <p class="label">Título:</p>
            </v-col>
            <v-col md="9">
              <v-text-field
                v-model="titulo"
                outlined
                dense
                :rules="[(v) => !!v || 'Título obrigatório']"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="label-col" md="3">
              <p class="label">Data da ocorrência:</p>
            </v-col>
            <v-col md="3">
              <DatePicker v-model="data" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                v-model="descricao"
                outlined
                rows="10"
                placeholder="Descreva a ocorrência..."
                required
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions
        style="display: flex; justify-content: space-evenly; padding-bottom: 20px"
      >
        <v-btn outlined width="190" color="#FF8D3F" @click="closeModal(false)">
          Cancelar
        </v-btn>
        <v-btn
          v-if="isEdit"
          depressed
          width="190"
          class="btn-controls"
          color="#FF8D3F"
          @click="editarOcorrencia"
        >
          Editar
        </v-btn>
        <v-btn
          v-else
          depressed
          width="190"
          class="btn-controls"
          color="#FF8D3F"
          @click="cadastrarOcorrencia"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { EventBus } from "../../services/event-bus";
import DatePicker from "../DatePicker";
import {
  atualizarOcorrencia,
  cadastrarOcorrencia,
} from "../../services/providers/ocorrenciasProvider";

export default {
  name: "ModalCrudOcorrencia",
  components: {
    DatePicker,
  },
  props: {
    modalVisible: Boolean,
    ocorrencia: Object,
  },
  data: () => ({
    isEdit: false,
    titulo: "",
    data: new Date().toISOString().slice(0, 10),
    descricao: "",
  }),
  created() {
    this.isEdit = !!this.ocorrencia;
    if (this.ocorrencia) {
      this.titulo = this.ocorrencia.titulo;
      this.data = this.ocorrencia.data;
      this.descricao = this.ocorrencia.conteudo;
    }
  },
  methods: {
    closeModal(reload) {
      this.titulo = "";
      this.data = new Date().toISOString().slice(0, 10);
      this.descricao = "";
      if (reload) {
        EventBus.$emit("ocorrencia-updated");
      }
      this.$emit("close");
    },
    async cadastrarOcorrencia() {
      if (this.$refs.formOcorrencia.validate()) {
        try {
          const payload = {
            titulo: this.titulo,
            data: this.data,
            conteudo: this.descricao,
            projetoId: this.$store.projeto.id,
          };
          await cadastrarOcorrencia(payload);
          this.$notify({
            title: "Ocorrência cadastrada!",
            text: "Ocorrência cadastrada com sucesso!",
            type: "success",
            duration: 5000,
          });
          this.closeModal(true);
        } catch (error) {
          console.error(error);
          this.$notify({
            title: "Erro interno!",
            text: error.message || error,
            type: "error",
            duration: 15000,
          });
        }
      }
    },
    async editarOcorrencia() {
      if (this.$refs.formOcorrencia.validate()) {
        try {
          const payload = {
            titulo: this.titulo,
            data: this.data,
            conteudo: this.descricao,
          };
          await atualizarOcorrencia(this.ocorrencia.id, payload);
          this.$notify({
            title: "Ocorrência editada!",
            text: "Ocorrência editada com sucesso!",
            type: "success",
            duration: 5000,
          });
          this.closeModal(true);
        } catch (error) {
          console.error(error);
          this.$notify({
            title: "Erro interno!",
            text: error.message || error,
            type: "error",
            duration: 15000,
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
