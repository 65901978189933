import api from "../api"

export const listagemDeProjetos = async () => {
    const res = await api.get("/projetos");
    return res.data;
}

export const adicionarDadosProjeto = async (payload) => {
    const res = await api.post(`/projetos`, payload);
    return res.data;
};

export const adicionarParcelas = async (payload) => {
    const res = await api.post(`/parcelas/cadastro-parcela`, payload);
    return res.data;
};

export const adicionarSubprojetos = async (payload) => {
    const res = await api.post(`/subprojetos/cadastro-subprojeto`, payload);
    return res.data;
};

export const editarSubprojeto = async (idSubprojeto,payload) => {
    const res = await api.patch(`/subprojetos/${idSubprojeto}`,payload);
    return res.data;
};

export const finalizarProjeto = async (id) => {
    const res = await api.put(`/projetos/finalizar-cadastro-projeto/${id}`);
    return res.data;
};

export const getProjetoById = async (idProjeto) => {
    const res = await api.get(`/projetos/${idProjeto}`);
    return res.data;
};

export const editProjetoById = async (idProjeto, payload) => {
    const res = await api.patch(`/projetos/${idProjeto}`,payload);
    return res.data;
};