<template>
  <v-dialog v-model="modalVisible" persistent max-width="1100">
    <v-card style="border-radius: 10px">
      <br />
      <div style="width: 100%; display: flex; justify-content: flex-end">
        <v-btn text large @click="closeModal()">
          <v-icon large color="#037F8C">mdi-close-circle-outline</v-icon>
        </v-btn>
      </div>
      <v-card-title class="modal-title">INFORMAÇÕES DA ATIVIDADE</v-card-title>
      <v-card-text v-if="atividade">
        <div v-if="atividade.meta" class="session">
          {{ atividade.meta.subprojeto.codigo }}
        </div>
        <br />
        <div style="padding-left: 20px; padding-right: 20px">
          <v-row>
            <v-col class="t-label-col t-label-inline" md="3">
              <p class="t-label">Meta relacionada:</p>
            </v-col>
            <v-col md="9" class="t-label-inline">
              <p v-if="atividade && atividade.meta" class="t-label-value">
                {{ atividade.meta.descricao }}
              </p>
              <p v-else>-</p>
            </v-col>
          </v-row>
        </div>
        <br />
        <div>
          <div class="session">RESPONSÁVEIS</div>
          <EquipeTable
            v-if="responsaveis.length"
            :participantes="responsaveis"
          />
          <p v-else class="t-label-value">
            <br />
            Nenhum responsável associado a esta atividade
          </p>
        </div>
        <br />
        <br />
        <div>
          <div class="session">ITENS ASSOCIADOS</div>
          <itens-table
            v-if="atividade && atividade.itens.length"
            :itens="atividade.itens"
            :showActions="false"
          />
          <p v-else class="t-label-value">
            <br />
            Nenhum item associado a esta atividade
          </p>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { EventBus } from "../../services/event-bus";
import { filtrarParticipantes } from "../../services/providers/participantesProvider";
import EquipeTable from "../tables/EquipeTable.vue";
import ItensTable from "../tables/ItensTable.vue";
export default {
  name: "ModalVerAtividade",
  components: { EquipeTable, ItensTable, },
  props: {
    modalVisible: Boolean,
    atividade: Object,
  },
  data: () => ({
    responsaveis: [],
  }),
  created() {
    this.getEquipe();
    EventBus.$on("participante-updated", async () => {
      await this.getEquipe();
    });
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async getEquipe() {
      try {
        if (this.atividade) {
          this.responsaveis = await filtrarParticipantes({
            atividades: [this.atividade.id],
          });
        }
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro interno!",
          text: error.message || error,
          type: "error",
          duration: 15000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.session {
  width: 100%;
  padding: 15px;
  padding-left: 25px;
  background-color: #e6f4f1;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  display: flex;
  color: #037f8c;
}
.t-label {
  color: #616161;
  font-size: 18px;
  text-align: left;
  font-weight: bold;
  margin: 0px;
}
.t-label-value {
  font-family: Roboto;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #616161;
}
.t-label-col {
  display: flex;
  justify-content: flex-start;
}
.t-label-inline {
  padding-bottom: 0px;
  padding-top: 0px;
}
</style>
