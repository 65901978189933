<template>
  <div>
    <div v-if="$store.subProjeto && isExactActive" class="container-div">
      <div class="header">
        <v-btn
          v-if="$route.name == 'resumo'"
          class="header-button"
          color="white"
          text
          @click="$router.go(-1)"
        >
          <v-icon large color="#037F8C">mdi-arrow-left-circle-outline</v-icon>
          <PageTitle
            :title="`<b>SUBPROJETOS</b>/${$store.subProjeto.codigo}`"
            size="18"
          />
        </v-btn>
        <v-btn
          v-if="$route.name == 'itens'"
          class="header-button"
          color="white"
          text
          @click="$router.go(-1)"
        >
          <v-icon large color="#037F8C">mdi-arrow-left-circle-outline</v-icon>
          <PageTitle
            :title="
              `<b>SUBPROJETOS/${$store.subProjeto.codigo}</b>/Verficar Itens`
            "
            size="18"
          />
        </v-btn>
        <span>
          <v-btn
            depressed
            :disabled="!itensHomologacao.length"
            color="#FF8D3F"
            class="btn-controls"
            width="210"
            style=" margin-right: 15px"
            @click="modalHomologacaoItensOpen = !modalHomologacaoItensOpen"
            >HOMOLOGAR ITENS</v-btn
          >
          <v-btn outlined width="210" color="#037F8C" @click="exportarItens">
            EXPORTAR RELATÓRIO
          </v-btn>
        </span>
      </div>
      <div>
        <div class="header">
          <PageTitle :title="`<b>${$store.subProjeto.codigo}</b>`" />
          <span>
            <v-btn
              depressed
              class="btn-controls"
              width="210"
              color="#FF8D3F"
              style=" margin-right: 15px"
              @click="modalResponsaveisOpen = !modalResponsaveisOpen"
            >
              <v-icon>mdi-account</v-icon>
              RESPONSÁVEIS
            </v-btn>
            <v-btn
              v-if="$route.name == 'resumo'"
              depressed
              class="btn-controls"
              width="210"
              color="#037F8C"
              :to="{
                path: `/projetos-vigentes/${$store.projeto.id}/subprojetos/${$store.subProjeto.id}/itens`,
              }"
            >
              VERIFICAR ITENS
            </v-btn>
            <v-tooltip v-if="$route.name == 'itens'" left color="transparent">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                depressed
                color="#037F8C"
                width="210"
                style="color: #fff; border-radius: 8px"
                @click="modalAddItemOpen = !modalAddItemOpen"
                :disabled="!itemEditavel"
              > 
                CADASTRAR ITEM
              </v-btn>
            </template>
          </v-tooltip>
          </span>
        </div>
        <br />
        <GraficosFinanceirosSubprojeto v-if="$route.name == 'resumo'" />
        <ListagemItens v-if="$route.name == 'itens'" />
        <br />
      </div>

      <ModalGerarRelatorioProjeto
        v-if="$store.subProjeto"
        :modalVisible="modalGerarRelatorioSubProjeto"
        @close="modalGerarRelatorioSubProjeto = !modalGerarRelatorioSubProjeto"
      />
      <ModalHomologacaoItens
        :key="itensHomologacao.length"
        :modalVisible="modalHomologacaoItensOpen"
        :itens="itensHomologacao"
        @close="modalHomologacaoItensOpen = !modalHomologacaoItensOpen"
      />
      <ModalAdicionarItem
        :key="String(modalAddItemOpen)"
        :modalVisible="modalAddItemOpen"
        @close="modalAddItemOpen = !modalAddItemOpen"
      />
      <ModalResponsaveisSubprojeto
        :modalVisible="modalResponsaveisOpen"
        @close="modalResponsaveisOpen = !modalResponsaveisOpen"
      />
    </div>
    <router-view ref="rvi"></router-view>
  </div>
</template>

<script>
import PageTitle from "../PageTitle";
import ModalHomologacaoItens from "../modals/ModalHomologacaoItens";
import ModalGerarRelatorioProjeto from "../modals/ModalGerarRelatorioProjeto";
import GraficosFinanceirosSubprojeto from "./GraficosFinanceirosSubprojeto";
import { listarItensPendentesHomologacaoProjeto } from "../../services/providers/itensProvider";
import ListagemItens from "../itens/ListagemItens";
import { makePDF } from "../../utils/makePDFRelatorioItens";
import { getRubricasItensSubProjeto } from "../../services/providers/rubricaProvider";
import ModalAdicionarItem from "../modals/ModalAdicionarItem";
import { EventBus } from "../../services/event-bus";
import ModalResponsaveisSubprojeto from "../modals/ModalResponsaveisSubprojeto.vue";

export default {
  name: "ResumoSubprojeto",
  components: {
    PageTitle,
    ModalHomologacaoItens,
    ModalGerarRelatorioProjeto,
    GraficosFinanceirosSubprojeto,
    ListagemItens,
    ModalAdicionarItem,
    ModalResponsaveisSubprojeto,
  },
  data: () => ({
    subProjeto: null,
    isExactActive: true,
    itensHomologacao: [],
    modalHomologacaoItensOpen: false,
    modalAddItemOpen: false,
    modalGerarRelatorioSubProjeto: false,
    modalResponsaveisOpen: false,
  }),
  updated() {
    this.isExactActive = typeof this.$refs.rvi === "undefined";
  },
  created() {
    this.isExactActive = typeof this.$refs.rvi === "undefined";
  },
  mounted() {
    this.getData();
    EventBus.$on("item-updated", async () => {
      await this.getData();
    });
    EventBus.$on("item-homologated", async () => {
      await this.getData();
    });
  },
  watch: {
    "$store.subProjeto": function() {
      this.getData();
    },
  },
  methods: {
    async getData() {
      try {
        this.itensHomologacao = await listarItensPendentesHomologacaoProjeto(
          this.$store.projeto.id
        );

        this.itensHomologacao.sort((a,b) => {
          let dataA = a.dataEdicao == null ? a.dataCriacao : a.dataEdicao;
          let dataB = b.dataEdicao == null ? b.dataCriacao : b.dataEdicao;
          return Date.parse(dataB) - Date.parse(dataA);
        })

        this.isItensParaHomologar = this.itensHomologacao.length;
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro interno!",
          text: error.message || error,
          type: "error",
          duration: 15000,
        });
      }
    },
    async exportarItens() {
      const rubricas = await getRubricasItensSubProjeto(
        this.$store.subProjeto.id
      );
      makePDF(this.$store.projeto, this.$store.subProjeto, rubricas);
    },
  },
  computed: {
    itemEditavel() {

      const projetoEmReformulacao = this.$store.projeto.reformulacao ? true : false;
      const projetoEmCadastro = this.$store.projeto.estadoCadastroProjeto != 'FINALIZADO';

      return projetoEmReformulacao || projetoEmCadastro;
    }
  }
};
</script>

<style lang="scss" scoped>
.add-item-label {
  background: #e6f4f1;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #037f8c;
  padding: 10px;
  border-radius: 10px;
  margin-top: 15px;
}
</style>
