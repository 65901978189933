<template>
    <div class="ma-0 pa-0 container-main" width="100%">
        <v-container class="ma-0 pa-0" width="100%">
            <v-row>
                <v-col
                    cols="8"
                    offset="2"
                >
                    <v-card class="d-flex justify-center mb-6 mt-6" flat tile>
                        <v-card-text class="card">
                            <div class="d-flex justify-center mb-4 mt-2">
                                <v-icon size="200PX" color="#1DA7A3">
                                    mdi-thumb-up
                                </v-icon>
                            </div>
                            
                            <div class="d-flex justify-center mb-4 mt-15">
                                <h1 class="card-text">
                                    Cadastro Finalizado<br>
                                    com sucesso!
                                </h1>
                            </div>

                            <div class="d-flex justify-center mb-4 mt-15">
                                <v-btn
                                    large
                                    centered
                                    depressed
                                    outlined
                                    color="#FF8D3F"
                                    class="btn-margin btn-size"
                                    @click="novoProjeto"
                                >
                                    Novo Projeto
                                </v-btn>
                                <v-btn
                                    large
                                    centered
                                    depressed
                                    color="#FF8D3F"
                                    class="btn-controls btn-size"
                                    @click="carregarProjeto"
                                >
                                    Visualizar Projeto
                                </v-btn>
                            </div>
                            
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>

import {getProjetoById} from "../../services/providers/projetosProvider"

export default {
    name: "CadastroFinalizado",
    data() {
        return {
            projetoId: null,
        }
    },
    mounted() {
        this.$actions.setDrawerOpen(false);
        this.projetoId = this.$route.params.id;
    },

    methods: {
        async getProjeto(id) {
            try {
                const projeto = await getProjetoById(id);
                return projeto;
            } catch (error) {
                console.error(error);
                this.$notify({
                title: "Erro interno!",
                text: error.message || error,
                type: "error",
                duration: 15000,
                });
            }
        },
        novoProjeto() {
            this.$router.push ({
                name: "novo-cadastro"
            })
        },
        async carregarProjeto() {
            const projeto = await this.getProjeto(this.projetoId);
            this.$actions.setProjeto(projeto);
            this.$router.push({
                name: "financeiro",
                params: { id: projeto.id, projeto: projeto },
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.container-main {
    width: 100%;
    height: 100vh;
    background-image: url("../../assets/malha.png");
    background-size: cover;
    background-attachment: fixed;
    border: none;
}
.card {
    background-color: #E6F4F1;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 15px 15px 15px 15px;
}
.btn-margin {
    margin-right: 5%;
}
.btn-size {
    width: 40%;
}
.card-text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 49px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #037F8C;
}
</style>