var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('br'),_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"title-tab",staticStyle:{"width":"5%"},attrs:{"rowspan":"2"}},[_vm._v("Nº")]),_c('th',{staticClass:"title-tab",staticStyle:{"width":"30%"},attrs:{"rowspan":"2"}},[_vm._v("ATIVIDADES")]),_c('th',{staticClass:"title-tab",attrs:{"rowspan":"2"}},[_vm._v("INDICADOR FÍSICO DE EXECUÇÃO")]),_c('th',{staticClass:"title-tab",attrs:{"colspan":"2"}},[_vm._v(" DURAÇÃO PREVISTA (MÊS DO PROJETO) ")]),_c('th',{staticClass:"title-tab",attrs:{"rowspan":"2"}})]),_c('tr',[_c('th',{staticClass:"title-tab"},[_vm._v("INÍCIO")]),_c('th',{staticClass:"title-tab"},[_vm._v("FIM")])])]),_c('tbody',[_vm._l((_vm.atividades),function(item,idx){return _c('tr',{key:item.id},[_c('td',{staticClass:"text-tab"},[_vm._v("A"+_vm._s(idx + 1))]),(item.descricao)?_c('td',{staticClass:"text-tab"},[_vm._v(" "+_vm._s(item.descricao)+" ")]):_c('td',{staticClass:"text-tab"},[_vm._v(" - ")]),(item.indicador)?_c('td',{staticClass:"text-tab"},[_vm._v(" "+_vm._s(item.indicador)+" ")]):_c('td',{staticClass:"text-tab"},[_vm._v(" - ")]),(item.inicio)?_c('td',{staticClass:"text-tab"},[_vm._v(" "+_vm._s(item.inicio)+" ")]):_c('td',{staticClass:"text-tab"},[_vm._v(" - ")]),(item.fim)?_c('td',{staticClass:"text-tab"},[_vm._v(" "+_vm._s(item.fim)+" ")]):_c('td',{staticClass:"text-tab"},[_vm._v(" - ")]),_c('td',{staticClass:"text-tab",staticStyle:{"width":"10%","padding-left":"0px","padding-right":"0px"}},[(_vm.itemSelecionado)?_c('div',{staticStyle:{"display":"flex","flex-direction":"row"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"option-hover",attrs:{"small":"","text":""},on:{"click":function($event){(_vm.modalVerOpen = !_vm.modalVerOpen) &
                        (_vm.atividadeSelecionada = item)}}},on),[_c('v-icon',{attrs:{"color":"#ff8d3f"}},[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v(" Visualizar ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"option-hover",attrs:{"text":"","small":""},on:{"click":function($event){(_vm.modalCrudOpen = !_vm.modalCrudOpen) &
                        (_vm.atividadeSelecionada = item)}}},on),[_c('v-icon',{attrs:{"color":"#ff8d3f"}},[_vm._v("mdi-pencil-box-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(" Editar ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"option-hover",attrs:{"text":"","small":""},on:{"click":function($event){(_vm.modalExcluirOpen = !_vm.modalExcluirOpen) &
                        (_vm.atividadeSelecionada = item)}}},on),[_c('v-icon',{attrs:{"color":"#ff8d3f"}},[_vm._v("mdi-delete-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(" Excluir ")])])],1):_c('div',{staticStyle:{"display":"flex","flex-direction":"row"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"option-hover",attrs:{"small":"","text":""},on:{"click":function($event){(_vm.modalVerOpen = !_vm.modalVerOpen) &
                        (_vm.atividadeSelecionada = item)}}},on),[_c('v-icon',{attrs:{"color":"#ff8d3f"}},[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v(" Visualizar ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"option-hover",attrs:{"small":"","text":""},on:{"click":function($event){(_vm.modalDesassociar = !_vm.modalDesassociar) &
                        (_vm.atividadeSelecionada = item)}}},on),[_c('v-icon',{attrs:{"color":"#ff8d3f"}},[_vm._v("mdi-account-minus")])],1)]}}],null,true)},[_c('span',[_vm._v(" Desassociar ")])])],1)])])}),_c('td',{staticClass:"div-body",attrs:{"colspan":"6"}},[_c('div',{staticClass:"text-body",staticStyle:{"border-right":"0.899715px solid rgba(97, 97, 97, 0.3)"}},[_vm._v(" TOTAL DE ATIVIDADES: "+_vm._s(_vm.atividades.length)+" ")])])],2)]},proxy:true}])}),_c('ModalVerAtividade',{key:String(_vm.modalVerOpen) + 'modalVer',attrs:{"modalVisible":_vm.modalVerOpen,"atividade":_vm.atividadeSelecionada},on:{"close":function($event){_vm.modalVerOpen = !_vm.modalVerOpen}}}),_c('ModalCrudAtividades',{key:String(_vm.modalCrudOpen) + 'modalCrud',attrs:{"modalVisible":_vm.modalCrudOpen,"atividade":_vm.atividadeSelecionada,"meta":_vm.atividadeSelecionada && _vm.atividadeSelecionada.meta},on:{"close":function($event){_vm.modalCrudOpen = !_vm.modalCrudOpen}}}),(_vm.atividadeSelecionada)?_c('ModalConfirmacao',{attrs:{"modalVisible":_vm.modalExcluirOpen,"titulo":'Você realmente deseja excluir a atividade',"textoBotaoDireito":"Excluir","onConfirm":_vm.deletarAtividade},on:{"close":function($event){_vm.modalExcluirOpen = !_vm.modalExcluirOpen}}}):_vm._e(),(_vm.atividadeSelecionada)?_c('ModalConfirmacao',{attrs:{"modalVisible":_vm.modalDesassociar,"titulo":'Você realmente deseja dessassociar a atividade do item',"textoBotaoDireito":"Desassociar","onConfirm":_vm.desassociarAtividade},on:{"close":function($event){_vm.modalDesassociar = !_vm.modalDesassociar}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }