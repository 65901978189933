<template>
  <v-dialog v-model="modalVisible" persistent max-width="1100">
    <v-card style="border-radius: 10px">
      <br />
      <div style="width: 100%; display: flex; justify-content: flex-end">
        <v-btn text large @click="closeModal()">
          <v-icon large color="#037F8C">mdi-close-circle-outline</v-icon>
        </v-btn>
      </div>
      <v-card-title style="justify-content: space-between">
        <p class="modal-title">RESPONSÁVEIS PELO ITEM</p>
        <v-btn outlined color="#037F8C" width="240" @click="isAddMode = true">
          ADICIONAR RESPONSÁVEL
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div v-if="isAddMode" style="padding-left: 10%; padding-right: 10%">
          <br />
          <p class="modal-title">ADICIONAR RESPONSÁVEL AO ITEM</p>
          <v-form ref="formAddParticipante">
            <v-row>
              <v-col class="label-col" md="2">
                <p class="label">Tipo de participante:</p>
              </v-col>
              <v-col md="10">
                <v-select
                  outlined
                  dense
                  clearable
                  class="select-form"
                  color="#037F8C"
                  item-color="#037F8C"
                  :items="tiposParticipanteFormatted"
                  v-model="formFields.tipoParticipante"
                  item-text="label"
                  item-value="value"
                  label="Selecione o tipo"
                  :rules="[(v) => !!v || 'Selecione o tipo']"
                  required
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="label-col" md="2">
                <p class="label">Tipo de equipe:</p>
              </v-col>
              <v-col md="10">
                <v-select
                  outlined
                  dense
                  clearable
                  class="select-form"
                  color="#037F8C"
                  item-color="#037F8C"
                  :items="tiposEquipeFormatted"
                  v-model="formFields.tipoEquipe"
                  item-text="label"
                  item-value="value"
                  label="Selecione o tipo"
                  :rules="[(v) => !!v || 'Selecione o tipo']"
                  required
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="label-col" md="2">
                <p class="label">Nome:</p>
              </v-col>
              <v-col md="10">
                <v-combobox
                  outlined
                  dense
                  v-model="formFields.nome"
                  :items="participantesEncontrados"
                  :loading="isLoading"
                  :search-input.sync="searchParticipanteByName"
                  item-text="pessoa.nome"
                  item-value="pessoa.nome"
                  :rules="[(v) => !!v || 'Nome é obrigatório']"
                  @change="fillParticipante"
                  required
                />
              </v-col>
            </v-row>
          </v-form>
          <div style="display: flex; justify-content: space-evenly;">
            <v-btn
              small
              outlined
              width="190"
              color="#037F8C"
              @click="isAddMode = false"
            >
              Cancelar
            </v-btn>
            <v-btn
              small
              depressed
              width="190"
              class="btn-controls"
              color="#037F8C"
              :disabled="!participante"
              @click="associarParticipante"
            >
              ADICIONAR
            </v-btn>
          </div>
          <br />
        </div>
        <div>
          <EquipeTable
            v-if="responsaveis.length"
            :participantes="responsaveis"
            :showActionButtons="false"
            :showDesassociarButton="true"
            @desassociarClicked="onDesassociarClicked"
          />
          <p v-else class="t-label-value">
            <br />
            Nenhum responsável associado a este item
          </p>
        </div>
        <br />
      </v-card-text>
    </v-card>
    <ModalConfirmacao
      v-if="participanteSelecionado"
      :modalVisible="modalDesassociarOpen"
      :titulo="
        'Você realmente deseja retirar a responsabilidade deste participante sobre este item'
      "
      textoBotaoDireito="Desassociar"
      :onConfirm="desassociarParticipante"
      @close="modalDesassociarOpen = !modalDesassociarOpen"
    />
  </v-dialog>
</template>

<script>
import { filtrarParticipantes } from "../../services/providers/participantesProvider";
import EquipeTable from "../tables/EquipeTable";
import { TIPO_EQUIPE, TIPO_PARTICIPANTE } from "../../utils/enums";
import {
  adicionarResponsavelItem,
  removerResponsavelItem,
} from "../../services/providers/itensProvider";
import ModalConfirmacao from "./ModalConfirmacao.vue";

const defaultForm = {
  nome: null,
  tipoParticipante: null,
  tipoEquipe: null,
};

export default {
  name: "ModalResponsaveisItem",
  components: {
    EquipeTable,
    ModalConfirmacao,
  },
  props: {
    modalVisible: Boolean,
    item: Object,
  },
  data: () => ({
    responsaveis: [],
    isAddMode: false,
    isLoading: false,
    formFields: { ...defaultForm },
    tiposParticipanteFormatted: [],
    tiposEquipeFormatted: [],
    participantesEncontrados: [],
    searchParticipanteByName: null,
    modalDesassociarOpen: false,
    participante: null,
    participanteSelecionado: null,
  }),
  created() {
    this.getEquipe();
  },
  mounted() {
    this.tiposParticipanteFormatted = Object.keys(TIPO_PARTICIPANTE).map(
      (k) => ({
        value: k,
        label: TIPO_PARTICIPANTE[k],
      })
    );
    this.tiposEquipeFormatted = Object.keys(TIPO_EQUIPE).map((k) => ({
      value: k,
      label: TIPO_EQUIPE[k],
    }));
  },
  methods: {
    closeModal() {
      this.clearForm();
      this.$emit("close");
    },
    clearForm() {
      this.isAddMode = false;
      this.participanteSelecionado = null;
      this.formFields = { ...defaultForm };
    },
    fillParticipante(participante) {
      this.participante = participante;
    },
    onDesassociarClicked(participante) {
      this.participanteSelecionado = participante;
      this.modalDesassociarOpen = !this.modalDesassociarOpen;
    },
    async associarParticipante() {
      try {
        const payload = {
          itemId: this.item.id,
          participanteId: this.participante.id,
        };
        await adicionarResponsavelItem(payload);
        this.clearForm();
        await this.getEquipe();
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro interno!",
          text: error.message || error,
          type: "error",
          duration: 15000,
        });
      }
    },
    async desassociarParticipante() {
      try {
        const payload = {
          itemId: this.item.id,
          participanteId: this.participanteSelecionado.id,
        };
        await removerResponsavelItem(payload);
        this.clearForm();
        await this.getEquipe();
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro interno!",
          text: error.message || error,
          type: "error",
          duration: 15000,
        });
      }
    },
    async getEquipe() {
      try {
        if (this.item) {
          this.responsaveis = await filtrarParticipantes({
            itens: [this.item.id],
          });
        }
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro interno!",
          text: error.message || error,
          type: "error",
          duration: 15000,
        });
      }
    },
  },
  watch: {
    isAddMode: function() {
      this.formFields = { ...defaultForm };
    },
    searchParticipanteByName: async function(val) {
      try {
        this.formFields.nome = val;
        if (val && val.length > 2) {
          this.$actions.setDisableGlobalLoader(true);
          this.isLoading = true;
          this.participantesEncontrados = await filtrarParticipantes({
            nome: this.formFields.nome,
            tipoParticipante: this.formFields.tipoParticipante,
            tipoEquipe: this.formFields.tipoEquipe,
          });
          this.isLoading = false;
          this.$actions.setDisableGlobalLoader(false);
        }
      } catch (error) {
        this.isLoading = false;
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.t-label-value {
  font-family: Roboto;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #616161;
}
</style>
