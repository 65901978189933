import api from "../api"

export const listarSnapshotCronogramasProjeto = async (idProjeto) => {
  const res = await api.get(`/snapshot-cronograma/projeto/search?projetos=${idProjeto}`);
  return res.data;
}

export const listarSnapshotCronogramasSubProjeto = async (idSubprojeto) => {
  const res = await api.get(`/snapshot-cronograma/subprojeto/search?subprojetos=${idSubprojeto}`);
  return res.data;
}

export const salvarSnapshotCronogramasProjeto = async (payload) => {
  const res = await api.post(`/snapshot-cronograma/projeto/salvar`, payload);
  return res.data;
}

export const salvarSnapshotCronogramasSubProjeto = async (payload) => {
  const res = await api.post(`/snapshot-cronograma/subprojeto/salvar`, payload);
  return res.data;
}