<template>
  <v-col>
    <div class="header">
      <PageTitle title="<b>RESUMO DOS RECURSOS</b>" />
      <v-btn
        class="btn-controls"
        depressed
        color="#037F8C"
        @click="modalEditDespesaPrevOpen = !modalEditDespesaPrevOpen"
        :disabled="!itemEditavel"
        >EDITAR RECURSO PREVISTO</v-btn
      >
    </div>
    <br />
    <div class="resumobox">
      <v-row>
        <v-col>
          <v-row v-if="item">
            <v-col md="4"></v-col>
            <v-col md="2" class="despesa-title">QTD</v-col>
            <v-col md="3" class="despesa-title">VALOR UNITÁRIO</v-col>
            <v-col md="3" class="despesa-title">VALOR TOTAL</v-col>
          </v-row>
          <p v-else style="font-family: Raleway; font-size: 18px">
            Nenhuma despesa realizada!
          </p>
        </v-col>
      </v-row>

      <!-- ITEM -->
      <div v-if="item">
        <p class="tipo-despesa-title">TOTAL</p>
        <v-row>
          <v-col>
            <v-row>
              <v-col md="4" class="t-label-inline despesa-title no-alg"
                >PREVISTO:</v-col
              >
              <v-col
                md="2"
                v-if="item && item.quantidade"
                class="t-label-inline despesa-title-value"
                >{{ item.quantidade }}</v-col
              >
              <v-col md="2" class="t-label-inline despesa-title-value" v-else
                >-</v-col
              >
              <v-col
                md="3"
                v-if="item && item.valorUnitario"
                class="t-label-inline despesa-title-value"
                >{{ item.valorUnitario | currency }}</v-col
              >
              <v-col md="3" class="t-label-inline despesa-title-value" v-else
                >-</v-col
              >
              <v-col
                md="3"
                v-if="item && item.valorAlocado"
                class="t-label-inline despesa-title-value"
                >{{ item.valorAlocado | currency }}</v-col
              >
              <v-col md="3" class="t-label-inline despesa-title-value" v-else
                >-</v-col
              >
            </v-row>
          </v-col>
        </v-row>
        <hr />
        <v-row>
          <v-col>
            <v-row>
              <v-col md="4" class="t-label-inline despesa-title no-alg"
                >REALIZADO:</v-col
              >
              <v-col md="2" class="t-label-inline despesa-title-value">-</v-col>
              <v-col md="3" class="t-label-inline despesa-title-value">-</v-col>
              <v-col
                md="3"
                v-if="item && item.valorExecutado"
                class="t-label-inline despesa-title-value"
                >{{ item.valorExecutado | currency }}</v-col
              >
              <v-col md="3" class="t-label-inline despesa-title-value" v-else
                >-</v-col
              >
            </v-row>
          </v-col>
        </v-row>
        <hr />
        <br />
        <br />
      </div>

      <!-- FNDCT -->
      <div v-if="totalPrevistoItemFndct || totalRealizadoItemFndct">
        <div v-if="totalPrevistoItemFndct">
          <p class="tipo-despesa-title">FNDCT</p>
          <v-row>
            <v-col>
              <v-row>
                <v-col md="4" class="t-label-inline despesa-title no-alg"
                  >PREVISTO:</v-col
                >
                <v-col md="2" class="t-label-inline despesa-title-value"
                  >-</v-col
                >
                <v-col md="3" class="t-label-inline despesa-title-value"
                  >-</v-col
                >
                <v-col
                  md="3"
                  v-if="totalPrevistoItemFndct"
                  class="t-label-inline despesa-title-value"
                  >{{ totalPrevistoItemFndct | currency }}</v-col
                >
                <v-col md="3" class="t-label-inline despesa-title-value" v-else
                  >-</v-col
                >
              </v-row>
            </v-col>
          </v-row>
          <hr />
        </div>
        <div v-if="totalRealizadoItemFndct">
          <v-row>
            <v-col>
              <v-row>
                <v-col md="4" class="t-label-inline despesa-title no-alg"
                  >REALIZADO:</v-col
                >
                <v-col md="2" class="t-label-inline despesa-title-value"
                  >-</v-col
                >
                <v-col md="3" class="t-label-inline despesa-title-value"
                  >-</v-col
                >
                <v-col
                  md="3"
                  v-if="totalRealizadoItemFndct"
                  class="t-label-inline despesa-title-value"
                  >{{ totalRealizadoItemFndct | currency }}</v-col
                >
                <v-col md="3" class="t-label-inline despesa-title-value" v-else
                  >-</v-col
                >
              </v-row>
            </v-col>
          </v-row>
          <hr />
        </div>
      </div>
      <br />
      <!-- RENDIMENTO -->
      <div v-if="totalPrevistoItemRendimento || totalRealizadoItemRendimento">
        <div v-if="totalPrevistoItemRendimento">
          <p class="tipo-despesa-title">RENDIMENTO</p>
          <v-row>
            <v-col>
              <v-row>
                <v-col md="4" class="t-label-inline despesa-title no-alg"
                  >PREVISTO:</v-col
                >
                <v-col md="2" class="t-label-inline despesa-title-value"
                  >-</v-col
                >
                <v-col md="3" class="t-label-inline despesa-title-value"
                  >-</v-col
                >
                <v-col
                  md="3"
                  v-if="totalPrevistoItemRendimento"
                  class="t-label-inline despesa-title-value"
                  >{{ totalPrevistoItemRendimento | currency }}</v-col
                >
                <v-col md="3" class="t-label-inline despesa-title-value" v-else
                  >-</v-col
                >
              </v-row>
            </v-col>
          </v-row>
          <hr />
        </div>
        <div v-if="totalRealizadoItemRendimento">
          <v-row>
            <v-col>
              <v-row>
                <v-col md="4" class="t-label-inline despesa-title no-alg"
                  >REALIZADO:</v-col
                >
                <v-col md="2" class="t-label-inline despesa-title-value"
                  >-</v-col
                >
                <v-col md="3" class="t-label-inline despesa-title-value"
                  >-</v-col
                >
                <v-col
                  md="3"
                  v-if="totalRealizadoItemRendimento"
                  class="t-label-inline despesa-title-value"
                  >{{ totalRealizadoItemRendimento | currency }}</v-col
                >
                <v-col md="3" class="t-label-inline despesa-title-value" v-else
                  >-</v-col
                >
              </v-row>
            </v-col>
          </v-row>
          <hr />
        </div>
      </div>
    </div>
    <ModalEdtInfoGeraisItem
      v-if="modalEditInfoOpen"
      :key="modalEditInfoOpen"
      :modalVisible="modalEditInfoOpen"
      :itemProp="item"
      @close="modalEditInfoOpen = !modalEditInfoOpen"
    />
    <ModalEdtRecursosPrevItem
      v-if="modalEditDespesaPrevOpen"
      :key="modalEditDespesaPrevOpen"
      :modalVisible="modalEditDespesaPrevOpen"
      :itemProp="item"
      @close="modalEditDespesaPrevOpen = !modalEditDespesaPrevOpen"
    />
  </v-col>
</template>

<script>
import PageTitle from "../../PageTitle.vue";
import ModalEdtInfoGeraisItem from "../../modals/ModalEdtInfoGeraisItem.vue";
import ModalEdtRecursosPrevItem from "../../modals/ModalEdtRecursosPrevItem.vue";

export default {
  name: "ResumoDespesas",
  components: {
    PageTitle,
    ModalEdtInfoGeraisItem,
    ModalEdtRecursosPrevItem,
  },
  props: {
    item: Object,
  },
  data: () => ({
    modalEditInfoOpen: false,
    modalEditDespesaPrevOpen: false,
    totalPrevistoItemFndct: 0,
    totalRealizadoItemFndct: 0,
    totalPrevistoItemRendimento: 0,
    totalRealizadoItemRendimento: 0,
  }),
  created() {
    if (this.item && this.item.recursos) {
      this.item.recursos.forEach((r) => {
        if (r.fonte && r.fonte.codigo == "F") {
          this.totalPrevistoItemFndct += r.valorAlocado;
          this.totalRealizadoItemFndct += r.valorExecutado;
        }
        if (r.fonte && r.fonte.codigo == "R") {
          this.totalPrevistoItemRendimento += r.valorAlocado;
          this.totalRealizadoItemRendimento += r.valorExecutado;
        }
      });
    }
  },
  computed: {
    itemEditavel() {
      const projetoEmReformulacao = this.$store.projeto.reformulacao ? true : false;
      const projetoEmCadastro = this.$store.projeto.estadoCadastroProjeto != 'FINALIZADO';
      return projetoEmReformulacao || projetoEmCadastro;
    }
  },
};
</script>

<style lang="scss" scoped>
.resumobox {
  border: 1px solid #037f8c;
  border-radius: 10px;
  padding: 15px;
}
.t-label {
  color: #616161;
  font-size: 18px;
  text-align: left;
  font-weight: bold;
  margin: 0px;
}
.t-label-col {
  display: flex;
  justify-content: flex-start;
}
.t-label-inline {
  padding-bottom: 0px;
  padding-top: 0px;
}
.saldo-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  line-height: 26px;
  color: #616161;
}
.tipo-despesa-title {
  color: $primary;
  font-family: Raleway;
  font-size: 22px;
  font-weight: 600;
}
.despesa-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  color: #000000;
  text-align: center;
}
.no-pd {
  padding-left: 0px;
  padding-right: 0px;
}
.no-alg {
  justify-content: flex-start;
}
.despesa-title-value {
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  align-items: center;
  color: #000000;
}
.item-t-label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #616161;
}
.despesa-t-label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-align: end;
  justify-content: flex-end;
  color: #616161;
}
hr {
  background-color: #c4c4c4 !important;
}
p {
  margin: 0px;
}
</style>
