import Vue from "vue";
import axios from "axios";
import NProgress from "nprogress";
import auth from "./auth";

const local = process.env.VUE_APP_API_URL;

const api = axios.create({
  baseURL: local,
});

let template = `
'<div class="bar" style='background-color: #F28705; height: 3px; z-index: 999999999 !important' role="bar">
`;

NProgress.configure({
  template: template,
});

const Loader = new Vue();

// before a request is made start the nprogress
api.interceptors.request.use(
  (config) => {
    NProgress.start();
    !Loader.$store.disableGlobalLoader ? Loader.$loading(true) : null;
    Loader.$actions.setGlobalLoadingActive(true);
    const usuario = auth.getUser();
    if (usuario) {
      let basicAuth = "Basic " + btoa(usuario.login + ":" + usuario.senha);
      config.headers = {
        Authorization: basicAuth,
      };
    }
    return config;
  },
  () => {
    NProgress.done();
    Loader.$loading(false);
  }
);

// before a response is returned stop nprogress
api.interceptors.response.use(
  (response) => {
    NProgress.done();
    Loader.$loading(false);
    Loader.$actions.setGlobalLoadingActive(false);
    return response;
  },
  (error) => {
    NProgress.done();
    Loader.$loading(false);
    Loader.$actions.setGlobalLoadingActive(false);
    console.error(error);
    return Promise.reject(error.response.data);
  }
);

export default api;
