<template>
  <div>
    <div v-if="isExactActive" class="container-div">
      <div
        v-if="this.$route.query.isCadastro"
        style="
          margin-top: 12px;
          margin-bottom: 12px;
          display: flex;
          justify-content: flex-end;
        "
      >
        <div class="reformulacao-div">
          <v-row>
            <v-col md="8">
              <p class="reformulacao-title">
                Cadastro de Projetos - Etapa 4: Metas e Atividades
              </p>
              <p class="reformulacao-sub-title">
                Ao concluir o cadastro de Metas e Atividades clique no botão
                para retornar o cadastro de projeto.
              </p>
            </v-col>
            <v-col
              md="4"
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <v-btn
                class="btn-controls"
                depressed
                color="#FF8D3F"
                width="250"
                large
                :to="{
                  path: `/cadastro-projetos/${$store.projeto.id}/editar-cadastro/`,
                  query: {currentTab: 3}
                }"
              >
                RETORNAR CADASTRO</v-btn
              >
            </v-col>
          </v-row>
        </div>
      </div>

      <div class="header">
        <v-btn
          class="header-button"
          large
          color="white"
          text
          @click="decidirDestino"
        >
          <v-icon large color="#037F8C">mdi-arrow-left-circle-outline</v-icon>
          <PageTitle :title="'<b>METAS</b>'" size="18" />
        </v-btn>
        <span>
          <v-btn
            depressed
            color="#037F8C"
            width="240"
            class="btn-controls"
            style="margin-right: 15px"
            @click="modalCrudMetasOpen = !modalCrudMetasOpen"
            >CADASTRAR META</v-btn
          >
        </span>
      </div>
      <br />
      <v-row>
        <v-col md="4">
          <v-text-field
            v-model="camposTextuais"
            class="search-item-input"
            outlined
            dense
            clearable
            label="Pesquisar..."
            append-icon="mdi-magnify"
            color="#037F8C"
            v-on:keyup.enter="searchMetaByDesc"
            @click:clear="limparFiltros"
            @click:append="searchMetaByDesc"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="!Object.keys(metas).length">
        <v-col style="display: flex; justify-content: center">
          <div>
            <img src="../../assets/ilustracao_home.png" height="500" />
            <PageTitle title="Cadastre e visualize as metas de um projeto" />
          </div>
        </v-col>
      </v-row>
      <v-row v-for="(key, idxkey) in Object.keys(metas)" :key="idxkey">
        <v-col>
          <v-expansion-panels :value="0">
            <v-expansion-panel>
              <v-expansion-panel-header
                style="border-bottom: 1px solid #037f8c; padding-bottom: 8px"
              >
                <p class="filter-title">{{ key }}</p>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <br />
                <MetasTable :metas="metas[key]" />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <br />
      <ModalCrudMetas
        :key="String(modalCrudMetasOpen) + 'meta'"
        :modalVisible="modalCrudMetasOpen"
        @close="modalCrudMetasOpen = !modalCrudMetasOpen"
      />
    </div>
    <router-view ref="rvp"></router-view>
  </div>
</template>

<script>
import ModalCrudMetas from "../../components/modals/ModalCrudMetas.vue";
import PageTitle from "../../components/PageTitle.vue";
import MetasTable from "../../components/tables/MetasTable.vue";
import { EventBus } from "../../services/event-bus";
import { searchMetas } from "../../services/providers/metasProvider";

export default {
  name: "Metas",
  components: {
    PageTitle,
    ModalCrudMetas,
    MetasTable,
  },
  data: () => ({
    panel: [0],
    opcoesSelecionadas: [],
    camposTextuais: "",
    subprojetos: [],
    subProjetoSelecionados: [],
    modalCrudMetasOpen: false,
    metas: [],
    isExactActive: true,
  }),
  mounted() {
    this.getData();
    window.scroll(0,0);
    EventBus.$on("meta-updated", async () => {
      await this.getData();
    });
    if (this.$route.query.isCadastro)
      this.$actions.setDrawerOpen(false);
  },
  updated() {
    this.isExactActive = typeof this.$refs.rvp === "undefined";
  },
  created() {
    this.isExactActive = typeof this.$refs.rvp === "undefined";
  },
  methods: {
    async getData() {
      const metas = await searchMetas({
        projetos: [this.$store.projeto.id],
      });
      this.groupMetasBySubprojeto(metas);
    },
    groupMetasBySubprojeto(metas) {
      const groupBySubprojeto = {};
      metas.forEach((item) => {
        groupBySubprojeto[item.subprojeto.codigo] = [
          ...(groupBySubprojeto[item.subprojeto.codigo] || []),
          item,
        ];
      });
      this.metas = groupBySubprojeto;
    },
    async searchMetaByDesc() {
      const metas = await searchMetas({
        projetos: [this.$store.projeto.id],
        descricao: this.camposTextuais,
      });
      this.groupMetasBySubprojeto(metas);
    },
    limparFiltros() {
      this.camposTextuais = "";
      this.getData();
    },
    decidirDestino() {
      if (this.$route.query)
        if (this.$route.query.isCadastro)
          this.$router.push({
            path: `/cadastro-projetos/${this.$store.projeto.id}/editar-cadastro/`,
            query: {currentTab: 3}
          });
      else
        this.$router.go(-1);
    }
  },
};
</script>

<style lang="scss" scoped>
.filter-title {
  font-family: Roboto;
  font-style: normal;
  text-transform: uppercase;
  margin: 0%;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: #037f8c;
}
.filter {
  .v-expansion-panel ::after {
    position: absolute;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  .v-expansion-panel--active {
    position: absolute;
    width: 100%;
    -webkit-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
  }
}
.v-text-field--outlined fieldset {
  border: 3px solid #037f8c !important;
  border-radius: 8px !important;
}
.label {
  margin-top: 8px !important;
}
.chips-content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.chip {
  display: flex;
  align-items: center;
  width: 100px;
  height: 30px;
  padding: 5px;
  margin: 5px;
  margin-left: 10px;
  border: 1.5px solid #ff8d3f;
  border-radius: 10px;
  text-overflow: ellipsis;
  overflow: hidden;

  span {
    width: 100%;
    font-family: Roboto;
    font-size: 14px;
    color: #ff8d3f;
    text-align: center;
  }
}
.reformulacao-div {
  background-color: #1da7a3;
  width: 100%;
  display: flex;
  padding: 40px;
  border-radius: 20px;
}
.reformulacao-title {
  font-family: Raleway;
  font-weight: bold;
  font-size: 28px;
  color: #f2f2f2;
}
.reformulacao-sub-title {
  font-family: Raleway;
  font-weight: bold;
  font-size: 19px;
  color: #f2f2f2;
}
</style>
