import moment from "moment";

function makeText(
  value,
  bold = false,
  isDesc = false,
  header = false,
  right = false
) {
  return {
    text: value,
    alignment: right ? "right" : isDesc ? "left" : "center",
    fontSize: header ? 12 : 9,
    bold: bold,
  };
}

function generateHeader(projeto, item) {
  return [
    {
      layout: "noBorders",
      table: {
        headerRows: 1,
        body: [
          [
            makeText("Projeto: ", true, true, true),
            makeText(
              projeto.codigo + " - " + projeto.descricao || "-",
              false,
              true,
              true
            ),
          ],
          [
            makeText("Subprojeto: ", true, true, true),
            makeText(item.subprojeto.codigo || "-", false, true, true),
          ],
          [
            makeText("Rubrica: ", true, true, true),
            makeText(item.rubrica.descricao || "-", false, true, true),
          ],
          [
            makeText("Descrição: ", true, true, true),
            makeText(item.descricao || "-", false, true, true),
          ],
          [
            makeText("Finalidade: ", true, true, true),
            makeText(item.finalidade || "-", false, true, true),
          ],
          [
            makeText("Parcela: ", true, true, true),
            makeText(item.parcela.numero || "-", false, true, true),
          ],
          [
            makeText("Status: ", true, true, true),
            makeText(item.situacaoItem.descricao || "-", false, true, true),
          ],
          [
            makeText("Executado: ", true, true, true),
            makeText(item.valorExecutado ? "Sim" : "Não", false, true, true),
          ],
          [
            makeText("Relatório emitido em: ", true, true, true),
            makeText(moment().format("DD/MM/YYYY"), false, true, true),
          ],
        ],
      },
    },
  ];
}

function generateTables(comentarios) {
  let content = [];
  comentarios.forEach((comentario) => {
    content.push({
      text: "",
      fontSize: 12,
      margin: [0, 10],
    });
    content.push({
      columns: [
        {
          text: `${
            comentario.usuarioEdicao ? "Atualizado por: " : "Criado por: "
          } ${comentario?.usuarioEdicao?.login ||
            comentario.usuarioCriacao.login}`,
          alignment: "left",
          margin: [0, 0, 0, 0],
          bold: true,
        },
        {
          text: `${moment(
            comentario.dataEdicao || comentario.dataCriacao
          ).format("DD/MM/YYYY HH:mm")}`,
          alignment: "left",
          margin: [0, 0, 0, 0],
          bold: true,
        },
      ],
    });
    content.push({
      text: comentario.conteudo,
      alignment: "justify",
      fontSize: 12,
      margin: [0, 20],
    });
    content.push({
      canvas: [
        { type: "line", x1: 0, y1: 5, x2: 680 - 2 * 60, y2: 5, lineWidth: 0.7 },
      ],
    });
  });
  return content;
}

export const makePDFComentariosItem = (projeto, item, comentaios) => {
  const pdfMake = require("pdfmake/build/pdfmake.js");
  if (pdfMake.vfs == undefined) {
    const pdfFonts = require("pdfmake/build/vfs_fonts.js");
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
  }
  const docDefinition = {
    pageSize: "A4",
    pageMargins: [20, 60, 20, 60],
    content: [
      {
        image: require("./logoFullBase64.json").image,
        width: 108,
        height: 82,
        alignment: "center",
        margin: [0, 0, 0, 10],
      },
      ...generateHeader(projeto, item),
      {
        text: "RELATÓRIO DE COMENTÁRIOS DO ITEM",
        fontSize: 14,
        alignment: "center",
        margin: [0, 20],
      },
      ...generateTables(comentaios),
    ],
    defaultStyle: {
      fontSize: 12,
    },
  };
  pdfMake.createPdf(docDefinition).print();
};
