<template>
  <v-dialog v-model="modalVisible" persistent scrollable max-width="1000">
    <v-form ref="formEditarItem">
      <v-card style="border-radius: 10px">
        <v-card-title class="modal-title"
          >EDITAR INFORMAÇÕES GERAIS DO ITEM</v-card-title
        >
        <v-card-text v-if="item" style="padding-left: 30px">
          <v-row>
            <v-col class="label-col" md="2">
              <p class="label">Sub-projeto:</p>
            </v-col>
            <v-col md="10">
              <v-select
                outlined
                clearable
                dense
                disabled
                class="select-form"
                color="#037F8C"
                item-color="#037F8C"
                :label="form.subProjeto ? form.subProjeto.codigo : '-'"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="label-col" md="2">
              <p class="label">Rubrica:</p>
            </v-col>
            <v-col md="10">
              <v-select
                outlined
                dense
                clearable
                class="select-form"
                color="#037F8C"
                item-color="#037F8C"
                :items="rubricasFormatted"
                v-model="form.rubrica"
                item-text="label"
                item-value="value"
                :rules="[(v) => !!v || 'Selecione uma rubrica']"
                required
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="label-col" md="2">
              <p class="label">Parcela:</p>
            </v-col>
            <v-col md="10">
              <v-select
                outlined
                dense
                clearable
                class="select-form"
                color="#037F8C"
                item-color="#037F8C"
                :items="parcelasFormatted"
                v-model="form.parcela"
                item-text="label"
                item-value="value"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="label-col" md="2">
              <p class="label">Descrição:</p>
            </v-col>
            <v-col md="10">
              <v-text-field
                v-model="form.descricao"
                outlined
                dense
                :rules="[(v) => !!v || 'Descrição é obrigatória']"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="label-col" md="2">
              <p class="label">Finalidade:</p>
            </v-col>
            <v-col md="10">
              <v-text-field
                v-model="form.finalidade"
                outlined
                dense
                :rules="[(v) => !!v || 'Finalidade é obrigatória']"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="modal-footer">
          <v-btn outlined width="190" color="#FF8D3F" @click="closeModal(false)"
            >Cancelar</v-btn
          >
          <v-btn
            depressed
            width="190"
            class="btn-controls"
            color="#FF8D3F"
            @click="validarForm"
            >Salvar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
    <ModalJustificativa
      :modalVisible="modalJustificativaOpen"
      @close="closeModalJustificativa"
      @confirm="editarItem"
    />
  </v-dialog>
</template>

<script>
import { editarInfoBasicasItem } from "../../services/providers/itensProvider";
import { getRubricas } from "../../services/providers/rubricaProvider";
import { getProjetoById } from "../../services/providers/projetosProvider";
import ModalJustificativa from "../modals/ModalJustificativa";
import { EventBus } from "../../services/event-bus";

export default {
  name: "ModalEdtInfoGeraisItem",
  props: {
    modalVisible: Boolean,
    itemProp: Object,
  },
  components: {
    ModalJustificativa,
  },
  data: () => ({
    item: null,
    modalJustificativaOpen: false,
    rubricasFormatted: [],
    parcelasFormatted: [],
    form: {},
    justificativa: "",
  }),
  async mounted() {
    const parcelas = this.$store.projeto.parcelas;
    if (parcelas) {
      this.parcelasFormatted = parcelas.map((p) => ({
        label: p.numero + "º Parcela",
        value: p.id,
      }));
      this.parcelasFormatted.push({
        label: "Sem parcela",
        value: 0,
      });
    }
    this.item = this.itemProp;
    if (this.item) {
      this.form = {
        subProjeto: this.item.subprojeto,
        rubrica: this.item.rubrica
          ? {
              label: this.item.rubrica.descricaoCompleta,
              value: this.item.rubrica.id,
            }
          : null,
        parcela: this.item.parcela
          ? {
              label: this.item.parcela.numero + "ª",
              value: this.item.parcela.id,
            }
          : {
              label: "Sem parcela",
              value: 0,
            },
        descricao: this.item.descricao,
        finalidade: this.item.finalidade,
      };

      try {
        const rubricas = await getRubricas();
        this.rubricasFormatted = rubricas.map((r) => ({
          label: r.descricaoCompleta,
          value: r.id,
        }));
      } catch (error) {
        console.error(error);
      }
    }
  },
  methods: {
    closeModal(reload) {
      this.$refs.formEditarItem.reset();
      this.modalConfirmarSair = false;
      if (reload) {
        EventBus.$emit("item-updated");
      }
      this.$emit("close");
    },
    closeModalJustificativa() {
      this.modalJustificativaOpen = false;
    },
    openModalJustificativa() {
      this.modalJustificativaOpen = !this.modalJustificativaOpen;
    },
    validarForm() {
      if (this.$refs.formEditarItem.validate()) {
        this.modalJustificativaOpen = true;
      } else {
        this.$notify({
          title: "Erro!",
          text: "Preencha todas as informações obrigatórias",
          type: "error",
          duration: 15000,
        });
      }
    },
    async editarItem(justificativa) {
      let parcelaSelecionada =
        typeof this.form.parcela == "object"
          ? this.form.parcela.value
          : this.form.parcela;

      const payload = {
        id: this.item.id,
        subprojetoId: this.item.subprojeto.id,
        situacaoItemId: this.item.situacaoItem.id,
        rubricaId: this.form.rubrica
          ? this.form.rubrica.value || this.form.rubrica
          : null,
        parcelaId:
          parcelaSelecionada && parcelaSelecionada != 0
            ? parcelaSelecionada
            : undefined,
        descricao: this.form.descricao,
        justificativa: justificativa,
        finalidade: this.form.finalidade,
      };
      try {
        const res = await editarInfoBasicasItem(this.item.id, payload);
        if (res) {
          this.$notify({
            title: "Item editado!",
            text: "O item foi editado com sucesso!",
            type: "success",
            duration: 5000,
          });
          const projeto = await getProjetoById(this.$store.projeto.id);
          if (projeto) {
            this.$actions.setProjeto(projeto);
          }
          this.closeModal(true);
        }
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro ao editar o item!",
          text: error.message,
          type: "error",
          duration: 15000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.saldo-title {
  font-family: Roboto;
  font-style: normal;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  line-height: 26px;
  color: #616161;
}
.label-inline {
  padding-bottom: 0px;
  padding-top: 0px;
}
hr {
  background-color: #c4c4c4 !important;
}
p {
  margin: 0px;
}
</style>
