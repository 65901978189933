<template>
  <v-dialog v-model="modalVisible" persistent scrollable max-width="1000">
    <v-form ref="formEditarItem">
      <v-card style="border-radius: 10px">
        <v-card-title class="modal-title"
          >EDITAR RECURSOS PREVISTOS</v-card-title
        >
        <v-card-text v-if="item" style="padding-left: 30px">
          <v-row>
            <v-col>
              <v-col class="box-ammount">
                <p class="title-ammount">
                  Saldo FNDCT não alocado:
                </p>
                <p class="text-ammount">
                  {{ saldoRemanejavel | currency }}
                </p>
              </v-col>
            </v-col>
            <v-col>
              <v-col class="box-ammount">
                <p class="title-ammount">
                  Saldo RENDIMENTO não alocado:
                </p>
                <p class="text-ammount">
                  {{ saldoRemanejavelRendimento | currency }}
                </p>
              </v-col>
            </v-col>
          </v-row>
          <br />
          <p class="saldo-title" style="text-align: left">
            DESPESA PREVISTA
          </p>
          <br />
          <p class="saldo-title" style="text-align: center; font-size: 17px">
            Valor total do item:
            <span style="font-weight: normal">
              {{ valorTotalItem | currency }}
            </span>
          </p>
          <br />
          <v-row>
            <v-col class="label-col" md="2">
              <p class="label">Quantidade:</p>
            </v-col>
            <v-col md="4">
              <v-text-field
                v-model="form.quantidade"
                type="number"
                min="1"
                outlined
                dense
                :rules="[
                  (v) => !!v || 'Quantidade é obrigatória',
                  (v) =>
                    !(parseFloat(v) <= 0) ||
                    'Quantidade deve ser maior que zero',
                ]"
                @blur="validarCamposRecurso"
                required
              ></v-text-field>
            </v-col>
            <v-col class="label-col" md="2">
              <p class="label">Valor unitário:</p>
            </v-col>
            <v-col md="4">
              <v-currency-field
                prefix="R$"
                v-model="form.valorUnitario"
                outlined
                dense
                :rules="[
                  (v) => !!v || 'Valor Unitario é obrigatório',
                  (v) =>
                    !(parseFloat(String(v).replace(',', '.')) <= 0) ||
                    'Valor Unitario deve ser maior que zero',
                ]"
                @blur="validarCamposRecurso"
                required
              ></v-currency-field>
              <div
                style="width: 100%; display: flex; justify-content: flex-end;"
              >
                <v-btn
                  text
                  dense
                  class="btn-link"
                  :disabled="!form.valorUnitario"
                  @click="simularRecursos"
                >
                  simular recursos
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="label-col" md="2">
              <p class="label">Total FNDCT do ITEM:</p>
            </v-col>
            <v-col md="3">
              <v-currency-field
                prefix="R$"
                v-model="totalFndctItem"
                outlined
                dense
                :rules="[
                  (v) =>
                    validarValoresRecursos(v, totalRendimentoItem) ||
                    'Os valores dos recursos devem ser iguais ao valor total do item',
                ]"
                required
              ></v-currency-field>
            </v-col>
            <v-col class="label-col" md="3">
              <p class="label">Total RENDIMENTO do ITEM:</p>
            </v-col>
            <v-col md="4">
              <v-currency-field
                prefix="R$"
                v-model="totalRendimentoItem"
                outlined
                dense
                :rules="[
                  (v) =>
                    validarValoresRecursos(v, totalFndctItem) ||
                    'Os valores dos recursos devem ser iguais ao valor total do item',
                ]"
                required
              ></v-currency-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="modal-footer">
          <v-btn outlined width="190" color="#FF8D3F" @click="closeModal(false)"
            >Cancelar</v-btn
          >
          <v-btn
            depressed
            width="190"
            class="btn-controls"
            color="#FF8D3F"
            @click="validarForm"
            >Salvar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
    <ModalConfirmacao
      v-if="item"
      :modalVisible="modalConfirmarAlteracao"
      :titulo="'CONFIRMAR ALOCAÇÃO DE RECURSOS'"
      :texto="
        `Você realmente deseja salvar o item <b>${ item.descricao }</b> com os recursos alocados da seguinte maneira:`
      "
      textoBotaoDireito="Confirmar"
      :onConfirm="
        () => (modalJustificativaOpen = true) & closeModalConfirmacao()
      "
      @close="closeModalConfirmacao"
    >
      <v-row>
        <v-col>
          <v-col class="box-ammount">
            <p class="title-ammount">
              TOTAL FNDCT DO ITEM
            </p>
            <p class="text-ammount">
              {{ totalFndctItem | currency }}
            </p>
          </v-col>
        </v-col>
        <v-col>
          <v-col class="box-ammount">
            <p class="title-ammount">
              TOTAL RENDIMENTO DO ITEM
            </p>
            <p class="text-ammount">
              {{ totalRendimentoItem | currency }}
            </p>
          </v-col>
        </v-col>
      </v-row>
    </ModalConfirmacao>
    <ModalJustificativa
      :modalVisible="modalJustificativaOpen"
      @close="closeModalJustificativa"
      @confirm="editarItem"
    />
  </v-dialog>
</template>

<script>
import { EventBus } from "../../services/event-bus";
import {
  simularRecursosItem,
  editarItem,
} from "../../services/providers/itensProvider";
import { getProjetoById } from "../../services/providers/projetosProvider";
import { convertCurrencyToNumber, decimalAdjust } from "../../utils/numbers";
import ModalConfirmacao from "../modals/ModalConfirmacao";
import ModalJustificativa from "../modals/ModalJustificativa";

export default {
  name: "ModalEdtRecursosPrevItem",
  props: {
    modalVisible: Boolean,
    itemProp: Object,
  },
  components: {
    ModalConfirmacao,
    ModalJustificativa,
  },
  data: () => ({
    item: null,
    modalConfirmarAlteracao: false,
    modalJustificativaOpen: false,
    form: {},
    isItemRendimento: false,
    saldoRemanejavel: 0,
    saldoRemanejavelRendimento: 0,
    totalRendimentoItem: null,
    totalFndctItem: null,
    idRecursoFndctItem: null,
    idRecursoRendimentoItem: null,
  }),
  async mounted() {
    this.item = this.itemProp;
    if (this.item) {
      this.form = {
        quantidade: this.item.quantidade || 1,
        valorUnitario: this.item.valorUnitario || null,
      };

      this.saldoRemanejavel = this.$store.projeto.metricas.saldoNaoAlocado || 0;

      this.saldoRemanejavelRendimento = this.$store.projeto.metricasRendimento.saldoNaoAlocado;

      let recursoFndct = this.item.recursos.find((r) => r.fonte.codigo == "F");
      if (recursoFndct) {
        this.idRecursoFndctItem = recursoFndct.id;
      }
      let recursoRendimento = this.item.recursos.find(
        (r) => r.fonte.codigo == "R"
      );
      if (recursoRendimento) {
        this.idRecursoRendimentoItem = recursoRendimento.id;
      }

      this.totalRendimentoItem = this.item.recursos
        .filter((r) => (r.fonte ? r.fonte.codigo == "R" : false))
        .reduce((total, recurso) => (total += recurso.valorAlocado), 0);

      this.totalFndctItem = this.item.recursos
        .filter((r) => (r.fonte ? r.fonte.codigo == "F" : false))
        .reduce((total, recurso) => (total += recurso.valorAlocado), 0);
    }
  },
  computed: {
    valorTotalItem: function() {
      return this.form.valorUnitario * this.form.quantidade;
    },
  },
  methods: {
    closeModal(reload) {
      this.$refs.formEditarItem.reset();
      this.modalConfirmarAlteracao = false;
      this.modalJustificativaOpen = false;
      if (reload) {
        EventBus.$emit("item-updated");
      }
      this.$emit("close");
    },
    closeModalConfirmacao() {
      this.modalConfirmarAlteracao = false;
    },
    openModalConfirmacao() {
      this.modalConfirmarAlteracao = true;
    },
    closeModalJustificativa() {
      this.modalJustificativaOpen = false;
    },
    openModalJustificativa() {
      this.modalJustificativaOpen = true;
    },
    validarValoresRecursos(valorCampo, valorRecurso) {
      valorCampo =
        typeof valorCampo == "string"
          ? convertCurrencyToNumber(valorCampo)
          : valorCampo;
      if (valorCampo) {
        valorRecurso =
          typeof valorRecurso == "string"
            ? convertCurrencyToNumber(valorRecurso)
            : valorRecurso;
        return (
          decimalAdjust(valorCampo + valorRecurso, 2) ==
          decimalAdjust(
            convertCurrencyToNumber(this.form.valorUnitario) *
              this.form.quantidade,
            2
          )
        );
      }
      return true;
    },
    validarCamposRecurso() {
      if (this.totalRendimentoItem || this.totalFndctItem) {
        this.$refs.formEditarItem.validate();
      }
    },
    validarForm() {
      if (this.$refs.formEditarItem.validate()) {
        this.modalConfirmarAlteracao = true;
      } else {
        this.$notify({
          title: "Erro!",
          text: "Preencha todas as informações obrigatórias",
          type: "error",
          duration: 15000,
        });
      }
    },
    async editarItem(justificativa) {
      let fontFndct = {
        id: 2,
        codigo: "F",
        descricao: "FNDCT",
        prioridade: 1,
        fndct: true,
      };
      let fontRendimento = {
        id: 3,
        codigo: "R",
        descricao: "RENDIMENTO",
        prioridade: 2,
        fndct: false,
      };
      let recursoFndct = undefined;
      let recursoRendimento = undefined;
      let recursos = [];
      let totalFndctItem = convertCurrencyToNumber(this.totalFndctItem);
      if (totalFndctItem) {
        recursoFndct = {
          id: this.idRecursoFndctItem || null,
          itemId: this.item.id,
          valorAlocado: totalFndctItem,
          fonte: fontFndct,
        };
        recursos.push(recursoFndct);
      }
      let totalRendimentoItem = convertCurrencyToNumber(
        this.totalRendimentoItem
      );
      if (totalRendimentoItem) {
        recursoRendimento = {
          id: this.idRecursoRendimentoItem || null,
          itemId: this.item.id,
          valorAlocado: totalRendimentoItem,
          fonte: fontRendimento,
        };
        recursos.push(recursoRendimento);
      }

      const payload = {
        item: {
          ...this.item,
          quantidade: this.form.quantidade,
          valorUnitario: convertCurrencyToNumber(this.form.valorUnitario),
          justificativa: justificativa,
        },
        recursos: recursos.length ? recursos : undefined,
      };
      try {
        const res = await editarItem(payload);
        if (res) {
          this.$notify({
            title: "Item editado!",
            text: "O item foi editado com sucesso!",
            type: "success",
            duration: 5000,
          });
          const projeto = await getProjetoById(this.$store.projeto.id);
          if (projeto) {
            this.$actions.setProjeto(projeto);
          }
          this.closeModal(true);
        }
      } catch (error) {
        console.error(error);
        this.modalConfirmarAlteracao = false;
        this.modalJustificativaOpen = false;
        this.$notify({
          title: "Erro ao editar o item!",
          text: error.message,
          type: "error",
          duration: 15000,
        });
      }
    },
    async simularRecursos() {
      try {
        let payload = {
          itemId: this.item.id,
          quantidade: this.form.quantidade,
          valorUnitario: convertCurrencyToNumber(this.form.valorUnitario),
        };
        const res = await simularRecursosItem(payload);
        if (res) {
          let recursoFndct = res.find((r) => r.fonte.codigo == "F");
          if (recursoFndct) {
            this.idRecursoFndctItem = recursoFndct.id;
          }
          let recursoRendimento = res.find((r) => r.fonte.codigo == "R");
          if (recursoRendimento) {
            this.idRecursoRendimentoItem = recursoRendimento.id;
          }
          this.totalRendimentoItem = res
            .filter((r) => (r.fonte ? r.fonte.codigo == "R" : true))
            .reduce((total, recurso) => (total += recurso.valorAlocado), 0);

          this.totalFndctItem = res
            .filter((r) => (r.fonte ? r.fonte.codigo == "F" : true))
            .reduce((total, recurso) => (total += recurso.valorAlocado), 0);

          this.$notify({
            title: "Simulação concluída!",
            text: "A simulação de recursos do item foi concluída!",
            type: "success",
            duration: 5000,
          });

          this.validarCamposRecurso();
        }
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro ao simular recursos do item!",
          text: error.message,
          type: "error",
          duration: 15000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-title {
  display: flex;
  font-size: 20px !important;
  text-transform: uppercase;
  font-weight: bold;
  font-family: Raleway;
  color: $primary;
  justify-content: center;
  margin: 20px;
  margin-bottom: 30px;
  border-radius: 10px;
  height: 65px;
}
.saldo-title {
  font-family: Roboto;
  font-style: normal;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  line-height: 26px;
  color: #616161;
}
.label-inline {
  padding-bottom: 0px;
  padding-top: 0px;
}
.total-title {
  font-family: Roboto;
  font-style: normal;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  line-height: 26px;
  color: #616161;
  span {
    font-weight: normal !important;
  }
}
.despesa-title {
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 16x;
  line-height: 21px;
  display: flex;
  align-items: flex-end;
  text-align: flex-end;
  color: #616161;
}
.despesa-title-value {
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: flex-end;
  text-align: flex-end;
  color: #000000;
}
.add-execucao-label {
  background: #e6f4f1;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #037f8c;
  padding: 5px;
  border-radius: 10px;
  margin-right: 10px;
}
.despesa-label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: flex-end;
  color: #616161;
}
hr {
  background-color: #c4c4c4 !important;
}
p {
  margin: 0px;
}
.title-tab {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 16px;
  text-align: center !important;
  color: #037f8c !important;
  border: 0.899715px solid rgba(97, 97, 97, 0.3);
  padding: 10px !important;
}
.text-tab {
  font-family: Roboto;
  font-style: normal;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 13px;
  text-align: center;
  color: #616161 !important;
  border: 0.899715px solid rgba(97, 97, 97, 0.3);
  padding: 10px !important;
}
.box-ammount {
  border: 2px solid $primary;
  margin: 5px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 10px;
}
.title-ammount {
  font-family: $title;
  text-transform: uppercase;
  text-align: center;
  font-size: 16px;
  color: $title-color;
  margin-bottom: 10px;
}
.text-ammount {
  font-family: $subtitle;
  text-transform: uppercase;
  text-align: center;
  color: $title-color;
  font-size: 22px;
}
.btn-link {
  display: flex;
  margin-top: -30px;
  width: 50%;
  text-decoration: underline;
  text-transform: lowercase;
  font-family: Roboto;
  color: $primary;
}
</style>
