import api from "../api";

export const searchAtividades = async (params) => {
  let query = Object.keys(params)
    .filter((k) => params[k])
    .map((k) => {
      if (Array.isArray(params[k])) {
        return k + "=" + params[k].join();
      } else {
        return k + "=" + params[k];
      }
    })
    .join("&");
  const res = await api.get(`/atividades/search?${query}`);
  return res.data;
};

export const cadastrarAtividade = async (payload) => {
  const res = await api.post(`/atividades`, payload);
  return res.data;
};

export const editarAtividade = async (idAtividade, payload) => {
  const res = await api.patch(`/atividades/${idAtividade}`, payload);
  return res.data;
};

export const excluirAtividade = async (idAtividade) => {
  const res = await api.delete(`/atividades/${idAtividade}`);
  return res.data;
};
