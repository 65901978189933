<template>
  <v-dialog v-model="modalVisible" persistent max-width="800">
    <v-card style="border-radius: 10px">
      <br />
      <v-card-title class="modal-title">Redefinir Senha</v-card-title>
      <v-card-text>
        <br />
        <v-form ref="formRedefinirSenha">
          <v-row>
            <v-col class="label-col" md="3">
              <p class="label">Nova senha:</p>
            </v-col>
            <v-col md="9">
              <v-text-field
                v-model="novaSenha"
                outlined
                dense
                :rules="[(v) => !!v || 'Nova senha é obrigatória!']"
                :append-icon="novaSenhaVisible ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="() => (novaSenhaVisible = !novaSenhaVisible)"
                :type="novaSenhaVisible ? 'password' : 'text'"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="label-col" md="3">
              <p class="label">Confirmar senha:</p>
            </v-col>
            <v-col md="9">
              <v-text-field
                v-model="confirmarNovaSenha"
                outlined
                dense
                :rules="[(v) => v == novaSenha || 'As senhas não coincidem!']"
                :append-icon="confirmarSenhaVisible ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="
                  () => (confirmarSenhaVisible = !confirmarSenhaVisible)
                "
                :type="confirmarSenhaVisible ? 'password' : 'text'"
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions
        style="
          display: flex;
          justify-content: space-evenly;
          padding-bottom: 20px;
        "
      >
        <v-btn outlined width="190" color="#FF8D3F" @click="closeModal(false)"
          >Cancelar</v-btn
        >
        <v-btn
          depressed
          width="190"
          class="btn-controls"
          color="#FF8D3F"
          @click="redefinirSenha"
          >Redefinir senha</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import auth from "../../services/auth";
import { atualizarUsuario } from "../../services/providers/usuarioProvider";

export default {
  name: "ModalRedefinirSenha",
  props: {
    modalVisible: Boolean,
  },
  data: () => ({
    novaSenhaVisible: true,
    confirmarSenhaVisible: true,
    novaSenha: null,
    confirmarNovaSenha: null,
    usuario: null,
  }),
  mounted() {
    this.usuario = auth.getUser();
  },
  methods: {
    closeModal() {
      this.$refs.formRedefinirSenha.reset();
      this.$emit("close");
    },
    async redefinirSenha() {
      if (this.$refs.formRedefinirSenha.validate() && this.usuario) {
        const payload = {
          senha: this.novaSenha,
        };
        try {
          await atualizarUsuario(this.usuario.id, payload);
          this.$notify({
            title: "Senha redefinida!",
            text: "Sua senha foi redefinida com sucesso!",
            type: "success",
            duration: 5000,
          });
          this.usuario.senha = this.novaSenha;
          auth.setUsuario(this.usuario);
          this.$refs.formRedefinirSenha.reset();
          this.closeModal();
        } catch (error) {
          console.error(error);
          this.$notify({
            title: "Senha antiga incorreta!",
            text: "Por favor verifique a senha antiga digitada!",
            type: "error",
            duration: 15000,
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
