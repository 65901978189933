<template>
  <div class="container-main">
    <div class="container-login">
      <div class="content-login">
        <img width="309" height="231" src="../assets/logo-full.png" />
        <br />
        <v-card elevation="0" width="80%" style="border-radius: 10px">
          <v-card-text>
            <div class="form-div-title">
              <p class="subtitle">Faça login para iniciar sua seção</p>
            </div>
            <v-text-field
              v-model="usuario"
              color="#037F8C"
              label="Usuário"
              :rules="[(v) => !!v || 'Usuário é obrigatório!']"
              class="form-input"
              required
            >
              <v-icon slot="prepend" class="input-icon">mdi-account-box</v-icon>
            </v-text-field>
            <br />
            <v-text-field
              v-model="pass"
              color="#037F8C"
              label="Senha"
              class="form-input"
              :rules="[(v) => !!v || 'Senha é obrigatória!']"
              @keyup.enter="login"
              :append-icon="passVisible ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="() => (passVisible = !passVisible)"
              :type="passVisible ? 'password' : 'text'"
              required
            >
              <v-icon slot="prepend" class="input-icon">mdi-lock</v-icon>
            </v-text-field>
            <div class="form-bottom">
              <v-checkbox
                color="#037F8C"
                label="Mantenha-me conectado"
                class="form-checkbox"
              ></v-checkbox>
              <router-link :to="{ path: 'recuperar-senha' }" class="form-link"
                >Esqueceu a senha?</router-link
              >
            </div>
            <p v-if="error" class="error-form">Usuário ou senha incorretos!</p>
          </v-card-text>
          <v-card-actions class="form-buttom">
            <v-btn depressed outlined width="40%" color="#037F8C"
              >CADASTRO</v-btn
            >
            <v-btn
              depressed
              @click="login"
              width="40%"
              color="#037F8C"
              style="color: #fff; border-radius: 10px"
              >ENTRAR</v-btn
            >
          </v-card-actions>
          <br />
        </v-card>
        <br />
      </div>
    </div>
    <div class="copyright">2021 Copyright Projeto Sapiencia</div>
  </div>
</template>

<script>
import auth from "../services/auth";
export default {
  name: "Login",
  data: () => ({
    usuario: "",
    pass: "",
    error: false,
    passVisible: true,
  }),
  methods: {
    login() {
      if (this.usuario.trim() && this.pass.trim()) {
        auth.login(this.usuario, this.pass, (loggedIn, error) => {
          if (error) {
            this.$notify({
              title: "Erro interno!",
              text: error.message || error,
              type: "error",
              duration: 15000,
            });
          }
          if (!loggedIn) {
            this.error = true;
          } else {
            this.$router.replace("/projetos-vigentes");
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.main-without-drawer {
  padding: 0px !important;
}
@media only screen and (max-width: 480px) {
  .content-login {
    width: 100% !important;
  }
}
.container-login {
  width: 100vw;
  height: 110vh;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.container-main {
  width: 100%;
  height: 120%;
  margin-top: -10%;
  background-color: $card-background;
  background-image: url("../assets/malha.png");
  background-size: cover;
}
.content-login {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  border-radius: 30px;
}
.form {
  padding: 5%;
}
.form-buttom {
  padding-left: 7%;
  padding-right: 7%;
  width: 100%;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: space-between;
}
.form-div-title {
  padding: 5%;
  padding-top: 7%;
  padding-bottom: 3%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.input-icon {
  color: $primary;
}
.form-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.title {
  font-size: 36px !important;
  color: $secondary;
  font-family: $title;
}
.subtitle {
  font-size: 18px;
  color: #7d7d7d;
  font-family: $title;
}
.form-checkbox {
  font-family: $title;
  position: relative;
  top: -20px;
}
.form-input {
  font-family: $title;
}
.form-link {
  color: #7d7d7d;
  font-family: $title;
}
.error-form {
  padding-left: 5%;
  font-family: $title;
  color: red;
}
.copyright {
  background: #55b4c2;
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: #e6f4f1;
}
</style>
