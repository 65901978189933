<template>
  <v-dialog v-model="modalVisible" persistent max-width="1100">
    <v-card style="border-radius: 10px">
      <br />
      <v-card-title v-if="isEdit" class="modal-title"
        >EDITAR PARTICIPANTE</v-card-title
      >
      <v-card-title v-else class="modal-title"
        >CADASTRAR PARTICIPANTE</v-card-title
      >
      <v-card-text>
        <div class="wizard-container">
          <div
            style="display: flex; width: 45%; justify-content: space-between"
          >
            <div :class="`wizard-circle ${context === 1 && 'wizard-active'}`">
              <p class="wizard-text">1</p>
              <p class="wizard-desc">
                Dados gerais
              </p>
            </div>
            <div class="wizard-mid-line"></div>
            <div :class="`wizard-circle ${context === 2 && 'wizard-active'}`">
              <p class="wizard-text">2</p>
              <p class="wizard-desc">
                Informações da função
              </p>
            </div>
          </div>
        </div>
        <!-- primeiro form -->
        <div v-if="context === 1">
          <v-form ref="formParticipante1">
            <v-row>
              <v-col class="label-col" md="2">
                <p class="label">Tipo de participante:</p>
              </v-col>
              <v-col md="10">
                <v-select
                  outlined
                  dense
                  clearable
                  class="select-form"
                  color="#037F8C"
                  item-color="#037F8C"
                  :items="tiposParticipanteFormatted"
                  v-model="formFields.tipoParticipante"
                  item-text="label"
                  item-value="value"
                  label="Selecione o tipo"
                  :rules="[(v) => !!v || 'Selecione o tipo']"
                  required
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="label-col" md="2">
                <p class="label">*Nome:</p>
              </v-col>
              <v-col md="10">
                <v-combobox
                  outlined
                  dense
                  v-model="formFields.nome"
                  :items="pessoasEncontradas"
                  :loading="isLoading"
                  :search-input.sync="searchPessoaByName"
                  item-text="nome"
                  item-value="nome"
                  :rules="[(v) => !!v || 'Nome é obrigatório']"
                  @change="fillFormByPessoaEncontrada"
                  required
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="label-col" md="2">
                <p class="label">*CPF:</p>
              </v-col>
              <v-col md="4">
                <v-text-field
                  v-model="formFields.cpf"
                  outlined
                  dense
                  v-mask="'###.###.###-##'"
                  :rules="[
                    (v) => !!v || 'CPF é obrigatório',
                    (v) => validateCpf(v) || 'CPF inválido!',
                  ]"
                  required
                ></v-text-field>
              </v-col>
              <v-col class="label-col" md="2">
                <p class="label">Matricula:</p>
              </v-col>
              <v-col md="4">
                <v-text-field
                  v-model="formFields.matricula"
                  outlined
                  dense
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="label-col" md="2">
                <p class="label">Telefone <br>principal:</p>
              </v-col>
              <v-col md="4">
                <v-text-field
                  v-model="formFields.telefonePrincipal"
                  outlined
                  dense
                  v-mask="telefonePatternPrimario"
                  required
                ></v-text-field>
              </v-col>
              <v-col class="label-col" md="2">
                <p class="label">Telefone secundário:</p>
              </v-col>
              <v-col md="4">
                <v-text-field
                  v-model="formFields.telefoneSecundario"
                  outlined
                  dense
                  v-mask="telefonePatternSecundario"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="label-col" md="2">
                <p class="label">*E-mail:</p>
              </v-col>
              <v-col md="10">
                <v-text-field
                  v-model="formFields.email"
                  outlined
                  dense
                  :rules="[
                    (v) => !!v || 'E-mail é obrigatório',
                    (v) => validateEmail(v) || 'E-mail inválido!',
                  ]"
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="label-col" md="2">
                <p class="label">Departamento vinculado:</p>
              </v-col>
              <v-col md="10">
                <v-text-field
                  v-model="formFields.departamento"
                  outlined
                  dense
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
          <v-card-actions
            style="display: flex; justify-content: space-evenly; padding-bottom: 20px"
          >
            <v-btn
              outlined
              width="190"
              color="#FF8D3F"
              @click="closeModal(false)"
            >
              Cancelar
            </v-btn>
            <v-btn
              depressed
              width="190"
              class="btn-controls"
              color="#FF8D3F"
              @click="onContinue"
            >
              Continuar
            </v-btn>
          </v-card-actions>
        </div>
        <!-- segundo form -->
        <div v-if="context === 2">
          <v-form ref="formParticipante2">
            <v-row>
              <v-col class="label-col" md="2">
                <p class="label">Subprojeto:</p>
              </v-col>
              <v-col md="10">
                <v-select
                  outlined
                  dense
                  clearable
                  class="select-form"
                  color="#fafafa"
                  item-color="#037F8C"
                  :items="subprojetosFormatted"
                  v-model="formFields.subprojeto"
                  item-text="label"
                  item-value="value"
                  label="Selecione um subprojeto"
                  :rules="[(v) => !!v || 'Selecione um subprojeto']"
                  required
                  @change="getMetasBySubprojeto() & getRubricasSubprojeto()"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="label-col" md="2">
                <p class="label">Tipo de equipe:</p>
              </v-col>
              <v-col md="10">
                <v-select
                  outlined
                  dense
                  clearable
                  class="select-form"
                  color="#037F8C"
                  item-color="#037F8C"
                  :items="tiposEquipeFormatted"
                  v-model="formFields.tipoEquipe"
                  item-text="label"
                  item-value="value"
                  label="Selecione o tipo"
                  :rules="[(v) => !!v || 'Selecione o tipo']"
                  required
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="label-col" md="2">
                <p class="label">Função:</p>
              </v-col>
              <v-col md="10">
                <v-select
                  outlined
                  dense
                  clearable
                  class="select-form"
                  color="#037F8C"
                  item-color="#037F8C"
                  :items="funcoesFormatted"
                  v-model="formFields.funcao"
                  item-text="label"
                  item-value="value"
                  label="Selecione a função"
                  :rules="[(v) => !!v || 'Selecione a função']"
                  required
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="label-col" md="2">
                <p class="label">*Horas/Semana:</p>
              </v-col>
              <v-col md="4">
                <v-text-field
                  :key="!!formFields.horaSemana"
                  v-model="formFields.horaSemana"
                  outlined
                  v-mask="'##########'"
                  dense
                  :rules="[(v) => !!v || 'Valor é obrigatório']"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="label-col" md="2">
                <p class="label">Metas:</p>
              </v-col>
              <v-col md="10">
                <v-select
                  outlined
                  clearable
                  multiple
                  dense
                  class="select-form"
                  color="#037F8C"
                  item-color="#037F8C"
                  :items="metasFormatted"
                  :disabled="!formFields.subprojeto"
                  v-model="formFields.metas"
                  item-text="label"
                  item-value="value"
                  label="Selecione as metas"
                  :rules="[(v) => !!v || 'Selecione as metas']"
                  required
                  @change="getAtividadesByMeta"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="label-col" md="2">
                <p class="label">Atividades:</p>
              </v-col>
              <v-col md="10">
                <v-select
                  outlined
                  clearable
                  multiple
                  dense
                  class="select-form"
                  color="#037F8C"
                  item-color="#037F8C"
                  :items="atividadesFormatted"
                  v-model="formFields.atividades"
                  :disabled="!formFields.metas"
                  item-text="label"
                  item-value="value"
                  label="Selecione as atividades"
                  :rules="[(v) => !!v || 'Selecione as atividades']"
                  required
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="label-col" md="2">
                <p class="label">Rubricas:</p>
              </v-col>
              <v-col md="10">
                <v-select
                  outlined
                  clearable
                  multiple
                  dense
                  class="select-form"
                  color="#037F8C"
                  item-color="#037F8C"
                  :items="rubricasFormatted"
                  v-model="rubricas"
                  :disabled="!formFields.subprojeto"
                  item-text="label"
                  item-value="value"
                  label="Selecione as rubricas"
                  @change="getItensRubrica"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="label-col" md="2">
                <p class="label">Itens:</p>
              </v-col>
              <v-col md="10">
                <v-select
                  outlined
                  clearable
                  multiple
                  dense
                  class="select-form"
                  color="#037F8C"
                  item-color="#037F8C"
                  :items="itensFormatted"
                  v-model="formFields.itens"
                  :disabled="!formFields.subprojeto || !rubricas"
                  item-text="label"
                  item-value="value"
                  label="Selecione os itens"
                ></v-select>
              </v-col>
            </v-row>
          </v-form>

          <v-card-actions
            style="display: flex; justify-content: space-evenly; padding-bottom: 20px"
          >
            <v-btn outlined width="190" color="#FF8D3F" @click="context = 1">
              Voltar
            </v-btn>
            <v-btn
              v-if="isEdit"
              depressed
              width="190"
              class="btn-controls"
              color="#FF8D3F"
              @click="crudParticipante"
            >
              Editar
            </v-btn>
            <v-btn
              v-else
              depressed
              width="190"
              class="btn-controls"
              color="#FF8D3F"
              @click="crudParticipante"
            >
              Salvar
            </v-btn>
          </v-card-actions>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { EventBus } from "../../services/event-bus";
import { getSubprojetosByProjetoApi } from "../../services/providers/subProjetosProvider";
import { validateCpf, validateEmail } from "../../utils/validators";
import {
  FUNCAO_PARTICIPANTE,
  TIPO_EQUIPE,
  TIPO_PARTICIPANTE,
} from "../../utils/enums";
import { getRubricas } from "../../services/providers/rubricaProvider";
import { filtrarItens } from "../../services/providers/itensProvider";
import { filtrarPessoas } from "../../services/providers/pessoasProvider";
import { searchMetas } from "../../services/providers/metasProvider";
import { searchAtividades } from "../../services/providers/atividadesProvider";
import {
  cadastrarParticipante,
  editarParticipante,
} from "../../services/providers/participantesProvider";

const defaultForm = {
  subprojeto: null,
  pessoa: {},
  nome: null,
  telefonePrincipal: null,
  telefoneSecundario: null,
  email: null,
  cpf: null,
  matricula: null,
  departamento: null,
  tipoParticipante: null,
  tipoEquipe: null,
  funcao: null,
  horaSemana: 2,
  metas: [],
  atividades: [],
  itens: [],
};

export default {
  name: "ModalCrudParticipante",
  props: {
    modalVisible: Boolean,
    participante: Object,
  },
  data: () => ({
    context: 1,
    isEdit: false,
    isLoading: false,
    formFields: { ...defaultForm },
    subprojetosFormatted: [],
    tiposParticipanteFormatted: [],
    tiposEquipeFormatted: [],
    funcoesFormatted: [],
    rubricasFormatted: [],
    itensFormatted: [],
    metasFormatted: [],
    atividadesFormatted: [],
    rubricas: null,
    pessoasEncontradas: [],
    searchPessoaByName: null,
    telefonePatternPrimario: '(##) ####-####',
    telefonePatternSecundario: '(##) ####-####',
  }),
  async created() {
    this.isEdit = !!this.participante;
    if (this.participante) {
      this.formFields = {
        ...this.participante,
        ...this.participante.pessoa,
        subprojeto: this.participante.subprojetoId,
        metas: this.participante.metas.map((i) => i.id),
        atividades: this.participante.atividades.map((i) => i.id),
        itens: this.participante.itens.map((i) => i.id),
      };
      this.rubricas = this.participante.itens
        .map((i) => ({
          label: i.rubrica.descricaoCompleta,
          value: i.rubrica.id,
        }))
        .reduce(
          (unique, item) =>
            unique.find((u) => u.value === item.value)
              ? unique
              : [...unique, item],
          []
        );

      let metass = await searchMetas({subprojetos: this.formFields.subprojeto});
      this.metasFormatted = metass.map((m) => ({
        label: m.descricao,
        value: m.id,
      }));


      let atividadess = await searchAtividades({metas: this.formFields.metas.map(m => m.id)});
      this.atividadesFormatted = atividadess.map((r) => ({
        label: r.descricao,
        value: r.id,
      }));

      this.itensFormatted = this.participante.itens.map((i) => ({
        label: i.descricao,
        value: i.id,
      }));
    }
  },
  async mounted() {
    const subProjetos = await getSubprojetosByProjetoApi(
      this.$store.projeto.id
    );
    this.subprojetosFormatted = subProjetos.map((sp) => ({
      label: sp.codigo,
      value: sp.id,
    }));
    this.tiposParticipanteFormatted = Object.keys(TIPO_PARTICIPANTE).map(
      (k) => ({
        value: k,
        label: TIPO_PARTICIPANTE[k],
      })
    );
    this.tiposEquipeFormatted = Object.keys(TIPO_EQUIPE).map((k) => ({
      value: k,
      label: TIPO_EQUIPE[k],
    }));
    this.funcoesFormatted = Object.keys(FUNCAO_PARTICIPANTE).map((k) => ({
      value: k,
      label: FUNCAO_PARTICIPANTE[k],
    }));
    try {
      const rubricas = await getRubricas();
      this.rubricasFormatted = rubricas.map((r) => ({
        label: r.descricaoCompleta,
        value: r.id,
      }));
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    closeModal(reload) {
      this.formFields = { ...defaultForm };
      if (reload) {
        EventBus.$emit("participante-updated");
      }
      this.$emit("close");
    },
    onContinue() {
      if (this.$refs.formParticipante1.validate()) {
        this.context = 2;
      }
    },
    async getRubricasSubprojeto() {
      try {
        this.rubricas = [];
        this.formFields.itens = [];
        const rubricas = await getRubricas();
        this.rubricasFormatted = rubricas.map((r) => ({
          label: r.descricaoCompleta,
          value: r.id,
        }));
      } catch (error) {
        console.error(error);
      }
    },
    async getItensRubrica() {
      try {
        this.formFields.itens = [];
        const itens = await filtrarItens({
          projetos: [this.$store.projeto.id],
          subprojetos: [this.formFields.subprojeto],
          rubricas: this.rubricas,
        });
        this.itensFormatted = itens.map((r) => ({
          label: r.descricao,
          value: r.id,
        }));
      } catch (error) {
        console.error(error);
      }
    },
    async getMetasBySubprojeto() {
      try {
        this.formFields.metas = [];
        this.formFields.atividades = [];
        const metas = await searchMetas({
          subprojetos: [this.formFields.subprojeto],
        });
        this.metasFormatted = metas.map((r) => ({
          label: r.descricao,
          value: r.id,
        }));
      } catch (error) {
        console.error(error);
      }
    },
    async getAtividadesByMeta() {
      try {
        this.formFields.atividades = [];
        const atividades = await searchAtividades({
          metas: this.formFields.metas,
        });
        this.atividadesFormatted = atividades.map((r) => ({
          label: r.descricao,
          value: r.id,
        }));
      } catch (error) {
        console.error(error);
      }
    },
    fillFormByPessoaEncontrada(pessoa) {
      if (pessoa && Object.keys(pessoa).length) {
        this.formFields = {
          ...this.formFields,
          pessoa,
          nome: pessoa.nome,
          telefonePrincipal: pessoa.telefonePrincipal,
          telefoneSecundario: pessoa.telefoneSecundario,
          email: pessoa.email,
          cpf: pessoa.cpf,
          matricula: pessoa.matricula,
          departamento: pessoa.departamento,
        };
      }
    },
    async crudParticipante() {
      if (this.$refs.formParticipante2.validate()) {
        try {
          const payload = {
            subprojetoId: this.formFields.subprojeto,
            pessoa: {
              id: this.formFields.pessoa.id || undefined,
              nome: this.formFields.nome,
              telefonePrincipal: this.formFields.telefonePrincipal === "" ? null : this.formFields.telefonePrincipal,
              telefoneSecundario: this.formFields.telefoneSecundario === "" ? null : this.formFields.telefoneSecundario,
              email: this.formFields.email,
              cpf: this.formFields.cpf.replace(/\.|-/g, ""),
              matricula: this.formFields.matricula === "" ? null : this.formFields.matricula,
              departamento: this.formFields.departamento === "" ? null : this.formFields.departamento,
            },
            tipoParticipante: this.formFields.tipoParticipante,
            tipoEquipe: this.formFields.tipoEquipe,
            funcao: this.formFields.funcao,
            horaSemana: Number(this.formFields.horaSemana),
            metas: this.formFields.metas.map((i) => ({ id: i })),
            atividades: this.formFields.atividades.map((i) => ({ id: i })),
            itens: this.formFields.itens.map((i) => ({ id: i })),
          };
          if (this.isEdit) {
            await editarParticipante(this.participante.id, payload);
          } else {
            await cadastrarParticipante(payload);
          }
          this.closeModal(true);
          this.$notify({
            title: "Participante cadastrado/editado!",
            text: "Participante cadastrado/editado com sucesso!",
            type: "success",
            duration: 5000,
          });
        } catch (error) {
          console.error(error);
          this.$notify({
            title: "Erro interno!",
            text: error.message || error,
            type: "error",
            duration: 15000,
          });
        }
      }
    },
    validateEmail: validateEmail,
    validateCpf: validateCpf,
  },
  watch: {
    searchPessoaByName: async function(val) {
      try {
        this.formFields.nome = val;
        if (val && val.length > 2) {
          this.$actions.setDisableGlobalLoader(true);
          this.isLoading = true;
          this.pessoasEncontradas = await filtrarPessoas({
            nome: this.formFields.nome,
          });
          this.isLoading = false;
          this.$actions.setDisableGlobalLoader(false);
        }
      } catch (error) {
        this.isLoading = false;
        console.error(error);
      }
    },
    'formFields.telefonePrincipal': async function(newValue) {
      let testValue = newValue.replace('(', '').replace(')', '').replace(' ', '').replace('-', '');

      if (testValue.length <= 8)
        this.telefonePatternPrimario = '####-####';

      else if (testValue.length <= 10)
        this.telefonePatternPrimario = '(##) ####-####';

      else
        this.telefonePatternPrimario = '(##) #####-####';
    },
    'formFields.telefoneSecundario': async function(newValue) {
      let testValue = newValue.replace('(', '').replace(')', '').replace(' ', '').replace('-', '');

      if (testValue.length <= 8)
        this.telefonePatternSecundario = '####-####';

      else if (testValue.length <= 10)
        this.telefonePatternSecundario = '(##) ####-####';

      else
        this.telefonePatternSecundario = '(##) #####-####';
    }
  },
};
</script>

<style lang="scss" scoped>
.wizard-container {
  display: flex;
  width: 100%;
  justify-content: center;
  padding-bottom: 80px;
}
.wizard-circle {
  position: relative;
  display: flex;
  width: 50px;
  height: 50px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid #ff8d3f;
  border-radius: 100%;
}
.wizard-active {
  background-color: #ff8d3f;
  .wizard-text {
    color: #ffffff !important;
  }
  .wizard-desc {
    color: #026873 !important;
  }
}
.wizard-text {
  margin-top: 8px;
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  color: #026873;
}
.wizard-desc {
  margin: 0px;
  position: absolute;
  bottom: -50px;
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #c4c4c4;
}
.wizard-mid-line {
  align-self: center;
  flex-grow: 1;
  height: 1px;
  border: 1px solid #ff8d3f;
}
</style>
