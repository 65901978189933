import api from "../api";

export const filtrarPessoas = async (params) => {
  let query = Object.keys(params)
    .filter((k) => params[k])
    .map((k) => {
      if (Array.isArray(params[k])) {
        return k + "=" + params[k].join();
      } else {
        return k + "=" + params[k];
      }
    })
    .join("&");
  const res = await api.get(`/membros/search?${query}`);
  return res.data;
};
