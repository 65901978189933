<template>
  <div class="container-div">
    <div
      v-if="this.$route.query.isCadastro"
      style="
        margin-top: 12px;
        margin-bottom: 12px;
        display: flex;
        justify-content: flex-end;
      "
    >
      <div class="reformulacao-div">
        <v-row>
          <v-col md="8">
            <p class="reformulacao-title">
              Cadastro de Projetos - Etapa 6: Itens
            </p>
            <p class="reformulacao-sub-title">
              Ao concluir o cadastro de Itens clique no botão para retornar o
              cadastro de projeto.
            </p>
          </v-col>
          <v-col
            md="4"
            style="display: flex; justify-content: center; align-items: center"
          >
            <v-btn
              class="btn-controls"
              depressed
              color="#FF8D3F"
              width="250"
              large
              :to="{
                path: `/cadastro-projetos/${$store.projeto.id}/editar-cadastro/`,
                query: {currentTab: 5}
              }"
            >
              RETORNAR CADASTRO</v-btn
            >
          </v-col>
        </v-row>
      </div>
    </div>
    <div class="header">
      <v-btn
        class="header-button"
        large
        color="white"
        text
        @click="decidirDestino"
      >
        <v-icon large color="#037F8C">mdi-arrow-left-circle-outline</v-icon>
        <PageTitle :title="'<b>TODOS OS ITENS</b>'" size="18" />
      </v-btn>
      <span>
        <v-btn
          depressed
          :disabled="!itensHomologacao.length"
          color="#FF8D3F"
          class="btn-controls"
          style="margin-right: 15px"
          @click="modalHomologacaoItensOpen = !modalHomologacaoItensOpen"
          >HOMOLOGAR ITENS</v-btn
        >
        <v-btn outlined color="#037F8C" @click="exportarItens">
          EXPORTAR RELATÓRIO
        </v-btn>
      </span>
    </div>
    <div style="display: flex; justify-content: end; padding-top: 5px">
      <div>
        <v-tooltip color="transparent">
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              depressed
              color="#037F8C"
              width="210"
              style="color: #fff; border-radius: 8px"
              @click="modalAddItemOpen = !modalAddItemOpen"
              :disabled="!itemEditavel"
            >
              CADASTRAR ITEM
            </v-btn>
          </template>
        </v-tooltip>
      </div>
    </div>
    <br />
    <ListagemItens context="projeto" />
    <ModalGerarRelatorioProjeto
      v-if="$store.subProjeto"
      :modalVisible="modalGerarRelatorioSubProjeto"
      @close="modalGerarRelatorioSubProjeto = !modalGerarRelatorioSubProjeto"
    />
    <ModalHomologacaoItens
      :key="itensHomologacao.length"
      :modalVisible="modalHomologacaoItensOpen"
      :itens="itensHomologacao"
      @close="modalHomologacaoItensOpen = !modalHomologacaoItensOpen"
    />
    <ModalAdicionarItem
      :key="String(modalAddItemOpen)"
      :modalVisible="modalAddItemOpen"
      @close="modalAddItemOpen = !modalAddItemOpen"
    />
  </div>
</template>

<script>
import ListagemItens from "../../components/itens/ListagemItens.vue";
import ModalGerarRelatorioProjeto from "../../components/modals/ModalGerarRelatorioProjeto.vue";
import ModalHomologacaoItens from "../../components/modals/ModalHomologacaoItens.vue";
import PageTitle from "../../components/PageTitle";
import { EventBus } from "../../services/event-bus";
import { listarItensPendentesHomologacaoProjeto } from "../../services/providers/itensProvider";
import { getRubricasItensProjeto } from "../../services/providers/rubricaProvider";
import { makePDF } from "../../utils/makePDFRelatorioItens";
import ModalAdicionarItem from "../../components/modals/ModalAdicionarItem.vue";

export default {
  name: "Itens",
  components: {
    PageTitle,
    ListagemItens,
    ModalGerarRelatorioProjeto,
    ModalHomologacaoItens,
    ModalAdicionarItem,
  },
  data: () => ({
    itensHomologacao: [],
    modalGerarRelatorioSubProjeto: false,
    modalHomologacaoItensOpen: false,
    modalAddItemOpen: false,
  }),
  mounted() {
    this.getData();
    window.scroll(0,0);
    EventBus.$on("item-updated", async () => {
      await this.getData();
    });
    EventBus.$on("item-homologated", async () => {
      await this.getData();
    });
    if (this.$route.query.isCadastro)
      this.$actions.setDrawerOpen(false);
  },
  methods: {
    async getData() {
      try {
        this.itensHomologacao = await listarItensPendentesHomologacaoProjeto(
          this.$store.projeto.id
        );

        this.itensHomologacao.sort((a,b) => {
          let dataA = a.dataEdicao == null ? a.dataCriacao : a.dataEdicao;
          let dataB = b.dataEdicao == null ? b.dataCriacao : b.dataEdicao;
          return Date.parse(dataA) - Date.parse(dataB);
        })

      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro interno!",
          text: error.message || error,
          type: "error",
          duration: 15000,
        });
      }
    },
    async exportarItens() {
      const rubricas = await getRubricasItensProjeto(this.$store.projeto.id);
      makePDF(this.$store.projeto, null, rubricas);
    },
    decidirDestino() {
      if (this.$route.query)
        if (this.$route.query.isCadastro)
          this.$router.push({
            path: `/cadastro-projetos/${this.$store.projeto.id}/editar-cadastro/`,
            query: {currentTab: 5}
          });
      else
        this.$router.go(-1);
    }
  },
  computed: {
    itemEditavel() {

      const projetoEmReformulacao = this.$store.projeto.reformulacao ? true : false;
      const projetoEmCadastro = this.$store.projeto.estadoCadastroProjeto != 'FINALIZADO';

      return projetoEmReformulacao || projetoEmCadastro;
    }
  },
};
</script>

<style lang="scss" scoped>
.reformulacao-div {
  background-color: #1da7a3;
  width: 100%;
  display: flex;
  padding: 40px;
  border-radius: 20px;
}
.reformulacao-title {
  font-family: Raleway;
  font-weight: bold;
  font-size: 28px;
  color: #f2f2f2;
}
.reformulacao-sub-title {
  font-family: Raleway;
  font-weight: bold;
  font-size: 19px;
  color: #f2f2f2;
}
</style>
