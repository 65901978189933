<template>
  <v-dialog v-model="modalVisible" persistent max-width="1100">
    <v-card style="border-radius: 10px">
      <br />
      <div style="width: 100%; display: flex; justify-content: flex-end">
        <v-btn text large @click="closeModal()">
          <v-icon large color="#037F8C">mdi-close-circle-outline</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <br />
        <p class="session-title">INFORMAÇÕES GERAIS</p>
        <br />
        <v-row>
          <v-col class="t-label-col" md="3">
            <p class="t-label">Descrição:</p>
          </v-col>
          <v-col md="9">
            <p v-if="execucao && execucao.descricao" class="execucao-t-label">
              {{ execucao.descricao }}
            </p>
            <p v-else>-</p>
          </v-col>
        </v-row>
        <hr />
        <v-row>
          <v-col md="6">
            <v-row>
              <v-col class="t-label-col t-label-inline" md="6">
                <p class="t-label">Quantidade:</p>
              </v-col>
              <v-col md="6" class="t-label-inline">
                <p
                  v-if="execucao && execucao.quantidade"
                  class="execucao-t-label"
                >
                  {{ execucao.quantidade }}
                </p>
                <p v-else>-</p>
              </v-col>
            </v-row>
          </v-col>
          <v-col md="6">
            <v-row>
              <v-col class="t-label-col t-label-inline" md="6">
                <p class="t-label">Valor Unitário:</p>
              </v-col>
              <v-col md="6" class="t-label-inline">
                <p
                  v-if="execucao && execucao.valorUnitario"
                  class="execucao-t-label"
                >
                  {{ execucao.valorUnitario | currency }}
                </p>
                <p v-else>-</p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <hr />
        <br />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ModalVerExecucao",
  props: {
    modalVisible: Boolean,
    execucao: Object,
  },
  data: () => ({
    panel1: 0,
    panel2: 0,
  }),
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-title {
  display: flex;
  justify-content: center;
  font-size: 24px !important;
  text-transform: uppercase;
  color: $primary;
  font-weight: 500;
}
.session-title {
  font-size: 24px !important;
  text-transform: uppercase;
  color: $primary;
  font-weight: 500;
}
.t-label {
  color: #616161;
  font-size: 18px;
  text-align: left;
  font-weight: bold;
  margin: 0px;
}
.t-label-col {
  display: flex;
  justify-content: flex-start;
}
.t-label-inline {
  padding-bottom: 0px;
  padding-top: 0px;
}
.tipo-despesa-title {
  color: $primary;
  font-family: Raleway;
  font-size: 22px;
  font-weight: 600;
}
.no-pd {
  padding-left: 0px;
  padding-right: 0px;
}
.no-alg {
  justify-content: flex-start;
}
.execucao-t-label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #616161;
}
hr {
  background-color: #c4c4c4 !important;
}
p {
  margin: 0px;
}
</style>
