import api from "../api";

export const listarEntregaObra = async () => {
  const res = await api.get(`/entrega-obra`);
  return res.data;
};

export const listarEntregaObraPorItem = async (itemId) => {
  const res = await api.get(`/entrega-obra/search?itens=${itemId}`);
  return res.data;
};

export const pegarItemObra = async (idEntrega) => {
  const res = await api.get(`/entrega-obra/${idEntrega}`);
  return res.data;
};

export const cadastrarEntregaObra = async (payload) => {
  const res = await api.post(`/entrega-obra`, payload);
  return res.data;
};

export const editarEntregaObra = async (idEntrega, payload) => {
  const res = await api.patch(`/entrega-obra/${idEntrega}`, payload);
  return res.data;
};

export const adicionarRequisicao = async ( payload) => {
  const res = await api.post(`/processo-entrega-obra/adicionar-requisicao`,payload);
  return res.data;
};

export const editarRequisicao = async (IdRequisicao, payload) => {
  const res = await api.patch(`/requisicoes/${IdRequisicao}`,payload);
  return res.data;
};

export const removerRequisicao = async ( payload) => {
  const res = await api.patch(`/processo-entrega-obra/remover-requisicao`,payload);
  return res.data;
};

export const adicionarEmpenho = async ( payload) => {
  const res = await api.post(`/processo-entrega-obra/adicionar-empenho`,payload);
  return res.data;
};

export const removerEmpenho = async ( payload) => {
  const res = await api.patch(`/processo-entrega-obra/remover-empenho`,payload);
  return res.data;
};

export const editarEmpenho = async (idEmpenho, payload) => {
  const res = await api.patch(`/empenhos/${idEmpenho}`,payload);
  return res.data;
};

export const listarEntregaObraIntermediariaPorItem = async (itemId) => {
  const res = await api.get(`/processo-entrega-obra/search?itens=${itemId}`);
  return res.data;
};

export const excluirProcessoObra = async (id) => {
  const res = await api.delete(`/processo-entrega-obra/${id}`);
  return res.data;
};