import api from "../api";

export const getMetaById = async (id) => {
  const res = await api.get(`/metas/${id}`);
  return res.data;
};

export const searchMetas = async (params) => {
  let query = Object.keys(params)
    .filter((k) => params[k])
    .map((k) => {
      if (Array.isArray(params[k])) {
        return k + "=" + params[k].join();
      } else {
        return k + "=" + params[k];
      }
    })
    .join("&");
  const res = await api.get(`/metas/search?${query}`);
  return res.data;
};

export const cadastrarMeta = async (payload) => {
  const res = await api.post(`/metas`, payload);
  return res.data;
};

export const editarMeta = async (idMeta, payload) => {
  const res = await api.patch(`/metas/${idMeta}`, payload);
  return res.data;
};

export const excluirMeta = async (idMeta) => {
  const res = await api.delete(`/metas/${idMeta}`);
  return res.data;
};
