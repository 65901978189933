<template>
  <div :key="JSON.stringify(item)" class="container-div">
    <v-row>
      <v-col>
        <div class="header">
          <PageTitle title="<b>INFORMAÇÕES GERAIS</b>" />
          <v-btn
            class="btn-controls"
            depressed
            width="250"
            color="#037F8C"
            @click="modalEditInfoOpen = !modalEditInfoOpen"
            :disabled="!itemEditavel"
            >
            EDITAR INFORMAÇÕES GERAIS
          </v-btn>
        </div>
        <br />
        <v-row>
          <v-col class="t-label-col" md="3">
            <p class="t-label">Sub-projeto:</p>
          </v-col>
          <v-col md="9">
            <p v-if="item && item.subprojeto" class="item-t-label">
              {{ item.subprojeto.codigo }}
            </p>
            <p v-else>-</p>
          </v-col>
        </v-row>
        <hr />
        <v-row>
          <v-col class="t-label-col" md="3">
            <p class="t-label">Rubrica:</p>
          </v-col>
          <v-col md="9">
            <p v-if="item && item.rubrica" class="item-t-label">
              {{ item.rubrica.descricaoCompleta }}
            </p>
            <p v-else>-</p>
          </v-col>
        </v-row>
        <hr />
        <v-row>
          <v-col class="t-label-col" md="3">
            <p class="t-label">Descrição:</p>
          </v-col>
          <v-col md="9">
            <p v-if="item && item.descricao" class="item-t-label">
              {{ item.descricao }}
            </p>
            <p v-else>-</p>
          </v-col>
        </v-row>
        <hr />
        <v-row>
          <v-col class="t-label-col" md="3">
            <p class="t-label">Finalidade:</p>
          </v-col>
          <v-col md="9">
            <p v-if="item && item.finalidade" class="item-t-label">
              {{ item.finalidade }}
            </p>
            <p v-else>-</p>
          </v-col>
        </v-row>
        <hr />
        <v-row>
          <v-col md="6">
            <v-row>
              <v-col class="t-label-col t-label-inline" md="6">
                <p class="t-label">Parcela:</p>
              </v-col>
              <v-col md="6" class="t-label-inline">
                <p v-if="item && item.parcela" class="item-t-label">
                  {{ item.parcela.numero }}º
                </p>
                <p v-else>-</p>
              </v-col>
            </v-row>
          </v-col>
          <v-col md="6">
            <v-row>
              <v-col class="t-label-col t-label-inline" md="6">
                <p class="t-label">Status:</p>
              </v-col>
              <v-col md="6" class="t-label-inline">
                <p
                  v-if="item && item.situacaoItem"
                  class="status-t-label"
                  :style="'background-color:' + setarCorPorSituacao(item)"
                >
                  {{ item.situacaoItem.descricao }}
                </p>
                <p v-else>-</p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <hr />
        <v-row>
          <v-col>
            <v-row>
              <v-col class="t-label-col t-label-inline" md="3">
                <p class="t-label">Executado:</p>
              </v-col>
              <v-col md="6" class="t-label-inline">
                <p v-if="item && item.valorExecutado" class="item-t-label">
                  Sim
                </p>
                <p v-else class="item-t-label">Não</p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <hr />
      </v-col>
    </v-row>
    <v-row>
      <ResumoDespesas :item="item" />
    </v-row>
    <v-row>
      <ProcessoAquisicao :item="item" />
    </v-row>
    <v-row>
      <DetalhamentoDespesas :item="item" />
    </v-row>
    <br />
    <br />
    <ModalEdtInfoGeraisItem
      :key="modalEditInfoOpen"
      :modalVisible="modalEditInfoOpen"
      :itemProp="item"
      @close="modalEditInfoOpen = !modalEditInfoOpen"
    />
  </div>
</template>

<script>
import PageTitle from "../../PageTitle";
import { getItem } from "../../../services/providers/itensProvider";
import ModalEdtInfoGeraisItem from "../../modals/ModalEdtInfoGeraisItem.vue";
import { EventBus } from "../../../services/event-bus";
import ResumoDespesas from "./ResumoDespesas.vue";
import DetalhamentoDespesas from "./DetalhamentoDespesas.vue";
import ProcessoAquisicao from "./ProcessoAquisicao.vue";
import { getCorPorSituacaoItem } from "../../../utils/itens";

export default {
  name: "InformacoesGerais",
  components: {
    PageTitle,
    ModalEdtInfoGeraisItem,
    ResumoDespesas,
    DetalhamentoDespesas,
    ProcessoAquisicao,
  },
  props: {
    itemProp: Object,
  },
  data: () => ({
    item: null,
    modalEditInfoOpen: false,
  }),
  created() {
    this.item = this.itemProp;
  },
  async mounted() {
    await this.getData();
    EventBus.$on("item-updated", async () => {
      await this.getData();
    });
    EventBus.$on("execucao-updated", async () => {
      await this.getData();
    });
  },
  methods: {
    async getData() {
      try {
        this.item = await getItem(this.itemProp.id);
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro interno!",
          text: error.message || error,
          type: "error",
          duration: 15000,
        });
      }
    },
    setarCorPorSituacao: getCorPorSituacaoItem,
  },
  computed: {
    itemEditavel() {
      const projetoEmReformulacao = this.$store.projeto.reformulacao ? true : false;
      const projetoEmCadastro = this.$store.projeto.estadoCadastroProjeto != 'FINALIZADO';
      return projetoEmReformulacao || projetoEmCadastro;
    }
  },
};
</script>

<style lang="scss" scoped>
.t-label {
  color: #616161;
  font-size: 18px;
  text-align: left;
  font-weight: bold;
  margin: 0px;
}
.t-label-col {
  display: flex;
  justify-content: flex-start;
}
.t-label-inline {
  padding-bottom: 0px;
  padding-top: 0px;
}
.item-t-label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #616161;
}
.no-pd {
  padding-left: 0px;
  padding-right: 0px;
}
.no-alg {
  justify-content: flex-start;
}
hr {
  background-color: #c4c4c4 !important;
}
p {
  margin: 0px;
}
.status-t-label {
  width: fit-content;
  margin: 0px;
  color: white;
  border-radius: 15px;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
}
</style>
