<template>
  <div>
    <v-row>
      <v-col style="margin-left: 10px">
        <p
          class="page-title"
          :style="`font-size: ${size || 24}px`"
          v-html="title"
        ></p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "PageTitle",
  props: {
    title: String,
    size: String,
  },
};
</script>

<style lang="scss" scoped>
.page-title {
  font-family: $title;
  color: $primary;
  margin-bottom: 0%;
}
</style>
