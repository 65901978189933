var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{style:({
    border: 'none',
    backgroundColor: _vm.custom.color,
    borderRadius: '14px',
    height: '120px',
  }),attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"alerta-title"},[(_vm.isNovo)?_c('div',{staticClass:"novo"},[_vm._v("novo")]):_c('div'),_c('v-menu',{attrs:{"offset-y":"","left":"","open-on-hover":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","rounded":"","text":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-dots-horizontal")])],1)]}}])},[_c('v-btn',{staticClass:"btn-alerta",attrs:{"text":"","rounded":""},on:{"click":_vm.custom.onClick}},[_vm._v(" Verificar alerta ")])],1)],1),_c('v-card-text',{staticStyle:{"color":"white"}},[_c('v-row',[_c('v-col',{staticStyle:{"display":"flex","justify-content":"center"},attrs:{"md":"3","xl":"2","sm":"3"}},[_c('v-icon',{attrs:{"x-large":"","color":"white"}},[_vm._v(_vm._s(_vm.custom.icon))])],1),_c('v-col',{attrs:{"md":"9","xl":"10","sm":"9"}},[_c('div',{staticClass:"titulo"},[_vm._v(" "+_vm._s(_vm.custom.titulo)+" ")]),_c('div',{staticClass:"menssagem",domProps:{"innerHTML":_vm._s(_vm.custom.menssagem)}})])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }