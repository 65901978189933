<template>
  <v-col :key="rerender">
    <div class="header">
      <PageTitle title="<b>PROCESSO DE AQUISIÇÃO</b>" />
      <v-btn
        class="btn-controls"
        depressed
        color="#037F8C"
        @click="openCrudModal"
      >
        CADASTRAR AQUISIÇÃO
      </v-btn>
    </div>
    <br />
    <div v-if="processosAquisicao.length > 0">
      <v-simple-table dense>
        <template v-slot:default>
          <thead>
            <tr>
              <th rowspan="2" class="title-tab" style="width: 5%">Nº</th>
              <th rowspan="2" class="title-tab" style="width: 30%">
                Nº DE REQUISIÇÃO
              </th>
              <th rowspan="2" class="title-tab">DATA DE REQUISIÇÃO</th>
              <th rowspan="2" class="title-tab">Nº DE EMPENHO</th>
              <th rowspan="2" class="title-tab">DATA DE EMPENHO</th>
              <th rowspan="2" class="title-tab">SITUAÇÃO EMPENHO</th>
              <th rowspan="2" class="title-tab">VALOR TOTAL EMPENHO</th>
              <th rowspan="2" class="title-tab">RECEBIMENTO</th>
              <th rowspan="2" class="title-tab" style=""></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in processosAquisicao" v-bind:key="item.id">
              <td class="text-tab">{{ item.indice}}</td>

              <!-- Número da requisicao -->
              <td v-if="item.processo.requisicao" class="text-tab">
                {{ item.processo.requisicao.numero }}
              </td>
              <td v-else class="text-tab">-</td>

              <!-- Data da requisicao -->
              <td class="text-tab" v-if="item.processo.requisicao && item.processo.requisicao.data">
                {{ formatBetterDate(item.processo.requisicao.data) }}
              </td>
              <td v-else class="text-tab">-</td>

              <!-- Número do empenho -->
              <td class="text-tab" v-if="item.empenho">
                {{ item.empenho.numero }}
              </td>
              <td v-else class="text-tab">-</td>

              <!-- data do empenho -->
              <td class="text-tab" v-if="item.empenho && item.empenho.data">
                {{ formatBetterDate(item.empenho.data) }}
              </td>
              <td v-else class="text-tab">-</td>

              <!-- Situação do empenho -->
              <td class="text-tab" v-if="item.empenho && item.empenho.situacao">
                {{ item.empenho.situacao }}
              </td>
              <td v-else class="text-tab">-</td>

              <!-- valor total do empenho -->
              <td class="text-tab">R$ 0,00</td>

              <!-- Situação da entrega -->
              <td class="text-tab">{{ item.situacao }}</td>
              <td
                class="text-tab"
                style="width: 10%; padding-left: 0px; padding-right: 0px"
              >
                <div style="display: flex; flex-direction: row">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" text small class="option-hover"
                      @click="(processoSelecionado = item.processo) & openModalEdit()"
                      >
                        <v-icon color="#ff8d3f">mdi-pencil-box-outline</v-icon>
                      </v-btn>
                    </template>
                    <span> Editar </span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        text
                        small
                        class="option-hover"
                        @click="
                          (processoSelecionado = item.processo) &
                            (modalExcluirOpen = !modalExcluirOpen)
                        "
                      >
                        <v-icon color="#ff8d3f">mdi-delete-outline</v-icon>
                      </v-btn>
                    </template>
                    <span> Excluir </span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn
                        v-on="on"
                        text
                        small
                        class="option-hover"
                        @click="(processoSelecionado = item.processo) & openModalVisu()"
                      >
                        <v-icon color="#ff8d3f">mdi-eye-outline</v-icon>
                      </v-btn>
                    </template>
                    <span> Visualizar </span>
                  </v-tooltip>
                </div>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <p v-else style="font-family: Raleway; font-size: 18px">
      Nenhum processo de aquisição cadastrado!
    </p>
    <ModalCrudEntregaItem
      v-if="!isItemObra"
      :key="String(entregaSelecionada ? entregaSelecionada.id : null)"
      :modalVisible="modalEntregaItemOpen"
      :item="item"
      :entrega="entregaSelecionada"
      @close="
        (modalEntregaItemOpen = !modalEntregaItemOpen) &
          (entregaSelecionada = null)"
    />
    <ModalCrudEntregaObra
      v-if="isItemObra"
      :key="String(entregaSelecionada ? entregaSelecionada.id : false)"
      :modalVisible="modalEntregaObraOpen"
      :item="item"
      :entrega="entregaSelecionada"
      @close="
        (modalEntregaObraOpen = !modalEntregaObraOpen) &
          (entregaSelecionada = null)"
    />
    <ModalInfoRecebiComum
      v-if="!isItemObra && processoSelecionado"
      :modalVisible="modalVisulOpen"
      :item="item"
      :processoId="processoSelecionado"
      @close="(modalVisulOpen = !modalVisulOpen) & (processoSelecionado = null)"
    />
    <ModalConfirmacao
      v-if="processoSelecionado"
      :modalVisible="modalExcluirOpen"
      :titulo="'Você realmente deseja excluir esta aquisição'"
      textoBotaoDireito="Excluir"
      :onConfirm="() => excluirprocesso() & getProcesso()"
      @close="(modalExcluirOpen = !modalExcluirOpen) & getProcesso()"
    />
    <ModalInfoRecebiObra
      v-if="isItemObra && processoSelecionado"
      :modalVisible="openModalVisulObra"
      :item="item"
      :processoId="processoSelecionado"
      @close="
        (openModalVisulObra = !openModalVisulObra) &
          (processoSelecionado = null)"
    />
    <ModalEntregaItemEditar
      v-if="!isItemObra && processoSelecionado"
      :key="String(processoSelecionado ? processoSelecionado.id : false)"
      :modalVisible="openModalEditComum"
      :item="item"
      :processoId="processoSelecionado"
      @close="
        (openModalEditComum = !openModalEditComum) &
          (processoSelecionado = null)"
    />
    <ModalEntregaObraEditar
      v-if="isItemObra && processoSelecionado"
      :key="String(processoSelecionado ? processoSelecionado.id : false)"
      :modalVisible="openModalEditObra"
      :item="item"
      :processoId="processoSelecionado"
      @close="
        (openModalEditObra = !openModalEditObra) & (processoSelecionado = null)"
    />
  </v-col>
</template>

<script>
import { EventBus } from "../../../services/event-bus";
import {
  listarEntregaItemIntermediariaPorItem,
  excluirProcessoComum,
} from "../../../services/providers/entregaItemProvider";
import {
  listarEntregaObraIntermediariaPorItem,
  excluirProcessoObra,
} from "../../../services/providers/entregaObraProvider";
import ModalCrudEntregaItem from "../../modals/ModalCrudEntregaItem";
import ModalInfoRecebiComum from "../../modals/ModalInfoRecebiComum";
import ModalInfoRecebiObra from "../../modals/ModalInfoRecebiObra.vue";
import ModalCrudEntregaObra from "../../modals/ModalCrudEntregaObra";
import ModalConfirmacao from "../../modals/ModalConfirmacao.vue";
import PageTitle from "../../PageTitle";
import ModalEntregaItemEditar from "../../modals/ModalEntregaItemEditar.vue";
import ModalEntregaObraEditar from "../../modals/ModalEntregaObraEditar.vue";

export default {
  name: "ProcessoAquisicao",
  props: {
    item: Object,
  },
  components: {
    PageTitle,
    ModalCrudEntregaItem,
    ModalCrudEntregaObra,
    ModalInfoRecebiComum,
    ModalConfirmacao,
    ModalInfoRecebiObra,
    ModalEntregaItemEditar,
    ModalEntregaObraEditar,
  },
  data: () => ({
    modalEntregaItemOpen: false,
    modalEntregaObraOpen: false,
    modalVisulOpen: false,
    modalExcluirOpen: false,
    openModalVisulObra: false,
    openModalEditComum: false,
    openModalEditObra: false,
    entregaSelecionada: null,
    entregas: [],
    idxAtual: 0,
    rerender: 0,
    dados: [],
    processoSelecionado: null,
  }),
  computed: {
    isItemObra: function () {
      return this.item.rubrica.codigo == "44.00.51";
    },
    processosAquisicao: function () {
      let processos = [];
      for (let ii = 0; ii < this.dados.length; ii++) {

        let situacao = "";

        if (this.dados[ii].recebimento == 'NAOREGISTRADO'){
          situacao = 'Não registrada'
        } else if (this.dados[ii].recebimento == 'RECEBIDO'){
          situacao = 'Recebido'
        } else if (this.dados[ii].recebimento == 'INSTALADO'){
          situacao = 'Instalado'
        } else if (this.dados[ii].recebimento == 'OCUPADO'){
          situacao = 'Ocupado'
        }

        if (this.dados[ii].empenhos && this.dados[ii].empenhos.length > 0){
          for (let jj = 0; jj < this.dados[ii].empenhos.length; jj++){
            processos.push({
                indice: `${ii+1}.${jj+1}`,
                situacao: situacao,
                empenho: this.dados[ii].empenhos[jj],
                processo: this.dados[ii]
              }
            );
          }
        } else{
          processos.push({
            indice: `${ii+1}`,
            situacao: situacao,
            empenho: null,
            processo: this.dados[ii]
          });
        }
      }
      return processos;
    }
  },
  async mounted() {
    await this.getProcesso();
    EventBus.$on("entrega-updated", async () => {
      await this.getProcesso();
      this.rerender += 1;
    });
  },
  methods: {
    openModalVisu() {
      if (this.isItemObra) {
        this.openModalVisulObra = !this.openModalVisulObra;
      } else {
        this.modalVisulOpen = !this.modalVisulOpen;
      }
    },
    openModalEdit() {
      if (this.isItemObra) {
        this.openModalEditObra = !this.openModalEditObra;
      } else {
        this.openModalEditComum = !this.openModalEditComum;
      }
    },
    openCrudModal() {
      if (this.isItemObra) {
        this.modalEntregaObraOpen = !this.modalEntregaObraOpen;
      } else {
        this.modalEntregaItemOpen = !this.modalEntregaItemOpen;
      }
    },
    async getProcesso() {
      try {
        if (this.isItemObra) {
          this.dados = await listarEntregaObraIntermediariaPorItem(
            this.item.id
          );
        } else {
          this.dados = await listarEntregaItemIntermediariaPorItem(
            this.item.id
          );
        }
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro interno!",
          text: error.message || error,
          type: "error",
          duration: 15000,
        });
      }
    },
    async excluirprocesso() {
      if (this.processoSelecionado) {
        if (this.isItemObra) {
          try {
            await excluirProcessoObra(this.processoSelecionado.id);
            this.$notify({
              title: "Processo excluída!",
              text: "A Processo foi excluído com sucesso!",
              type: "success",
              duration: 5000,
            });
            EventBus.$emit("entrega-updated");
          } catch (error) {
            console.error(error);
            this.$notify({
              title: "Erro!",
              text: "Erro ao excluir o Processo!",
              type: "error",
              duration: 15000,
            });
          }
        } else {
          try {
            await excluirProcessoComum(this.processoSelecionado.id);
            this.$notify({
              title: "Processo excluída!",
              text: "A Processo foi excluído com sucesso!",
              type: "success",
              duration: 5000,
            });
            EventBus.$emit("entrega-updated");
          } catch (error) {
            console.error(error);
            this.$notify({
              title: "Erro!",
              text: "Erro ao excluir o Processo!",
              type: "error",
              duration: 15000,
            });
          }
        }
      }
    },
    formatBetterDate(dateString) {
      if (!dateString)
        return ""
      const date = new Date(dateString + " ");
      const yyyy = date.getFullYear();
      let mm = date.getMonth() + 1; // Months start at 0!
      let dd = date.getDate();

      if (dd < 10) dd = '0' + dd;
      if (mm < 10) mm = '0' + mm;

      return `${dd}/${mm}/${yyyy}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 0.8px solid #037f8c;
  border-radius: 10px;
  padding-top: 50px;
  padding-bottom: 50px;
}
.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.t-label {
  color: #616161;
  font-size: 18px;
  text-align: left;
  font-weight: bold;
  margin: 0px;
  margin-right: 20px;
}
.t-label-col {
  display: flex;
  justify-content: flex-start;
}
.t-label-inline {
  padding-bottom: 0px;
  padding-top: 0px;
}
.item-t-label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #616161;
}
.no-pd {
  padding-left: 0px;
  padding-right: 0px;
}
.no-alg {
  justify-content: flex-start;
}
hr {
  background-color: #c4c4c4 !important;
}
p {
  margin: 0px;
}

.title-tab {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 16px;
  text-align: center !important;
  color: $primary !important;
  background-color: #e6f4f1;
  border: 0.899715px solid rgba(97, 97, 97, 0.3);
  height: 45px !important;
}
.text-tab {
  font-family: Roboto;
  font-style: normal;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 13px;
  text-align: center;
  color: #616161;
  border: 0.899715px solid rgba(97, 97, 97, 0.3);
  height: 45px !important;
}
.div-body {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 16px;
  text-align: center !important;
  color: $primary !important;
  padding: 0px;
}
.text-body {
  display: flex;
  height: 45px;
  padding: 10px;
  width: 100%;
  background-color: #e6f4f1;
  text-align: center;
  border: 0.899715px solid rgba(97, 97, 97, 0.3);
  border-left: none;
  border-right: none;
}
</style>
