<template>
  <div class="container-div">
    <div class="header d-flex flex-row-reverse">
      <v-btn
        outlined
        color="#037F8C"
        style="margin-top: 16px"
        width="240"
        @click="modalGerarRelatorioProjeto = !modalGerarRelatorioProjeto"
      >
        EXPORTAR RELATÓRIO
      </v-btn>
    </div>
    <div>
      <div class="container-header">
        <p class="session-title">RENDIMENTO DE APLICAÇÃO FINANCEIRA</p>
        <v-btn
          class="btn-controls"
          depressed
          color="#037F8C"
          width="240"
          @click="openModal"
          >ATUALIZAR RENDIMENTO</v-btn
        >
      </div>
      <v-row>
        <v-col>
          <v-row>
            <v-col>
              <v-row>
                <v-col md="6"></v-col>
                <v-col class="despesa-title">VALOR</v-col>
                <v-col class="despesa-title">ATÉ</v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-row>
                <v-col md="6" class="label-inline despesa-title"
                  >Total de rendimentos:</v-col
                >
                <v-col
                  v-if="projeto && projeto.metricasRendimento"
                  class="label-inline despesa-title-value"
                  >{{
                    projeto.metricasRendimento.valorAutorizado | currency
                  }}</v-col
                >
                <v-col class="label-inline despesa-title-value" v-else>-</v-col>
                <v-col
                  v-if="projeto && projeto.metricasRendimento"
                  class="label-inline despesa-title-value"
                  >{{
                    new Date(projeto.metricasRendimento.dataReferencia)
                      | moment("DD/MM/YYYY")
                  }}</v-col
                >
                <v-col class="label-inline despesa-title-value" v-else>-</v-col>
              </v-row>
            </v-col>
          </v-row>
          <hr />
          <v-row>
            <v-col>
              <v-row>
                <v-col md="6" class="label-inline despesa-title"
                  >Total de Rendimento alocado:</v-col
                >
                <v-col
                  v-if="projeto && projeto.metricasRendimento"
                  class="label-inline despesa-title-value"
                  >{{
                    projeto.metricasRendimento.valorAlocado | currency
                  }}</v-col
                >
                <v-col class="label-inline despesa-title-value" v-else>-</v-col>
                <v-col
                  v-if="projeto && projeto.dataUltimoRendimentoSuplementado"
                  class="label-inline despesa-title-value"
                  >{{
                    new Date(projeto.dataUltimoRendimentoSuplementado)
                      | moment("DD/MM/YYYY")
                  }}</v-col
                >
                <v-col class="label-inline despesa-title-value" v-else>-</v-col>
              </v-row>
            </v-col>
          </v-row>
          <hr />
          <v-row>
            <v-col>
              <v-row>
                <v-col md="6" class="label-inline despesa-title"
                  >RENDIMENTO NÃO ALOCADO:</v-col
                >
                <v-col
                  v-if="projeto && projeto.metricasRendimento"
                  class="label-inline despesa-title-value"
                  >{{
                    projeto.metricasRendimento.saldoNaoAlocado | currency
                  }}</v-col
                >
                <v-col class="label-inline despesa-title-value" v-else>-</v-col>
                <v-col class="label-inline despesa-title-value">-</v-col>
              </v-row>
            </v-col>
          </v-row>
          <hr />
        </v-col>
      </v-row>
      <br />
    </div>
    <ModalAttRendimento
      :key="modalVisible"
      :modalVisible="modalVisible"
      :projeto="projeto"
      @close="closeModal"
    />
    <ModalGerarRelatorioProjeto
      v-if="projeto"
      :modalVisible="modalGerarRelatorioProjeto"
      @close="modalGerarRelatorioProjeto = !modalGerarRelatorioProjeto"
    />
  </div>
</template>

<script>
import ModalAttRendimento from "../../components/modals/ModalAttRendimento";
import ModalGerarRelatorioProjeto from "../../components/modals/ModalGerarRelatorioProjeto.vue";

export default {
  name: "ResumoFinanceiro",
  components: {
    ModalAttRendimento,
    ModalGerarRelatorioProjeto,
  },
  data: () => ({
    projeto: null,
    modalVisible: false,
    modalGerarRelatorioProjeto: false,
  }),
  created() {
    this.projeto = this.$store.projeto;
  },
  watch: {
    "$store.projeto": function(projeto) {
      this.projeto = projeto;
    },
  },
  methods: {
    closeModal() {
      this.modalVisible = false;
    },
    openModal() {
      this.modalVisible = !this.modalVisible;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-controls {
  border-radius: 8px;
  color: #fff;
}
.session-title {
  margin: 20px;
  margin-top: 5px;
  margin-left: 0px;
  margin-bottom: 0px;
  text-transform: uppercase;
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #037f8c;
}
.container-header {
  display: flex;
  justify-content: space-between;
  padding: 25px;
  padding-left: 0px;
  padding-right: 0px;
}
.despesa-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16x;
  line-height: 21px;
  display: flex;
  align-items: flex-end;
  text-align: flex-end;
  color: #037f8c;
  text-transform: uppercase;
  text-align: center;
}
.despesa-title-value {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: flex-end;
  color: #037f8c;
}
</style>
