import api from "../api"

export const atualizarUsuario = async (id, payload) => {
    const res = await api.patch(`/usuarios/${id}`, payload);
    return res.data;
}

export const redefinirSenha = async (payload) => {
    const res = await api.patch(`/usuarios/redefinir-senha`, payload);
    return res.data;
}

export const solicitarRecuperacaoDeSenha = async (email) => {
    const res = await api.get(`/usuarios/solicitar-redefinicao-senha?email=${email}`);
    return res.data;
}