<template>
  <v-app-bar app flat color="#037F8C" dark>
    <div class="navbar-content">
      <div class="logo-header">
        <a href="/"
          ><img width="90" height="67" src="../assets/logo_branca.png"
        /></a>
      </div>
      <SearchBar />
      <v-list class="menu-header" dark v-for="(item, idx) in items" :key="idx">
        <v-list-item
          active-class="highlight"
          link
          :to="item.route"
          :id="'item-' + idx"
        >
          <v-list-item-content>
            <v-list-item-title id="list-item-title">{{
              item.title
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <ToolsBar />
    </div>
  </v-app-bar>
</template>

<script>
import SearchBar from "./SearchBar";
import ToolsBar from "./ToolsBar";
export default {
  name: "NavBar",
  components: {
    SearchBar,
    ToolsBar,
  },
  data: () => ({
    items: [
      {
        title: "Projetos Vigentes",
        route: "/projetos-vigentes",
      },
      {
        title: "Cadastrar Projetos",
        route: "/cadastro-projetos",
      },
      {
        title: "Projetos Finalizados",
        route: "/projetos-finalizados",
      },
      {
        title: "Alertas",
        route: "/alertas",
      },
    ],
  }),
};
</script>

<style lang="scss" scoped>
.logo-header {
  display: flex;
  justify-content: center;
  width: 12%;
  background: #037F8C;
  padding-left: 30px;
  padding-right: 30px;
  margin-right: 30px;

  @media (max-width: 1366px) {
    width: 16.4%;
  }
}
.navbar-content {
  display: flex;
  width: 100%;
  height: 85%;
  background-color: $primary;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  align-items: center;
  justify-content: space-between;
  align-self: center;
  align-content: center;
  z-index: 99999999999;
}
.menu-header {
  height: 72px;
  padding: 0px;
  align-items: center;
  background-color: $primary;
}
.highlight {
  background-color: #026873 !important;
}
</style>
