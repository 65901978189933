<template>
  <div>
    <v-row>
      <v-col md="4">
        <v-text-field
          v-model="camposTextuais"
          class="search-item-input"
          outlined
          dense
          clearable
          label="Pesquisar..."
          append-icon="mdi-magnify"
          color="#037F8C"
          v-on:keyup.enter="buscarItemPorCamposTextuais"
          @click:clear="limparFiltros"
          @click:append="buscarItemPorCamposTextuais"
        >
        </v-text-field>
      </v-col>
      <v-col
        md="8"
        :style="{ marginTop: opcoesSelecionadas.length ? '-20px' : '-12px' }"
      >
        <v-expansion-panels v-model="panel" accordion>
          <v-expansion-panel>
            <v-expansion-panel-header
              style="
                border-bottom: 1px solid #037f8c;
                padding-bottom: 8px;
                justify-content: flex-start;
              "
            >
              <p class="filter-title">Filtros:</p>
              <div class="chips-content">
                <div v-for="op in opcoesSelecionadas" :key="op">
                  <div class="chip">
                    <span>{{ op }}</span>
                  </div>
                </div>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row v-if="context == 'projeto'">
                <v-col class="label-col label" md="2">Subprojeto:</v-col>
                <v-col>
                  <v-select
                    class="select-form"
                    outlined
                    hide-details
                    clearable
                    small-chips
                    multiple
                    dense
                    color="#037F8C"
                    item-color="#037F8C"
                    :items="subprojetos"
                    item-text="label"
                    item-value="value"
                    label="Todos os subprojetos"
                    solo
                    v-model="subProjetoSelecionados"
                    return-object
                    ><template v-slot:prepend-item>
                      <v-list-item
                        ripple
                        @click="
                          subProjetoSelecionados = selecionarTodasOpcoes(
                            subProjetoSelecionados,
                            subprojetos
                          )
                        "
                      >
                        <v-list-item-action>
                          <v-icon>
                            {{
                              subProjetoSelecionados.length ==
                              subprojetos.length
                                ? "mdi-close-box"
                                : "mdi-checkbox-blank-outline"
                            }}
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            Selecionar todos
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="label-col label" md="2">Rubrica:</v-col>
                <v-col>
                  <v-select
                    class="select-form"
                    outlined
                    hide-details
                    clearable
                    small-chips
                    multiple
                    dense
                    label="Todas as rubricas"
                    solo
                    color="#037F8C"
                    item-color="#037F8C"
                    :items="rubricasFilter"
                    item-text="label"
                    item-value="value"
                    v-model="rubricaFilterSelecionada"
                    return-object
                    ><template v-slot:prepend-item>
                      <v-list-item
                        ripple
                        @click="
                          rubricaFilterSelecionada = selecionarTodasOpcoes(
                            rubricaFilterSelecionada,
                            rubricasFilter
                          )
                        "
                      >
                        <v-list-item-action>
                          <v-icon>
                            {{
                              rubricaFilterSelecionada.length ==
                              rubricasFilter.length
                                ? "mdi-close-box"
                                : "mdi-checkbox-blank-outline"
                            }}
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            Selecionar todas
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="label-col label" md="2">Status:</v-col>
                <v-col>
                  <v-select
                    class="select-form"
                    outlined
                    hide-details
                    clearable
                    chips
                    multiple
                    dense
                    label="Todos os status"
                    solo
                    color="#037F8C"
                    item-color="#037F8C"
                    :items="status"
                    item-text="label"
                    item-value="value"
                    v-model="statusSelecionado"
                    return-object
                  >
                    <template v-slot:prepend-item>
                      <v-list-item
                        ripple
                        @click="
                          statusSelecionado = selecionarTodasOpcoes(
                            statusSelecionado,
                            status
                          )
                        "
                      >
                        <v-list-item-action>
                          <v-icon>
                            {{
                              statusSelecionado.length == status.length
                                ? "mdi-close-box"
                                : "mdi-checkbox-blank-outline"
                            }}
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            Selecionar todos
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="label-col label" md="2">Parcela:</v-col>
                <v-col>
                  <v-select
                    outlined
                    chips
                    hide-details
                    clearable
                    multiple
                    dense
                    label="Todas as parcelas"
                    solo
                    :items="parcelas"
                    color="#037F8C"
                    item-color="#037F8C"
                    item-text="label"
                    item-value="value"
                    v-model="parcelaSelecionada"
                    return-object
                  >
                    <template v-slot:prepend-item>
                      <v-list-item
                        ripple
                        @click="
                          parcelaSelecionada = selecionarTodasOpcoes(
                            parcelaSelecionada,
                            parcelas
                          )
                        "
                      >
                        <v-list-item-action>
                          <v-icon>
                            {{
                              parcelaSelecionada.length == parcelas.length
                                ? "mdi-close-box"
                                : "mdi-checkbox-blank-outline"
                            }}
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            Selecionar todas
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-select>
                </v-col>
                <v-col class="label-col label" md="2">Recurso:</v-col>
                <v-col>
                  <v-select
                    class="select-form"
                    outlined
                    hide-details
                    clearable
                    chips
                    multiple
                    dense
                    label="Todos os recursos"
                    solo
                    color="#037F8C"
                    item-color="#037F8C"
                    :items="recursos"
                    item-text="label"
                    item-value="value"
                    v-model="recursoSelecionado"
                    return-object
                  >
                    <template v-slot:prepend-item>
                      <v-list-item
                        ripple
                        @click="
                          recursoSelecionado = selecionarTodasOpcoes(
                            recursoSelecionado,
                            recursos
                          )
                        "
                      >
                        <v-list-item-action>
                          <v-icon>
                            {{
                              recursoSelecionado.length == recursos.length
                                ? "mdi-close-box"
                                : "mdi-checkbox-blank-outline"
                            }}
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            Selecionar todos
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="label-col label" md="2">Saldo:</v-col>
                <v-col>
                  <v-select
                    class="select-form"
                    outlined
                    hide-details
                    clearable
                    chips
                    multiple
                    dense
                    label="Todos os saldos"
                    solo
                    color="#037F8C"
                    item-color="#037F8C"
                    :items="tiposSaldos"
                    item-text="label"
                    item-value="value"
                    v-model="tiposSaldoSelecionado"
                    return-object
                  >
                    <template v-slot:prepend-item>
                      <v-list-item
                        ripple
                        @click="
                          tiposSaldoSelecionado = selecionarTodasOpcoes(
                            tiposSaldoSelecionado,
                            tiposSaldos
                          )
                        "
                      >
                        <v-list-item-action>
                          <v-icon>
                            {{
                              tiposSaldoSelecionado.length == tiposSaldos.length
                                ? "mdi-close-box"
                                : "mdi-checkbox-blank-outline"
                            }}
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            Selecionar todos
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-select>
                </v-col>
                <v-col class="label-col label" md="2">Execução:</v-col>
                <v-col>
                  <v-select
                    class="select-form"
                    outlined
                    hide-details
                    clearable
                    dense
                    chips
                    multiple
                    label="Todas as execuções"
                    solo
                    color="#037F8C"
                    item-color="#037F8C"
                    :items="tipoExecucaos"
                    item-text="label"
                    item-value="value"
                    v-model="tipoExecucaoSelecionada"
                    return-object
                  >
                    <template v-slot:prepend-item>
                      <v-list-item
                        ripple
                        @click="
                          tipoExecucaoSelecionada = selecionarTodasOpcoes(
                            tipoExecucaoSelecionada,
                            tipoExecucaos
                          )
                        "
                      >
                        <v-list-item-action>
                          <v-icon>
                            {{
                              tipoExecucaoSelecionada.length ==
                              tipoExecucaos.length
                                ? "mdi-close-box"
                                : "mdi-checkbox-blank-outline"
                            }}
                          </v-icon>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            Selecionar todas
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider class="mt-2"></v-divider>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="5"></v-col>
                <v-col
                  style="
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                  "
                  md="7"
                >
                  <v-btn
                    small
                    outlined
                    width="190"
                    color="#037f8c"
                    @click="limparFiltros"
                    >Limpar filtros</v-btn
                  >
                  <v-btn
                    small
                    depressed
                    width="190"
                    class="btn-controls"
                    color="#037f8c"
                    @click="filtrarItens()"
                    >Aplicar filtros</v-btn
                  >
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <div v-if="rubricas.length">
      <div :key="rubricas.length + 'ItensTable'">
        <div v-for="(rubrica, idx) in rubricas" v-bind:key="idx">
          <p v-if="rubrica" class="session-title">
            {{ rubrica.codigo }} - {{ rubrica.descricaoCompleta }}
          </p>
          <ItensTable
            :context="context"
            v-if="rubrica && rubrica.itens.length"
            :itens="rubrica.itens"
          />
        </div>
      </div>
    </div>
    <p v-else style="font-family: Raleway; font-size: 20px">
      Nenhum item encontrado!
    </p>
    <br />
    <br />
  </div>
</template>

<script>
import {
  formatItensPorRubrica,
  getRubricasItensSubProjeto,
  getRubricasItensProjeto,
  getRubricasPorSubProjeto,
  getRubricasPorProjeto,
} from "../../services/providers/rubricaProvider";
import ItensTable from "../tables/ItensTable";
import {
  filtrarItens,
  listarSituacoesItem,
} from "../../services/providers/itensProvider";
import { EventBus } from "../../services/event-bus";
import { getSubprojetosByProjetoApi } from "../../services/providers/subProjetosProvider";

export default {
  name: "ListagemItens",
  props: {
    context: {
      type: String,
      default: "subprojeto",
    },
  },
  components: {
    ItensTable,
  },
  data: () => ({
    subProjeto: null,
    itens: [],
    itensHomologacao: [],
    rubricas: [],
    camposTextuais: "",
    subprojetos: [],
    subProjetoSelecionados: [],
    rubricasFilter: [],
    rubricaFilterSelecionada: [],
    parcelas: [
      { label: "1º parcela", value: 1 },
      { label: "2º parcela", value: 2 },
      { label: "3º parcela", value: 3 },
      { label: "Sem parcela", value: null },
    ],
    parcelaSelecionada: [],
    status: [],
    statusSelecionado: [],
    recursos: [
      { label: "FNDCT", value: "SOMENTE_FNDCT" },
      { label: "Rendimento", value: "SOMENTE_RENDIMENTO" },
      { label: "Complementação", value: "AMBOS" },
    ],
    recursoSelecionado: [],
    tiposSaldos: [
      { label: "Saldo positivo", value: 1 },
      { label: "Saldo zero", value: 0 },
      { label: "Saldo negativo", value: -1 },
    ],
    tiposSaldoSelecionado: [],
    tipoExecucaos: [
      { label: "Executado", value: 1 },
      { label: "Não executado", value: -1 },
    ],
    tipoExecucaoSelecionada: [],
    panel: [1],
    opcoesSelecionadas: [],
  }),
  created() {
    this.$watch(
      (vm) => [
        vm.subProjetoSelecionados,
        vm.rubricaFilterSelecionada,
        vm.parcelaSelecionada,
        vm.statusSelecionado,
        vm.recursoSelecionado,
        vm.tiposSaldoSelecionado,
        vm.tipoExecucaoSelecionada,
      ],
      () => {
        this.opcoesSelecionadas = this.getSelecionados();
      }
    );
  },
  mounted() {
    this.getData();
    EventBus.$on("item-updated", async () => {
      await this.getRubricasItens();
    });
    EventBus.$on("item-homologated", async () => {
      await this.getRubricasItens();
    });
  },
  methods: {
    async getData() {
      try {
        await this.getRubricasItens();
        let status = await listarSituacoesItem();
        this.status = status.map((o) => ({ label: o.descricao, value: o.id }));
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro interno!",
          text: error.message || error,
          type: "error",
          duration: 15000,
        });
      }
    },
    async getRubricasItens() {
      let rubricasFilter = [];
      if (this.context == "projeto") {
        let projetoId = this.$store.projeto.id;
        rubricasFilter = await getRubricasPorProjeto(projetoId);
        this.rubricas = await getRubricasItensProjeto(projetoId);
        let subprojetos = await getSubprojetosByProjetoApi(projetoId);
        this.subprojetos = subprojetos.map((o) => ({
          label: o.codigo,
          value: o.id,
        }));
      } else {
        this.subProjeto = this.$store.subProjeto;
        rubricasFilter = await getRubricasPorSubProjeto(this.subProjeto.id);
        this.rubricas = await getRubricasItensSubProjeto(this.subProjeto.id);
      }
      this.rubricasFilter = rubricasFilter.map((o) => ({
        label: o.descricaoCompleta,
        value: o.id,
      }));
      this.limparCamposFiltro();
    },
    getValueObjetoSelecao(selecoes) {
      return selecoes.map((selecao) => {
        if (typeof selecao === "object") {
          return selecao ? selecao.value : null;
        } else {
          return selecao || null;
        }
      });
    },
    async buscarItemPorCamposTextuais() {
      try {
        if (this.camposTextuais) {
          const params = {
            camposTextuais: this.camposTextuais,
            subprojetos:
              this.context == "subprojeto" ? [this.subProjeto.id] : undefined,
            projetos: [this.$store.projeto.id],
          };
          const itens = await filtrarItens(params);
          this.rubricas = await formatItensPorRubrica(itens);
        }
      } catch (error) {
        console.error(error);
      }
    },
    async filtrarItens() {
      try {
        const params = {
          rubricas: this.rubricaFilterSelecionada.length
            ? this.getValueObjetoSelecao(this.rubricaFilterSelecionada)
            : undefined,
          situacoes: this.statusSelecionado.length
            ? this.getValueObjetoSelecao(this.statusSelecionado)
            : undefined,
          parcelas: this.parcelaSelecionada.length
            ? this.getValueObjetoSelecao(this.parcelaSelecionada)
            : undefined,
          fontes: this.recursoSelecionado.length
            ? this.getValueObjetoSelecao(this.recursoSelecionado)
            : undefined,
          saldos: this.tiposSaldoSelecionado.length
            ? this.getValueObjetoSelecao(this.tiposSaldoSelecionado)
            : undefined,
          executados: this.tipoExecucaoSelecionada.length
            ? this.tipoExecucaoSelecionada.length == 2
              ? null
              : this.getValueObjetoSelecao(this.tipoExecucaoSelecionada)[0]
            : undefined,
          subprojetos:
            this.context == "subprojeto"
              ? [this.subProjeto.id]
              : (this.subProjetoSelecionados.length == 0
              ? null 
              : this.getValueObjetoSelecao(this.subProjetoSelecionados)),
          projetos: [this.$store.projeto.id],
        };
        const itens = await filtrarItens(params);
        this.rubricas = await formatItensPorRubrica(itens);
        this.panel = [1];
      } catch (error) {
        console.error(error);
      }
    },
    limparCamposFiltro() {
      this.camposTextuais = "";
      this.subProjetoSelecionados = [];
      this.statusSelecionado = [];
      this.recursoSelecionado = [];
      this.tiposSaldoSelecionado = [];
      this.tipoExecucaoSelecionada = [];
      this.rubricaFilterSelecionada = [];
      this.parcelaSelecionada = [];
      this.panel = [1];
    },
    limparFiltros() {
      this.limparCamposFiltro();
      this.getData();
    },
    selecionarTodasOpcoes(arrModel, arrComparacao) {
      if (arrModel.length == arrComparacao.length) {
        arrModel = [];
      } else {
        arrModel = arrComparacao;
      }
      return arrModel;
    },
    getSelecionados() {
      const selecteds = [
        ...this.subProjetoSelecionados,
        ...this.rubricaFilterSelecionada,
        ...this.statusSelecionado,
        ...this.parcelaSelecionada,
        ...this.recursoSelecionado,
        ...this.tiposSaldoSelecionado,
        ...this.tipoExecucaoSelecionada,
      ];
      return selecteds.map((v) => v.label.slice(0, 15));
    },
  },
};
</script>

<style lang="scss" scoped>
.session-title {
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: bold;
  color: $primary;
  font-size: 20px;
  margin-top: 30px;
  margin-bottom: 20px;
}
.filter-title {
  font-family: Roboto;
  font-style: normal;
  margin: 0%;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  display: flex;
  align-items: center;
  color: #037f8c;
}
.v-expansion-panel ::after {
  position: absolute;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.v-expansion-panel--active {
  position: absolute;
  width: 100%;
  -webkit-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !important;
}
.v-text-field--outlined fieldset {
  border: 3px solid #037f8c !important;
  border-radius: 8px !important;
}
.label {
  margin-top: 8px !important;
}
.chips-content {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.chip {
  display: flex;
  align-items: center;
  width: 100px;
  height: 30px;
  padding: 5px;
  margin: 5px;
  margin-left: 10px;
  border: 1.5px solid #ff8d3f;
  border-radius: 10px;
  text-overflow: ellipsis;
  overflow: hidden;

  span {
    width: 100%;
    font-family: Roboto;
    font-size: 14px;
    color: #ff8d3f;
    text-align: center;
  }
}
</style>
