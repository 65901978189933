import api from "./api";

export default {
  async login(login, senha, cb) {
    cb = arguments[arguments.length - 1];
    if (this.loggedIn()) {
      if (cb) cb(true);
      this.onChange(true);
      return;
    }
    await pretendRequest(login, senha, (res, error) => {
      if (res.authenticated) {
        localStorage.setItem("usuario", JSON.stringify(res.usuario));
        if (cb) cb(true);
        this.onChange(true);
      } else {
        if (cb) cb(false, error);
        this.onChange(false);
      }
    });
  },
  getUser() {
    const usuario = JSON.parse(localStorage.getItem("usuario"));
    return usuario;
  },
  setUsuario(usuario) {
    localStorage.setItem("usuario", JSON.stringify(usuario));
  },
  logout(cb) {
    localStorage.setItem("usuario", null);
    if (cb) cb();
    this.onChange(false);
  },
  loggedIn() {
    return !!this.getUser();
  },
  //Funcao declarada dentro do App.vue que muda o contexto caso o usuario faça login ou logout
  onChange() {},
};

async function pretendRequest(login, senha, cb) {
  try {
    const res = await api.post("/usuarios/login", {
      login,
      senha,
    });
    if (res && res.data) {
      const usuario = res.data;
      const nome = String(usuario.login);
      cb({
        authenticated: true,
        usuario: {
          id: usuario.id,
          login: usuario.login,
          email: usuario.email,
          senha: senha,
          nome: nome.charAt(0).toUpperCase() + nome.slice(1),
        },
      });
    }
  } catch (error) {
    console.error(error);
    cb({ authenticated: false }, error);
  }
}
