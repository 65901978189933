<template>
  <v-dialog v-model="modalVisible" persistent max-width="900">
    <v-card style="border-radius: 10px">
      <br />
      <v-card-title class="modal-title">Editar Informações Gerais</v-card-title>

    <v-card-text>
      <v-container>

        <v-row>
          <v-col class="label-col" cols="2">
            <p class="label">*Título:</p>
          </v-col>
          <v-col cols="10">
            <v-text-field
              v-model="titulo"
              outlined
              dense
              required
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row >
          <v-col class="label-col" md="2">
            <p class="label">* Código:</p>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="codigo"
              outlined
              dense
              required
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      
        <v-row >
          <v-col class="label-col" md="2">
            <p class="label">* Demanda:</p>
          </v-col>
          <v-col md="10">
            <v-text-field
              v-model="demanda"
              outlined
              dense
              required
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      
        <v-row >
          <v-col class="label-col" md="2">
            <p class="label">* Convênio:</p>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="convenio"
              outlined
              dense
              required
              hide-details
              v-mask="'############################'"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row >
          <v-col class="label-col" md="2">
            <p class="label">* Referência:</p>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="referencia"
              outlined
              dense
              required
              hide-details
              v-mask="'############################'"
            ></v-text-field>
          </v-col>
        </v-row>
      
        <v-row >
          <v-col class="label-col" md="2">
            <p class="label">*SIGFundação:</p>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="sigFundacao"
              outlined
              dense
              required
              hide-details
              v-mask="'############################'"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row >
          <v-col class="label-col" md="2">
            <p class="label">* Vigência:</p>
          </v-col>
          <v-col md="4">
            <DatePicker v-model="inicioVigencia" />
          </v-col>
          <v-col md="4">
            <DatePicker v-model="fimVigencia" label="fim" />
          </v-col>
        </v-row>
      
        <v-row>
          <v-col class="label-col" md="2">
            <p class="label">* Valor Total do Projeto:</p>
          </v-col>
          <v-col cols="3">
            <v-currency-field
              v-model="valorTotal"
              outlined
              dense
              required
              hide-details
              prefix="R$"
            ></v-currency-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
        

      <v-card-actions
        style="
          display: flex;
          justify-content: space-evenly;
          padding-bottom: 20px;
        "
      >
        <v-btn outlined width="190" color="#FF8D3F" @click="closeModal(false)"
          >Cancelar</v-btn
        >
        <v-btn
          depressed
          width="190"
          class="btn-controls"
          color="#FF8D3F"
          @click="atualizarInforamacoesGerais"
          >Salvar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { editProjetoById } from "../../services/providers/CadastroProjeto";
import { getProjetoById } from "../../services/providers/projetosProvider";
import DatePicker from "../DatePicker.vue";

export default {
    name: 'ModalEditarInformacoesGerais',
    data() {
      return {
        titulo: "",
        demanda: "",
        convenio: "",
        referencia: "",
        sigFundacao: "",
        codigo: "",
        inicioVigencia: new Date().toLocaleDateString("en-CA"),
        fimVigencia: new Date().toLocaleDateString("en-CA"),
        valorTotal: "",
      }
    },
    props: {
      modalVisible: Boolean
    },
    components: {
      DatePicker
    },
    async created() {
      if (!this.$store.projeto) {
      try {
        const projeto = await getProjetoById(this.$route.params.id);
        if (projeto) {
          this.$actions.setProjeto(projeto);
          this.projeto = projeto;
          this.pageTitle = projeto.codigo;
        } else {
          this.$router.push("/projetos-vigentes");
        }
        } catch (error) {
          console.error(error);
          this.$notify({
            title: "Erro interno!",
            text: error.message || error,
            type: "error",
            duration: 15000,
          });
        }
      }
      const projeto = this.$store.projeto;
      this.titulo = projeto.titulo;
      this.codigo = projeto.codigo;
      this.demanda = projeto.demanda;
      this.convenio = projeto.convenio;
      this.referencia = projeto.referencia;
      this.sigFundacao = projeto.sigFundacao;
      this.inicioVigencia = projeto.dataInicio.split(" ")[0];
      this.fimVigencia = projeto.dataFim.split(" ")[0];
      if (projeto.valorTotal) {
        this.valorTotal = projeto.valorTotal;
      } else {
        const fontes = projeto.fontes;
        const fonteFNDCT = fontes.filter(fonte => fonte.fonte.codigo === "F");
        this.valorTotal =  fonteFNDCT[0].valorAutorizado;
      }

    },
    methods: {
      async atualizarInforamacoesGerais() {
        try {
          const payload = {
            codigo: this.codigo,
            titulo: this.titulo,
            demanda: this.demanda,
            convenio: this.convenio,
            referencia: this.referencia,
            sigFundacao: this.sigFundacao,
            dataInicio: `${this.inicioVigencia} 00:00:00`,
            dataFim: `${this.fimVigencia} 00:00:00`,
            valorTotal: this.valorTotal,
          }
          const res = await editProjetoById(this.$store.projeto.id, payload);
          if (res) {
            this.$notify({
              title: "Dados do Projeto cadastrado com Sucesso!",
              text: "Dados do Projeto cadastrado com sucesso!",
              type: "success",
              duration: 5000,
            });
            this.$actions.setProjeto(res);
            this.closeModal(true);
          }
        } catch (error) {
          console.error(error);
          this.$notify({
            title: "Erro ao cadastrar dados do projeto",
            text: error.message,
            type: "error",
            duration: 15000,
          });
        }
      },
      closeModal(reload) {
        this.$emit("close");
        if (reload)
          this.$emit("reload");
        },
    }
}
</script>

<style scoped lang="scss">
.label {
  margin: 0px !important;
  color: $primary;
  font-weight: 500;
  font-size: 18px;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: center;
}

</style>