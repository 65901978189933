export const TIPO_PARTICIPANTE = {
  MEMBRO: "Membro",
  INTERESSADO: "Interessado",
};

export const TIPO_EQUIPE = {
  EXECUTORA: "Executora",
  PESQUISADORA: "Pesquisadora",
};

export const FUNCAO_PARTICIPANTE = {
  COORDENADOR_GERAL: "Coordenador Geral",
  COORDENADOR_SUBPROJETO: "Coordenador de Subprojeto",
  APOIO_ADMINISTRATIVO: "Apoio Administrativo",
  APOIO_TECNICO: "Apoio Técnico",
  PESQUISADOR: "Pesquisador",
};
