import api from "../api";

export const listarNotificacoes = async () => {
  const res = await api.get(`/notificacoes`);
  return res.data;
};

export const marcarNotificacaoComoLida = async (id) => {
  const res = await api.get(`/notificacoes/marcar-como-lido?ids=${id}`);
  return res.data;
};
