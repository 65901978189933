import moment from "moment";

function generateTables(subprojeto, rubricasItens) {
  let content = [];
  rubricasItens.forEach(rubrica => {
    let title = {
      text: rubrica.descricaoCompleta || "-",
      fontSize: 12,
      bold: true,
      margin: [0, 20]
    };

    let tableHeader = [
      makeText("STATUS", true),
      makeText("ALTERAÇÃO", true),
      makeText("JUSTIFICATIVA", true),
      makeText("ALTERADO POR", true),
      makeText("DATA", true)
    ];
    let table = {
      headerRows: 1,
      body: [tableHeader]
    };

    rubrica.itens.forEach(({ item, historico }) => {
      table.body.push([{ colSpan: 5, ...makeText((item.subprojeto.codigo || "-") + ": " + (item.descricao || "-"), true, true) }])
      historico.forEach(historico => {
        let alteracoes = Object.keys(historico.depois).map((k) => renderAlteracoesHistorico(k, historico.depois).join("\n")).join("\n");
        let bodyItem = [
          makeText(String(historico.depois.situacaoItem || "-").toUpperCase()),
          makeText(alteracoes || "-"),
          makeText(historico.justificativa || "-"),
          makeText(String(historico.usuario.login)
            .charAt(0)
            .toUpperCase() +
            String(historico.usuario.login).slice(1) || "-"),
          makeText(moment(historico.data).format("DD/MM/YYYY HH:mm") || "-"),
        ];
        table.body.push(bodyItem);
      });
      if (historico.length) {
        let alteracoes = Object.keys(historico[historico.length - 1].antes).map((k) => renderAlteracoesHistorico(k, historico[historico.length - 1].antes).join("\n")).join("\n");
        let bodyItem = [
          makeText(String(historico[historico.length - 1].antes.situacaoItem || "-").toUpperCase()),
          makeText(alteracoes || "-"),
          makeText(""),
          makeText(""),
          makeText(""),
        ];
        table.body.push(bodyItem);
      }
    });

    content.push({ ...title });
    content.push({
      table
    });
  });
  return content;
}

function formatAlteracoesHistorico(campo, objeto) {
  switch (campo) {
    case "valorUnitario":
      return `Valor Unitário: R$ ${objeto[campo].toLocaleString("pt-BR")}`;
    case "valorAlocado":
      return `Valor alocado: R$ ${objeto[campo].toLocaleString("pt-BR")}`;
    case "valorExecutado":
      return `Valor executado: R$ ${objeto[campo].toLocaleString("pt-BR")}`;
    case "valorTotal":
      return `Valor total: R$ ${objeto[campo].toLocaleString("pt-BR")}`;
    case "recursos":
      return formatRecursoField(campo, objeto);
    case "situacaoItem":
      return "";
    case "descricao":
      return `Descrição:  ${objeto[campo]}`;
    default:
      return (
        String(campo).charAt(0).toUpperCase() +
        String(campo).slice(1) +
        ": " +
        objeto[campo]
      );
  }
}

function formatRecursoField(campo, objeto) {
  let str = "";
  if (objeto[campo].rendimento) {
    str += `Recurso de Rendimento: R$ ${objeto[
      campo
    ].rendimento.toLocaleString("pt-BR")}  ###`;
  }
  if (objeto[campo].fndct) {
    str += `Recurso FNDCT: R$ ${objeto[campo].fndct.toLocaleString(
      "pt-BR"
    )}`;
  }
  return str;
}

function renderAlteracoesHistorico(campo, objeto) {
  let str = formatAlteracoesHistorico(campo, objeto);
  return str.split("###");
}

function makeText(value, bold = false, isDesc = false, header = false, right = false) {
  return {
    text: value,
    alignment: right ? 'right' : isDesc ? 'left' : 'center',
    fontSize: header ? 12 : 9,
    bold: bold
  }
}

function generateHeader(projeto, subprojeto, dataRefIni, dataRefFim) {
  return [
    {
      layout: 'noBorders',
      table: {
        headerRows: 1,
        body: subprojeto ? [
          [makeText("Demanda: ", true, true, true), makeText(projeto.demanda || "-", false, true, true)],
          [makeText("Projeto: ", true, true, true), makeText((projeto.codigo + " - " + projeto.descricao) || "-", false, true, true)],
          [makeText("Subprojeto: ", true, true, true), makeText(subprojeto.codigo || "-", false, true, true)],
        ] :
          [
            [makeText("Demanda: ", true, true, true), makeText(projeto.demanda || "-", false, true, true)],
            [makeText("Projeto: ", true, true, true), makeText((projeto.codigo + " - " + projeto.descricao) || "-", false, true, true)],
          ]
      }
    },
    {
      layout: 'noBorders',
      table: {
        headerRows: 1,
        body: [
          [
            makeText("Convênio: ", true, true, true),
            makeText(projeto.convenio || "-", false, true, true),
            makeText("Ref.: ", true, true, true),
            makeText(projeto.referencia || "-", false, true, true),
          ],
        ]
      }
    },
    {
      layout: 'noBorders',
      table: {
        headerRows: 1,
        body: [
          dataRefIni ? [
            makeText("Relatório referente a: ", true, true, true),
            makeText(moment(dataRefIni).format("DD/MM/YYYY") + " até " + moment(dataRefFim).format("DD/MM/YYYY"), false, true, true),
            makeText("Relatório emitido em: ", true, true, true),
            makeText(moment().format("DD/MM/YYYY"), false, true, true),
          ] : [
              makeText("Relatório emitido em: ", true, true, true),
              makeText(moment().format("DD/MM/YYYY"), false, true, true),
            ],
        ]
      }
    },
  ]
}

export const makePDFHistorico = (projeto, subprojeto, rubricasItens, dataRefIni, dataRefFim) => {
  const pdfMake = require("pdfmake/build/pdfmake.js");
  if (pdfMake.vfs == undefined) {
    const pdfFonts = require("pdfmake/build/vfs_fonts.js");
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
  }
  const docDefinition = {
    pageSize: "A4",
    pageMargins: [20, 60, 20, 60],
    pageOrientation: 'landscape',
    content: [
      {
        image: require('./logoFullBase64.json').image,
        width: 108,
        height: 82,
        alignment: 'center',
        margin: [0, 0, 0, 10]
      },
      ...generateHeader(projeto, subprojeto, dataRefIni, dataRefFim),
      { text: "RELATÓRIO DE HISTÓRICO DE ITENS", fontSize: 14, alignment: 'center', margin: [0, 20] },
      ...generateTables(subprojeto, rubricasItens)
    ],
    defaultStyle: {
      fontSize: 12
    }
  };
  pdfMake.createPdf(docDefinition).print();
}