import api from "../api";

export const cadastrarOcorrencia = async (payload) => {
  const res = await api.post(`/ocorrencias-projeto/`, payload);
  return res.data;
};

export const getOcorrencias = async (projetoId) => {
  const res = await api.get(
    `/ocorrencias-projeto/search?projetos=${projetoId}`
  );
  return res.data;
};

export const atualizarOcorrencia = async (idOcorrencia, payload) => {
  const res = await api.patch(`/ocorrencias-projeto/${idOcorrencia}`, payload);
  return res.data;
};

export const excluirOcorrencia = async (idOcorrencia) => {
  const res = await api.delete(`/ocorrencias-projeto/${idOcorrencia}`);
  return res.data;
};
