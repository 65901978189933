<template>
  <v-dialog v-model="modalVisible" persistent scrollable max-width="1000">
    <v-form ref="formCadastroItem">
      <v-card style="border-radius: 10px">
        <br />
        <div style="width: 100%; display: flex; justify-content: flex-end">
          <v-btn text large @click="closeModal()">
            <v-icon large color="#037F8C">mdi-close-circle-outline</v-icon>
          </v-btn>
        </div>
        <v-card-title class="modal-title">CADASTRAR ITEM</v-card-title>
        <v-card-text style="padding-left: 30px">
          <v-row>
            <v-col>
              <p class="session-title">INFORMAÇÕES GERAIS</p>
              <v-row>
                <v-col class="label-col" md="2">
                  <p class="label">Sub-projeto:</p>
                </v-col>
                <v-col md="10">
                  <v-select
                    outlined
                    dense
                    clearable
                    class="select-form"
                    color="#037F8C"
                    item-color="#037F8C"
                    :items="subProjetosFormatted"
                    v-model="form.subProjeto"
                    item-text="label"
                    item-value="value"
                    label="Selecione um sub-projeto"
                    :rules="[(v) => !!v || 'Selecione um sub-projeto']"
                    required
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="label-col" md="2">
                  <p class="label">Rubrica:</p>
                </v-col>
                <v-col md="10">
                  <v-select
                    outlined
                    dense
                    clearable
                    class="select-form"
                    color="#037F8C"
                    item-color="#037F8C"
                    :items="rubricasFormatted"
                    v-model="form.rubrica"
                    item-text="label"
                    item-value="value"
                    label="Selecione uma rubrica"
                    :rules="[(v) => !!v || 'Selecione uma rubrica']"
                    required
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="label-col" md="2">
                  <p class="label">Parcela:</p>
                </v-col>
                <v-col md="10">
                  <v-select
                    outlined
                    dense
                    clearable
                    :disabled="!form.subProjeto"
                    class="select-form"
                    color="#037F8C"
                    item-color="#037F8C"
                    :items="parcelasFormatted"
                    v-model="parcelaSelecionada"
                    item-text="label"
                    item-value="value"
                    label="Selecione a parcela"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="label-col" md="2">
                  <p class="label">Descrição:</p>
                </v-col>
                <v-col md="10">
                  <v-text-field
                    v-model="form.descricao"
                    outlined
                    dense
                    :rules="[(v) => !!v || 'Descrição é obrigatória']"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="label-col" md="2">
                  <p class="label">Finalidade:</p>
                </v-col>
                <v-col md="10">
                  <v-text-field
                    v-model="form.finalidade"
                    outlined
                    dense
                    :rules="[(v) => !!v || 'Finalidade é obrigatória']"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <p class="session-title">DESPESAS</p>
              <v-row>
                <v-col>
                  <v-col class="box-ammount">
                    <p class="title-ammount">Saldo FNDCT não alocado:</p>
                    <p class="text-ammount">
                      {{ saldoRemanejavel | currency }}
                    </p>
                  </v-col>
                </v-col>
                <v-col>
                  <v-col class="box-ammount">
                    <p class="title-ammount">Saldo RENDIMENTO não alocado</p>
                    <p class="text-ammount">
                      {{ saldoRemanejavelRendimento | currency }}
                    </p>
                  </v-col>
                </v-col>
              </v-row>
              <br />
              <p
                class="saldo-title"
                style="text-align: center; font-size: 17px"
              >
                Valor total do item:
                <span style="font-weight: normal">
                  {{ (form.valorUnitario * form.quantidade) | currency }}
                </span>
              </p>
              <br />
              <v-row>
                <v-col class="label-col" md="2">
                  <p class="label">Quantidade:</p>
                </v-col>
                <v-col md="4">
                  <v-text-field
                    v-model="form.quantidade"
                    type="number"
                    min="1"
                    outlined
                    dense
                    :rules="[
                      (v) => !!v || 'Quantidade é obrigatória',
                      (v) =>
                        !(parseFloat(v) <= 0) ||
                        'Quantidade deve ser maior que zero',
                    ]"
                    @blur="validarCamposRecurso"
                    required
                  ></v-text-field>
                </v-col>
                <v-col class="label-col" md="2">
                  <p class="label">Valor unitário:</p>
                </v-col>
                <v-col md="4">
                  <v-currency-field
                    prefix="R$"
                    v-model="form.valorUnitario"
                    :disabled="!form.subProjeto"
                    outlined
                    dense
                    :rules="[
                      (v) => !!v || 'Valor Unitario é obrigatório',
                      (v) =>
                        !(parseFloat(String(v).replace(',', '.')) <= 0) ||
                        'Valor Unitario deve ser maior que zero',
                    ]"
                    @blur="validarCamposRecurso"
                    required
                  ></v-currency-field>
                  <div
                    style="width: 100%; display: flex; justify-content: flex-end;"
                  >
                    <v-btn
                      text
                      dense
                      class="btn-link"
                      :disabled="!form.valorUnitario"
                      @click="simularRecursos"
                    >
                      simular recursos
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="label-col" md="2">
                  <p class="label">Total FNDCT do ITEM:</p>
                </v-col>
                <v-col md="3">
                  <v-currency-field
                    prefix="R$"
                    v-model="totalFndctItem"
                    outlined
                    dense
                    :key="JSON.stringify(parcelaSelecionada)"
                    :rules="[
                      (v) =>
                        validarValoresRecursos(v, totalRendimentoItem) ||
                        'Os valores dos recursos devem ser iguais ao valor total do item',
                    ]"
                    :required="fndctObrigatorio"
                    :disabled="!fndctObrigatorio"
                    
                  ></v-currency-field>
                </v-col>
                <v-col class="label-col" md="3">
                  <p class="label">Total RENDIMENTO do ITEM:</p>
                </v-col>
                <v-col md="4">
                  <v-currency-field
                    prefix="R$"
                    v-model="totalRendimentoItem"
                    outlined
                    dense
                    :key="JSON.stringify(parcelaSelecionada)"
                    :rules="[
                      (v) =>
                        validarValoresRecursos(v, totalFndctItem) ||
                        'Os valores dos recursos devem ser iguais ao valor total do item',
                    ]"
                    :required="rendimentoObrigatorio"
                    :disabled="!rendimentoObrigatorio"
                  ></v-currency-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="modal-footer">
          <v-btn outlined width="190" color="#FF8D3F" @click="closeModal()"
            >Cancelar</v-btn
          >
          <v-btn
            depressed
            width="190"
            class="btn-controls"
            color="#FF8D3F"
            @click="validarForm()"
            >Salvar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
    <ModalJustificativa
      :modalVisible="modalJustificativaOpen"
      @close="closeModalJustificativa"
      @confirm="cadastrarItem"
    />
  </v-dialog>
</template>

<script>
import { EventBus } from "../../services/event-bus";
import {
  cadastrarItem,
  simularRecursosItem,
} from "../../services/providers/itensProvider";
import { getProjetoById } from "../../services/providers/projetosProvider";
import { getRubricas } from "../../services/providers/rubricaProvider";
import { getSubprojetosByProjetoApi } from "../../services/providers/subProjetosProvider";
import { convertCurrencyToNumber, decimalAdjust } from "../../utils/numbers";
import ModalJustificativa from "./ModalJustificativa.vue";

const formInitialState = {
  subProjeto: null,
  rubrica: null,
  descricao: null,
  finalidade: null,
  quantidade: 1,
  valorUnitario: null,
};

export default {
  components: { ModalJustificativa },
  name: "ModalAdicionarItem",
  props: {
    modalVisible: Boolean,
    rubricas: Array,
  },
  data: () => ({
    subProjetosFormatted: [],
    rubricasFormatted: [],
    parcelasFormatted: [],
    form: { ...formInitialState },
    parcelaSelecionada: null,
    saldoRemanejavel: 0,
    saldoRemanejavelRendimento: 0,
    totalRendimentoItem: 0,
    totalFndctItem: 0,
    idRecursoFndctItem: null,
    idRecursoRendimentoItem: null,
    modalJustificativaOpen: false,
  }),
  async mounted() {
    const subProjetos = await getSubprojetosByProjetoApi(
      this.$store.projeto.id
    );
    this.subProjetosFormatted = subProjetos.map((sp) => ({
      label: sp.codigo,
      value: sp,
    }));

    this.saldoRemanejavel = this.$store.projeto.metricas.saldoNaoAlocado || 0;
    this.saldoRemanejavelRendimento = this.$store.projeto.metricasRendimento
      ? this.$store.projeto.metricasRendimento.saldoNaoAlocado
      : 0;

    try {
      const rubricas = await getRubricas();
      this.rubricasFormatted = rubricas.map((r) => ({
        label: r.descricaoCompleta,
        value: r.id,
      }));
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    buscarParcelas() {
      if (this.$store.projeto) {
        const parcelas = this.$store.projeto.parcelas;
        if (parcelas) {
          this.parcelasFormatted = parcelas.map((p) => ({
            label: p.numero + "º Parcela",
            value: {
              ...p,
              rendimento: false,
              fndct: true,
            },
          }));
          this.parcelasFormatted.push(...parcelas.map(p => ({
            label: p.numero + "º Parcela e rendimento",
            value: {
              ...p,
              rendimento: true,
              fndct: true,
            },
          })));
          this.parcelasFormatted.push({
            label: "Sem parcela",
            value: {
              id: 0,
              rendimento: true,
              fndct: false,
            },
          });
        }
      }
    },
    validarForm() {
      if (this.$refs.formCadastroItem.validate()) {
        this.modalJustificativaOpen = true;
      } else {
        this.$notify({
          title: "Erro!",
          text: "Preencha todas as informações obrigatórias",
          type: "error",
          duration: 15000,
        });
      }
    },
    async cadastrarItem(justificativa) {
      let fontFndct = {
        id: 2,
        codigo: "F",
        descricao: "FNDCT",
        prioridade: 1,
        fndct: true,
      };
      let fontRendimento = {
        id: 3,
        codigo: "R",
        descricao: "RENDIMENTO",
        prioridade: 2,
        fndct: false,
      };
      let recursoFndct = undefined;
      let recursoRendimento = undefined;
      let recursos = [];
      let totalFndctItem = convertCurrencyToNumber(this.totalFndctItem);
      if (totalFndctItem) {
        recursoFndct = {
          id: this.idRecursoFndctItem || null,
          valorAlocado: totalFndctItem,
          fonte: fontFndct,
        };
        recursos.push(recursoFndct);
      }
      let totalRendimentoItem = convertCurrencyToNumber(
        this.totalRendimentoItem
      );
      if (totalRendimentoItem) {
        recursoRendimento = {
          id: this.idRecursoRendimentoItem || null,
          valorAlocado: totalRendimentoItem,
          fonte: fontRendimento,
        };
        recursos.push(recursoRendimento);
      }
      const payload = {
        item: {
          subprojetoId: this.form.subProjeto.id,
          rubricaId: this.form.rubrica
            ? this.form.rubrica.value || this.form.rubrica
            : null,
          parcelaId: this.parcelaSelecionada
            ? this.parcelaSelecionada.id != 0
              ? this.parcelaSelecionada.id
              : undefined
            : undefined,
          descricao: this.form.descricao,
          finalidade: this.form.finalidade,
          quantidade: this.form.quantidade,
          valorUnitario: this.form.valorUnitario,
          justificativa,
        },
        recursos: recursos.length ? recursos : undefined,
      };
      try {
        const res = await cadastrarItem(payload);
        if (res) {
          this.modalJustificativaOpen = false;
          this.$notify({
            title: "Item cadastrado!",
            text: "O item foi cadastrado com sucesso!",
            type: "success",
            duration: 5000,
          });
          const projeto = await getProjetoById(res.item.subprojeto.projetoId);
          if (projeto) {
            this.$actions.setProjeto(projeto);
          }
          this.closeModal(true);
        }
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro ao cadastrar o item!",
          text: error.message,
          type: "error",
          duration: 15000,
        });
      }
    },
    closeModal(reload) {
      this.$refs.formCadastroItem.reset();
      this.form.valorUnitario = null;
      if (reload) {
        EventBus.$emit("item-updated");
      }
      this.$emit("close");
    },
    async simularRecursos() {
      try {
        let payload = {
          subprojetoId: this.form.subProjeto
            ? this.form.subProjeto.id || this.form.subProjeto
            : null,
          quantidade: this.form.quantidade,
          valorUnitario: convertCurrencyToNumber(this.form.valorUnitario),
          parcelaId: this.parcelaSelecionada
            ? this.parcelaSelecionada.id != 0
              ? this.parcelaSelecionada.id
              : undefined
            : undefined,
        };
        const res = await simularRecursosItem(payload);
        if (res) {
          let recursoFndct = res.find((r) => r.fonte.codigo == "F");
          if (recursoFndct) {
            this.idRecursoFndctItem = recursoFndct.id;
          }
          let recursoRendimento = res.find((r) => r.fonte.codigo == "R");
          if (recursoRendimento) {
            this.idRecursoRendimentoItem = recursoRendimento.id;
          }
          this.totalRendimentoItem = res
            .filter((r) => (r.fonte ? r.fonte.codigo == "R" : false))
            .reduce((total, recurso) => (total += recurso.valorAlocado), 0);

          this.totalFndctItem = res
            .filter((r) => (r.fonte ? r.fonte.codigo == "F" : false))
            .reduce((total, recurso) => (total += recurso.valorAlocado), 0);

          this.$notify({
            title: "Simulação concluída!",
            text: "A simulação de recursos do item foi concluída!",
            type: "success",
            duration: 5000,
          });

          this.validarCamposRecurso();
        }
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro ao simular recursos do item!",
          text: error.message,
          type: "error",
          duration: 15000,
        });
      }
    },
    validarValoresRecursos(valorCampo, valorRecurso) {
      valorCampo =
        typeof valorCampo == "string"
          ? convertCurrencyToNumber(valorCampo)
          : valorCampo;
      if (valorCampo) {
        valorRecurso =
          typeof valorRecurso == "string"
            ? convertCurrencyToNumber(valorRecurso)
            : valorRecurso;
        return (
          decimalAdjust(valorCampo + valorRecurso, 2) ==
          decimalAdjust(
            convertCurrencyToNumber(this.form.valorUnitario) *
              this.form.quantidade,
            2
          )
        );
      }
      return true;
    },
    validarCamposRecurso() {
      if (this.totalRendimentoItem || this.totalFndctItem) {
        this.$refs.formCadastroItem.validate();
      }
    },
    closeModalJustificativa() {
      this.modalJustificativaOpen = false;
    },
  },
  watch: {
    "form.subProjeto": async function() {
      this.buscarParcelas();
    },
    parcelaSelecionada: function() {
      this.saldoRemanejavel = this.$store.projeto.metricas.saldoNaoAlocado;
    },
  },
  computed: {
    rendimentoObrigatorio() {
      return (this.parcelaSelecionada ? true : false) && this.parcelaSelecionada.rendimento;
    },
    fndctObrigatorio() {
      return (this.parcelaSelecionada ? true : false) && this.parcelaSelecionada.fndct;
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-title {
  display: flex;
  font-size: 20px !important;
  text-transform: uppercase;
  font-weight: bold;
  //background-color: #e6f4f1;
  font-family: Raleway;
  color: #037F8C;
  margin: 20px;
  margin-bottom: 30px;
  border-radius: 10px;
  height: 65px;
}
.session-title {
  color: $primary;
  font-family: Roboto;
  font-size: 20px;
}
.saldo-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  line-height: 26px;
  color: #616161;
  text-transform: uppercase;
}
.box-ammount {
  border: 2px solid $primary;
  margin: 5px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 10px;
}
.title-ammount {
  font-family: $title;
  text-transform: uppercase;
  text-align: center;
  font-size: 16px;
  color: $title-color;
  margin-bottom: 10px;
}
.text-ammount {
  font-family: $subtitle;
  text-transform: uppercase;
  text-align: center;
  color: $title-color;
  font-size: 22px;
}
.btn-link {
  display: flex;
  margin-top: -30px;
  width: 50%;
  text-decoration: underline;
  text-transform: lowercase;
  font-family: Roboto;
  color: $primary;
}
</style>
