import api from "../api";

export const getItem = async (idItem) => {
  const res = await api.get(`/itens/${idItem}`);
  return res.data;
};

export const buscarItensPorSubProjeto = async (idSubrojeto) => {
  const res = await api.get(`/itens/search?subprojetos=${idSubrojeto}`);
  return res.data;
};

export const buscarItensPorSubProjetoParcela = async (
  idSubrojeto,
  parcelas = []
) => {
  const res = await api.get(
    `/itens/search?subprojetos=${idSubrojeto}&parcelas=${parcelas.join(
      ","
    )}&fontes=SOMENTE_FNDCT`
  );
  return res.data;
};

export const cadastrarItem = async (payload) => {
  const res = await api.post(`/cadastro-item/`, payload);
  return res.data;
};

export const associarItens = async (idItemRendimento, idItemFndct) => {
  const res = await api.post(
    `/itens/associar/${idItemRendimento}/a/${idItemFndct}`,
    {}
  );
  return res.data;
};

export const deletarItem = async (idItem, payload) => {
  const res = await api.post(`/itens/${idItem}/excluir`, payload);
  return res.data;
};

export const restaurarItem = async (idItem, payload) => {
  const res = await api.post(`/itens/${idItem}/restaurar`, payload);
  return res.data;
};

export const editarItem = async (payload) => {
  const res = await api.put(`/cadastro-item/`, payload);
  return res.data;
};

export const editarInfoBasicasItem = async (idItem, payload) => {
  const res = await api.patch(`/itens/${idItem}`, payload);
  return res.data;
};

export const listarExecucoesItem = async (idItem) => {
  const res = await api.get(`/execucoes/search?itemId=${idItem}`);
  return res.data;
};

export const listarItensPendentesHomologacaoProjeto = async (idProjeto) => {
  const res = await api.get(
    `/itens/search?projetos=${idProjeto}&situacoes=7,8,9`
  );
  return res.data;
};

export const homologarItens = async (payload) => {
  const res = await api.post(`/itens/homologar`, payload);
  return res.data;
};

export const listarComentariosItem = async (itemId) => {
  const res = await api.get(`comentarios-itens/search?itemId=${itemId}`);
  return res.data;
};

export const addComentarioItem = async (payload) => {
  const res = await api.post(`comentarios-itens`, payload);
  return res.data;
};

export const editarComentarioItem = async (payload) => {
  const res = await api.put(`comentarios-itens`, payload);
  return res.data;
};

export const deletarComentarioItem = async (idComentario) => {
  const res = await api.delete(`comentarios-itens/${idComentario}`);
  return res.data;
};

export const listarHistoricoItem = async (idItem) => {
  const res = await api.get(`/itens/${idItem}/historico`);
  return res.data;
};

export const listarSituacoesItem = async () => {
  const res = await api.get(`/situacoes-item`);
  return res.data;
};

export const simularRecursosItem = async (payload) => {
  const res = await api.post(`/cadastro-item/simular-recursos`, payload);
  return res.data;
};

export const filtrarItens = async (params) => {
  let query = Object.keys(params)
    .filter((k) => params[k])
    .map((k) => {
      if (Array.isArray(params[k])) {
        return k + "=" + params[k].join();
      } else {
        return k + "=" + params[k];
      }
    })
    .join("&");
  const res = await api.get(`/itens/search?${query}`);
  return res.data;
};

export const relatorioItens = async (params) => {
  let query = Object.keys(params)
    .filter((k) => params[k])
    .map((k) => {
      if (Array.isArray(params[k])) {
        return k + "=" + params[k].join();
      } else {
        return k + "=" + params[k];
      }
    })
    .join("&");
  const res = await api.get(`/itens/relatorios/data?${query}`);
  return res.data;
};

export const relatorioItensHistorico = async (params) => {
  let query = Object.keys(params)
    .filter((k) => params[k])
    .map((k) => {
      if (Array.isArray(params[k])) {
        return k + "=" + params[k].join();
      } else {
        return k + "=" + params[k];
      }
    })
    .join("&");
  const res = await api.get(`/itens/relatorios/historico?${query}`);
  return res.data;
};

export const trocarParcelaItens = async (payload) => {
  const res = await api.patch(`/itens/trocar-parcela`, payload);
  return res.data;
};

export const adicionarResponsavelItem = async (payload) => {
  const res = await api.patch(`/itens/adicionar-responsavel`, payload);
  return res.data;
};

export const removerResponsavelItem = async (payload) => {
  const res = await api.patch(`/itens/remover-responsavel`, payload);
  return res.data;
};

export const adicionarAtividadeItem = async (payload) => {
  const res = await api.patch(`/itens/adicionar-atividade`, payload);
  return res.data;
};

export const removerAtividadeItem = async (payload) => {
  const res = await api.patch(`/itens/remover-atividade`, payload);
  return res.data;
};