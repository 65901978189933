<template>
  <div class="container-div">
    <div class="header">
      <v-btn
        class="header-button"
        large
        color="white"
        text
        @click="$router.go(-1)"
      >
        <v-icon large color="#037F8C">mdi-arrow-left-circle-outline</v-icon>
        <PageTitle :title="'<b>INFORMAÇÕES GERAIS</b>'" size="18" />
      </v-btn>
    </div>
    <div>
      <br />

      <v-container>
        <v-row>
          <v-col cols="8" align-self="center">
            <p class="page-title">Informações Gerais</p>
          </v-col>
          <v-col cols="4" align-self="center" class="d-flex justify-center">
            <v-btn align-self="center" 
              class="px-10 py-4 btn-controls" 
              color="#037F8C" 
              @click.prevent="modalEditarInformacoesOpen = !modalEditarInformacoesOpen"
              :disabled="!projetoEmReformulacao">
              Editar Informações
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

      <InfoBasicasProjeto v-if="renderComponent"/>
    </div>
      <ModalEditarInformacoesGerais
      :modalVisible="modalEditarInformacoesOpen"
      @close="modalEditarInformacoesOpen = !modalEditarInformacoesOpen"
      @reload="forceRerender"
    />
  </div>

</template>

<script>
import InfoBasicasProjeto from "../../components/informacoesGerais/InfoBasicasProjeto.vue";
import ModalEditarInformacoesGerais from "../../components/modals/ModalEditarInformacoesGerais.vue";
import PageTitle from "../../components/PageTitle";

export default {
  name: "InformacoesGerais",
  components: {
    PageTitle,
    InfoBasicasProjeto,
    ModalEditarInformacoesGerais,
  },
  data: () => ({
    currentTab: "info",
    renderComponent: true,
    modalEditarInformacoesOpen: false,
  }),
  methods: {
    forceRerender() {
        this.renderComponent = false;
        this.$nextTick(() => {
          this.renderComponent = true;
        });
      }
  },
  computed: {
    projetoEmReformulacao() {
      return this.$store.projeto.reformulacao ? true : false;
    }
  },
};
</script>

<style lang="scss" scoped>

.page-title {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 29px;
  display: flex;
  align-items: center;

  color: $primary;
}

</style>
