<template>
  <div>
    <div class="container-div">
      <br />
      <div>
        <v-row style="justify-content: space-between">
          <v-btn
            class="header-button"
            large
            color="white"
            text
            to="/projetos-vigentes"
          >
            <v-icon large color="#037F8C">mdi-arrow-left-circle-outline</v-icon>
            <PageTitle :title="'<b>CRONOGRAMAS</b>'" size="18" />
          </v-btn>
        </v-row>
        <v-row>
          <v-col>
            <CronogramaSession :key="String(reload) + 2" :projeto="projeto" />
          </v-col>
        </v-row>
      </div>
      <br />
    </div>
  </div>
</template>

<script>
import PageTitle from "../../components/PageTitle";
import CronogramaSession from "../../components/CronogramaSession";

export default {
  name: "Cronogramas",
  components: {
    PageTitle,
    CronogramaSession,
  },
  data: () => ({
    projeto: null,
  }),
  created() {
    this.projeto = this.$store.projeto;
  },
};
</script>

<style lang="scss" scoped>
.btn-detalhes {
  position: absolute;
  margin-top: 1%;
  border-radius: 8px;
}
.breadcrumb {
  text-decoration: none;
  font-weight: bold;
  color: #037f8c;
  font-size: 20px;
  position: relative;
  top: 10px;
}
</style>
