<template>
  <v-dialog v-model="modalVisible" persistent max-width="1100">
    <v-card style="border-radius: 10px">
      <br />
      <div style="width: 100%; display: flex; justify-content: flex-end">
        <v-btn text large @click="closeModal()">
          <v-icon large color="#037F8C">mdi-close-circle-outline</v-icon>
        </v-btn>
      </div>
      <v-card-title style="justify-content: space-between">
        <p class="modal-title">ATIVIDADE DO ITEM</p>
        <v-btn outlined color="#037F8C" width="240" @click="isAddMode = true">
          ADICIONAR ATIVIDADE
        </v-btn>
      </v-card-title>
      <v-card-text>
        <div v-if="isAddMode" style="padding-left: 10%; padding-right: 10%">
          <br />
          <p class="modal-title">ADICIONAR ATIVIDADE AO ITEM</p>
          <v-form ref="formAtividadeItem">
            <v-row>
              <v-col class="label-col" md="2">
                <p class="label">Metas:</p>
              </v-col>
              <v-col md="10">
                <v-select
                  outlined
                  clearable
                  dense
                  class="select-form"
                  color="#037F8C"
                  item-color="#037F8C"
                  :items="metasFormatted"
                  v-model="formFields.meta"
                  item-text="label"
                  item-value="value"
                  label="Selecione as metas"
                  :rules="[(v) => !!v || 'Selecione as metas']"
                  required
                  @change="getAtividadesByMeta"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="label-col" md="2">
                <p class="label">Atividades:</p>
              </v-col>
              <v-col md="10">
                <v-select
                  outlined
                  clearable
                  dense
                  class="select-form"
                  color="#037F8C"
                  item-color="#037F8C"
                  :items="atividadesFormatted"
                  v-model="formFields.atividade"
                  :disabled="!formFields.meta"
                  item-text="label"
                  item-value="value"
                  label="Selecione as atividades"
                  :rules="[(v) => !!v || 'Selecione as atividades']"
                  required
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
          <div style="display: flex; justify-content: space-evenly">
            <v-btn
              small
              outlined
              width="190"
              color="#037F8C"
              @click="isAddMode = false"
            >
              Cancelar
            </v-btn>
            <v-btn
              small
              depressed
              width="190"
              class="btn-controls"
              color="#037F8C"
              @click="relacionarAtividadeItem"
            >
              ADICIONAR
            </v-btn>
          </div>
          <br />
        </div>
        <div>
          <AtividadesTable v-if="atividades.length" :atividades="atividades" />
          <span v-else>
            <p class="t-label-value">
              <v-icon color="#FF8D3F" style="margin-right: 10px">
                mdi-alert-circle-outline
              </v-icon>
              Este item ainda não possui atividade associada.
            </p>
          </span>
        </div>
        <br />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import AtividadesTable from "../tables/AtividadesTable.vue";

import { EventBus } from "../../services/event-bus";
import { searchAtividades } from "../../services/providers/atividadesProvider";
import { searchMetas } from "../../services/providers/metasProvider";
import { adicionarAtividadeItem } from "../../services/providers/itensProvider";

const defaultForm = {
  meta: null,
  atividade: null,
};

export default {
  name: "ModalAtividadeItem",
  components: {
    AtividadesTable,
  },
  props: {
    modalVisible: Boolean,
    item: Object,
  },
  data: () => ({
    isAddMode: false,
    isLoading: false,
    formFields: { ...defaultForm },
    atividades: [],
    metasFormatted: [],
    atividadesFormatted: [],
  }),
  async mounted() {
    try {
      if (this.item) {
        await this.getAtividades();
        const metas = await searchMetas({
          subprojetos: [this.item.subprojeto.id],
        });
        this.metasFormatted = metas.map((r) => ({
          label: r.descricao,
          value: r.id,
        }));
      }
    } catch (error) {
      console.error(error);
      this.$notify({
        title: "Erro interno!",
        text: error.message || error,
        type: "error",
        duration: 15000,
      });
    }
    EventBus.$on("atividade-updated", async () => {
      await this.getAtividades();
    });
  },
  methods: {
    closeModal() {
      this.clearForm();
      this.clearItemSelecionado();
      this.$emit("close");
    },
    clearForm() {
      this.isAddMode = false;
      this.formFields = { ...defaultForm };
    },
    async getAtividadesByMeta() {
      try {
        this.formFields.atividade = [];
        if (this.formFields.meta) {
          const atividades = await searchAtividades({
            metas: [this.formFields.meta],
          });
          this.atividadesFormatted = atividades.map((r) => ({
            label: r.descricao,
            value: r.id,
          }));
        }
      } catch (error) {
        console.error(error);
      }
    },
    async getAtividades() {
      try {
        if (this.item) {
          this.atividades = await searchAtividades({
            itens: [this.item.id],
          });
        }
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro interno!",
          text: error.message || error,
          type: "error",
          duration: 15000,
        });
      }
    },
    async relacionarAtividadeItem() {
      if (this.$refs.formAtividadeItem.validate()) {
        try {
          await adicionarAtividadeItem({
            atividadeId: this.formFields.atividade,
            itemId: this.item.id,
          });
          this.$notify({
            title: "Atividade associada!",
            text: "Atividade associada com sucesso! ",
            type: "success",
            duration: 5000,
          });
          await this.getAtividades();
          this.clearForm();
        } catch (error) {
          console.error(error);
          this.$notify({
            title: "Erro interno!",
            text: error.message || error,
            type: "error",
            duration: 15000,
          });
        }
      }
    },
    clearItemSelecionado() {
      this.$actions.setItemSelecionado(null);
    }
  },
};
</script>

<style lang="scss" scoped>
.t-label-value {
  font-family: Roboto;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  color: #ff8d3f;
}
</style>