<template>
  <div>
    <br />
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th rowspan="2" class="title-tab" style="width: 5%">Nº</th>
            <th rowspan="2" class="title-tab" style="width: 30%">ATIVIDADES</th>
            <th rowspan="2" class="title-tab">INDICADOR FÍSICO DE EXECUÇÃO</th>
            <th colspan="2" class="title-tab">
              DURAÇÃO PREVISTA (MÊS DO PROJETO)
            </th>
            <th rowspan="2" class="title-tab"></th>
          </tr>
          <tr>
            <th class="title-tab">INÍCIO</th>
            <th class="title-tab">FIM</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, idx) in atividades" v-bind:key="item.id">
            <td class="text-tab">A{{ idx + 1 }}</td>
            <td v-if="item.descricao" class="text-tab">
              {{ item.descricao }}
            </td>
            <td v-else class="text-tab">
              -
            </td>
            <td v-if="item.indicador" class="text-tab">
              {{ item.indicador }}
            </td>
            <td v-else class="text-tab">
              -
            </td>
            <td v-if="item.inicio" class="text-tab">
              {{ item.inicio }}
            </td>
            <td v-else class="text-tab">
              -
            </td>
            <td v-if="item.fim" class="text-tab">
              {{ item.fim }}
            </td>
            <td v-else class="text-tab">
              -
            </td>
            <td
              class="text-tab"
              style="width: 10%; padding-left: 0px; padding-right: 0px"
            >
              <!-- Atividade -->
              <div style="display: flex; flex-direction: row" v-if="itemSelecionado">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      small
                      text
                      @click="
                        (modalVerOpen = !modalVerOpen) &
                          (atividadeSelecionada = item)
                      "
                      class="option-hover"
                    >
                      <v-icon color="#ff8d3f">mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>
                    Visualizar
                  </span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      text
                      small
                      class="option-hover"
                      @click="
                        (modalCrudOpen = !modalCrudOpen) &
                          (atividadeSelecionada = item)
                      "
                    >
                      <v-icon color="#ff8d3f">mdi-pencil-box-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>
                    Editar
                  </span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      text
                      small
                      class="option-hover"
                      @click="
                        (modalExcluirOpen = !modalExcluirOpen) &
                          (atividadeSelecionada = item)
                      "
                    >
                      <v-icon color="#ff8d3f">mdi-delete-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>
                    Excluir
                  </span>
                </v-tooltip>
              </div>
              <div style="display: flex; flex-direction: row" v-else>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      small
                      text
                      @click="
                        (modalVerOpen = !modalVerOpen) &
                          (atividadeSelecionada = item)
                      "
                      class="option-hover"
                    >
                      <v-icon color="#ff8d3f">mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>
                    Visualizar
                  </span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      small
                      text
                      @click="
                        (modalDesassociar = !modalDesassociar) &
                          (atividadeSelecionada = item)
                      "
                      class="option-hover"
                    >
                      <v-icon color="#ff8d3f">mdi-account-minus</v-icon>
                    </v-btn>
                  </template>
                  <span>
                    Desassociar
                  </span>
                </v-tooltip>
              </div>
            </td>
          </tr>
          <td colspan="6" class="div-body">
            <div
              class="text-body"
              style="border-right: 0.899715px solid rgba(97, 97, 97, 0.3)"
            >
              TOTAL DE ATIVIDADES: {{ atividades.length }}
            </div>
          </td>
        </tbody>
      </template>
    </v-simple-table>
    <ModalVerAtividade
      :key="String(modalVerOpen) + 'modalVer'"
      :modalVisible="modalVerOpen"
      :atividade="atividadeSelecionada"
      @close="modalVerOpen = !modalVerOpen"
    />
    <ModalCrudAtividades
      :key="String(modalCrudOpen) + 'modalCrud'"
      :modalVisible="modalCrudOpen"
      :atividade="atividadeSelecionada"
      :meta="atividadeSelecionada && atividadeSelecionada.meta"
      @close="modalCrudOpen = !modalCrudOpen"
    />
    <ModalConfirmacao
      v-if="atividadeSelecionada"
      :modalVisible="modalExcluirOpen"
      :titulo="'Você realmente deseja excluir a atividade'"
      textoBotaoDireito="Excluir"
      :onConfirm="deletarAtividade"
      @close="modalExcluirOpen = !modalExcluirOpen"
    />
    <ModalConfirmacao
      v-if="atividadeSelecionada"
      :modalVisible="modalDesassociar"
      :titulo="'Você realmente deseja dessassociar a atividade do item'"
      textoBotaoDireito="Desassociar"
      :onConfirm="desassociarAtividade"
      @close="modalDesassociar = !modalDesassociar"
    />
  </div>
</template>

<script>
import ModalVerAtividade from "../modals/ModalVerAtividade.vue";
import ModalConfirmacao from "../modals/ModalConfirmacao.vue";
import { EventBus } from "../../services/event-bus";
import { excluirAtividade } from "../../services/providers/atividadesProvider";
import ModalCrudAtividades from "../modals/ModalCrudAtividades.vue";
import { removerAtividadeItem } from "../../services/providers/itensProvider"

export default {
  name: "AtividadesTable",
  components: {
    ModalVerAtividade,
    ModalConfirmacao,
    ModalCrudAtividades,
  },
  props: {
    atividades: Array,
  },
  data: () => ({
    atividadeSelecionada: null,
    modalVerOpen: false,
    modalCrudOpen: false,
    modalExcluirOpen: false,
    modalDesassociar: false,
  }),
  async mounted() {},
  methods: {
    async deletarAtividade() {
      try {
        await excluirAtividade(this.atividadeSelecionada.id);
        this.$notify({
          title: "Atividade exlcuída!",
          text: `Atividade exlcuída com sucesso!`,
          type: "success",
          duration: 5000,
        });
        EventBus.$emit("atividade-updated");
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro!",
          text: error.message,
          type: "error",
          duration: 15000,
        });
      }
    },
    async desassociarAtividade() {
      const payload = {
        itemId: this.$store.itemSelecionado.id,
        atividadeId: this.atividadeSelecionada.id,
      }
      try {
        await removerAtividadeItem(payload);
        this.$notify({
          title: "Atividade desassociada!",
          text: `Atividade desassociada com sucesso!`,
          type: "success",
          duration: 5000,
        });
        EventBus.$emit("atividade-updated");
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro!",
          text: error.message,
          type: "error",
          duration: 15000,
        });
      }
    },
  },
  computed: {
    itemSelecionado() {
      return this.$store.itemSelecionado === null;
    }

  }
};
</script>

<style lang="scss" scoped>
.title-tab {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 16px;
  text-align: center !important;
  color: $primary !important;
  background-color: #e6f4f1;
  border: 0.899715px solid rgba(97, 97, 97, 0.3);
  height: 45px !important;
}
.text-tab {
  font-family: Roboto;
  font-style: normal;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 13px;
  text-align: center;
  color: #616161;
  border: 0.899715px solid rgba(97, 97, 97, 0.3);
  height: 45px !important;
}
.div-body {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 16px;
  text-align: center !important;
  color: $primary !important;
  padding: 0px;
}
.text-body {
  display: flex;
  height: 45px;
  padding: 10px;
  width: 100%;
  background-color: #e6f4f1;
  text-align: center;
  border: 0.899715px solid rgba(97, 97, 97, 0.3);
  border-left: none;
  border-right: none;
}

tr th {
  border: none;
}
</style>
