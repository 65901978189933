<template>
  <div v-if="subProjeto">
    <v-card
      class="card"
      max-width="370"
      height="auto"
      outlined
      style="border: none"
    >
      <v-card-text style="padding-bottom: 0px;">
        <div style="height: 100px">
          <p id="card-title" style="color: #000; padding-top: 0px">
            {{ subProjeto.codigo }}
          </p>
          <p id="card-title" style="color: #000; padding-top: 0px">
            {{ subProjeto.descricao }}
          </p>
        </div>
        <br />

        <!-- LISTA DE PARCELAS -->
        <div v-if="parcelas.length" class="parcelas-container">
          <div v-for="parcela in parcelas" v-bind:key="parcela.id">
            <div>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <div
                    v-on="on"
                    :id="'progress_bar_' + subProjeto.id + '_' + parcela.id"
                  >
                    <p class="progress_bar_desc">REALIZADO</p>
                  </div>
                </template>
                <span
                  style="text-align: left; text-transform: uppercase; font-size: 13px; font-weight: bold"
                >
                  Executado:
                  {{ parcela.valorExecutado | currency }}
                  <p>Previsto: {{ parcela.valorAlocado | currency }}</p>
                </span>
              </v-tooltip>
            </div>
            <p class="parcela-title">
              Parcela {{ parcela.parcela.numero ? parcela.id : "-" }}
            </p>
          </div>
        </div>
        <div v-else class="div-sem-parcelas">
          <p id="card-title" style="padding-top: 0px; height: 60px">
            Nenhuma parcela cadastrada!
          </p>
        </div>
        <br />
        <br />
        <!-- LISTA DE METRICAS -->
        <!-- FNDCT -->
        <div
          style="display: flex; justify-content: space-between; margin-top: 20px' : 'display: flex; justify-content: space-between"
        >
          <span id="metrica-title">TOTAL EXECUTADO</span>
          <span
            v-if="subProjeto.metricas.valorAlocado != null"
            id="ammount-text"
            class="metrica-ammount"
            >{{ subProjeto.metricas.valorAlocado | currency }}</span
          >
          <span v-else id="ammount-text" class="metrica-ammount"
            >Não informado</span
          >
        </div>
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <div
              v-on="on"
              :id="'progress_bar_line_' + subProjeto.id + '_execucao'"
            ></div>
          </template>
          <span
            v-if="subProjeto.metricas.valorExecutado != null"
            style="text-align: center; text-transform: uppercase; font-size: 13px"
          >
            Executado:
            <p>{{ parseInt(subProjeto.metricas.percentualExecucao * 100) }}%</p>
            {{ subProjeto.metricas.valorExecutado | currency }}
          </span>
          <span v-else>Não informado</span>
        </v-tooltip>
        <br />
        <!-- FOOTER -->
        <v-row style="display: flex; justify-content: center">
          <v-col md="8" class="pb-0">
            <div class="ammount-box">
              <p>
                <b>SALDO DO SUBPROJETO</b>
              </p>
              <p v-if="subProjeto.metricas.saldoNaoExecutado != null">
                {{ subProjeto.metricas.saldoNaoExecutado | currency }}
              </p>
              <p v-else>Não informado</p>
            </div>
          </v-col>
        </v-row>
        <br />
      </v-card-text>
      <v-card-actions class="card-actions pa-0">
      <v-container class="pa-0">
        <v-row>
          <v-col class="pt-0">
            <v-btn
              depressed
              width="190"
              color="#037F8C"
              class="d-flex align-center"
              style="color: #fff; border-radius: 8px; margin-left: auto; margin-right: auto;"
              @click="openItensSubProjetosSession"
            >
              Ver itens
            </v-btn>
          </v-col>
        </v-row>
      
        <v-row>
          <v-col>
            <v-btn
              depressed
              width="190"
              color="#FF8D3F"
              class="d-flex align-center"
              style="color: #fff; border-radius: 8px; margin-left: auto; margin-right: auto;"
              @click.prevent="excluirSubprojeto"
            >
              Excluir
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
        
        
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { deleteSubprojetoById } from '../services/providers/subProjetosProvider';


var ProgressBar = require("progressbar.js");
import { optionsLine, optionsSemiCircle } from "../utils/progressBarOptions";
export default {
  name: "SubProjetoCard",
  props: {
    subProjeto: Object,
  },
  data: () => ({
    colors: ["#DD4B39", "#2699FB", "#037F8C"],
    parcelas: [],
  }),
  created() {
    if (this.subProjeto) {
      this.parcelas = this.subProjeto.metricasParcela
        .map((p) => ({
          ...p,
          id: p.parcela.numero
            ? p.parcela.numero
            : Math.round(Math.random() * 10),
        }))
        .sort((a, b) => (a.id > b.id ? 1 : -1));
    }
  },
  mounted() {
    if (this.subProjeto) {
      this.parcelas.forEach((parcela) => {
        const semiCircle = new ProgressBar.Circle(
          "#progress_bar_" + this.subProjeto.id + "_" + parcela.id,
          optionsSemiCircle
        );
        let percent = parcela.percentualExecucao;
        semiCircle.animate(percent > 1 ? 1 : percent);
      });
      const line_execucao = new ProgressBar.Line(
        "#progress_bar_line_" + this.subProjeto.id + "_execucao",
        optionsLine
      );
      line_execucao.animate(
        this.subProjeto.metricas.percentualExecucao > 1
          ? 1
          : this.subProjeto.metricas.percentualExecucao
      );
    }
  },
  methods: {
    openItensSubProjetosSession() {
      this.$actions.setSubProjeto(this.subProjeto);
      this.$router.push({
        path: `/projetos-vigentes/${this.$store.projeto.id}/subprojetos/${this.subProjeto.id}/resumo`,
      });
    },
    async excluirSubprojeto() {

      try {
        await deleteSubprojetoById(this.subProjeto.id);
         this.$notify({
          title: "Subprojeto excluído com sucesso",
          text: "Subprojeto excluído com sucesso",
          type: "success",
          duration: 15000,
        });
        this.$emit("reload");
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro interno!",
          text: error.message || error,
          type: "error",
          duration: 15000,
        });
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.card {
  background-color: $card-background !important;
}
.parcelas-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 120px !important;
  justify-content: space-around;
  align-content: center;
  align-items: center;
}
.parcela-title-div {
  padding-top: 10px;
}
.parcela-title {
  margin-left: 13px;
  text-transform: uppercase;
}
.div-sem-parcelas {
  height: 120px;
  display: flex;
  justify-content: space-around;
  align-content: center;
  align-items: center;
}
#card-title {
  padding: 10px;
  font-family: $title;
  text-align: center;
  color: $text-color;
  font-size: 16px;
}
#metrica-title {
  font-family: $subtitle;
  margin: 0;
  font-size: 16px;
  text-transform: uppercase;
  color: $text-color;
}
#ammount-text {
  font-family: $subtitle;
  text-align: right;
  font-size: 14px;
  font-weight: 500;
}
.metrica-ammount {
  font-size: 12px !important;
}
.progress_bar_desc {
  font-size: 10px;
  position: absolute;
  top: 50%;
  left: 23%;
  text-align: center;
  color: $secondary;
}
.alert-text {
  font-size: 14px;
  font-family: $subtitle;
}
.amount-column {
  padding-top: 0%;
  padding-bottom: 2px;
}
.ammount-box {
  border: 1px solid $primary;
  font-family: $subtitle;
  border-radius: 10px;
  color: $primary;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5px;
  p {
    margin-bottom: 5px;
  }
}
.card-actions {
  display: flex;
  flex: 1;
  justify-content: center;
}
p {
  margin-bottom: 0px;
}

@media only screen and (max-width: 600px) {
  .progress_bar_desc {
    font-size: 55%;
  }
}
</style>
