<template>
  <div>
    <p class="session-title">DESEMBOLSO FNDCT</p>
    <v-row>
      <v-col v-for="parcela in parcelas" v-bind:key="parcela.id" :cols="4">
        <div class="box-chart" style="height: 10px;">
          <h1 class="box-header">{{ parcela.parcela.numero }}ª PARCELA</h1>
          <v-row>
            <v-col md="6">
              <h2 class="chart-label">PREVISTO</h2>
              <p class="value-label">
                {{ parcela.valorAutorizado | currency }}
              </p>
            </v-col>
            <v-col md="6">
              <h2 class="chart-label">DESEMBOLSADO</h2>
              <p class="value-label">
                {{ parcela.valorDesembolsado | currency }}
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <div
                    v-on="on"
                    :id="'progress-line-' + parcela.id"
                    class="progress-bar"
                  ></div>
                  <div style="display: flex; justify-content: flex-end">
                    <p class="chart-label">
                      {{ parcela.percentualDesembolsado | percent }}
                    </p>
                  </div>
                </template>
                <span
                  style="text-align: left; text-transform: uppercase; font-size: 13px; font-weight: bold"
                >
                  Desembolso: {{ parcela.percentualDesembolsado | percent }}
                </span>
              </v-tooltip>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col style="width: 25% !important">
        <div class="box-chart-main">
          <v-row>
            <v-col md="6">
              <v-row>
                <v-col>
                  <h1 class="box-header-main">TOTAL</h1>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="6">
                  <h2 class="col-header-main">PREVISTO</h2>
                  <p class="content-main-box">
                    {{ projeto.valorAutorizado | currency }}
                  </p>
                </v-col>
                <v-col md="6">
                  <h2 class="col-header-main">DESEMBOLSADO</h2>
                  <p class="content-main-box">
                    {{ projeto.valorDesembolsado | currency }}
                  </p>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-btn
                    class="btn-controls mt-6"
                    depressed
                    color="#FF8D3F"
                    width="250"
                    style="margin-right: 10px"
                    @click="
                      modalSolicitarDesembolsoOpen = !modalSolicitarDesembolsoOpen
                    "
                  >
                    <v-icon style="margin-right: 10px"
                      >mdi-cash-multiple</v-icon
                    >
                    REGISTRAR DESEMBOLSO
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              md="6"
              style="height: 300px; position: relative;"
              class="ma-0 pa-0"
            >
              <v-tooltip top nudge-left="200" nudge-bottom="60">
                <template v-slot:activator="{ on, attrs }">
                  <div id="total-desembolsado"></div>
                  <div
                    v-bind="attrs"
                    v-on="on"
                    class="total-desembolso-div"
                    align-center
                  >
                    <p
                      class="total-desembolso-label"
                      v-if="projeto && projeto.valorDesembolsado"
                    >
                      {{
                        ((projeto.valorDesembolsado / projeto.valorAutorizado) *
                          100)
                          | percent
                      }}
                    </p>
                    <p v-else class="total-desembolso-label">
                      0%
                    </p>
                  </div>
                </template>
                <div
                  v-if="
                    100 -
                      (projeto.valorDesembolsado / projeto.valorAutorizado) *
                        100 !=
                      0
                  "
                >
                  <span
                    style="text-align: left; text-transform: uppercase; font-size: 13px; font-weight: bold"
                  >
                    À desembolsar:
                    {{
                      (100 -
                        (projeto.valorDesembolsado / projeto.valorAutorizado) *
                          100)
                        | percent
                    }}
                  </span>
                </div>

                <div
                  v-else
                  style="text-align: left; text-transform: uppercase; font-size: 13px; font-weight: bold"
                >
                  <span>
                    À desembolsar: 0%
                  </span>
                </div>
              </v-tooltip>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <ModalSolicitacaoDesembolso
      :modalVisible="modalSolicitarDesembolsoOpen"
      @close="modalSolicitarDesembolsoOpen = !modalSolicitarDesembolsoOpen"
    />
  </div>
</template>

<script>
var ProgressBar = require("progressbar.js");
import { optionsLine } from "../utils/progressBarOptions";
import ModalSolicitacaoDesembolso from "../components/modals/ModalSolicitacaoDesembolso.vue";

export default {
  name: "DesembolsoCharts",
  props: {
    projeto: Object,
  },
  data: () => ({
    parcelas: [],
    traceTotalPie: null,
    layoutTotalPie: null,
    modalSolicitarDesembolsoOpen: false,
  }),
  components: {
    ModalSolicitacaoDesembolso,
  },
  created() {
    if (this.projeto) {
      this.parcelas = this.projeto.metricasParcela
        .map((p) => ({
          ...p,
          id: p.parcela.numero,
          percentualDesembolsado: null,
        }))
        .sort((a, b) => (a.id > b.id ? 1 : -1));
    }
  },
  mounted() {
    if (this.projeto) {
      this.parcelas = this.projeto.metricasParcela
        .map((k) => ({
          ...k,
          id: k.parcela.numero,
        }))
        .sort((a, b) => (a.id > b.id ? 1 : -1));

      this.parcelas.forEach((parcela) => {
        if (parcela.valorDesembolsado)
          parcela.percentualDesembolsado =
            (parcela.valorDesembolsado * 100) / parcela.valorAutorizado;
        else parcela.percentualDesembolsado = 0.0;

        const line_desembolso = new ProgressBar.Line(
          "#progress-line-" + parcela.id,
          {
            ...optionsLine,
            trailWidth: 2.5,
            strokeWidth: 2.5,
            svgStyle: {
              "border-radius": "23px",
              strokeLinecap: "round",
            },
          }
        );

        line_desembolso.animate(parcela.percentualDesembolsado / 100);
      });

      let percent_desembolsado = 0;
      if (this.projeto.valorDesembolsado);
      percent_desembolsado =
        this.projeto.valorDesembolsado / this.projeto.valorAutorizado;

      const total = new ProgressBar.Circle("#total-desembolsado", {
        strokeWidth: 20,
        trailWidth: 20,
        color: "#55B4C2",
        svgStyle: {
          display: "block",
          width: "300px",
          height: "300px",
          "margin-top": "0px",
          "margin-bottom": "10px",
        },
      });

      total.animate(percent_desembolsado);
    }
  },
  filters: {
    percent(value) {
      if (!value) return "-";
      return `${value.toFixed(1)}%`;
    },
  },
  methods: {
    percent(value) {
      if (!value) return "-";
      return `${value.toFixed(0)}%`;
    },
  },
};
</script>

<style lang="scss" scoped>
.session-title {
  margin: 20px;
  text-transform: uppercase;
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #037f8c;
}
.box-chart-main {
  background-color: $card-background;
  min-height: 350px;
  width: 100%;
  border-radius: 20px;
  padding: 20px 40px;
}

.box-header-main {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 42px;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #ff8d3f;
  margin-bottom: 30px;
}

.box-chart {
  background-color: $card-background;
  min-height: 250px;
  width: 100%;
  padding: 40px;
  border-radius: 20px;
}

.box-header {
  color: #ff8d3f;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  padding-bottom: 10px;
  font-feature-settings: "pnum" on, "lnum" on;
}

.chart-label {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: #55b4c2;
  font-feature-settings: "pnum" on, "lnum" on;
  padding-bottom: 10px;
}

.value-label {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 23px;
  display: flex;
  align-items: center;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #037f8c;
}

.progress_bar_desc {
  font-size: 10px;
  position: absolute;
  top: 50%;
  left: 23%;
  text-align: center;
  color: $secondary;
}

.col-header-main {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 23px;
  color: #55b4c2;
  width: 100%;
  margin-bottom: 10px;
}

.content-main-box {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 33px;
  display: flex;
  align-items: center;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #037f8c;
  width: 100%;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

#total-desembolsado {
  margin-bottom: 5px;
  padding: 0px;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.total-desembolso-div {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.total-desembolso-label {
  color: #55b4c2;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 50px;
  font-feature-settings: "pnum" on, "lnum" on;
  margin: 0px;
}

path {
  height: 100px !important;
}

svg {
  height: 100% !important;
}
</style>
