<template>
  <v-dialog v-model="modalVisible" persistent max-width="1100">
    <v-card style="border-radius: 10px">
      <br />
      <div style="width: 100%; display: flex; justify-content: space-between">
        <v-btn
          v-if="context == 'visualizar'"
          text
          large
          style="color: #037f8c"
          @click="context = 'listar'"
        >
          <v-icon large color="#037F8C">mdi-arrow-left-circle-outline</v-icon>
          <span style="margin-left: 10px">Voltar</span>
        </v-btn>
        <div v-else></div>
        <v-btn text large @click="closeModal(false)">
          <v-icon large color="#037F8C">mdi-close-circle-outline</v-icon>
        </v-btn>
      </div>
      <div ref="snapshotView">
        <v-card-title v-if="context == 'listar'" class="modal-title"
          >CRONOGRAMAS DE DESEMBOLSO ANTERIORES</v-card-title
        >
        <v-card-title
          v-else
          class="modal-title"
          style="
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          "
        >
          <div></div>
          <div style="text-align: center">
            CRONOGRAMAS DE DESEMBOLSO
            {{ tipo != "projeto" ? " SUBPROJETOS" : "" }}
            {{ snapshotSelecionado.id }}
            <div style="font-size: 20px; font-family: Roboto">
              {{
                new Date(snapshotSelecionado.dataCriacao) | moment("DD/MM/YYYY")
              }}
            </div>
          </div>
          <div>
            <v-btn
              v-if="!onPrint"
              text
              @click="printCronograma('snapshotView')"
            >
              <v-icon color="#F39C12">mdi-download</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-card-text>
          <br />
          <v-row v-if="context == 'listar'">
            <v-col>
              <v-simple-table>
                <template v-slot:default>
                  <thead style="border: 1px solid black">
                    <tr>
                      <th class="title-tab">CRONOGRAMA</th>
                      <th class="title-tab">DATA</th>
                      <th class="title-tab"></th>
                    </tr>
                  </thead>
                  <tbody v-if="tipo == 'projeto'">
                    <tr v-for="(snapshot, idx) in snapshots" v-bind:key="idx">
                      <td style="width: 60%" class="text-tab">
                        CRONOGRAMA DESEMBOLSO {{ idx + 1 }}
                      </td>
                      <td style="width: 25%" class="text-tab">
                        {{
                          new Date(snapshot.dataCriacao) | moment("DD/MM/YYYY")
                        }}
                      </td>
                      <td class="text-tab">
                        <v-btn
                          small
                          text
                          @click="
                            (context = 'visualizar') &
                              (snapshotSelecionado = snapshot)
                          "
                        >
                          <v-icon color="#F39C12">mdi-eye-outline</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr
                      v-for="(key, idx) in Object.keys(snapshots)"
                      v-bind:key="idx"
                    >
                      <td
                        v-if="tipo == 'projeto'"
                        style="width: 60%"
                        class="text-tab"
                      >
                        CRONOGRAMA DESEMBOLSO {{ idx + 1 }}
                      </td>
                      <td v-else style="width: 60%" class="text-tab">
                        CRONOGRAMA DESEMBOLSO SUBPROJETOS {{ idx + 1 }}
                      </td>
                      <td style="width: 25%" class="text-tab">
                        {{ new Date(key) | moment("DD/MM/YYYY") }}
                      </td>
                      <td class="text-tab">
                        <v-btn
                          small
                          text
                          @click="
                            (context = 'visualizar') &
                              (snapshotSelecionado = {
                                id: idx + 1,
                                dataCriacao: key,
                                snapshots: snapshots[key],
                              })
                          "
                        >
                          <v-icon color="#037F8C">mdi-eye-outline</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
          <v-row v-if="context == 'visualizar' && snapshotSelecionado">
            <div v-if="tipo == 'projeto'" style="width: 100%">
              <CronogramaDesembolsoTable
                :key="JSON.stringify(snapshotSelecionado) + context"
                :metricas="snapshotSelecionado.metricasCronogramaProjeto"
              />
            </div>
            <div v-else style="width: 100%">
              <CronogramaSubProjetoTable
                :key="JSON.stringify(snapshotSelecionado) + context"
                :metricasSubprojetos="snapshotSelecionado.snapshots"
              />
            </div>
          </v-row>
        </v-card-text>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  listarSnapshotCronogramasProjeto,
  listarSnapshotCronogramasSubProjeto,
} from "../../services/providers/cronogramaProvider";
import { getSubprojetosByProjetoApi } from "../../services/providers/subProjetosProvider";
import CronogramaDesembolsoTable from "../tables/CronogramaDesembolsoTable.vue";
import CronogramaSubProjetoTable from "../tables/CronogramaSubProjetoTable.vue";
export default {
  components: { CronogramaDesembolsoTable, CronogramaSubProjetoTable },
  name: "SnapshotCronogramas",
  props: {
    modalVisible: Boolean,
    tipo: String,
  },
  data: () => ({
    snapshots: [],
    context: "listar",
    snapshotSelecionado: null,
    onPrint: false,
  }),
  async mounted() {
    if (this.tipo == "projeto") {
      await this.getSnapshotProjeto();
    } else {
      await this.getSnapshotSubprojeto();
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async getSnapshotProjeto() {
      try {
        const snapshots = await listarSnapshotCronogramasProjeto(
          this.$store.projeto.id
        );
        this.snapshots = snapshots.sort((a, b) =>
          new Date(a.dataCriacao).getTime() < new Date(b.dataCriacao).getTime()
            ? 1
            : -1
        );
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro interno!",
          text: error.message || error,
          type: "error",
          duration: 15000,
        });
      }
    },
    async getSnapshotSubprojeto() {
      try {
        let subProjetos = await getSubprojetosByProjetoApi(
          this.$store.projeto.id
        );
        const allSnapshots = await Promise.all(
          subProjetos.map(async (s) => {
            return {
              subprojeto: s,
              snapshots: await listarSnapshotCronogramasSubProjeto(s.id),
            };
          })
        );
        const snapshotBundle = {};
        allSnapshots.forEach((subproj) => {
          subproj.snapshots
            .sort((a, b) =>
              new Date(a.dataCriacao).getTime() <
              new Date(b.dataCriacao).getTime()
                ? 1
                : -1
            )
            .forEach((snapshot) => {
              snapshot = {
                ...snapshot,
                ...{
                  codigo: subproj.subprojeto.codigo,
                  idSubprojeto: subproj.subprojeto.id,
                },
              };
              snapshot.metricasCronograma =
                snapshot.metricasCronogramaSubprojeto;
              delete snapshot.metricasCronogramaSubprojeto;
              snapshotBundle[snapshot.dataCriacao] = [
                ...(snapshotBundle[snapshot.dataCriacao] || []),
                snapshot,
              ];
            });
        });
        this.snapshots = snapshotBundle;
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro interno!",
          text: error.message || error,
          type: "error",
          duration: 15000,
        });
      }
    },
    async printCronograma(id) {
      this.onPrint = !this.onPrint;
      const el = this.$refs[id];
      const options = {
        type: "dataURL",
      };
      const output = await this.$html2canvas(el, options);
      this.download(
        `cronograma_desembolso_${this.tipo}_${
          this.$store.projeto.codigo
        }_${new Date(this.snapshotSelecionado.dataCriacao).toLocaleDateString(
          "pt-BR"
        )}.png`,
        output
      );
      this.onPrint = !this.onPrint;
    },
    download(filename, link) {
      const element = document.createElement("a");
      element.setAttribute("href", link);
      element.setAttribute("download", filename);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-title {
  display: flex;
  justify-content: center;
  font-size: 24px !important;
  text-transform: uppercase;
  color: $primary;
  font-weight: bold;
  font-family: Raleway;
}
.session-title {
  color: $primary;
  font-family: Raleway;
  font-size: 20px;
}
.title-tab {
  font-family: Roboto;
  background-color: #e6f4f1;
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 15px !important;
  text-align: center !important;
  color: #037f8c !important;
  border: 0.899715px solid rgba(97, 97, 97, 0.3);
  padding: 10px !important;
}
.text-tab {
  font-family: Roboto;
  font-style: normal;
  font-size: 13px;
  line-height: 13px;
  text-align: center;
  text-transform: uppercase;
  color: #616161 !important;
  border: 0.899715px solid rgba(97, 97, 97, 0.3);
  padding: 0px 10px 0px 10px !important;
}
</style>
