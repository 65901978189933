<template>
  <v-container style="max-width: 1450px">
    <div v-if="isExactActive">
      <div v-if="alertas.length">
        <div
          style="margin-top: 12px; margin-bottom: 12px; display:flex; justify-content: flex-end"
        >
          <v-btn outlined color="#037F8C" to="/alertas">
            VISUALIZAR TODOS OS ALERTAS
          </v-btn>
        </div>
        <v-row>
          <v-col md="3" xl="2" v-for="(alerta, idx) in alertas" :key="idx">
            <AlertaCard :alerta="alerta" />
          </v-col>
        </v-row>
      </div>
      <PageTitle title="<b>Projetos Vigentes</b>" />
      <v-row>
        <v-col
          md="4"
          xl="3"
          sm="4"
          v-for="projeto in projetos"
          v-bind:key="projeto.id"
        >
          <ProjectCard :projeto="projeto" :isSubProjeto="false" />
        </v-col>
        <div
          v-if="!projetos.length && !$store.globalLoadingActive"
          class="loading"
        >
          <v-progress-circular
            size="50"
            indeterminate
            color="#ababab"
          ></v-progress-circular>
          <p style="color: #ababab; font-size: 13px">
            Carregando...
          </p>
        </div>
      </v-row>
    </div>
    <router-view ref="rv"></router-view>
  </v-container>
</template>

<script>
import { getProjetosApi } from "../services/providers/projetosProvider";
import { listarPrimeirosAlertas } from "../services/providers/AlertasProvider";
import PageTitle from "../components/PageTitle";
import ProjectCard from "../components/ProjectCard";
import AlertaCard from "../components/AlertaCard";

export default {
  name: "ProjetosVigentes",
  components: {
    PageTitle,
    ProjectCard,
    AlertaCard,
  },
  data: () => ({
    projetos: [],
    alertas: [],
    isExactActive: true,
  }),
  updated() {
    this.isExactActive = typeof this.$refs.rv === "undefined";
  },
  created() {
    this.isExactActive = typeof this.$refs.rv === "undefined";
    this.getProjetos();
    this.getAlertas();
  },
  methods: {
    async getProjetos() {
      try {
        const projetos = await getProjetosApi();
        this.projetos = projetos.filter(projeto => projeto.estadoCadastroProjeto === "FINALIZADO" );
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro interno!",
          text: error.message || error,
          type: "error",
          duration: 15000,
        });
      }
    },
    async getAlertas() {
      try {
        this.alertas = await listarPrimeirosAlertas();
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro interno!",
          text: error.message || error,
          type: "error",
          duration: 15000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 400px;
}
</style>
