<template>
  <div :class="{ 'container-main': backgroundActive }">
    <v-container>
      <div v-if="isExactActive">
        <v-row class="mt-4" v-if="isVisible">
          <v-col offset-md="2" cols="8" align-self="center">
            <div
              style="
                margin-top: 12px;
                margin-bottom: 12px;
                display: flex;
                justify-content: flex-end;
              "
            >
              <div class="reformulacao-div px-16 py-5 card-shadow">
                <v-row no-gutters>
                  <v-col cols="2" class="ma-0 pa-0">
                    <v-icon size="75" style="color: white"
                      >mdi-text-box-multiple-outline</v-icon
                    >
                  </v-col>
                  <v-col cols="6" class="ma-0 pa-0">
                    <p
                      class="reformulacao-title"
                      style="line-height: 120%; margin: 0%"
                    >
                      Cadastro <br />de Projetos
                    </p>
                  </v-col>
                  <v-col
                    cols="4"
                    class="ma-0 pa-0"
                    style="
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <v-btn
                      class="btn-controls"
                      depressed
                      color="#FF8D3F"
                      width="250"
                      large
                      @click="goToCadastro"
                      style="margin-left: auto; margin-right: auto"
                    >
                      NOVO PROJETO</v-btn
                    >
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <template v-if="this.projetos">
            <v-col offset-md="2" cols="8" align-self="center">
              <div
                class="projeto-div mb-4 px-16 py-5 card-shadow"
                v-for="projeto in projetos"
                :key="projeto.id"
              >
                <v-row no-gutters>
                  <v-col cols="4" class="ma-0 pa-0">
                    <p class="title-tab-card ma-1">Projeto:</p>
                    <p class="content-tab-card ma-1">
                      {{ projeto.codigo }}
                    </p>
                  </v-col>
                  <v-col
                    cols="4"
                    class="ma-0 pa-0"
                    style="display: flex; justify-content: center"
                  >
                    <div>
                      <p class="title-tab-card ma-1">Criado por:</p>
                      <p class="content-tab-card ma-1">
                        <v-icon class="mr-2" color="#1DA7A3"
                          >mdi-account</v-icon
                        >
                        {{ projeto.usuarioCriacao.login }}
                      </p>
                    </div>
                  </v-col>
                  <v-col
                    cols="4"
                    class="ma-0 pa-0"
                    style="
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <p style="text-align: center">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <a
                            class="mr-4"
                            v-on="on"
                            @click="goToEdit(projeto.id)"
                          >
                            <v-icon color="#FF8D3F" size="32">
                              mdi-arrow-right-circle-outline
                            </v-icon>
                          </a>
                        </template>
                        <span> CONTINUAR CADASTRO </span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <a
                            v-on="on"
                            @click="
                              (projetoSelecionado = projeto) &
                                (modalExcluirProjeto = !modalExcluirProjeto)
                            "
                          >
                            <v-icon color="#FF8D3F" size="32"
                              >mdi-delete-outline</v-icon
                            >
                          </a>
                        </template>
                        <span> EXCLUIR PROJETO </span>
                      </v-tooltip>
                    </p>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </template>
          <template v-if="semProjetos">
            <v-col offset-md="2" cols="8">
              <div class="projeto-div card-shadow">
                <p
                  class="text-empty-projetos text-center py-16"
                  style="width: 100%"
                >
                  Não há cadastros <br />em andamento
                </p>
              </div>
            </v-col>
          </template>
        </v-row>
      </div>
      <router-view ref="rvv"></router-view>
    </v-container>
    <ModalConfirmacao
      v-if="projetoSelecionado"
      :modalVisible="modalExcluirProjeto"
      :titulo="'Você realmente deseja excluir o projeto'"
      :item="projetoSelecionado.codigo"
      textoBotaoDireito="Excluir"
      :onConfirm="excluirProjeto"
      @close="
        (modalExcluirProjeto = !modalExcluirProjeto) &
          (projetoSelecionado = null)
      "
    />
  </div>
</template>

<script>
import {
  getProjetosApi,
  excluirProjeto,
} from "../services/providers/projetosProvider";
import ModalConfirmacao from "../components/modals/ModalConfirmacao.vue";

export default {
  name: "CadastroProjetos",
  components: { ModalConfirmacao },
  data: () => ({
    projetos: [],
    isExactActive: true,
    backgroundActive: false,
    projetoSelecionado: null,
    modalExcluirProjeto: false,
    isVisible: true,
    semProjetos:false
  }),
  watch:{
    projetos(newValue){
      if(newValue.length == 0){
        this.semProjetos = true;
      }
    }
  },
  updated() {
    this.isExactActive = typeof this.$refs.rvv === "undefined";
  },
  created() {
    this.isExactActive = typeof this.$refs.rvv === "undefined";
    this.getProjetosInacabados();
    this.backgroundActive = this.$router.history.current.path === "/cadastro-projetos";
    this.isVisible = this.$router.history.current.path === "/cadastro-projetos";
    this.$watch(
      () => this.$route,
      (toParams) => {
        this.backgroundActive = toParams.path === "/cadastro-projetos";
        this.isVisible = toParams.path === "/cadastro-projetos";
      }
    );
  },
  methods: {
    excluirProjeto() {
      try {
        excluirProjeto(this.projetoSelecionado.id);
        this.projetos = this.projetos.filter(
          (projeto) => projeto.id !== this.projetoSelecionado.id
        );
        this.$notify({
          title: "Projeto excluído",
          text: "Projeto excluído com sucesso",
          type: "warning",
          duration: 15000,
        });
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro interno!",
          text: error.message || error,
          type: "error",
          duration: 15000,
        });
      }
    },
    goToCadastro() {
      this.backgroundActive = false;
      this.$router.push({
        path: `/cadastro-projetos/novo-cadastro`,
      });
    },
    goToEdit(id) {
      this.$router.push({
        path: `/cadastro-projetos/${id}/editar-cadastro/`,
      });
    },
    async getProjetosInacabados() {
      try {
        const projetos = await getProjetosApi();
        this.projetos = projetos.filter(
          (projeto) => projeto.estadoCadastroProjeto !== "FINALIZADO"
        );
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro interno!",
          text: error.message || error,
          type: "error",
          duration: 15000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container-main {
  width: 100vw;
  height: 100%;
  background-image: url("../assets/malha.png");
  background-size: cover;
  background-attachment: fixed;
  border: none;
}
.main-without-drawer {
  padding: 0px !important;
}
@media only screen and (max-width: 480px) {
  .content-login {
    width: 100% !important;
  }
}
.content-cadastro {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  border-radius: 30px;
}
.content-cadastro {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  border-radius: 30px;
}
.form-div-title {
  padding: 5%;
  padding-top: 7%;
  padding-bottom: 3%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.reformulacao-div {
  background-color: #1da7a3;
  width: 100%;
  display: flex;
  padding: 40px;
  border-radius: 20px;
}
.reformulacao-title {
  font-family: Raleway;
  font-weight: bold;
  font-size: 28px;
  color: #f2f2f2;
}
p {
  color: white;
  font-size: 25px;
}
.projeto-div {
  background-color: #e6f4f1;
  width: 100%;
  display: flex;
  padding: 40px;
  border-radius: 20px;
}
.projeto-title {
  color: #1da7a3;
  font-family: Raleway;
  font-weight: bold;
  font-size: 28px;
}
.title-tab-card {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;

  color: #037f8c;
}
.content-tab-card {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: center;

  color: #1da7a3;
}
.card-shadow {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.text-empty-projetos {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 42px;
  line-height: 49px;
  color: #037f8c;
}
.exclude-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ff8d3f;
}
</style>
