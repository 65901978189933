<template>
  <v-list-item
    :style="
      `padding-left: 5px; 
      background-color: ${notificacao.lido ? 'white' : '#f9f9f9'};
      padding-top: ${withTempoEstimado ? '5px !important' : '0px'};
      padding-bottom: ${withTempoEstimado ? '5px !important' : '0px'};
    `
    "
  >
    <v-list-item-avatar>
      <v-icon large :color="iconColor">{{ icon }}</v-icon>
    </v-list-item-avatar>
    <v-list-item-content
      class="notificacao-text"
      :style="!withTempoEstimado ? 'margin-top: 20px' : ''"
    >
      <div>
        <span v-html="menssagem"></span>
        <span
          v-if="withTempoEstimado"
          style="font-size: 13px; color: #616161"
          >{{ getTempoNotificacao(notificacao) }}</span
        >
        <p v-else style="font-size: 14px; color: #037f8c; margin-top: 10px">
          {{ notificacao.dataCriacao | moment("DD/MM/YYYY HH:MM") }}
        </p>
      </div>
    </v-list-item-content>
    <v-list-item-avatar>
      <v-btn icon>
        <v-icon color="#CCCCCC" mediun large @click="goToProject(notificacao)"
          >mdi-arrow-right-circle</v-icon
        >
      </v-btn>
    </v-list-item-avatar>
  </v-list-item>
</template>

<script>
import { marcarNotificacaoComoLida } from "../services/providers/notificacoesProvider";
import { EventBus } from "../services/event-bus.js";

export default {
  name: "NotificationCard",
  props: {
    notificacao: Object,
    withTempoEstimado: Boolean,
  },
  computed: {
    icon: function() {
      switch (this.notificacao.tipo) {
        case "MUDANCA_STATUS_EMPENHO":
          return "mdi-sync";
        case "MUDANCA_STATUS_REQUISICAO":
          return "mdi-sync";
        case "ATRASO_STATUS_EMPENHO":
          return "mdi-clock-alert-outline";
        case "ATRASO_STATUS_REQUISICAO":
          return "mdi-clock-alert-outline";
        default:
          return "mdi-sync";
      }
    },
    iconColor: function() {
      switch (this.notificacao.tipo) {
        case "MUDANCA_STATUS_REQUISICAO":
          return "rgba(232, 62, 140, 0.6)";
        case "MUDANCA_STATUS_EMPENHO":
          return "rgba(111, 66, 193, 0.7)";
        case "ATRASO_STATUS_REQUISICAO":
          return "rgba(232, 62, 140, 0.6)";
        case "ATRASO_STATUS_EMPENHO":
          return "rgba(111, 66, 193, 0.7)";
        default:
          return "rgba(232, 62, 140, 0.6)";
      }
    },
    menssagem: function() {
      switch (this.notificacao.tipo) {
        case "MUDANCA_STATUS_EMPENHO":
          return `<b>EMPENHO:</b> o empenho 
            <b>${this.notificacao.numero}</b> do projeto 
            <b>${this.notificacao.projeto.codigo}</b>
            mudou seu status para ${this.notificacao.status}. `;
        case "MUDANCA_STATUS_REQUISICAO":
          return `<b>REQUISIÇÃO:</b> a requisição 
            <b>${this.notificacao.numero}</b> do projeto 
            <b>${this.notificacao.projeto.codigo}</b> 
            mudou seu status para ${this.notificacao.status}. `;
        case "ATRASO_STATUS_EMPENHO":
          return `<b>EMPENHO:</b> ao empenho 
            <b>${this.notificacao.numero}</b> do projeto 
            <b>${this.notificacao.projeto.codigo}</b> 
            está a muito tempo sem mudar o status. `;
        case "ATRASO_STATUS_REQUISICAO":
          return `<b>REQUISIÇÃO:</b> a requisição 
            <b>${this.notificacao.numero}</b> do projeto 
            <b>${this.notificacao.projeto.codigo}</b> 
            está a muito tempo sem mudar o status. `;
        default:
          return "Não informado";
      }
    },
  },
  methods: {
    async goToProject() {
      await this.marcarNotificacaoLida();
      const { projeto } = this.notificacao;
      if (projeto) {
        this.$router.push({
          path: `/projetos-vigentes/${projeto.id}/`,
        });
      }
    },
    async marcarNotificacaoLida() {
      try {
        await marcarNotificacaoComoLida(this.notificacao.id);
        EventBus.$emit("read-notification");
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro interno!",
          text: error.message || error,
          type: "error",
          duration: 15000,
        });
      }
    },
    getTempoNotificacao(notificacao) {
      const date = new Date();
      const dataAlerta = new Date(notificacao.dataCriacao);
      let ms = date.getTime() - dataAlerta.getTime();
      let min = parseInt(ms / 1000 / 60);
      if (min >= 60) {
        let horas = min / 60;
        if (horas >= 24) {
          let dias = horas / 24;
          if (dias >= 7) {
            if (dias >= 30) {
              return parseInt(dias / 30) + " meses";
            }
            return parseInt(dias / 7) + " sem";
          }
          return parseInt(horas / 24) + " dias";
        }
        return parseInt(min / 60) + "h";
      }
      return min + " min";
    },
  },
};
</script>

<style lang="scss" scoped>
.notificacao-title {
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #616161;
}
.notificacao-text {
  font-family: Raleway;
  font-style: normal;
  font-weight: normal;
  display: flex;
  align-items: center;
  color: #616161;
}
</style>
