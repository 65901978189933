<template>
  <v-dialog v-model="modalVisible" max-width="600">
    <v-card
      style="border-radius: 10px; border: 3px solid #1DA7A3; overflow-x: hidden"
    >
      <br />
      <div style="width: 100%; display: flex; justify-content: flex-end">
        <v-btn text large @click="closeModal()">
          <v-icon large color="#037F8C">mdi-close-circle-outline</v-icon>
        </v-btn>
      </div>
      <v-card-text
        style="display: flex; justify-content: center; flex-direction: column;"
      >
        <v-icon size="130" color="#1DA7A3">mdi-check-circle</v-icon>
        <br />
        <div style="align-self: center; text-align: center">
          <slot></slot>
        </div>
        <br />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ModalSuccess",
  props: {
    modalVisible: Boolean,
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped></style>
