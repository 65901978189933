<template>
  <div>
    <v-simple-table style="background-color: #ffff">
      <template v-slot:default>
        <thead>
          <tr>
            <td class="title-tab">Nº</td>
            <td v-if="context == 'projeto'" class="title-tab">SUBPROJETO</td>
            <td class="title-tab">DESCRIÇÃO</td>
            <td class="title-tab">PARCELA</td>
            <td class="title-tab">QTD</td>
            <td class="title-tab">VALOR UNIT. PREVISTO</td>
            <td class="title-tab">TOTAL PREVISTO</td>
            <td class="title-tab">TOTAL EXECUTADO</td>
            <td class="title-tab">SALDO DISPONÍVEL</td>
            <td class="title-tab">STATUS</td>
            <td class="title-tab"></td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, idx) in itens" v-bind:key="item.id">
            <td
              :class="
                item.situacaoItem.codigo == 'E'
                  ? 'text-tab excluido'
                  : 'text-tab'
              "
            >
              {{ idx + 1 }}
            </td>
            <td
              v-if="context == 'projeto'"
              :class="
                item.situacaoItem.codigo == 'E'
                  ? 'text-tab excluido'
                  : 'text-tab'
              "
            >
              {{ item.subprojeto.codigo }}
            </td>
            <td
              v-if="item.descricao"
              style="width: 30%; text-align: left"
              :class="
                item.situacaoItem.codigo == 'E'
                  ? 'text-tab excluido'
                  : 'text-tab'
              "
            >
              {{ item.descricao }}
            </td>
            <td
              v-else
              style="width: 30%; text-align: left"
              :class="
                item.situacaoItem.codigo == 'E'
                  ? 'text-tab excluido'
                  : 'text-tab'
              "
            >
              -
            </td>
            <td
              v-if="item.parcela"
              :class="
                item.situacaoItem.codigo == 'E'
                  ? 'text-tab excluido'
                  : 'text-tab'
              "
            >
              {{ item.parcela.numero }}º
            </td>
            <td
              v-else
              :class="
                item.situacaoItem.codigo == 'E'
                  ? 'text-tab excluido'
                  : 'text-tab'
              "
            >
              -
            </td>
            <td
              v-if="item.quantidade"
              :class="
                item.situacaoItem.codigo == 'E'
                  ? 'text-tab excluido'
                  : 'text-tab'
              "
            >
              {{ item.quantidade }}
            </td>
            <td
              v-else
              :class="
                item.situacaoItem.codigo == 'E'
                  ? 'text-tab excluido'
                  : 'text-tab'
              "
            >
              -
            </td>
            <td
              v-if="item.valorUnitario"
              :class="
                item.situacaoItem.codigo == 'E'
                  ? 'text-tab excluido'
                  : 'text-tab'
              "
            >
              {{ item.valorUnitario | currency }}
            </td>
            <td
              v-else
              :class="
                item.situacaoItem.codigo == 'E'
                  ? 'text-tab excluido'
                  : 'text-tab'
              "
            >
              -
            </td>
            <td
              v-if="item.valorAlocado"
              :class="
                item.situacaoItem.codigo == 'E'
                  ? 'text-tab excluido'
                  : 'text-tab'
              "
            >
              {{ item.valorAlocado | currency }}
            </td>
            <td
              v-else
              :class="
                item.situacaoItem.codigo == 'E'
                  ? 'text-tab excluido'
                  : 'text-tab'
              "
            >
              -
            </td>
            <td
              v-if="item.valorExecutado"
              :class="
                item.situacaoItem.codigo == 'E'
                  ? 'text-tab excluido'
                  : 'text-tab'
              "
            >
              {{ item.valorExecutado | currency }}
            </td>
            <td
              v-else
              :class="
                item.situacaoItem.codigo == 'E'
                  ? 'text-tab excluido'
                  : 'text-tab'
              "
            >
              -
            </td>
            <td
              v-if="item.saldoNaoExecutado"
              :class="
                item.situacaoItem.codigo == 'E'
                  ? 'text-tab excluido'
                  : 'text-tab'
              "
            >
              {{ item.saldoNaoExecutado | currency }}
            </td>
            <td
              v-else
              :class="
                item.situacaoItem.codigo == 'E'
                  ? 'text-tab excluido'
                  : 'text-tab'
              "
            >
              -
            </td>
            <td
              v-if="item.situacaoItem"
              :class="
                item.situacaoItem.codigo == 'E'
                  ? 'text-tab excluido'
                  : 'text-tab'
              "
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span 
                    v-bind="attrs"
                    v-on="on"
                  >
                    <p
                      class="status-label"
                      :style="'background-color:' + setarCorPorSituacao(item)"
                    >
                      {{ item.situacaoItem.codigo }}
                    </p>
                  </span>
                </template>
                <span>
                  {{ getLabelPorSituacaoItem(item) }}
                </span>
              </v-tooltip>

            </td>
            <td v-else class="text-tab">-</td>
            <td class="text-tab" style="padding-left: 0px; padding-right: 0px">
              <div style="display: flex; flex-direction: row">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      small
                      text
                      :disabled="!item.situacaoItem"
                      @click="goToItem(item)"
                      class="arrow-hover"
                    >
                      <v-icon color="#CCCCCC"
                        >mdi-arrow-right-bold-circle</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>
                    Visualizar
                  </span>
                </v-tooltip>
                <v-menu offset-y left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      text
                      small
                      class="action-hover"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon color="#CCCCCC"
                        >mdi-dots-horizontal-circle</v-icon
                      >
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <v-btn
                        :disabled="item.situacaoItem.codigo == 'E'"
                        text
                        small
                        @click="
                          (itemSelecionado = item) &
                            (modalResponsaveisOpen = !modalResponsaveisOpen)
                        "
                      >
                        <v-icon color="#037F8C">mdi-account</v-icon>
                        <p class="btn-text">Responsável</p>
                      </v-btn>
                    </v-list-item>
                    <v-list-item>
                      <v-btn
                        :disabled="item.situacaoItem.codigo == 'E'"
                        text
                        small
                        @click.prevent="abrirModalAtividade(item)"
                      >
                        <!-- @click="(itemSelecionado = item) &
                            (modalAtividadeisOpen = !modalAtividadeisOpen)" -->
                        <v-icon color="#037F8C"
                          >mdi-checkbox-marked-circle-outline</v-icon
                        >
                        <p class="btn-text">Atividade</p>
                      </v-btn>
                    </v-list-item>
                    <v-list-item>
                      <v-btn
                        :disabled="item.situacaoItem.codigo == 'E' || !itemEditavel"
                        text
                        small
                        @click="openModalExcluirItem(item)"
                      >
                        <v-icon color="#037F8C">mdi-delete-outline</v-icon>
                        <p class="btn-text">Excluir</p>
                      </v-btn>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </td>
          </tr>
          <td :colspan="context == 'projeto' ? 3 : 2" class="div-valor-total">
            <div
              class="text-valor-total"
              style="border-left: 0.899715px solid rgba(97, 97, 97, 0.3)"
            ></div>
          </td>
          <td colspan="1" class="div-valor-total">
            <div class="text-valor-total">
              <p>TOTAL:</p>
            </div>
          </td>
          <td colspan="1" class="div-valor-total">
            <div class="text-valor-total">
              <p>{{ totalItens.quantidade }}</p>
            </div>
          </td>
          <td colspan="1" class="div-valor-total">
            <div class="text-valor-total">
              <p>{{ totalItens.valorUnitario | currency }}</p>
            </div>
          </td>
          <td colspan="1" class="div-valor-total">
            <div class="text-valor-total">
              <p>{{ totalItens.valorAlocado | currency }}</p>
            </div>
          </td>
          <td colspan="1" class="div-valor-total">
            <div class="text-valor-total">
              <p>{{ totalItens.valorExecutado | currency }}</p>
            </div>
          </td>
          <td colspan="1" class="div-valor-total">
            <div class="text-valor-total">
              <p>{{ totalItens.saldoNaoExecutado | currency }}</p>
            </div>
          </td>
          <td colspan="3" class="div-valor-total">
            <div
              class="text-valor-total"
              style="border-right: 0.899715px solid rgba(97, 97, 97, 0.3)"
            ></div>
          </td>
        </tbody>
      </template>
    </v-simple-table>
    <ModalConfirmacao
      v-if="itemSelecionado"
      :modalVisible="modalExcluirOpen"
      :titulo="'Você realmente deseja excluir o item'"
      :item="itemSelecionado.descricao"
      textoBotaoDireito="Excluir"
      :onConfirm="openModalJustificativaExcluir"
      @close="closeModalExcluirItem"
    />
    <ModalJustificativa
      :key="String(modalJustificativaExcluirOpen) + 'justificativa'"
      :modalVisible="modalJustificativaExcluirOpen"
      :item="itemSelecionado"
      @close="closeModalJustificativa"
      @confirm="excluirItem"
    />
    <ModalResponsaveisItem
      :key="String(modalResponsaveisOpen) + 'responsavel'"
      :modalVisible="modalResponsaveisOpen"
      :item="itemSelecionado"
      @close="modalResponsaveisOpen = !modalResponsaveisOpen"
    />
    <ModalAtividadeItem
      :key="String(modalAtividadeisOpen) + 'atividade'"
      :modalVisible="modalAtividadeisOpen"
      :item="itemSelecionado"
      @close="modalAtividadeisOpen = !modalAtividadeisOpen"
    />
  </div>
</template>

<script>
import { EventBus } from "../../services/event-bus";
import { deletarItem } from "../../services/providers/itensProvider";
import { getProjetoById } from "../../services/providers/projetosProvider";
import { getCorPorSituacaoItem, getLabelPorSituacaoItem } from "../../utils/itens";
import ModalConfirmacao from "../modals/ModalConfirmacao.vue";
import ModalJustificativa from "../modals/ModalJustificativa.vue";
import ModalResponsaveisItem from "../modals/ModalResponsaveisItem.vue";
import ModalAtividadeItem from "../modals/ModalAtividadeItem.vue";

export default {
  name: "ItensTable",
  components: {
    ModalConfirmacao,
    ModalJustificativa,
    ModalResponsaveisItem,
    ModalAtividadeItem,
  },
  props: {
    itens: Array,
    context: {
      type: String,
      default: "subprojeto",
    },
    showActions: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    totalItens: {
      quantidade: 0,
      valorUnitario: 0,
      valorAlocado: 0,
      valorTotalRealizado: 0,
      valorExecutado: 0,
      saldoNaoExecutado: 0,
    },
    itemSelecionado: null,
    execucoes: [],
    modalExcluirOpen: false,
    modalJustificativaExcluirOpen: false,
    modalResponsaveisOpen: false,
    modalAtividadeisOpen: false,
    justificativa: "",
  }),
  mounted() {
    this.calcularValoresTotais();
  },
  methods: {
    setarCorPorSituacao: getCorPorSituacaoItem,
    calcularValoresTotais() {
      if (this.itens) {
        this.itens.forEach((item) => {
          if (item) {
            this.totalItens.quantidade += item.quantidade;
            this.totalItens.valorUnitario += item.valorUnitario;
            this.totalItens.valorAlocado += item.valorAlocado;
            this.totalItens.valorTotalRealizado += item.valorTotalRealizado;
            this.totalItens.valorExecutado += item.valorExecutado;
            this.totalItens.saldoNaoExecutado += item.saldoNaoExecutado;
          }
        });
      }
    },
    reloadItens() {
      this.$emit("reloadComponent");
    },
    goToItem(item) {
      if (item && item.subprojeto.id) {
        this.$router.push({
          path: `/projetos-vigentes/${this.$store.projeto.id}/subprojetos/${item.subprojeto.id}/itens/${item.id}`,
        });
      }
    },
    async excluirItem(justificativa) {
      try {
        const payload = {
          justificativa,
        };
        await deletarItem(this.itemSelecionado.id, payload);
        this.$notify({
          title: "Item exlcuído!",
          text: `Item exlcuído com sucesso!`,
          type: "success",
          duration: 5000,
        });
        const projeto = await getProjetoById(
          this.itemSelecionado.subprojeto.projetoId
        );
        if (projeto) {
          this.$actions.setProjeto(projeto);
        }
        EventBus.$emit("item-updated");
        this.closeModalJustificativa();
      } catch (error) {
        this.closeModalJustificativa();
        console.error(error);
        this.$notify({
          title: "Erro!",
          text: error.message,
          type: "error",
          duration: 15000,
        });
      }
    },
    closeModalExcluirItem() {
      this.modalExcluirOpen = false;
    },
    openModalExcluirItem(item) {
      this.itemSelecionado = item;
      this.modalExcluirOpen = !this.modalExcluirOpen;
    },
    closeModalJustificativa() {
      this.modalJustificativaExcluirOpen = false;
      this.itemSelecionado = null;
    },
    openModalJustificativaExcluir() {
      this.modalJustificativaExcluirOpen = !this.modalJustificativaExcluirOpen;
    },
    getLabelPorSituacaoItem(item) {
      return getLabelPorSituacaoItem(item)
    },
    abrirModalAtividade(itemSelecionado) {
      this.itemSelecionado = itemSelecionado;
      this.$actions.setItemSelecionado(this.itemSelecionado);
      this.modalAtividadeisOpen = true;
    },
  },
  watch: {
    itens: function() {
      this.totalItens = {
        quantidade: 0,
        valorUnitario: 0,
        valorAlocado: 0,
        valorTotalRealizado: 0,
        valorExecutado: 0,
      };
      this.calcularValoresTotais();
    },
  },
  computed: {
    itemEditavel() {

      const projetoEmReformulacao = this.$store.projeto.reformulacao ? true : false;
      const projetoEmCadastro = this.$store.projeto.estadoCadastroProjeto != 'FINALIZADO';

      return projetoEmReformulacao || projetoEmCadastro;
    },
  },
};
</script>

<style lang="scss" scoped>
.title-tab {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 16px;
  text-align: center !important;
  color: $primary !important;
  background-color: #e6f4f1;
  border: 0.899715px solid rgba(97, 97, 97, 0.3);

  @media (max-width: 1366px) {
    font-size: 11px !important;
  }
}
.text-tab {
  font-family: Roboto;
  font-style: normal;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 13px;
  text-align: center;
  color: #616161;
  border: 0.899715px solid rgba(97, 97, 97, 0.3);
  padding: 15px;

  @media (max-width: 1366px) {
    font-size: 11px !important;
  }
}
.div-valor-total {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 16px;
  text-align: center !important;
  color: $primary !important;
  padding: 0px;

  @media (max-width: 1366px) {
    font-size: 11px !important;
  }
}
.text-valor-total {
  display: flex;
  align-content: center;
  justify-content: center;
  align-self: center;
  height: 45px;
  padding: 10px;
  width: 100%;
  background-color: #e6f4f1;
  text-align: center;
  border: 0.899715px solid rgba(97, 97, 97, 0.3);
  border-left: none;
  border-right: none;

  @media (max-width: 1366px) {
    font-size: 11px !important;
  }
}
.excluido {
  color: rgba(221, 75, 57, 0.5);
}
.arrow-hover :hover {
  color: $primary !important;
}
.action-hover :hover {
  color: $primary !important;
}
.status-label {
  margin: 0px;
  color: white;
  font-size: 16px;
  border-radius: 10px;
  padding: 5px;
}
.btn-text {
  display: flex;
  align-self: center;
  padding: 5px;
  margin: 0px;
}
</style>
