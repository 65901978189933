<template>
  <div class="container-div">
    <div class="header">
      <br />
    </div>
    <div>
      <v-tabs
        background-color="white"
        color="#FF8D3F"
        center-active
        centered
        grow
      >
        <v-tabs-slider></v-tabs-slider>
        <v-tab @click="currentTab = 'relatorios'" style="font-size: 18px"
          >RELATÓRIOS</v-tab
        >
        <v-tab @click="currentTab = 'ocorrencias'" style="font-size: 18px"
          >OCORRÊNCIAS</v-tab
        >
        <v-tab-item :key="currentTab + 1">
          <div style="display: flex; margin-top: 20px">
            <p class="session-title" style="width: 100%">RELATÓRIOS ENVIADOS</p>
            <div style="width: 100%; display: flex; justify-content: flex-end">
              <v-btn
                depressed
                class="btn-controls"
                color="#037F8C"
                width="280"
                @click="modalNotificarEntregaRelatorio = !modalNotificarEntregaRelatorio"
                style="margin-top: 10px"
              >
                REGISTRAR ENVIO
              </v-btn>
            </div>
          </div>
          <Relatorios />
        </v-tab-item>
        <v-tab-item :key="currentTab + 2">
          <div style="display: flex; margin-top: 20px; margin-bottom: 20px">
            <p class="session-title" style="width: 100%">
              Ocorrências {{ projeto }}
            </p>
            <div style="justify-content: flex-end; margin-left: 300px">
              <v-btn
                depressed
                class="btn-controls"
                color="#037F8C"
                width="280"
                @click="modalCrudOpen = !modalCrudOpen"
              >
                CADASTRAR OCORRÊNCIA
              </v-btn>

              <v-btn
                outlined
                width="280"
                color="#037F8C"
                style="margin-top: 10px"
              >
                EXPORTAR RELATÓRIO
              </v-btn>
            </div>
          </div>

          <div v-if="ocorrencias && ocorrencias.length">
            <v-row v-for="ocorrencia in ocorrencias" :key="ocorrencia.id">
              <v-col>
                <div>
                  <p class="ocorrencia-title">{{ ocorrencia.titulo }}</p>
                  <v-row style="display: flex; justify-content: space-between">
                    <v-col class="info-col">
                      <p
                        v-if="ocorrencia.usuarioCriacao"
                        class="ocorrencia-header-label"
                      >
                        <v-icon style="margin-right: 10px" color="#037F8C"
                          >mdi-account</v-icon
                        >
                        {{ ocorrencia.usuarioCriacao.login }}
                      </p>
                      <p
                        v-if="ocorrencia.dataEdicao"
                        class="ocorrencia-header-label"
                      >
                        <v-icon style="margin-right: 10px" color="#037F8C"
                          >mdi-calendar</v-icon
                        >
                        {{
                          new Date(ocorrencia.dataEdicao) | moment("DD/MM/YYYY")
                        }}
                      </p>
                      <p v-else class="ocorrencia-header-label">
                        <v-icon style="margin-right: 10px" color="#037F8C"
                          >mdi-calendar</v-icon
                        >
                        {{
                          new Date(ocorrencia.data + " 00:00:00")
                            | moment("DD/MM/YYYY")
                        }}
                      </p>
                    </v-col>
                    <v-col md="8" class="actions-col">
                      <v-btn
                        v-if="ocorrencia.usuarioCriacao"
                        text
                        :disabled="usuario.id != ocorrencia.usuarioCriacao.id"
                        @click="
                          (modalCrudOpen = !modalCrudOpen) &
                            (ocorrenciaSelecionada = ocorrencia)
                        "
                      >
                        <v-icon color="#F39C12">mdi-pencil-box-outline</v-icon>
                      </v-btn>
                      <v-btn
                        v-if="ocorrencia.usuarioCriacao"
                        text
                        :disabled="usuario.id != ocorrencia.usuarioCriacao.id"
                        @click="
                          (modalExcluirOcorrenciaOpen =
                            !modalExcluirOcorrenciaOpen) &
                            (ocorrenciaSelecionada = ocorrencia)
                        "
                      >
                        <v-icon color="#F39C12">mdi-delete</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <p class="ocorrencia-text">{{ ocorrencia.conteudo }}</p>
                  <div
                    style="
                      width: 100%;
                      display: flex;
                      justify-content: flex-end;
                    "
                  >
                    <v-btn
                      depressed
                      text
                      @click="
                        (modalViewMoreOpen = !modalViewMoreOpen) &
                          (ocorrenciaSelecionada = ocorrencia)
                      "
                    >
                      <p class="read-more-btn">Leia mais</p>
                    </v-btn>
                  </div>
                  <div style="width: 100%; border: 1px solid #c4c4c4" />
                  <br />
                </div>
              </v-col>
            </v-row>
          </div>
          <p v-else style="font-family: Raleway; font-size: 18px">
            Nenhuma ocorrência cadastrada para este projeto!
          </p>
        </v-tab-item>
      </v-tabs>
    </div>

    <ModalCrudOcorrencia
      :key="String(ocorrenciaSelecionada ? ocorrenciaSelecionada.id : false)"
      :modalVisible="modalCrudOpen"
      :ocorrencia="ocorrenciaSelecionada"
      @close="(modalCrudOpen = !modalCrudOpen) & (ocorrenciaSelecionada = null)"
    />
    <ModalVerOcorrencia
      v-if="ocorrenciaSelecionada"
      :modalVisible="modalViewMoreOpen"
      :ocorrencia="ocorrenciaSelecionada"
      @close="
        (modalViewMoreOpen = !modalViewMoreOpen) &
          (ocorrenciaSelecionada = null)
      "
    />
    <ModalConfirmacao
      v-if="ocorrenciaSelecionada"
      :modalVisible="modalExcluirOcorrenciaOpen"
      :titulo="'Você realmente deseja excluir a ocorrência'"
      textoBotaoDireito="Excluir"
      :onConfirm="() => deletarOcorrencia() & getData()"
      @close="
        (modalExcluirOcorrenciaOpen = !modalExcluirOcorrenciaOpen) & getData()
      "
    />
    <ModalNotificarEntregaRelatorio
      :modalVisible="modalNotificarEntregaRelatorio"
      @close="(modalNotificarEntregaRelatorio = !modalNotificarEntregaRelatorio) & getData()"
    />
  </div>
</template>

<script>
import ModalConfirmacao from "../../components/modals/ModalConfirmacao.vue";
import ModalCrudOcorrencia from "../../components/modals/ModalCrudOcorrencia.vue";
import ModalVerOcorrencia from "../../components/modals/ModalVerOcorrencia.vue";
import Relatorios from "../../components/relatorio/relatorios";
import ModalNotificarEntregaRelatorio from "../../components/modals/ModalNotificarEntregaRelatorio.vue"
import auth from "../../services/auth";
import { EventBus } from "../../services/event-bus";
import {
  excluirOcorrencia,
  getOcorrencias,
} from "../../services/providers/ocorrenciasProvider";

export default {
  name: "ocorrencias",
  components: {
    ModalCrudOcorrencia,
    ModalVerOcorrencia,
    ModalConfirmacao,
    Relatorios,
    ModalNotificarEntregaRelatorio,
  },
  data: () => ({
    modalCrudOpen: false,
    modalViewMoreOpen: false,
    modalExcluirOcorrenciaOpen: false,
    ocorrencias: [],
    ocorrenciaSelecionada: null,
    usuario: auth.getUser(),
    currentTab: "info",
    modalNotificarEntregaRelatorio: false
  }),
  mounted() {
    this.getData();
    EventBus.$on("ocorrencia-updated", () => {
      this.getData();
    });
  },
  methods: {
    async getData() {
      try {
        this.ocorrencias = await getOcorrencias(this.$store.projeto.id);
      } catch (error) {
        this.$notify({
          title: "Erro interno!",
          text: error.message || error,
          type: "error",
          duration: 15000,
        });
      }
    },
    async deletarOcorrencia() {
      if (this.ocorrenciaSelecionada) {
        try {
          await excluirOcorrencia(this.ocorrenciaSelecionada.id);
          this.$notify({
            title: "Ocorrência excluída!",
            text: "A Ocorrência foi excluída com sucesso!",
            type: "success",
            duration: 5000,
          });
        } catch (error) {
          console.error(error);
          this.$notify({
            title: "Erro!",
            text: "Erro ao excluir a ocorrência!",
            type: "error",
            duration: 15000,
          });
        }
      }
    },
  },
  computed: {
    projeto() {
      return this.$store.projeto.codigo;
    },
  },
};
</script>

<style lang="scss" scoped>
.info-col {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
.actions-col {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.ocorrencia-header-label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: justify;
  text-transform: uppercase;
  color: #037f8c;
  margin-right: 20px;
}
.ocorrencia-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  display: block;
  text-align: justify;
  color: #616161;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
  line-height: 23px;
  max-height: 92px !important;
  white-space: pre-line;
}
.ocorrencia-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  color: #037f8c;
}
.read-more-btn {
  font-family: Roboto;
  font-style: normal;
  font-size: 14px;
  color: #037f8c;
  text-transform: initial !important;
}
.session-title {
  margin: 20px;
  text-transform: uppercase;
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #037f8c;
}
</style>
