<template>
  <v-navigation-drawer
    v-if="active"
    color="#037F8C"
    app
    disable-resize-watcher
    class="drawer"
    permanent
    expand-on-hover
  >
    <v-row>
      <v-col align="center" justify="center">
        <br />
        <div style="display: flex; justify-content: center">
          <p v-if="projeto" class="drawer-title">
            {{ projeto.codigo }}
          </p>
          <div v-if="projeto && projeto.reformulacao" class="badge">
            <v-tooltip  bottom>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" color="#FF8D3F">mdi-exclamation-thick</v-icon>
              </template>
              <span>
                Reformulação
              </span>
            </v-tooltip>
          </div>
        </div>
      </v-col>
    </v-row>

    <hr style="border: 0.5px solid rgba(230, 244, 241, 0.3)" />
    <v-list dense v-if="projeto">
      <!-- Informações Gerais -->
      <v-list-item
        active-class="highlight"
        link
        :to="{ path: `/projetos-vigentes/${projeto.id}/informacoes-gerais` }"
      >
        <v-list-item-icon>
          <v-icon color="white">mdi-file</v-icon>
        </v-list-item-icon>
        <v-list-item-title>INFORMAÇÕES GERAIS</v-list-item-title>
      </v-list-item>
      <!-- Resumo Financeiro -->
      <v-list-item
        active-class="highlight"
        link
        :to="{ path: `/projetos-vigentes/${projeto.id}/financeiro` }"
      >
        <v-list-item-icon>
          <v-icon color="white">mdi-chart-bar</v-icon>
        </v-list-item-icon>
        <v-list-item-title>FINANCEIRO</v-list-item-title>
      </v-list-item>
      <!-- Cronogramas -->
      <v-list-item
        active-class="highlight"
        link
        :to="{ path: `/projetos-vigentes/${projeto.id}/cronogramas` }"
      >
        <v-list-item-icon>
          <v-icon color="white">mdi-calendar-month</v-icon>
        </v-list-item-icon>
        <v-list-item-title>CRONOGRAMAS</v-list-item-title>
      </v-list-item>
      <!-- Subprojetos -->
      <v-list-group
        v-if="subProjetos.length"
        v-model="activeSubprojetos"
        color="black"
        no-action
      >
        <template v-slot:activator>
          <v-list-item-icon>
            <v-icon color="white">mdi-view-dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-title>SUBPROJETOS</v-list-item-title>
        </template>
        
        <div class="allsubproj">
        <v-list-item
        :to="{ path: `/projetos-vigentes/${projeto.id}/subprojetos` }">
          <v-list-item-title>Ver todos</v-list-item-title>
        </v-list-item>
        </div>
        
        <div class="items">
          <v-list-item
            active-class="highlight"
            v-for="item in subProjetos"
            :key="item.id"
            link
            class="subitem"
            :to="{
              path: `/projetos-vigentes/${projeto.id}/subprojetos/${item.id}/`,
            }"
          >
            <v-list-item-title>{{ item.codigo }}</v-list-item-title>
          </v-list-item>
        </div>

      </v-list-group>
      <!-- Relatórios -->
      <v-list-item
        active-class="highlight"
        link
        :to="{ path: `/projetos-vigentes/${projeto.id}/ocorrencias` }"
      >
        <v-list-item-icon>
          <v-icon color="white">mdi-file-edit </v-icon>
        </v-list-item-icon>
        <v-list-item-title>RELATÓRIOS</v-list-item-title>
      </v-list-item>
      <!-- Metas e Atividades -->
      <v-list-item
        active-class="highlight"
        link
        :to="{ path: `/projetos-vigentes/${projeto.id}/metas` }"
      >
        <v-list-item-icon>
          <v-icon color="white">mdi-checkbox-marked-circle-outline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>METAS E ATIVIDADES</v-list-item-title>
      </v-list-item>
      <!-- Equipe -->
      <v-list-item
        active-class="highlight"
        link
        :to="{ path: `/projetos-vigentes/${projeto.id}/equipe` }"
      >
        <v-list-item-icon>
          <v-icon color="white">mdi-account-group </v-icon>
        </v-list-item-icon>
        <v-list-item-title>EQUIPE</v-list-item-title>
      </v-list-item>
      <!-- Itens -->
      <v-list-item
        active-class="highlight"
        link
        :to="{ path: `/projetos-vigentes/${projeto.id}/itens` }"
      >
        <v-list-item-icon>
          <v-icon color="white">mdi-file-table-box-multiple </v-icon>
        </v-list-item-icon>
        <v-list-item-title>ITENS</v-list-item-title>
      </v-list-item>
      <v-list-item
        active-class="highlight"
        link
        :to="{path: `/projetos-vigentes/${projeto.id}/reformulacoes`}"
      >
        <v-list-item-icon>
          <v-icon color="white">mdi-cog</v-icon>
        </v-list-item-icon>
        <v-list-item-title>REFORMULAÇÕES</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { getSubprojetosByProjetoApi } from "../services/providers/subProjetosProvider";
export default {
  name: "Drawer",
  data: () => ({
    active: false,
    activeSubprojetos: false,
    subProjetos: [],
    projeto: null,
  }),
  methods: {
    async getSubprojetos() {
      try {
        this.subProjetos = await getSubprojetosByProjetoApi(this.projeto.id);
        this.subProjetos.sort((a, b) => (a.codigo < b.codigo ? -1 : 1));
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro interno!",
          text: error.message || error,
          type: "error",
          duration: 15000,
        });
      }
    },
  },
  watch: {
    active: function(value) {
      this.$actions.setDrawerOpen(value);
      
    },
    "$store.projeto": function(value) {
      this.active = !!value;
      this.projeto = value;
      if (this.projeto) {
        this.getSubprojetos();
      }
    },
    "$store.drawerOpen": function(value) {
      this.active = !!value;
    },
  },
};
</script>

<style lang="scss" scoped>
.drawer {
  .drawer-title {
    color: $light;
    font-size: 1.1rem !important;
    font-family: $subtitle;
    font-weight: bold;
    text-transform: uppercase;
  }
  .highlight {
    background-color: #026873 !important;
    border-radius: 0px !important;
  }
  .v-list-item__title {
    font-family: $subtitle !important;
    font-size: 0.9rem !important;
    color: white;
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .v-list-group .items {
    @media (max-height: 900px) {
      max-height: 200px !important;
      overflow-y: auto !important;
      overflow-x: hidden !important;
    }
  }
  .subitem {
    padding: 0px !important;
    margin-left: 25px !important;
    .v-list-item__title {
      padding-left: 18px;
      font-size: 0.8rem !important;
    }
  }
  .allsubproj {
    padding: 0px;
    margin-left: 25px;
    color: #037f8c;
  }
  .badge {
    margin-left: 5px;
  }
}
</style>
