//pages
import ProjetosVigentes from "./pages/ProjetosVigentes";
import CadastroProjetos from "./pages/CadastroProjetos";
import ProjetosFinalizados from "./pages/ProjetosFinalizados";
import Login from "./pages/Login";
import RecuperarSenha from "./pages/RecuperarSenha";
import NotFound from "./pages/NotFound";
import Notifications from "./pages/Notifications";
import Alertas from "./pages/Alertas";

//Rotas Projeto
import GraficoFinanceiro from "./pages/projeto/GraficoFinanceiro";
import InformacoesGerais from "./pages/projeto/InformacoesGerais";
import Cronogramas from "./pages/projeto/Cronogramas";
import Subprojetos from "./pages/projeto/Subprojetos";
import Itens from "./pages/projeto/Itens";
import Ocorrencias from "./pages/projeto/Ocorrencias";
import Equipe from "./pages/projeto/Equipe";
import Metas from "./pages/projeto/Metas";
import ReformulacaoProjeto from "./pages/projeto/ReformulacaoProjeto";

//Rotas Subprojeto
import ResumoSubprojeto from "./components/subprojetos/ResumoSubprojeto";

//Rotas Item
import VerItem from "./components/itens/VerItem";

//Rotas Meta
import VerMeta from "./components/metas/VerMeta";

//Rotas Participante
import VerParticipante from "./components/equipe/VerParticipante";

//Rotas Cadastro de Projetos
import NovoCadastro from "./components/cadastroProjetos/NovoCadastro"
import EditarCadastro from "./components/cadastroProjetos/EditarCadastro"
import CadastroFinalizado from "./components/cadastroProjetos/CadastroFinalizado"


import Vue from "vue";
import VueRouter from "vue-router";
import auth from "./services/auth";
import NProgress from "nprogress";
import { getProjetoById } from "./services/providers/projetosProvider";
import { getSubprojetoById } from "./services/providers/subProjetosProvider";

const VueInstance = new Vue();

function requireAuth(to, from, next) {
  if (!auth.loggedIn()) {
    next({
      path: "/login",
      query: { redirect: to.fullPath },
    });
  } else {
    next();
  }
}

const routes = [
  { path: "/", redirect: "/projetos-vigentes" },
  {
    path: "/404",
    component: NotFound,
  },
  { path: "*", redirect: "/404" },
  {
    path: "/login",
    component: Login,
    meta: {
      guest: true,
    },
  },
  {
    path: "/recuperar-senha",
    component: RecuperarSenha,
    meta: {
      guest: true,
    },
  },
  {
    path: "/logout",
    beforeEnter(to, from, next) {
      VueInstance.$actions.setDrawerOpen(false);
      auth.logout();
      next("/login");
    },
  },
  {
    path: "/projetos-vigentes",
    component: ProjetosVigentes,
    beforeEnter: requireAuth,
    children: [
      { path: ":id/", redirect: ":id/financeiro" },
      {
        name: "financeiro",
        path: ":id/financeiro",
        component: GraficoFinanceiro,
        beforeEnter: loadProjetoInStore,
      },
      {
        name: "subprojetos",
        path: ":id/subprojetos",
        component: Subprojetos,
        beforeEnter: loadProjetoInStore,
        children: [
          { path: ":idsubprojeto/", redirect: ":idsubprojeto/resumo" },
          {
            path: ":idsubprojeto/resumo",
            name: "resumo",
            component: ResumoSubprojeto,
            beforeEnter: loadSubprojetoInStore,
          },
          {
            path: ":idsubprojeto/itens",
            name: "itens",
            component: ResumoSubprojeto,
            beforeEnter: loadSubprojetoInStore,
            children: [
              {
                path: ":iditem/",
                name: "ver-item",
                component: VerItem,
              },
            ],
          },
        ],
      },
      {
        name: "cronogramas",
        path: ":id/cronogramas",
        component: Cronogramas,
        beforeEnter: loadProjetoInStore,
      },
      {
        name: "informacoes-gerais",
        path: ":id/informacoes-gerais",
        component: InformacoesGerais,
        beforeEnter: loadProjetoInStore,
      },
      {
        name: "allitens",
        path: ":id/itens",
        component: Itens,
        beforeEnter: loadProjetoInStore,
      },
      {
        name: "ocorrencias",
        path: ":id/ocorrencias",
        component: Ocorrencias,
        beforeEnter: loadProjetoInStore,
      },
      {
        name: "equipe",
        path: ":id/equipe",
        component: Equipe,
        beforeEnter: loadProjetoInStore,
        children: [
          {
            path: ":idparticipante/",
            name: "ver-participante",
            component: VerParticipante,
          },
        ],
      },
      {
        name: "metas",
        path: ":id/metas",
        component: Metas,
        beforeEnter: loadProjetoInStore,
        children: [
          {
            path: ":idmeta/",
            name: "ver-meta",
            component: VerMeta,
          },
        ],
      },
      {
        name: "reformulacoes",
        path: ":id/reformulacoes",  
        component: ReformulacaoProjeto,
        beforeEnter: loadProjetoInStore,
      }
    ],
  },
  {
    path: "/cadastro-projetos",
    component: CadastroProjetos,
    beforeEnter: requireAuth,
    children: [
      {
        name:"novo-cadastro",
        path: "novo-cadastro",
        component: NovoCadastro,
        beforeEnter: loadProjetoInStore,
      },
      {
        name:"editar-cadastro",
        path:":id/editar-cadastro",
        component: EditarCadastro,
        beforeEnter: loadProjetoInStore,
      },
      {
        name: "cadastro-finalizado",
        path: ":id/cadastro-finalizado",
        component: CadastroFinalizado,
        beforeEnter: loadProjetoInStore,
      }
    ]
  },
  {
    path: "/projetos-finalizados",
    component: ProjetosFinalizados,
    beforeEnter: requireAuth,
  },
  {
    path: "/notificacoes",
    component: Notifications,
    beforeEnter: requireAuth,
  },
  {
    path: "/alertas",
    component: Alertas,
    beforeEnter: requireAuth,
  },
];

const router = new VueRouter({ mode: "history", routes });

/* eslint-disable-next-line */
router.beforeResolve((to, from, next) => {
  if (to.name) {
    NProgress.start();
  }
  next();
});

/* eslint-disable-next-line */
router.beforeEach(async (to, from, next) => {
  if (!to.path.includes("/projetos-vigentes/")) {
    VueInstance.$actions.setProjeto(null);
  }
  if (!to.path.includes("/subprojetos/")) {
    VueInstance.$actions.setSubProjeto(null);
  }
  next();
});

/* eslint-disable-next-line */
router.afterEach((to, from) => {
  NProgress.done();
});

async function loadProjetoInStore(to, from, next) {
  if (!VueInstance.$store.projeto && to.params.id) {
    try {
      const projeto = await getProjetoById(to.params.id);
      if (projeto) {
        VueInstance.$actions.setProjeto(projeto);
      }
    } catch (error) {
      VueInstance.$notify({
        title: "Erro interno!",
        text: error.message || error,
        type: "error",
        duration: 15000,
      });
    }
  }
  next();
}

async function loadSubprojetoInStore(to, from, next) {
  if (!VueInstance.$store.subProjeto && to.params.idsubprojeto) {
    try {
      const subprojeto = await getSubprojetoById(to.params.idsubprojeto);
      if (subprojeto) {
        VueInstance.$actions.setSubProjeto(subprojeto);
      }
    } catch (error) {
      VueInstance.$notify({
        title: "Erro interno!",
        text: error.message || error,
        type: "error",
        duration: 15000,
      });
    }
  }
  next();
}

function patchRouterMethod(router, methodName) {
  router["old" + methodName] = router[methodName];
  router[methodName] = async function (location) {
    return router["old" + methodName](location).catch((error) => {
      if (error.name === "NavigationDuplicated") {
        return this.currentRoute;
      }
      throw error;
    });
  };
}

patchRouterMethod(router, "push");
patchRouterMethod(router, "replace");

Vue.use(VueRouter);

export default router;
