import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import VueCurrencyFilter from "vue-currency-filter";
import VueLoading from "vuejs-loading-plugin";
import router from "./router";
import VCurrencyField from "v-currency-field";
import { VTextField } from "vuetify/lib";
import Notifications from "vue-notification";
import VueHtml2Canvas from "vue-html2canvas";
import VueMask from "v-mask";
import { store, actions } from "./services/store";

Vue.prototype.$store = store;
Vue.prototype.$actions = actions;

Vue.config.productionTip = false;

Vue.use(VueLoading, {
  text: "Carregando...",
  background: "transparent",
});

Vue.use(Notifications);

Vue.use(VueCurrencyFilter, {
  symbol: "R$",
  thousandsSeparator: ".",
  fractionCount: 2,
  fractionSeparator: ",",
  symbolPosition: "front",
  symbolSpacing: true,
});

Vue.use(VueHtml2Canvas);

Vue.use(require("vue-moment"));

Vue.component("v-text-field", VTextField);
Vue.use(VCurrencyField, {
  locale: "pt-BR",
  decimalLength: 2,
  autoDecimalMode: true,
  min: null,
  max: null,
  defaultValue: 0,
  valueAsInteger: false,
  allowNegative: true,
});

Vue.use(VueMask);

new Vue({
  router,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
