<template>
  <div>
    <div v-if="isExactActive" class="container-div">
      <div class="header">
        <v-btn
          class="header-button"
          large
          color="white"
          text
          @click="$router.go(-1)"
        >
          <v-icon large color="#037F8C">mdi-arrow-left-circle-outline</v-icon>
          <PageTitle :title="'<b>SUBPROJETOS</b>'" size="18" />
        </v-btn>
      </div>
      <div>
        <v-row>
          <v-col
            md="4"
            xl="3"
            sm="4"
            v-for="subProjeto in subProjetos"
            v-bind:key="subProjeto.id"
          >
            <SubProjetoCard :subProjeto="subProjeto" @reload="recarregarSubprojetos"/>
          </v-col>
        </v-row>
      </div>
    </div>
    <router-view ref="rvp"></router-view>
  </div>
</template>

<script>
import PageTitle from "../../components/PageTitle";
import SubProjetoCard from "../../components/SubProjetoCard";
import {
  getSubprojetoById,
  getSubprojetosByProjetoApi,
} from "../../services/providers/subProjetosProvider";

export default {
  name: "Subprojetos",
  components: {
    SubProjetoCard,
    PageTitle,
  },
  data: () => ({
    projeto: null,
    isExactActive: true,
    subProjetos: [],
  }),
  updated() {
    this.isExactActive = typeof this.$refs.rvp === "undefined";
  },
  created() {
    this.isExactActive = typeof this.$refs.rvp === "undefined";
  },
  async mounted() {
    await this.getData();
  },
  methods: {
    async getData() {
      try {
        this.projeto = this.$store.projeto;
        if (this.projeto) {
          this.subProjetos = await getSubprojetosByProjetoApi(this.projeto.id);
          this.subProjetos.sort((a, b) => (a.codigo < b.codigo ? -1 : 1));
        }
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro interno!",
          text: error.message || error,
          type: "error",
          duration: 15000,
        });
      }
    },
    async recarregarSubprojetos() {
      await this.getData();
    }
  },
  watch: {
    "$route.params.idsubprojeto": async function(value) {
      try {
        if (value) {
          const subprojeto = await getSubprojetoById(value);
          if (subprojeto) {
            this.$actions.setSubProjeto(subprojeto);
          }
        }
      } catch (error) {
        this.$notify({
          title: "Erro interno!",
          text: error.message || error,
          type: "error",
          duration: 15000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.breadcrumb {
  text-decoration: none;
  font-weight: bold;
  color: #f39c12;
  font-size: 20px;
  position: relative;
  top: 10px;
}
.add-item-label {
  background: #e6f4f1;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #037f8c;
  padding: 5px;
  border-radius: 10px;
  margin-top: 10px;
}
.btn-controls {
  border-radius: 8px;
  margin-right: 20px;
  color: #fff;
  font-weight: bold;
}
</style>
