<template>
  <v-container style="max-width: 1450px">
    <PageTitle :title="'<b>Painel de alertas</b>'" />
    <v-col v-for="key in Object.keys(projetosAlertas)" :key="key">
      <p class="p-title">{{ key }}</p>
      <v-row>
        <v-col
          md="3"
          xl="2"
          v-for="(alerta, idx) in projetosAlertas[key]"
          :key="idx"
        >
          <AlertaCard :alerta="alerta" />
        </v-col>
      </v-row>
    </v-col>
    <p
      v-if="projetosAlertas == {}"
      style="text-align: center; font-family: Raleway"
    >
      Nenhum alerta.
    </p>
  </v-container>
</template>

<script>
import PageTitle from "../components/PageTitle";
import { listarTodosAlertas } from "../services/providers/AlertasProvider";
import AlertaCard from "../components/AlertaCard";

export default {
  name: "Alertas",
  data: () => ({
    projetosAlertas: {},
  }),
  components: {
    PageTitle,
    AlertaCard,
  },
  async mounted() {
    try {
      const alertas = await listarTodosAlertas();
      const projetos = {};
      alertas.forEach((alerta) => {
        projetos[alerta.projeto.codigo] = [
          ...(projetos[alerta.projeto.codigo] || []),
          alerta,
        ];
      });
      this.projetosAlertas = projetos;
    } catch (error) {
      console.error(error);
      this.$notify({
        title: "Erro interno!",
        text: error.message || error,
        type: "error",
        duration: 15000,
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.p-title {
  margin: 0px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 35px;
  display: flex;
  align-items: center;
  color: #026873;
}
</style>
