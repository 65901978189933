import api from "../api";

export const getRubricas = async () => {
  const res = await api.get("/rubricas");
  return res.data;
};

export const getRubricasPorProjeto = async (idProjeto) => {
  const itensRes = await api.get(`/itens/search?projetos=${idProjeto}`);
  const itens = itensRes.data;
  let rubricasFiltered = new Set();
  if (itens) {
    const rubricasRes = await api.get(`rubricas`);
    let rubricas = rubricasRes.data;
    if (rubricas) {
      itens.forEach((item) => {
        rubricas.forEach((rubrica) => {
          if (item.rubrica.id == rubrica.id) {
            rubricasFiltered.add(rubrica);
          }
        });
      });
    }
  }
  rubricasFiltered = Array.from(rubricasFiltered);
  return rubricasFiltered;
};

export const getRubricasPorSubProjeto = async (idSubProjeto) => {
  const res = await api.get(`/subprojetos/${idSubProjeto}/rubricas`);
  return res.data;
};

export const formatItensPorRubrica = async (itens) => {
  const rubricasPorItens = [];
  if (itens) {
    const rubricasRes = await api.get(`rubricas`);
    let rubricas = rubricasRes.data;
    rubricas = rubricas.sort((a, b) =>
      parseInt(String(a.codigo).replace(".", "")) >
      parseInt(String(b.codigo).replace(".", ""))
        ? 1
        : -1
    );
    if (rubricas) {
      itens.forEach((item) => {
        rubricas.forEach((rubrica) => {
          if (item.rubrica.id == rubrica.id) {
            if (!rubricasPorItens.some((r) => r.id == rubrica.id)) {
              rubrica.itens = [item];
              rubricasPorItens.push(rubrica);
            } else {
              rubrica.itens.push(item);
            }
          }
        });
      });
    }
  }
  return rubricasPorItens;
};

export const formatItensHistoricoPorRubrica = async (itens) => {
  const rubricasPorItens = [];
  if (itens) {
    const rubricasRes = await api.get(`rubricas`);
    let rubricas = rubricasRes.data;
    rubricas = rubricas.sort((a, b) =>
      parseInt(String(a.codigo).replace(".", "")) >
      parseInt(String(b.codigo).replace(".", ""))
        ? 1
        : -1
    );
    if (rubricas) {
      itens.forEach((item) => {
        rubricas.forEach((rubrica) => {
          if (item.item.rubrica.id == rubrica.id) {
            if (!rubricasPorItens.some((r) => r.id == rubrica.id)) {
              rubrica.itens = [item];
              rubricasPorItens.push(rubrica);
            } else {
              rubrica.itens.push(item);
            }
          }
        });
      });
    }
  }
  return rubricasPorItens;
};

export const getRubricasItensSubProjeto = async (idSubProjeto) => {
  const itensRes = await api.get(
    `/itens/search?subprojetos=${idSubProjeto}&situacoes=2,4,5,6,7,8,9`
  );
  const itens = itensRes.data;
  return formatItensPorRubrica(itens);
};

export const getRubricasItensProjeto = async (idProjeto) => {
  const itensRes = await api.get(
    `/itens/search?projetos=${idProjeto}&situacoes=2,4,5,6,7,8,9`
  );
  const itens = itensRes.data;
  return formatItensPorRubrica(itens);
};
