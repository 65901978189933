<template>
  <div>
    <br />
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th rowspan="2" class="title-tab" style="width: 5%">Nº</th>
            <th rowspan="2" class="title-tab" >Nº DE OFÍCIO</th>
            <th rowspan="2" class="title-tab">DATA DO ENVIO</th>
            <th rowspan="2" class="title-tab" style="width: 30%">
              ENVIADO POR
            </th>
            <th rowspan="2" class="title-tab" style=""></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, idx) in relatorios" v-bind:key="item.id">
            <td class="text-tab">{{ idx + 1 }}</td>

            <td v-if="item.numeroOficio" class="text-tab"> {{ item.numeroOficio }} </td>
            <td v-else class="text-tab">-</td>
            
            <td v-if="item.dataExecucao" class="text-tab">
              {{new Date(item.dataExecucao).toISOString().slice(0,10).replace(/-/g,"/") | moment("DD/MM/YYYY")}}
            </td>
            <td v-else class="text-tab">-</td>

            <td v-if="item.usuarioCriacao.login" class="text-tab">
              {{ item.usuarioCriacao.login }}
            </td>
            <td v-else class="text-tab">-</td>
            <td
              class="text-tab"
              style="width: 10%; padding-left: 0px; padding-right: 0px"
            >
              <div style="display: flex; flex-direction: row">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      text
                      small
                      class="option-hover"
                      @click="(relatorioSelecionado = item) &
                        (modalNotificarEntregaRelatorio =
                          !modalNotificarEntregaRelatorio)
                      "
                    >
                      <v-icon color="#ff8d3f">mdi-pencil-box-outline</v-icon>
                    </v-btn>
                  </template>
                  <span> Editar </span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      text
                      small
                      class="option-hover"
                      @click="
                        (modalExcluirOpen = !modalExcluirOpen) &
                          (relatorioSelecionado = item)
                      "
                    >
                      <v-icon color="#ff8d3f">mdi-delete-outline</v-icon>
                    </v-btn>
                  </template>
                  <span> Excluir </span>
                </v-tooltip>
              </div>
            </td>
          </tr>
          <td colspan="6" class="div-body">
            <div
              class="text-body"
              style="border-right: 0.899715px solid rgba(97, 97, 97, 0.3)"
            >
              TOTAL ENVIADO: {{ relatorios.length }}
            </div>
          </td>
        </tbody>
      </template>
    </v-simple-table>
    <ModalNotificarEntregaRelatorio
      v-if="relatorioSelecionado"
      :modalVisible="modalNotificarEntregaRelatorio"
      :relatorioEdit="relatorioSelecionado"
      @close="
        (modalNotificarEntregaRelatorio = !modalNotificarEntregaRelatorio) &
          getData() & (relatorioSelecionado = null)
      "
    />
    <ModalConfirmacao
      v-if="relatorioSelecionado"
      :modalVisible="modalExcluirOpen"
      :texto="'Você realmente deseja excluir este registro de envio de relatório?'"
      textoBotaoDireito="Excluir"
      :onConfirm="() => excluirRelatorio() & getData()"
      @close="
        (modalExcluirOpen = !modalExcluirOpen) & getData() & (relatorioSelecionado = null)
      "
    />
  </div>
</template>

<script>
import ModalNotificarEntregaRelatorio from "../../components/modals/ModalNotificarEntregaRelatorio.vue";
import ModalConfirmacao from "../../components/modals/ModalConfirmacao.vue";
import {
  getRelatorio,
  excluirRelatorio,
} from "../../services/providers/RelatorioPrestContasProvider";
import { EventBus } from "../../services/event-bus";

export default {
  name: "RelatoriosTable",
  components: {
    ModalNotificarEntregaRelatorio,
    ModalConfirmacao,
  },
  data: () => ({
    relatorios: [],
    relatorioSelecionado: null,
    modalVerOpen: false,
    modalCrudOpen: false,
    modalExcluirOpen: false,
    modalNotificarEntregaRelatorio: false,
  }),
  mounted() {
    this.getData();
    EventBus.$on("RelatoriosTable-updated", () => {
      this.getData();
    });
  },
  methods: {
    async getData() {
      try {
        this.relatorios = await getRelatorio(this.$store.projeto.id);
      } catch (error) {
        this.$notify({
          title: "Erro interno!",
          text: error.message || error,
          type: "error",
          duration: 15000,
        });
      }
    },
    async excluirRelatorio() {
      if (this.relatorioSelecionado) {
        try {
          await excluirRelatorio(this.relatorioSelecionado.id);
          this.$notify({
            title: "Relatório excluída!",
            text: "A Relatório foi excluída com sucesso!",
            type: "success",
            duration: 5000,
          });
          this.modalExcluirOpen = false;
          this.getData();
        } catch (error) {
          console.error(error);
          this.$notify({
            title: "Erro!",
            text: "Erro ao excluir a Relatorio!",
            type: "error",
            duration: 15000,
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title-tab {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 16px;
  text-align: center !important;
  color: $primary !important;
  background-color: #e6f4f1;
  border: 0.899715px solid rgba(97, 97, 97, 0.3);
  height: 45px !important;
}
.text-tab {
  font-family: Roboto;
  font-style: normal;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 13px;
  text-align: center;
  color: #616161;
  border: 0.899715px solid rgba(97, 97, 97, 0.3);
  height: 45px !important;
}
.div-body {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 16px;
  text-align: center !important;
  color: $primary !important;
  padding: 0px;
}
.text-body {
  display: flex;
  height: 45px;
  padding: 10px;
  width: 100%;
  background-color: #e6f4f1;
  text-align: center;
  border: 0.899715px solid rgba(97, 97, 97, 0.3);
  border-left: none;
  border-right: none;
}
</style>
