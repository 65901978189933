<template>
  <div v-if="isLogged" class="container404">
    <p class="title404">404</p>
    <p class="subtitle">Não encontramos a página que você está procurando</p>
    <br />
    <br />
    <v-btn
      depressed
      @click="$router.back()"
      width="20%"
      color="#F39C12"
      style="color: #fff; border-radius: 10px"
      >VOLTAR</v-btn
    >
  </div>
  <div v-else class="container-main">
    <div class="container404" style="margin: 0%; width: 100%; height: 80%">
      <p class="title404">404</p>
      <p class="subtitle">Não encontramos a página que você está procurando</p>
      <br />
      <br />
      <v-btn
        depressed
        @click="$router.push('login')"
        width="20%"
        color="#F39C12"
        style="color: #fff; border-radius: 10px"
        >VOLTAR AO LOGIN</v-btn
      >
    </div>
    <div class="copyright">2021 Copyright Projeto Sapiencia</div>
  </div>
</template>

<script>
import Auth from "../services/auth";
export default {
  name: "NotFound",
  data: () => ({
    isLogged: Auth.loggedIn(),
  }),
};
</script>

<style lang="scss" scoped>
.container404 {
  margin: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.container-main {
  width: 100%;
  height: 100%;
  background-color: $card-background;
  background-image: url("../assets/malha.png");
  background-size: cover;
}
.title404 {
  height: 13rem;
  font-size: 170px;
  font-family: Roboto;
  font-weight: 400;
  color: #026873;
}
.subtitle {
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  color: #026873;
}
.copyright {
  background: #55b4c2;
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: #e6f4f1;
}
</style>
