<template>
  <v-dialog v-model="modalVisible" persistent max-width="900">
    <v-card style="border-radius: 10px">
      <br />
      <div style="width: 100%; display: flex; justify-content: flex-end">
        <v-btn text large @click="closeModal(false)">
          <v-icon large color="#037F8C">mdi-close-circle-outline</v-icon>
        </v-btn>
      </div>
      <v-card-title class="modal-title"
        >ITENS AGUARDANDO HOMOLOGAÇÃO</v-card-title
      >
      <v-card-text>
        <br />
        <v-row>
          <v-col>
            <v-simple-table>
              <template v-slot:default>
                <thead style="border: 1px solid black">
                  <tr>
                    <th class="title-tab"></th>
                    <th class="title-tab">STATUS</th>
                    <th class="title-tab">ITEM</th>
                    <th class="title-tab">SUB-PROJETO</th>
                    <th class="title-tab">DATA DE ALTERAÇÃO</th>
                    <th class="title-tab"></th>
                  </tr>
                </thead>
                <tbody :key="qtdSelecionados">
                  <tr
                    v-for="item in itens"
                    v-bind:key="item.id"
                    :style="
                      selecionados[item.id]
                        ? 'background-color: rgba(85, 180, 194, 0.3) !important;'
                        : null
                    "
                  >
                    <td class="text-tab">
                      <v-checkbox
                        v-model="selecionados[item.id]"
                        large
                        color="#037F8C"
                        @click="selectItem(item)"
                      />
                    </td>
                    <td class="text-tab">
                      <p
                        v-if="item && item.situacaoItem"
                        class="status-label"
                        :style="'background-color:' + setarCorPorSituacao(item)"
                      >
                        {{ item.situacaoItem.codigo }}
                      </p>
                      <p v-else>-</p>
                    </td>
                    <td class="text-tab">{{ item.descricao }}</td>
                    <td class="text-tab">{{ item.subprojeto.codigo }}</td>
                    <td v-if="item.dataEdicao" class="text-tab">
                      {{
                        new Date(item.dataEdicao) | moment("DD/MM/YYYY HH:mm")
                      }}
                    </td>
                    <td v-else class="text-tab">
                      {{
                        new Date(item.dataCriacao) | moment("DD/MM/YYYY HH:mm")
                      }}
                    </td>
                    <td class="text-tab">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-btn
                            v-on="on"
                            small
                            text
                            :to="{
                              path: `/projetos-vigentes/${$store.projeto.id}/subprojetos/${item.subprojeto.id}/itens/${item.id}`,
                            }"
                          >
                            <v-icon color="#037F8C">mdi-arrow-right-bold-circle</v-icon>
                          </v-btn>
                        </template>
                        <span>
                          Visualizar
                        </span>
                      </v-tooltip>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions
        style="
          display: flex;
          justify-content: space-evenly;
          padding-bottom: 20px;
        "
      >
        <v-btn outlined width="190" color="#FF8D3F" @click="closeModal(false)"
          >Cancelar</v-btn
        >
        <v-btn
          depressed
          width="190"
          class="btn-controls"
          color="#FF8D3F"
          @click="homologarItens"
          >Salvar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { EventBus } from "../../services/event-bus";
import { homologarItens } from "../../services/providers/itensProvider";
import { getCorPorSituacaoItem } from "../../utils/itens";

export default {
  name: "ModalHomologacaoItens",
  props: {
    itens: Array,
    modalVisible: Boolean,
  },
  data: () => ({
    selecionados: {},
    itemSelecionado: null,
    qtdSelecionados: 0,
  }),
  created() {
    this.itens.forEach((item) => {
      this.selecionados[item.id] = false;
    });
  },
  methods: {
    setarCorPorSituacao: getCorPorSituacaoItem,
    closeModal(reload) {
      this.selecionados = {};
      if (reload) {
        EventBus.$emit("item-homologated");
      }
      this.$emit("close");
    },
    async homologarItens() {
      try {
        let ids = Object.keys(this.selecionados).filter(
          (k) => this.selecionados[k]
        );
        if (ids.length) {
          const payload = {
            ids,
          };
          await homologarItens(payload);
          this.$notify({
            title: "Itens homologados!",
            text: "Os itens selecionados foram homologados com sucesso!",
            type: "success",
            duration: 5000,
          });
          this.closeModal(true);
        }
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro ao homologar itens!",
          text: error.message,
          type: "error",
          duration: 15000,
        });
      }
    },
    selectItem(item) {
      this.selecionados[item.id] = this.selecionados[item.id] ? true : false;
      this.qtdSelecionados += 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.session-title {
  color: $primary;
  font-family: Roboto;
  font-size: 20px;
}
.title-tab {
  font-family: Roboto;
  background-color: #e6f4f1;
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 15px !important;
  text-align: center !important;
  color: #037f8c !important;
  border: 0.899715px solid rgba(97, 97, 97, 0.3);
  padding: 10px !important;
}
.text-tab {
  font-family: Roboto;
  font-style: normal;
  font-size: 13px;
  line-height: 13px;
  text-align: center;
  color: #037f8c !important;
  border: 0.899715px solid rgba(97, 97, 97, 0.3);
  padding: 0px 10px 0px 10px !important;
}
.status-label {
  margin: 0px;
  color: white;
  font-size: 16px;
  border-radius: 10px;
  padding: 5px;
}
</style>
