<template>
  <div>
    <br />
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="title-tab" style="width: 5%">Nº</th>
            <th class="title-tab">META</th>
            <th class="title-tab"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, idx) in metas" v-bind:key="item.id">
            <td class="text-tab">M{{ idx + 1 }}</td>
            <td v-if="item.descricao" class="text-tab">
              {{ item.descricao }}
            </td>
            <td v-else class="text-tab">
              -
            </td>
            <td
              class="text-tab"
              style="width: 10%; padding-left: 0px; padding-right: 0px"
            >
              <div style="display: flex; flex-direction: row">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      small
                      text
                      @click="goToMeta(item)"
                      class="option-hover"
                    >
                      <v-icon color="#ff8d3f"
                        >mdi-arrow-right-bold-circle</v-icon
                      >
                    </v-btn>
                  </template>
                  <span>
                    Visualizar
                  </span>
                </v-tooltip>
                <v-tooltip top v-if="showActions">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      text
                      small
                      class="option-hover"
                      @click="
                        (modalExcluirOpen = !modalExcluirOpen) &
                          (metaSelecionada = item)
                      "
                    >
                      <v-icon color="#ff8d3f">mdi-delete-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>
                    Excluir
                  </span>
                </v-tooltip>
              </div>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <ModalConfirmacao
      v-if="metaSelecionada"
      :modalVisible="modalExcluirOpen"
      :titulo="'Você realmente deseja excluir a meta'"
      textoBotaoDireito="Excluir"
      :onConfirm="deletarMeta"
      @close="modalExcluirOpen = !modalExcluirOpen"
    />
  </div>
</template>

<script>
import ModalConfirmacao from "../modals/ModalConfirmacao.vue";
import { EventBus } from "../../services/event-bus";
import { excluirMeta } from "../../services/providers/metasProvider";

export default {
  name: "MetasTable",
  components: {
    ModalConfirmacao,
  },
  props: {
    metas: Array,
    showActions: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    metaSelecionada: null,
    modalExcluirOpen: false,
  }),
  async mounted() {},
  methods: {
    async deletarMeta() {
      try {
        await excluirMeta(this.metaSelecionada.id);
        this.$notify({
          title: "Meta exlcuída!",
          text: `Meta exlcuída com sucesso!`,
          type: "success",
          duration: 5000,
        });
        EventBus.$emit("meta-updated");
        this.modalExcluirOpen = false;
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro!",
          text: error.message,
          type: "error",
          duration: 15000,
        });
      }
    },
    goToMeta(meta) {
      if (meta) {
        this.$router.push({
          path: `/projetos-vigentes/${this.$store.projeto.id}/metas/${meta.id}/`,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.title-tab {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 16px;
  text-align: center !important;
  color: $primary !important;
  background-color: #e6f4f1;
  border: 0.899715px solid rgba(97, 97, 97, 0.3);
  height: 45px !important;
}
.text-tab {
  font-family: Roboto;
  font-style: normal;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 13px;
  text-align: center;
  color: #616161;
  border: 0.899715px solid rgba(97, 97, 97, 0.3);
  height: 45px !important;
}
.div-body {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 16px;
  text-align: center !important;
  color: $primary !important;
  padding: 0px;
}
.text-body {
  display: flex;
  height: 45px;
  padding: 10px;
  width: 100%;
  background-color: #e6f4f1;
  text-align: center;
  border: 0.899715px solid rgba(97, 97, 97, 0.3);
  border-left: none;
  border-right: none;
}
.option-hover :hover {
  color: #037F8C !important;
}
</style>
