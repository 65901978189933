export const getCorPorSituacaoItem = (item) => {
  switch (item.situacaoItem.codigo) {
    case "O":
      return "rgba(38, 153, 251, 0.5)";
    case "I":
      return "rgba(3, 127, 140, 0.5)";
    case "E":
      return "rgba(221, 75, 57, 0.5)";
    case "IP":
      return "rgba(243, 156, 18, 0.5)";
    case "OP":
      return "rgba(243, 156, 18, 0.5)";
    case "S":
      return "rgba(204, 68, 204, 0.5)";
    default:
      return "rgba(252, 75, 75, 0.5)";
  }
};

export const getLabelPorSituacaoItem = (item) => {
  switch (item.situacaoItem.codigo) {
    case "O":
      return "Original";
    case "I":
      return "Incluído";
    case "E":
      return "Excluído";
    case "IP":
      return "Incluído - Pendente de Homologação";
    case "OP":
      return "Original - Pendente de Homologação";
    case "S":
      return "Associado";
    case "EP":
      return "Excluído - Pendente de Homologação";
    default:
      return "Desconhecido";
  }
}
