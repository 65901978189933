import api from "../api";

export const listarTodosAlertas = async () => {
  const res = await api.get(`/alertas`);
  return res.data;
};

export const listarPrimeirosAlertas = async () => {
  let quantidade = 6;
  const w = window.innerWidth;
  if (w <= 1600 && w > 600) {
    quantidade = 4;
  } else if (w <= 600) {
    quantidade = 3;
  }
  const res = await api.get(`/alertas/search?quantidade=${quantidade}`);
  return res.data;
};
