<template>
  <v-dialog v-model="modalVisible" persistent max-width="600">
    <v-card class="confirm-content">
      <br />
      <v-card-title style="display: flex; justify-content: center;">
        <p v-if="titulo" style="font-weight: normal; color: #037f8c; text-align: center" class="content">
          {{ titulo }} <b>{{ item }}</b
          >?
        </p>
      </v-card-title>
      <v-card-text
        style="display: flex; justify-content: center; flex-direction: column"
      >
        <p style="font-size: 18px; text-align: center" v-html="texto"></p>
        <slot></slot>
      </v-card-text>
      <v-card-actions
        style="display: flex; justify-content: space-evenly; padding-bottom: 20px"
      >
        <v-btn
          outlined
          width="190"
          color="#FF8D3F"
          @click="closeModal"
          >{{ textoBotaoEsquerdo || "Cancelar" }}</v-btn
        >
        <v-btn
          depressed
          width="190"
          class="btn-controls"
          color="#FF8D3F"
          @click="confirm"
          >{{ textoBotaoDireito || "Confirmar" }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ModalConfirmacao",
  props: {
    modalVisible: Boolean,
    titulo: String,
    texto: String,
    item: String,
    textoBotaoDireito: String,
    textoBotaoEsquerdo: String,
    onConfirm: Function,
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    confirm() {
      this.onConfirm();
      this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-controls {
  border-radius: 8px;
  color: #fff;
}
.confirm-content {
  border: 2px solid #ff8d3f;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
}

.content {
  overflow-wrap: normal;
  line-break: strict;
  word-break: keep-all;
}
</style>
