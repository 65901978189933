<template>
  <div class="container-div">
    <div class="header">
      <div>
        <v-btn
          class="header-button"
          large
          color="white"
          text
          @click="$router.go(-1)"
        >
          <v-icon large color="#037F8C">mdi-arrow-left-circle-outline</v-icon>
          <PageTitle :title="'<b>FINANCEIRO</b>'" size="18" />
        </v-btn>
      </div>
    </div>

    <v-tabs v-model="tab"
        background-color="white"
        color="#FF8D3F"
        center-active
        centered
        grow>
      <v-tabs-slider></v-tabs-slider>
      
      <v-tab style="font-size: 18px">Financeiro</v-tab>
      <v-tab style="font-size: 18px">Rendimentos</v-tab>
      <v-tab style="font-size: 18px">Desembolsos</v-tab>

    </v-tabs>

    <v-tabs-items v-model="tab">
      <!-- Gráficos -->
      <v-tab-item>
        <div class="d-flex flex-row-reverse">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                outlined
                color="#037F8C"
                style="margin-top: 16px"
                v-bind="attrs"
                v-on="on"
                width="240"
              >
                EXPORTAR RELATÓRIO
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <v-btn text @click="openModalRelatorio(false)"
                  >RELATÓRIO DE FONTES DE RECURSO</v-btn
                >
              </v-list-item>
              <v-list-item>
                <v-btn text @click="openModalRelatorio(true)"
                  >RELATÓRIO DE HISTÓRICO DE ITEM</v-btn
                >
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div>
          <br />
          <v-row class="container-ammount">
            <v-col class="box-ammount container-ammount-active">
              <p class="title-ammount">VALOR Contratado</p>
              <p class="text-ammount" v-if="projeto && projeto.metricas">
                {{ projeto.metricas.valorAutorizado | currency }}
              </p>
              <p v-else>-</p>
            </v-col>
            <v-col class="box-ammount">
              <p class="title-ammount" style="margin-bottom: 0px">VALOR FNDCT</p>
              <p class="title-ammount-label">(alocado)</p>
              <p class="text-ammount" v-if="projeto && projeto.metricas">
                {{ projeto.metricas.valorAlocado | currency }}
              </p>
              <p v-else>-</p>
            </v-col>
            <v-col class="box-ammount">
              <p class="title-ammount">VALOR EXECUTADO FNDCT</p>
              <p class="text-ammount" v-if="projeto && projeto.metricas">
                {{ projeto.metricas.valorExecutado | currency }}
              </p>
              <p v-else>-</p>
            </v-col>
            <v-col class="box-ammount">
              <p class="title-ammount" style="margin-bottom: 0px">SALDO FNDCT</p>
              <p class="title-ammount-label">(alocado não executado)</p>
              <p class="text-ammount" v-if="projeto && projeto.metricas">
                {{ projeto.metricas.saldoNaoExecutado | currency }}
              </p>
              <p v-else>-</p>
            </v-col>
            <v-col class="box-ammount">
              <p class="title-ammount" style="margin-bottom: 0px">
                SALDO RENDIMENTO
              </p>
              <p class="title-ammount-label">(alocado não executado)</p>
              <p class="text-ammount" v-if="projeto && projeto.metricasRendimento">
                {{ projeto.metricasRendimento.saldoNaoExecutado | currency }}
              </p>
              <p v-else>-</p>
            </v-col>
          </v-row>
          <br />
          <br />
          <p class="session-title">Rubricas</p>
          <v-row>
            <v-col md="8">
              <div class="box-chart">
                <div class="download-chart">
                  <v-btn
                    depressed
                    text
                    color="#FF8D3F"
                    @click="downloadChart('plotRubricaBar')"
                  >
                    <v-icon>mdi-download</v-icon>
                  </v-btn>
                </div>
                <Chart
                  ref="plotRubricaBar"
                  v-if="tracesRubricaBar"
                  :traces="tracesRubricaBar"
                  title="<b>TOTAL EXECUTADO POR RUBRICA</b>"
                  width="800"
                  height="400"
                />
                <v-progress-circular
                  v-else
                  indeterminate
                  color="#037F8C"
                ></v-progress-circular>
              </div>
            </v-col>
            <v-col md="4">
              <div class="box-chart">
                <div class="download-chart">
                  <v-btn
                    depressed
                    text
                    color="#FF8D3F"
                    @click="downloadChart('plotRubricaPie')"
                  >
                    <v-icon>mdi-download</v-icon>
                  </v-btn>
                </div>
                <Chart
                  ref="plotRubricaPie"
                  v-if="tracesRubricaPie"
                  :traces="tracesRubricaPie"
                  title="<b>PERCENTUAL EXECUTADO <br> POR RUBRICA</b>"
                  width="450"
                  height="400"
                />
                <v-progress-circular
                  v-else
                  indeterminate
                  color="#037F8C"
                ></v-progress-circular>
              </div>
            </v-col>
          </v-row>
          <br />
          <p class="session-title">Tipo de despesas</p>
          <v-row>
            <v-col md="8">
              <div class="box-chart">
                <div class="download-chart">
                  <v-btn
                    depressed
                    text
                    color="#FF8D3F"
                    @click="downloadChart('plotTipoDespesaBar')"
                  >
                    <v-icon>mdi-download</v-icon>
                  </v-btn>
                </div>
                <Chart
                  ref="plotTipoDespesaBar"
                  v-if="tracesTipoDespesaBar"
                  :traces="tracesTipoDespesaBar"
                  title="<b>TOTAL EXECUTADO POR TIPO DE DESPESA</b>"
                  width="800"
                  height="400"
                />
                <v-progress-circular
                  v-else
                  indeterminate
                  color="#037F8C"
                ></v-progress-circular>
              </div>
            </v-col>
            <v-col md="4">
              <div class="box-chart">
                <div class="download-chart">
                  <v-btn
                    depressed
                    text
                    color="#FF8D3F"
                    @click="downloadChart('plotTipoDespesaPie')"
                  >
                    <v-icon>mdi-download</v-icon>
                  </v-btn>
                </div>
                <Chart
                  ref="plotTipoDespesaPie"
                  v-if="tracesTipoDespesaPie"
                  :traces="tracesTipoDespesaPie"
                  title="<b>PERCENTUAL EXECUTADO <br> POR TIPO DE DESPESA</b>"
                  width="450"
                  height="400"
                />
                <v-progress-circular
                  v-else
                  indeterminate
                  color="#037F8C"
                ></v-progress-circular>
              </div>
            </v-col>
          </v-row>
          <br />
          <p class="session-title">Parcelas</p>
          <v-row>
            <v-col md="8">
              <div class="box-chart">
                <div class="download-chart">
                  <v-btn
                    depressed
                    text
                    color="#FF8D3F"
                    @click="downloadChart('plotParcelaBar')"
                  >
                    <v-icon>mdi-download</v-icon>
                  </v-btn>
                </div>
                <Chart
                  ref="plotParcelaBar"
                  v-if="tracesParcelaBar"
                  :traces="tracesParcelaBar"
                  title="<b>TOTAL EXECUTADO POR PARCELA</b>"
                  width="800"
                  height="400"
                />
                <v-progress-circular
                  v-else
                  indeterminate
                  color="#037F8C"
                ></v-progress-circular>
              </div>
            </v-col>
            <v-col md="4">
              <div class="box-chart">
                <div class="download-chart">
                  <v-btn
                    depressed
                    text
                    color="#FF8D3F"
                    @click="downloadChart('plotParcelaPie')"
                  >
                    <v-icon>mdi-download</v-icon>
                  </v-btn>
                </div>
                <Chart
                  ref="plotParcelaPie"
                  v-if="tracesParcelaPie"
                  :traces="tracesParcelaPie"
                  title="<b>PERCENTUAL EXECUTADO <br> POR PARCELA</b>"
                  width="450"
                  height="400"
                />
                <v-progress-circular
                  v-else
                  indeterminate
                  color="#037F8C"
                ></v-progress-circular>
              </div>
            </v-col>
          </v-row>
          <br />
          <br />
        </div>
      </v-tab-item>
      <!-- Rendimentos -->
      <v-tab-item>
        <resumo-financeiro/>
      </v-tab-item>
      <!-- Desembolsos -->
      <v-tab-item>
        <desembolsos/>
      </v-tab-item>
    
    </v-tabs-items>

    <ModalGerarRelatorioProjeto
      v-if="projeto"
      :modalVisible="modalGerarRelatorioProjeto"
      @close="modalGerarRelatorioProjeto = !modalGerarRelatorioProjeto"
      :isHistorico="isHistorico"
    />
  </div>
</template>

<script>
import PageTitle from "../../components/PageTitle";
import Chart from "../../components/Chart";
import Plotly from "plotly.js-dist";
import { calcularExecucaoRubricaPorProjeto } from "../../services/providers/projetosProvider";
import ModalGerarRelatorioProjeto from "../../components/modals/ModalGerarRelatorioProjeto";
import ResumoFinanceiro from "./ResumoFinanceiro.vue";
import Desembolsos from "./Desembolsos.vue";

export default {
  name: "GraficoFinanceiro",
  components: {
    PageTitle,
    ModalGerarRelatorioProjeto,
    Chart,
    ResumoFinanceiro,
    Desembolsos,
  },
  data: () => ({
    tab: 0,
    projeto: null,
    modalGerarRelatorioProjeto: false,
    isHistorico: false,
    tracesParcelaBar: null,
    tracesParcelaPie: null,
    tracesRubricaBar: null,
    tracesRubricaPie: null,
    tracesFontePie: null,
    tracesTipoDespesaPie: null,
    tracesTipoDespesaBar: null,
  }),
  mounted() {
    this.loadData();
  },
  created() {
    window.scrollTo(0, 0);
  },
  watch: {
    "$store.projeto": function() {
      this.loadData();
    },
  },
  methods: {
    async loadData() {
      this.projeto = this.$store.projeto;
      if (this.projeto) {
        try {
          let rubricas = await calcularExecucaoRubricaPorProjeto(
            this.projeto.id
          );

          const parcelas = this.projeto.metricasParcela.map((k) => ({
            ...k,
            id: k.parcela.numero,
          }));

          if (rubricas) {
            let despesasCorrentesExecutado = 0;
            let despesasCapitalExecutado = 0;
            let despesasCorrentesPrevisto = 0;
            let despesasCapitalPrevisto = 0;
            rubricas.forEach((rubrica) => {
              if (rubrica.codigo.split(".")[0] == "33") {
                despesasCorrentesExecutado += rubrica.valorExecutado;
                despesasCorrentesPrevisto += rubrica.valorAlocado;
              }
              if (rubrica.codigo.split(".")[0] == "44") {
                despesasCapitalExecutado += rubrica.valorExecutado;
                despesasCapitalPrevisto += rubrica.valorAlocado;
              }
            });

            //TIPODE DESPESA
            this.tracesTipoDespesaPie = [
              {
                values: [despesasCorrentesExecutado, despesasCapitalExecutado],
                labels: ["Despesas Correntes", "Despesas de Capital"],
                type: "pie",
                hole: 0.6,
                marker: {
                  colors: ["#037F8C", "#1DA7A3", "#60C1C8"],
                },
                name: "",
                hovertemplate: "%{label} <br> R$ %{value:,.2f} <br> %{percent}",
              },
            ];
            this.tracesTipoDespesaBar = [
              {
                x: ["Despesas Correntes", "Despesas de Capital"],
                y: [despesasCorrentesPrevisto, despesasCapitalPrevisto],
                type: "bar",
                text: this.formatLabelsBarChart([
                  despesasCorrentesPrevisto,
                  despesasCapitalPrevisto,
                ]),
                textposition: "auto",
                hovertemplate: "%{label} <br> R$ %{value:,.2f}",
                marker: {
                  color: "#037F8C",
                },
                name: "Total Previsto",
              },
              {
                x: ["Despesas Correntes", "Despesas de Capital"],
                y: [despesasCorrentesExecutado, despesasCapitalExecutado],
                type: "bar",
                text: this.formatLabelsBarChart([
                  despesasCorrentesExecutado,
                  despesasCapitalExecutado,
                ]),
                textposition: "auto",
                hovertemplate: "%{label} <br> R$ %{value:,.2f}",
                marker: {
                  color: "#1DA7A3",
                },
                name: "Total Executado",
              },
            ];

            //RUBRICA
            this.tracesRubricaBar = [
              {
                x: rubricas.map((r) => r.codigo),
                y: rubricas.map((r) => parseFloat(r.valorAlocado)),
                type: "bar",
                textposition: "auto",
                hovertemplate: "%{label} <br> R$ %{value:,.2f}",
                marker: {
                  color: "#037F8C",
                },
                name: "Total Previsto",
              },
              {
                x: rubricas.map((r) => r.codigo),
                y: rubricas.map((r) => parseFloat(r.valorExecutado)),
                type: "bar",
                textposition: "auto",
                hovertemplate: "%{label} <br> R$ %{value:,.2f}",
                marker: {
                  color: "#1DA7A3",
                },
                name: "Total Executado",
              },
            ];
            this.tracesRubricaPie = [
              {
                values: rubricas
                  .filter((r) => r.valorExecutado > 0)
                  .map((r) => parseFloat(r.valorExecutado)),
                labels: rubricas
                  .filter((r) => r.valorExecutado > 0)
                  .map((r) => r.descricao),
                type: "pie",
                hole: 0.6,
                marker: {
                  colors: [
                    "#BCDBDF",
                    "#9A2C80",
                    "#EE7777",
                    "#CC44CC",
                    "#037F8C",
                    "#1DA7A3",
                    "#60C1C8",
                    "#53CDE2",
                  ],
                },
                name: "",
                hovertemplate: "%{label} <br> R$ %{value:,.2f} <br> %{percent}",
              },
            ];
          }

          //PARCELA
          if (parcelas) {
            let labels = parcelas.map((p) => "Parcela " + p.id);
            let valoresPrevistos = parcelas.map((p) => p.valorAutorizado);
            let valoresExecutados = parcelas.map((p) => p.valorExecutado);

            this.tracesParcelaBar = [
              {
                x: labels,
                y: valoresPrevistos,
                type: "bar",
                text: this.formatLabelsBarChart(valoresPrevistos),
                textposition: "auto",
                hovertemplate: "%{label} <br> R$ %{value:,.2f}",
                marker: {
                  color: "#037F8C",
                },
                name: "Total Previsto",
              },
              {
                x: labels,
                y: valoresExecutados,
                type: "bar",
                text: this.formatLabelsBarChart(valoresExecutados),
                textposition: "auto",
                hovertemplate: "%{label} <br> R$ %{value:,.2f}",
                marker: {
                  color: "#1DA7A3",
                },
                name: "Total Executado",
              },
            ];
            this.tracesParcelaPie = [
              {
                values: valoresExecutados,
                labels: labels,
                type: "pie",
                hole: 0.6,
                marker: {
                  colors: ["#037F8C", "#1DA7A3", "#60C1C8", "#53CDE2"],
                },
                name: "",
                hovertemplate: "%{label} <br> R$ %{value:,.2f} <br> %{percent}",
              },
            ];
          }
        } catch (error) {
          console.error(error);
          this.$notify({
            title: "Erro interno!",
            text: error.message || error,
            type: "error",
            duration: 15000,
          });
        }
      }
    },
    formatLabelsBarChart(arr) {
      return arr.map((value) =>
        new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(value)
      );
    },
    downloadChart(refStr) {
      let ref = this.$refs[refStr].$refs.chart;
      Plotly.downloadImage(ref, {
        format: "png",
        width: 800,
        height: 600,
        filename: Date.now(),
      });
    },
    openModalRelatorio(isHistorico) {
      this.modalGerarRelatorioProjeto = !this.modalGerarRelatorioProjeto;
      this.isHistorico = isHistorico;
    },
  },
};
</script>

<style lang="scss" scoped>
.box-ammount {
  background-color: $card-background;
  margin: 5px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 15px;
}
.session-title {
  margin: 20px;
  text-transform: uppercase;
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #037f8c;
}
.box-chart {
  background-color: $card-background;
  max-height: 410px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  > .vue-plotly {
    position: relative;
    top: -26px;
  }
}
.box-progress {
  background-color: $card-background;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.title-ammount {
  font-family: $title;
  text-transform: uppercase;
  text-align: center;
  font-size: 14px;
  color: $title-color;
  margin-bottom: 10px;
}
.title-ammount-label {
  font-family: $title;
  text-transform: uppercase;
  text-align: center;
  font-size: 10px;
  color: $title-color;
  margin-bottom: 5px;
}
.text-ammount {
  font-family: $subtitle;
  text-transform: uppercase;
  text-align: center;
  color: $title-color;
  font-size: 24px;
}
p {
  margin: 0px;
}
.container-ammount-active {
  background-color: $primary;

  .title-ammount {
    color: white;
  }

  .text-ammount {
    color: white;
  }
}
.progress_bar_desc {
  font-size: 16px;
  position: absolute;
  top: 80%;
  left: 38%;
  text-align: center;
  color: $secondary;
}
.download-chart {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  position: relative;
  top: 35px;
  z-index: 2;
}
.configAlertaBtn {
  border-radius: 15px;
  min-width: 42px !important;
  margin-right: 10px;
  margin-top: 15px;
  padding: 2px;
  color: white;
}
</style>
