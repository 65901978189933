import moment from "moment";

function generateTables(subprojeto, rubricasItens) {
    let content = [];
    rubricasItens.forEach(rubrica => {
        let title = {
            text: rubrica.descricaoCompleta || "-",
            fontSize: 12,
            bold: true,
            margin: [0, 20]
        };
        let tableHeader = [
            !subprojeto ? makeText("SP", true) : makeText("Nº", true),
            makeText("DESCRIÇÃO", true),
            makeText("PARCELA", true),
            makeText("QTD", true),
            makeText("VALOR UNIT. PREVISTO", true),
            makeText("TOTAL PREVISTO", true),
            makeText("TOTAL EXECUTADO", true),
            makeText("RECURSO FNDCT", true),
            makeText("RECURSO RENDIMENTO", true),
            makeText("SALDO DISPONÍVEL", true),
            makeText("STATUS", true),
        ];
        let table = {
            headerRows: 1,
            body: [tableHeader]
        };
        let totalItens = {
            quantidade: 0,
            valorUnitario: 0,
            valorAlocado: 0,
            valorTotalRealizado: 0,
            valorExecutado: 0,
            saldoNaoExecutado: 0,
            totalRendimento: 0,
            totalFndct: 0,
        };
        rubrica.itens.forEach((item, idx) => {
            item.totalRendimento = item.recursos
                .filter(r => (r.fonte ? r.fonte.codigo == "R" : true))
                .reduce((total, recurso) => (total += recurso.valorAlocado), 0);

            item.totalFndct = item.recursos
                .filter(r => (r.fonte ? r.fonte.codigo == "F" : true))
                .reduce((total, recurso) => (total += recurso.valorAlocado), 0);

            totalItens.quantidade += item.quantidade;
            totalItens.valorUnitario += item.valorUnitario;
            totalItens.valorAlocado += item.valorAlocado;
            totalItens.valorTotalRealizado += item.valorTotalRealizado;
            totalItens.valorExecutado += item.valorExecutado;
            totalItens.saldoNaoExecutado += item.saldoNaoExecutado;
            totalItens.totalFndct += item.totalFndct;
            totalItens.totalRendimento += item.totalRendimento;

            let bodyItem = [
                !subprojeto ? makeText(item.subprojeto.codigo || "-") : makeText(idx + 1),
                makeText(item.descricao || "-", false, true),
                makeText(item.parcela ? item.parcela.numero : "-"),
                makeText(item.quantidade || "-"),
                makeText((item.valorUnitario ? "R$ " + item.valorUnitario.toLocaleString("pt-BR") : "-")),
                makeText((item.valorAlocado ? "R$ " + item.valorAlocado.toLocaleString("pt-BR") : "-")),
                makeText((item.valorExecutado ? "R$ " + item.valorExecutado.toLocaleString("pt-BR") : "-")),
                makeText((item.totalFndct ? "R$ " + item.totalFndct.toLocaleString("pt-BR") : "-")),
                makeText((item.totalRendimento ? "R$ " + item.totalRendimento.toLocaleString("pt-BR") : "-")),
                makeText((item.saldoNaoExecutado ? "R$ " + item.saldoNaoExecutado.toLocaleString("pt-BR") : "-")),
                makeText(item.situacaoItem.codigo)
            ];
            table.body.push(bodyItem);
        });

        let footerItem = [
            "",
            "",
            makeText("TOTAL"),
            makeText(totalItens.quantidade ? totalItens.quantidade : "-"),
            makeText((totalItens.valorUnitario ? "R$ " + totalItens.valorUnitario.toLocaleString("pt-BR") : "-")),
            makeText((totalItens.valorAlocado ? "R$ " + totalItens.valorAlocado.toLocaleString("pt-BR") : "-")),
            makeText((totalItens.valorExecutado ? "R$ " + totalItens.valorExecutado.toLocaleString("pt-BR") : "-")),
            makeText((totalItens.totalFndct ? "R$ " + totalItens.totalFndct.toLocaleString("pt-BR") : "-")),
            makeText((totalItens.totalRendimento ? "R$ " + totalItens.totalRendimento.toLocaleString("pt-BR") : "-")),
            makeText((totalItens.saldoNaoExecutado ? "R$ " + totalItens.saldoNaoExecutado.toLocaleString("pt-BR") : "-")),
            ""
        ]
        table.body.push(footerItem);
        content.push({ ...title });
        content.push({
            table
        });
    });
    return content;
}

function generateFooter(rubricasItens) {
    let tableHeader = [
        makeText("RUBRICA", true),
        makeText("VALOR UNIT. PREVISTO", true),
        makeText("TOTAL PREVISTO", true),
        makeText("TOTAL EXECUTADO", true),
        makeText("RECURSO FNDCT", true),
        makeText("RECURSO RENDIMENTO", true),
        makeText("SALDO DISPONÍVEL", true)
    ];
    let table = {
        headerRows: 1,
        body: [tableHeader]
    };
    let totalRubricas = {
        valorUnitario: 0,
        valorAlocado: 0,
        valorTotalRealizado: 0,
        valorExecutado: 0,
        saldoNaoExecutado: 0,
        totalRendimento: 0,
        totalFndct: 0,
    };
    rubricasItens.forEach(rubrica => {
        let totalItens = {
            valorUnitario: 0,
            valorAlocado: 0,
            valorTotalRealizado: 0,
            valorExecutado: 0,
            saldoNaoExecutado: 0,
            totalRendimento: 0,
            totalFndct: 0,
        };
        rubrica.itens.forEach((item) => {
            item.totalRendimento = item.recursos
                .filter(r => (r.fonte ? r.fonte.codigo == "R" : true))
                .reduce((total, recurso) => (total += recurso.valorAlocado), 0);
            item.totalFndct = item.recursos
                .filter(r => (r.fonte ? r.fonte.codigo == "F" : true))
                .reduce((total, recurso) => (total += recurso.valorAlocado), 0);
            totalItens.valorUnitario += item.valorUnitario;
            totalItens.valorAlocado += item.valorAlocado;
            totalItens.valorTotalRealizado += item.valorTotalRealizado;
            totalItens.valorExecutado += item.valorExecutado;
            totalItens.saldoNaoExecutado += item.saldoNaoExecutado;
            totalItens.totalFndct += item.totalFndct;
            totalItens.totalRendimento += item.totalRendimento;
        });
        totalRubricas.valorUnitario += totalItens.valorUnitario;
        totalRubricas.valorAlocado += totalItens.valorAlocado;
        totalRubricas.valorTotalRealizado += totalItens.valorTotalRealizado;
        totalRubricas.valorExecutado += totalItens.valorExecutado;
        totalRubricas.saldoNaoExecutado += totalItens.saldoNaoExecutado;
        totalRubricas.totalFndct += totalItens.totalFndct;
        totalRubricas.totalRendimento += totalItens.totalRendimento;

        let bodyItem = [
            makeText(rubrica.descricaoCompleta || "-", false, true),
            makeText((totalItens.valorUnitario ? "R$ " + totalItens.valorUnitario.toLocaleString("pt-BR") : "-")),
            makeText((totalItens.valorAlocado ? "R$ " + totalItens.valorAlocado.toLocaleString("pt-BR") : "-")),
            makeText((totalItens.valorExecutado ? "R$ " + totalItens.valorExecutado.toLocaleString("pt-BR") : "-")),
            makeText((totalItens.totalFndct ? "R$ " + totalItens.totalFndct.toLocaleString("pt-BR") : "-")),
            makeText((totalItens.totalRendimento ? "R$ " + totalItens.totalRendimento.toLocaleString("pt-BR") : "-")),
            makeText((totalItens.saldoNaoExecutado ? "R$ " + totalItens.saldoNaoExecutado.toLocaleString("pt-BR") : "-")),
        ];
        table.body.push(bodyItem);
    });
    let footerItem = [
        makeText("TOTAL", true, false, false, true),
        makeText((totalRubricas.valorUnitario ? "R$ " + totalRubricas.valorUnitario.toLocaleString("pt-BR") : "-"), true),
        makeText((totalRubricas.valorAlocado ? "R$ " + totalRubricas.valorAlocado.toLocaleString("pt-BR") : "-"), true),
        makeText((totalRubricas.valorExecutado ? "R$ " + totalRubricas.valorExecutado.toLocaleString("pt-BR") : "-"), true),
        makeText((totalRubricas.totalFndct ? "R$ " + totalRubricas.totalFndct.toLocaleString("pt-BR") : "-"), true),
        makeText((totalRubricas.totalRendimento ? "R$ " + totalRubricas.totalRendimento.toLocaleString("pt-BR") : "-"), true),
        makeText((totalRubricas.saldoNaoExecutado ? "R$ " + totalRubricas.saldoNaoExecutado.toLocaleString("pt-BR") : "-"), true)
    ]
    table.body.push(footerItem);
    return [{ table }];
}

function makeText(value, bold = false, isDesc = false, header = false, right = false) {
    return {
        text: value,
        alignment: right ? 'right' : isDesc ? 'left' : 'center',
        fontSize: header ? 12 : 9,
        bold: bold
    }
}

function generateHeader(projeto, subprojeto, dataRef) {
    return [
        {
            layout: 'noBorders',
            table: {
                headerRows: 1,
                body: subprojeto ? [
                    [makeText("Demanda: ", true, true, true), makeText(projeto.demanda || "-", false, true, true)],
                    [makeText("Projeto: ", true, true, true), makeText((projeto.codigo + " - " + projeto.descricao) || "-", false, true, true)],
                    [makeText("Subprojeto: ", true, true, true), makeText(subprojeto.codigo || "-", false, true, true)],
                ] :
                    [
                        [makeText("Demanda: ", true, true, true), makeText(projeto.demanda || "-", false, true, true)],
                        [makeText("Projeto: ", true, true, true), makeText((projeto.codigo + " - " + projeto.descricao) || "-", false, true, true)],
                    ]
            }
        },
        {
            layout: 'noBorders',
            table: {
                headerRows: 1,
                body: [
                    [
                        makeText("Convênio: ", true, true, true),
                        makeText(projeto.convenio || "-", false, true, true),
                        makeText("Ref.: ", true, true, true),
                        makeText(projeto.referencia || "-", false, true, true),
                    ],
                ]
            }
        },
        {
            layout: 'noBorders',
            table: {
                headerRows: 1,
                body: [
                    dataRef ? [
                        makeText("Relatório referente a: ", true, true, true),
                        makeText(moment(dataRef).format("DD/MM/YYYY"), false, true, true),
                        makeText("Relatório emitido em: ", true, true, true),
                        makeText(moment().format("DD/MM/YYYY"), false, true, true),
                    ] : [
                            makeText("Relatório emitido em: ", true, true, true),
                            makeText(moment().format("DD/MM/YYYY"), false, true, true),
                        ],
                ]
            }
        },
    ]
}

export const makePDF = (projeto, subprojeto, rubricasItens, dataRef) => {
    const pdfMake = require("pdfmake/build/pdfmake.js");
    if (pdfMake.vfs == undefined) {
        const pdfFonts = require("pdfmake/build/vfs_fonts.js");
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
    }
    const docDefinition = {
        pageSize: "A4",
        pageMargins: [20, 60, 20, 60],
        pageOrientation: 'landscape',
        content: [
            {
                image: require('./logoFullBase64.json').image,
                width: 108,
                height: 82,
                alignment: 'center',
                margin: [0, 0, 0, 10]
            },
            ...generateHeader(projeto, subprojeto, dataRef),
            { text: "RELATÓRIO", fontSize: 14, alignment: 'center', margin: [0, 20] },
            ...generateTables(subprojeto, rubricasItens),
            { text: "", fontSize: 14, alignment: 'center', margin: [30, 30] },
            ...generateFooter(rubricasItens)
        ],
        defaultStyle: {
            fontSize: 12
        }
    };
    pdfMake.createPdf(docDefinition).print();
}