<template>
  <v-dialog v-model="modalVisible" persistent max-width="900">
    <v-card style="border-radius: 10px">
      <br />
      <v-card-title v-if="isEdit" class="modal-title">EDITAR META</v-card-title>
      <v-card-title v-else class="modal-title">CADASTRAR META</v-card-title>
      <v-card-text>
        <v-form ref="formMeta">
          <v-row>
            <v-col class="label-col" md="2">
              <p class="label">Subprojeto:</p>
            </v-col>
            <v-col md="10">
              <v-select
                outlined
                dense
                clearable
                class="select-form"
                color="#037F8C"
                item-color="#037F8C"
                :items="subprojetosFormatted"
                v-model="subprojeto"
                item-text="label"
                item-value="value"
                label="Selecione um subprojeto"
                :rules="[(v) => !!v || 'Selecione um subprojeto']"
                required
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="label-col" md="2">
              <p class="label">Nome:</p>
            </v-col>
            <v-col md="10">
              <v-textarea
                v-model="descricao"
                outlined
                dense
                rows="3"
                :rules="[(v) => !!v || 'Nome obrigatório']"
                required
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
        <v-card-actions
          style="display: flex; justify-content: space-evenly; padding-bottom: 20px"
        >
          <v-btn outlined width="190" color="#FF8D3F" @click="closeModal">
            Cancelar
          </v-btn>
          <v-btn
            v-if="isEdit"
            depressed
            width="190"
            class="btn-controls"
            color="#FF8D3F"
            @click="crudMeta"
          >
            Editar
          </v-btn>
          <v-btn
            v-else
            depressed
            width="190"
            class="btn-controls"
            color="#FF8D3F"
            @click="crudMeta"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { EventBus } from "../../services/event-bus";
import { getSubprojetosByProjetoApi } from "../../services/providers/subProjetosProvider";
import {
  cadastrarMeta,
  editarMeta,
} from "../../services/providers/metasProvider";

export default {
  name: "ModalCrudMetas",
  props: {
    modalVisible: Boolean,
    meta: Object,
  },
  data: () => ({
    isEdit: false,
    subprojetosFormatted: [],
    subprojeto: null,
    descricao: "",
  }),
  created() {
    this.isEdit = !!this.meta;
    if (this.meta) {
      this.subprojeto = this.meta.subprojeto.id;
      this.descricao = this.meta.descricao;
    }
  },
  async mounted() {
    const subProjetos = await getSubprojetosByProjetoApi(
      this.$store.projeto.id
    );
    this.subprojetosFormatted = subProjetos.map((sp) => ({
      label: sp.codigo,
      value: sp.id,
    }));
  },
  methods: {
    closeModal(reload) {
      this.descricao = "";
      if (reload) {
        EventBus.$emit("meta-updated");
      }
      this.$emit("close");
    },
    async crudMeta() {
      if (this.$refs.formMeta.validate()) {
        try {
          const payload = {
            subprojeto: {
              id: this.subprojeto,
            },
            descricao: this.descricao,
          };
          if (this.isEdit) {
            await editarMeta(this.meta.id, payload);
          } else {
            await cadastrarMeta(payload);
          }
          this.closeModal(true);
          this.$notify({
            title: "Meta cadastrado/editado!",
            text: "Meta cadastrado/editado com sucesso!",
            type: "success",
            duration: 5000,
          });
        } catch (error) {
          console.error(error);
          this.$notify({
            title: "Erro interno!",
            text: error.message || error,
            type: "error",
            duration: 15000,
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
