<template>
  <div>
    <p v-if="!historicos.length" style="font-family: Raleway; font-size: 18px">
      Ainda não há nenhuma modificação registrada nesse item.
    </p>
    <div v-else>
      <v-simple-table>
        <template v-slot:default>
          <thead style="border: 1px solid black">
            <tr>
              <th class="title-tab" style="width: 10%">STATUS</th>
              <th class="title-tab" style="width: 30%">ALTERAÇÃO</th>
              <th class="title-tab">JUSTIFICATIVA</th>
              <th class="title-tab" style="width: 15%">
                ALTERADO POR
              </th>
              <th class="title-tab">DATA</th>
            </tr>
          </thead>
          <tbody v-for="(historico, idx) of historicos" v-bind:key="idx">
            <tr>
              <td v-if="historico.depois.situacaoItem" class="text-tab">
                {{ String(historico.depois.situacaoItem).toUpperCase() }}
              </td>
              <td v-else class="text-tab">-</td>
              <td class="text-tab" style="width: 40%">
                <div
                  v-for="(key, idx) of Object.keys(historico.depois)"
                  v-bind:key="idx"
                >
                  <p
                    style="margin-bottom: 10px; text-align: left"
                    v-for="str in renderAlteracoesHistorico(
                      key,
                      historico.depois
                    )"
                    v-bind:key="str"
                  >
                    {{ str }}
                  </p>
                </div>
              </td>
              <td class="text-tab" style="width: 30%">
                <p class="justificativa-text">{{ historico.justificativa }}</p>
                <div
                  v-if="historico.justificativa.length > 200"
                  style="width: 100%; display: flex; margin-top: 10px"
                >
                  <v-btn
                    depressed
                    text
                    x-small
                    @click="
                      (modalOpen = !modalOpen) &
                        (justificativaSelecionada = historico.justificativa)
                    "
                  >
                    <p class="read-more-btn">Continuar lendo</p>
                  </v-btn>
                </div>
              </td>
              <td class="text-tab">
                {{
                  String(historico.usuario.login)
                    .charAt(0)
                    .toUpperCase() + String(historico.usuario.login).slice(1)
                }}
              </td>
              <td class="text-tab">
                {{ new Date(historico.data) | moment("DD/MM/YYYY HH:mm") }}
              </td>
            </tr>
          </tbody>
          <tbody v-if="historicos[historicos.length - 1]">
            <tr>
              <td
                v-if="historicos[historicos.length - 1].antes.situacaoItem"
                class="text-tab"
              >
                {{
                  String(
                    historicos[historicos.length - 1].antes.situacaoItem
                  ).toUpperCase()
                }}
              </td>
              <td v-else class="text-tab">-</td>
              <td class="text-tab" style="width: 40%">
                <div
                  v-for="(key, idx) of Object.keys(
                    historicos[historicos.length - 1].antes
                  )"
                  v-bind:key="idx"
                >
                  <p
                    style="margin-bottom: 10px; text-align: left"
                    v-for="str in renderAlteracoesHistorico(
                      key,
                      historicos[historicos.length - 1].antes
                    )"
                    v-bind:key="str"
                  >
                    {{ str }}
                  </p>
                </div>
              </td>
              <td class="text-tab" style="width: 30%"></td>
              <td class="text-tab"></td>
              <td class="text-tab"></td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
    <ModalLerJustificativaInteira
      :modalVisible="modalOpen"
      :justificativa="justificativaSelecionada"
      @close="modalOpen = !modalOpen & (justificativaSelecionada = null)"
    />
  </div>
</template>

<script>
import { listarHistoricoItem } from "../../services/providers/itensProvider";
import ModalLerJustificativaInteira from "../modals/ModalLerJustificativaInteira";
export default {
  name: "Historico",
  components: {
    ModalLerJustificativaInteira,
  },
  props: {
    itemProp: Object,
    historicoProp: Array,
  },
  data: () => ({
    item: null,
    historicos: [],
    modalOpen: false,
    justificativaSelecionada: null,
  }),
  created() {
    this.item = this.itemProp;
    if (this.historicoProp) {
      this.historicos = this.historicoProp;
    } else {
      this.getData();
    }
  },
  methods: {
    async getData() {
      try {
        this.historicos = await listarHistoricoItem(this.item.id);
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro interno!",
          text: error.message || error,
          type: "error",
          duration: 15000,
        });
      }
    },
    formatRecursoField(campo, objeto) {
      let str = "";
      if (objeto[campo].rendimento) {
        str += `Recurso de Rendimento: R$ ${objeto[
          campo
        ].rendimento.toLocaleString("pt-BR")}  ###`;
      }
      if (objeto[campo].fndct) {
        str += `Recurso FNDCT: R$ ${objeto[campo].fndct.toLocaleString(
          "pt-BR"
        )}`;
      }
      return str;
    },
    formatAlteracoesHistorico(campo, objeto) {
      switch (campo) {
        case "valorUnitario":
          return `Valor Unitário: R$ ${objeto[campo].toLocaleString("pt-BR")}`;
        case "valorAlocado":
          return `Valor alocado: R$ ${objeto[campo].toLocaleString("pt-BR")}`;
        case "valorExecutado":
          return `Valor executado: R$ ${objeto[campo].toLocaleString("pt-BR")}`;
        case "valorTotal":
          return `Valor total: R$ ${objeto[campo].toLocaleString("pt-BR")}`;
        case "recursos":
          return this.formatRecursoField(campo, objeto);
        case "situacaoItem":
          return "";
        case "descricao":
          return `Descrição:  ${objeto[campo]}`;
        default:
          return (
            String(campo)
              .charAt(0)
              .toUpperCase() +
            String(campo).slice(1) +
            ": " +
            objeto[campo]
          );
      }
    },
    renderAlteracoesHistorico(campo, objeto) {
      let str = this.formatAlteracoesHistorico(campo, objeto);
      return str.split("###");
    },
  },
};
</script>

<style lang="scss" scoped>
.title-tab {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 15px !important;
  line-height: 16px;
  text-align: center !important;
  color: #037f8c !important;
  border: 0.899715px solid rgba(97, 97, 97, 0.3);
  padding: 10px !important;
}
.text-tab {
  font-family: Roboto;
  font-style: normal;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  color: #616161 !important;
  border: 0.899715px solid rgba(97, 97, 97, 0.3);
  padding: 10px !important;
  vertical-align: middle !important;
}
.justificativa-text {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  word-wrap: break-word !important;
  line-height: 15px !important;
  max-height: 60px !important;
  white-space: pre-line !important;
  vertical-align: middle !important;
  margin: 0px;
}
.read-more-btn {
  font-family: Roboto;
  font-style: normal;
  font-size: 14px;
  color: #037f8c;
  text-transform: initial !important;
}
</style>
