<template>
  <div style="padding-left: 20px; padding-right: 20px">
    <br />
    <v-simple-table v-if="metricasSubprojetos" dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="title-tab">SUB-PROJETO</th>
            <th class="title-tab">RUBRICA</th>
            <th class="title-tab">1ª Parcela</th>
            <th class="title-tab">2ª Parcela</th>
            <th class="title-tab">3ª Parcela</th>
            <th class="title-tab">TOTAL</th>
          </tr>
        </thead>
        <tbody v-for="subproj in metricasSubprojetos" v-bind:key="subproj.id">
          <td
            v-if="subproj.rubricas"
            :rowspan="subproj.rubricas.length + 1"
            class="text-tab"
          >
            {{ subproj.codigo }}
          </td>
          <tr v-for="(rubrica, idx) in subproj.rubricas" v-bind:key="idx">
            <td class="text-tab">{{ rubrica.descricao }}</td>
            <td v-if="rubrica.parcela1" class="text-tab">
              {{ rubrica.parcela1 | currency }}
            </td>
            <td v-else class="text-tab">-</td>
            <td v-if="rubrica.parcela2" class="text-tab">
              {{ rubrica.parcela2 | currency }}
            </td>
            <td v-else class="text-tab">-</td>
            <td v-if="rubrica.parcela3" class="text-tab">
              {{ rubrica.parcela3 | currency }}
            </td>
            <td v-else class="text-tab">-</td>
            <td class="text-tab">{{ rubrica.total | currency }}</td>
          </tr>
          <td colspan="6" class="div-valor-total">
            <div class="text-valor-total">
              <p>
                TOTAL SUB-PROJETO {{ subproj.codigo }}:
                <span style="margin-left: 20px">{{
                  subproj.valorTotal | currency
                }}</span>
              </p>
            </div>
          </td>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  name: "CronogramaSubProjetoTable",
  props: {
    metricasSubprojetos: Array,
  },
  created() {
    if (this.metricasSubprojetos) {
      this.metricasSubprojetos.forEach((metrica) => {
        metrica.valorTotal = 0;
        let { metricasCronograma } = metrica;

        metricasCronograma.forEach(
          (metrica) =>
            (metrica.rubrica.codigoImportacao =
              metrica.rubrica.codigo +
              (metrica.rubrica.importado ? "-I" : "-N"))
        );

        metricasCronograma = metricasCronograma.filter((m) =>
          m.fonte ? m.fonte.codigo == "F" : true
        );

        const rubricasPorCodigo = [];

        metricasCronograma.forEach((metrica) => {
          if (
            !rubricasPorCodigo.some(
              (r) => r.codigoImportacao == metrica.rubrica.codigoImportacao
            )
          ) {
            rubricasPorCodigo.push({
              codigo: metrica.rubrica.codigo,
              codigoImportacao: metrica.rubrica.codigoImportacao,
              descricao: metrica.rubrica.descricaoCompleta,
            });
          }
        });

        rubricasPorCodigo.forEach((rubrica) => {
          rubrica.parcela1 = metricasCronograma
            .filter(
              (m) =>
                m.rubrica.codigoImportacao == rubrica.codigoImportacao &&
                m.parcela.numero == 1
            )
            .reduce((total, metrica) => (total += metrica.valorAlocado), 0);
          rubrica.parcela2 = metricasCronograma
            .filter(
              (m) =>
                m.rubrica.codigoImportacao == rubrica.codigoImportacao &&
                m.parcela.numero == 2
            )
            .reduce((total, metrica) => (total += metrica.valorAlocado), 0);
          rubrica.parcela3 = metricasCronograma
            .filter(
              (m) =>
                m.rubrica.codigoImportacao == rubrica.codigoImportacao &&
                m.parcela.numero == 3
            )
            .reduce((total, metrica) => (total += metrica.valorAlocado), 0);
          rubrica.total =
            rubrica.parcela1 + rubrica.parcela2 + rubrica.parcela3;
          metrica.valorTotal += rubrica.total;
        });

        metrica.rubricas = rubricasPorCodigo;
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.title-tab {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 16px;
  text-align: center !important;
  color: #616161 !important;
  border: 0.899715px solid rgba(97, 97, 97, 0.3);
}
.text-tab {
  font-family: Roboto;
  font-style: normal;
  text-transform: uppercase;
  line-height: 13px;
  text-align: center;
  color: #616161;
  border: 0.899715px solid rgba(97, 97, 97, 0.3);
  padding: 15px !important;
}
.div-valor-total {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 16px;
  text-align: center !important;
  color: #037f8c !important;
  padding: 0px;
}
.text-valor-total {
  height: 45px;
  width: 100%;
  background-color: #e6f4f1;
  margin-bottom: 10px;
  padding: 15px;
  padding-right: 20px;
  border: 0.899715px solid rgba(97, 97, 97, 0.3);
  text-align: right;
}
</style>
