<template>
  <div id="navbar-search">
    <v-autocomplete
      class="search-input"
      clearable
      v-model="model"
      :items="itemsComputed"
      :loading="isLoading"
      :search-input.sync="search"
      color="white"
      hide-no-data
      item-text="codigo"
      item-value="codigo"
      placeholder="Pesquisar Projetos"
      append-icon="mdi-magnify"
      return-object
    >
      <template v-slot:item="data">
        <v-list-item-content
          v-text="data.item.codigo"
          @click="goToProject(data.item.codigo)"
        ></v-list-item-content>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { buscarProjetosApi } from "../services/providers/projetosProvider";
export default {
  name: "SearchBar",
  data: () => ({
    descriptionLimit: 60,
    entries: [],
    isLoading: false,
    model: null,
    search: null,
  }),
  methods: {
    goToProject(codigo) {
      const projeto = this.entries.find((e) => e.codigo === codigo);
      if (projeto) {
        this.$actions.setProjeto(projeto);
        this.$router.push({
          name: "financeiro",
          params: { id: projeto.id, projeto: projeto },
        });
      }
    },
  },
  computed: {
    itemsComputed() {
      return this.entries.map((entry) => {
        const codigo =
          entry.codigo.length > this.descriptionLimit
            ? entry.codigo.slice(0, this.descriptionLimit) + "..."
            : entry.codigo;

        return Object.assign({}, entry, { codigo });
      });
    },
  },
  watch: {
    async search() {
      if (this.itemsComputed.length > 0) return;
      if (this.isLoading) return;
      this.isLoading = true;
      const res = await buscarProjetosApi(this.search);
      this.entries = res;
      this.count = res.length;
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
#navbar-search {
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: space-between;
  align-content: center;
  width: 40%;
  float: left;
  z-index: 999999999;
  margin-right: 20px;
}
.search-input {
  height: 26px;
}
</style>
