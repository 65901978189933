import api from "../api";

export const filtrarParticipantes = async (params) => {
  let query = Object.keys(params)
    .filter((k) => params[k])
    .map((k) => {
      if (Array.isArray(params[k])) {
        return k + "=" + params[k].join();
      } else {
        return k + "=" + params[k];
      }
    })
    .join("&");
  const res = await api.get(`/participantes/search?${query}`);
  return res.data;
};

export const getParticipantePorId = async (idParticipante) => {
  const res = await api.get(`/participantes/${idParticipante}`);
  return res.data;
};

export const cadastrarParticipante = async (payload) => {
  const res = await api.post(`/participantes`, payload);
  return res.data;
};

export const editarParticipante = async (idParticipante, payload) => {
  const res = await api.patch(`/participantes/${idParticipante}`, payload);
  return res.data;
};

export const removerParticipante = async (idParticipante) => {
  const res = await api.patch(`/participantes/remover-participante/${idParticipante}`);
  return res.data;
};

export const reativarParticipante = async (idParticipante) => {
  const res = await api.patch(`/participantes/reativar-participante/${idParticipante}`);
  return res.data;
};

export const excluirParticipante = async (idParticipante) => {
  const res = await api.delete(`/participantes/${idParticipante}`);
  return res.data;
}