<template>
  <div>
    <div class="container-div">
      <br />
      <div v-if="context === 'desembolso'">
        <v-row class="d-flex justify-end">
          <v-btn
            outlined
            color="#037F8C"
            width="240"
            @click="
              modalGerarRelatorioProjetoOpen = !modalGerarRelatorioProjetoOpen
            "
          >
            EXPORTAR RELATÓRIO
          </v-btn>
        </v-row>

        <v-row style="justify-content: flex-end">
          <v-btn
            class="btn-detalhes"
            depressed
            width="240"
            color="#037F8C"
            style="color: #fff"
            @click="changeContext('parcela')"
          >
            VER DETALHES
          </v-btn>
        </v-row>
        <v-row>
          <v-col>
            <DesembolsoCharts :key="String(reload) + 1" :projeto="projeto" />
          </v-col>
        </v-row>
        <br />
        <p class="session-title">DESEMBOLSOS SOLICITADOS</p>
        <br />
        <!-- <div v-if="desembolsos && desembolsos.length != 0">
          <v-row v-for="(key, idxkey) in Object.keys(desembolsos)" :key="key">
            <v-col>
              <v-expansion-panels style="padding: 0px !important">
                <v-expansion-panel>
                  <v-expansion-panel-header
                    class="session-title"
                    style="padding: 0px !important"
                  >
                    <p
                      style="border-bottom: 1px solid #037F8C; padding-bottom: 10px"
                    >
                      DESEMBOLSO {{ idxkey + 1 }}
                    </p>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content style="display: flex">
                    <div
                      v-for="(desembolso, idx) in desembolsos[key]"
                      :key="idx"
                    >
                      <br />
                      <v-row>
                        <v-col class="t-label-col">
                          <p class="t-label">N° de Ofício:</p>
                          <p
                            v-if="desembolso.numeroOficio"
                            class="item-t-label"
                          >
                            {{ desembolso.numeroOficio }}
                          </p>
                          <p v-else>-</p>
                        </v-col>
                        <v-col class="t-label-col">
                          <p class="t-label">Parcela:</p>
                          <p v-if="desembolso.parcela" class="item-t-label">
                            {{ desembolso.parcela.numero }}º
                          </p>
                          <p v-else>-</p>
                        </v-col>
                        <v-col class="t-label-col">
                          <p class="t-label">Data de desembolso:</p>
                          <p v-if="desembolso.data" class="item-t-label">
                            {{
                              new Date(desembolso.data + " 00:00:00")
                                | moment("DD/MM/YYYY")
                            }}
                          </p>
                          <p v-else>-</p>
                        </v-col>
                      </v-row>
                      <hr />
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </div>
        <p v-else style="font-family: Raleway; font-size: 18px">
          Nenhum desembolso solicitado para este projeto!
        </p> -->
        <desembolso-table :desembolsos="desembolsos"></desembolso-table>
      </div>
      <div v-else-if="context === 'parcela'">
        <div class="header">
          <v-btn
            class="header-button"
            large
            color="white"
            text
            @click="changeContext('desembolso')"
          >
            <v-icon color="#037f8c" large>mdi-arrow-left-circle-outline</v-icon>
            <PageTitle :title="'<b>DESEMBOLSOS</b>/PARCELA'" size="18" />
          </v-btn>
        </div>
        <ParcelaSession :projeto="projeto" />
      </div>
      <br />
    </div>
    <ModalGerarRelatorioProjeto
      v-if="projeto"
      :modalVisible="modalGerarRelatorioProjetoOpen"
      @close="modalGerarRelatorioProjetoOpen = !modalGerarRelatorioProjetoOpen"
    />
  </div>
</template>

<script>
import DesembolsoTable from "../../components/tables/DesembolsoTable.vue";
import PageTitle from "../../components/PageTitle";
import DesembolsoCharts from "../../components/DesembolsoCharts";
import ParcelaSession from "../../components/ParcelaSession";
import ModalGerarRelatorioProjeto from "../../components/modals/ModalGerarRelatorioProjeto.vue";
import { listarSolicitacoesDesembolsoPorProjeto } from "../../services/providers/desembolsoProvider";
import { EventBus } from "../../services/event-bus";
// import DesembolsoTable from "../../components/tables/DesembolsoTable.vue";

export default {
  name: "Cronogramas",
  components: {
    PageTitle,
    DesembolsoCharts,
    ParcelaSession,
    ModalGerarRelatorioProjeto,
    DesembolsoTable,
    // DesembolsoTable,
  },
  data: () => ({
    projeto: null,
    context: "desembolso",
    reload: false,
    modalGerarRelatorioProjetoOpen: false,
    modalSolicitarDesembolsoOpen: false,
    desembolsos: [],
  }),
  created() {
    this.projeto = this.$store.projeto;
  },
  mounted() {
    this.getData();
    EventBus.$on("desembolso-updated", async () => {
      await this.getData();
    });
  },
  methods: {
    changeContext(context) {
      this.context = context;
    },
    async getData() {
      try {
        const desembolsos = await listarSolicitacoesDesembolsoPorProjeto(
          this.$store.projeto.id
        );
        this.desembolsos = desembolsos;
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro interno!",
          text: error.message || error,
          type: "error",
          duration: 15000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-detalhes {
  position: absolute;
  margin-top: 1%;
  border-radius: 8px;
}
.session-title {
  color: $primary;
  font-family: Roboto;
  font-size: 20px;
}
.breadcrumb {
  text-decoration: none;
  font-weight: bold;
  color: #037f8c;
  font-size: 20px;
  position: relative;
  top: 10px;
}
.t-label {
  color: #616161;
  font-size: 18px;
  text-align: left;
  font-weight: bold;
  margin: 0px;
  margin-right: 20px;
}
.t-label-col {
  display: flex;
  justify-content: flex-start;
}
.t-label-inline {
  padding-bottom: 0px;
  padding-top: 0px;
}
.item-t-label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  display: flex;
  align-items: center;
  color: #616161;
}
.no-pd {
  padding-left: 0px;
  padding-right: 0px;
}
.no-alg {
  justify-content: flex-start;
}
hr {
  background-color: #c4c4c4 !important;
}
</style>
