<template>
  <v-dialog v-model="modalVisible" persistent max-width="900">
    <v-card style="border-radius: 10px">
      <br />
      <v-card-title class="modal-title">Editar Parcela Prevista</v-card-title>
      <v-card-text>
        <div
          style="display: flex; justify-content: flex-end; align-itens: center"
        >
          <v-tooltip left color="#FFF">
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                small
                fab
                depressed
                color="#037F8C"
                @click="addDesembolso()"
              >
                <v-icon color="#FFF">mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>
              <p class="add-desembolso-label" style="text-align: left">
                ADICIONAR RECEBIMENTO
              </p>
            </span>
          </v-tooltip>
        </div>
        <br />
        <v-row>
          <v-col class="label-col" md="3">
            <p class="label">Título:</p>
          </v-col>
          <v-col md="9">
            <v-text-field
              v-model="titulo"
              outlined
              dense
              disabled
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="label-col" md="3">
            <p class="label" style="margin-top: 0px">Previsão de desembolso:</p>
          </v-col>
          <v-col md="3">
            <DatePicker :key="data" v-model="data" disabled />
          </v-col>
          <v-col class="label-col" md="1">
            <p class="label">R$:</p>
          </v-col>
          <v-col md="5">
            <v-currency-field
              prefix="R$"
              v-model="valor"
              outlined
              dense
              disabled
            ></v-currency-field>
          </v-col>
        </v-row>
        <v-row v-for="(desembolso, idx) in desembolsosData" v-bind:key="idx">
          <v-col class="label-col" md="3">
            <div>
              <p class="label">
                {{ idx + 1 }}/{{ desembolsos.length }} desembolsado em:
              </p>
            </div>
          </v-col>
          <v-col md="3">
            <DatePicker
              v-model="desembolso.dataExecucao"
              @change="(val) => markDesembolsoModified(idx, val, 'date')"
            />
          </v-col>
          <v-col class="label-col" md="1">
            <p class="label">R$:</p>
          </v-col>
          <v-col>
            <v-currency-field
              prefix="R$"
              v-model="desembolso.valor"
              outlined
              dense
              @change="(val) => markDesembolsoModified(idx, val, 'currency')"
            ></v-currency-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions
        style="
          display: flex;
          justify-content: space-evenly;
          padding-bottom: 20px;
        "
      >
        <v-btn outlined width="190" color="#FF8D3F" @click="closeModal(false)"
          >Cancelar</v-btn
        >
        <v-btn
          depressed
          width="190"
          class="btn-controls"
          color="#FF8D3F"
          @click="atualizarDesembolsos"
          >Salvar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { EventBus } from "../../services/event-bus";
import {
  cadastrarDesembolso,
  editarDesembolso,
} from "../../services/providers/desembolsoProvider";
import { convertCurrencyToNumber } from "../../utils/numbers";
import DatePicker from "../DatePicker";

export default {
  name: "ModalEditarParcela",
  props: {
    parcela: Object,
    modalVisible: Boolean,
    desembolsos: Array,
  },
  components: {
    DatePicker,
  },
  data: (vm) => ({
    titulo: "",
    valor: 0,
    data: null,
    desembolsosData: JSON.parse(JSON.stringify(vm.desembolsos)),
    length: vm.desembolsos.length,
  }),
  async mounted() {
    if (this.parcela) {
      this.titulo = this.parcela.numero + "ª Parcela";
      this.valor = this.parcela.valorAutorizado;
      this.data = this.parcela.dataPrevisao;
      this.desembolsosData.forEach((d) => {
        d.valorOriginal = d.valor;
        d.dataOriginal = d.dataExecucao;
        d.valor = parseFloat(d.valor).toString();
        d.haveModify = false;
      });
    }
  },
  methods: {
    closeModal(reload) {
      this.desembolsosData = [];
      if (reload) {
        EventBus.$emit("desembolso-updated");
      }
      this.$emit("close");
    },
    markDesembolsoModified(idx, val, type) {
      let haveModify = false;
      if (type == "currency") {
        if (
          this.desembolsosData[idx].valorOriginal !=
          convertCurrencyToNumber(val)
        ) {
          haveModify = true;
        }
      } else {
        if (this.desembolsosData[idx].dataOriginal != val) {
          haveModify = true;
        }
      }
      let desembolso = JSON.parse(JSON.stringify(this.desembolsosData));
      desembolso[idx].haveModify = haveModify;
      this.desembolsosData = desembolso;
    },
    addDesembolso() {
      if (this.desembolsosData.length - this.length < 1) {
        this.desembolsosData.push({
          editable: true,
          haveModify: true,
          isNew: true,
          dataExecucao: new Date().toISOString().substr(0, 10),
          valor: 0,
        });
      }
    },
    async atualizarDesembolsos() {
      try {
        const desembolsoNovo = this.desembolsosData.find((d) => d.isNew);
        const desembolsosAtualizados = this.desembolsosData.filter(
          (d) => !d.isNew && d.haveModify
        );
        if (desembolsosAtualizados && desembolsosAtualizados.length) {
          await Promise.all(
            desembolsosAtualizados.map(async (desembolso) => {
              await editarDesembolso({
                id: desembolso.id,
                parcelaId: this.parcela.id,
                dataExecucao: desembolso.dataExecucao.split(" ")[0],
                valor: desembolso.valor,
              });
            })
          );
          this.$notify({
            title: "Desembolsos atualizados!",
            text: "Os Desembolsos foram atualizados com sucesso!",
            type: "success",
            duration: 5000,
          });
        }
        if (desembolsoNovo) {
          const res = await cadastrarDesembolso({
            parcelaId: this.parcela.id,
            dataExecucao: desembolsoNovo.dataExecucao.split(" ")[0],
            valor: desembolsoNovo.valor,
          });
          if (res) {
            this.$notify({
              title: "Desembolso cadastrado!",
              text: "O Desembolso foi cadastrado com sucesso!",
              type: "success",
              duration: 5000,
            });
          }
          this.closeModal(true);
        }
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro interno!",
          text: error.message || error,
          type: "error",
          duration: 15000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.add-desembolso-label {
  background: #e6f4f1;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #037f8c;
  padding: 5px;
  border-radius: 10px;
  margin-right: 5px;
  margin-top: 10px;
}
</style>
