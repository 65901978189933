var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","scrollable":"","max-width":"1000"},model:{value:(_vm.modalVisible),callback:function ($$v) {_vm.modalVisible=$$v},expression:"modalVisible"}},[_c('v-form',{ref:"formEditarItem"},[_c('v-card',{staticStyle:{"border-radius":"10px"}},[_c('v-card-title',{staticClass:"modal-title"},[_vm._v("EDITAR RECURSOS PREVISTOS")]),(_vm.item)?_c('v-card-text',{staticStyle:{"padding-left":"30px"}},[_c('v-row',[_c('v-col',[_c('v-col',{staticClass:"box-ammount"},[_c('p',{staticClass:"title-ammount"},[_vm._v(" Saldo FNDCT não alocado: ")]),_c('p',{staticClass:"text-ammount"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.saldoRemanejavel))+" ")])])],1),_c('v-col',[_c('v-col',{staticClass:"box-ammount"},[_c('p',{staticClass:"title-ammount"},[_vm._v(" Saldo RENDIMENTO não alocado: ")]),_c('p',{staticClass:"text-ammount"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.saldoRemanejavelRendimento))+" ")])])],1)],1),_c('br'),_c('p',{staticClass:"saldo-title",staticStyle:{"text-align":"left"}},[_vm._v(" DESPESA PREVISTA ")]),_c('br'),_c('p',{staticClass:"saldo-title",staticStyle:{"text-align":"center","font-size":"17px"}},[_vm._v(" Valor total do item: "),_c('span',{staticStyle:{"font-weight":"normal"}},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.valorTotalItem))+" ")])]),_c('br'),_c('v-row',[_c('v-col',{staticClass:"label-col",attrs:{"md":"2"}},[_c('p',{staticClass:"label"},[_vm._v("Quantidade:")])]),_c('v-col',{attrs:{"md":"4"}},[_c('v-text-field',{attrs:{"type":"number","min":"1","outlined":"","dense":"","rules":[
                function (v) { return !!v || 'Quantidade é obrigatória'; },
                function (v) { return !(parseFloat(v) <= 0) ||
                  'Quantidade deve ser maior que zero'; } ],"required":""},on:{"blur":_vm.validarCamposRecurso},model:{value:(_vm.form.quantidade),callback:function ($$v) {_vm.$set(_vm.form, "quantidade", $$v)},expression:"form.quantidade"}})],1),_c('v-col',{staticClass:"label-col",attrs:{"md":"2"}},[_c('p',{staticClass:"label"},[_vm._v("Valor unitário:")])]),_c('v-col',{attrs:{"md":"4"}},[_c('v-currency-field',{attrs:{"prefix":"R$","outlined":"","dense":"","rules":[
                function (v) { return !!v || 'Valor Unitario é obrigatório'; },
                function (v) { return !(parseFloat(String(v).replace(',', '.')) <= 0) ||
                  'Valor Unitario deve ser maior que zero'; } ],"required":""},on:{"blur":_vm.validarCamposRecurso},model:{value:(_vm.form.valorUnitario),callback:function ($$v) {_vm.$set(_vm.form, "valorUnitario", $$v)},expression:"form.valorUnitario"}}),_c('div',{staticStyle:{"width":"100%","display":"flex","justify-content":"flex-end"}},[_c('v-btn',{staticClass:"btn-link",attrs:{"text":"","dense":"","disabled":!_vm.form.valorUnitario},on:{"click":_vm.simularRecursos}},[_vm._v(" simular recursos ")])],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"label-col",attrs:{"md":"2"}},[_c('p',{staticClass:"label"},[_vm._v("Total FNDCT do ITEM:")])]),_c('v-col',{attrs:{"md":"3"}},[_c('v-currency-field',{attrs:{"prefix":"R$","outlined":"","dense":"","rules":[
                function (v) { return _vm.validarValoresRecursos(v, _vm.totalRendimentoItem) ||
                  'Os valores dos recursos devem ser iguais ao valor total do item'; } ],"required":""},model:{value:(_vm.totalFndctItem),callback:function ($$v) {_vm.totalFndctItem=$$v},expression:"totalFndctItem"}})],1),_c('v-col',{staticClass:"label-col",attrs:{"md":"3"}},[_c('p',{staticClass:"label"},[_vm._v("Total RENDIMENTO do ITEM:")])]),_c('v-col',{attrs:{"md":"4"}},[_c('v-currency-field',{attrs:{"prefix":"R$","outlined":"","dense":"","rules":[
                function (v) { return _vm.validarValoresRecursos(v, _vm.totalFndctItem) ||
                  'Os valores dos recursos devem ser iguais ao valor total do item'; } ],"required":""},model:{value:(_vm.totalRendimentoItem),callback:function ($$v) {_vm.totalRendimentoItem=$$v},expression:"totalRendimentoItem"}})],1)],1)],1):_vm._e(),_c('v-card-actions',{staticClass:"modal-footer"},[_c('v-btn',{attrs:{"outlined":"","width":"190","color":"#FF8D3F"},on:{"click":function($event){return _vm.closeModal(false)}}},[_vm._v("Cancelar")]),_c('v-btn',{staticClass:"btn-controls",attrs:{"depressed":"","width":"190","color":"#FF8D3F"},on:{"click":_vm.validarForm}},[_vm._v("Salvar")])],1)],1)],1),(_vm.item)?_c('ModalConfirmacao',{attrs:{"modalVisible":_vm.modalConfirmarAlteracao,"titulo":'CONFIRMAR ALOCAÇÃO DE RECURSOS',"texto":("Você realmente deseja salvar o item <b>" + (_vm.item.descricao) + "</b> com os recursos alocados da seguinte maneira:"),"textoBotaoDireito":"Confirmar","onConfirm":function () { return (_vm.modalJustificativaOpen = true) & _vm.closeModalConfirmacao(); }},on:{"close":_vm.closeModalConfirmacao}},[_c('v-row',[_c('v-col',[_c('v-col',{staticClass:"box-ammount"},[_c('p',{staticClass:"title-ammount"},[_vm._v(" TOTAL FNDCT DO ITEM ")]),_c('p',{staticClass:"text-ammount"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.totalFndctItem))+" ")])])],1),_c('v-col',[_c('v-col',{staticClass:"box-ammount"},[_c('p',{staticClass:"title-ammount"},[_vm._v(" TOTAL RENDIMENTO DO ITEM ")]),_c('p',{staticClass:"text-ammount"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.totalRendimentoItem))+" ")])])],1)],1)],1):_vm._e(),_c('ModalJustificativa',{attrs:{"modalVisible":_vm.modalJustificativaOpen},on:{"close":_vm.closeModalJustificativa,"confirm":_vm.editarItem}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }