<template>
  <v-dialog v-model="modalVisible" persistent max-width="1100">
    <v-card style="border-radius: 10px">
      <br />
      <div style="width: 100%; display: flex; justify-content: flex-end">
        <v-btn text large @click="closeModal()">
          <v-icon large color="#037F8C">mdi-close-circle-outline</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <br />
        <p class="session-title">{{ ocorrencia.titulo }}</p>
        <br />
        <br />
        <v-row>
          <v-col class="t-label-col">
            <v-icon style="margin-right: 10px" color="#616161"
              >mdi-account</v-icon
            >
            <p class="t-label" style="margin-right: 10px">Criado por:</p>
            <p
              v-if="ocorrencia && ocorrencia.usuarioCriacao"
              class="ocorrencia-t-label"
            >
              {{ ocorrencia.usuarioCriacao.login }}
            </p>
            <p v-else>-</p>
          </v-col>
        </v-row>
        <hr />
        <v-row>
          <v-col class="t-label-col">
            <v-icon style="margin-right: 10px" color="#616161"
              >mdi-calendar</v-icon
            >
            <p v-if="ocorrencia && ocorrencia.data" class="ocorrencia-t-label">
              {{
                new Date(ocorrencia.data + " 00:00:00") | moment("DD/MM/YYYY")
              }}
            </p>
            <p v-else>-</p>
          </v-col>
        </v-row>
        <hr />
        <br /><br />
        <p v-if="ocorrencia && ocorrencia.conteudo" class="ocorrencia-t-label">
          {{ `${ocorrencia.conteudo}` }}
        </p>
        <br />
        <br />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ModalVerOcorrencia",
  props: {
    modalVisible: Boolean,
    ocorrencia: Object,
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-title {
  font-size: 24px !important;
  text-transform: uppercase;
  color: $primary;
  font-weight: 500;
}
.session-title {
  text-align: center !important;
  font-size: 24px !important;
  text-transform: uppercase;
  color: $primary;
  font-weight: 500;
}
.t-label {
  color: #616161;
  font-size: 18px;
  text-align: left;
  font-weight: bold;
  margin: 0px;
}
.t-label-col {
  display: flex;
  justify-content: flex-start;
}
.ocorrencia-t-label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #616161;
  text-align: justify;
  white-space: pre-line;
}
hr {
  background-color: #c4c4c4 !important;
}
p {
  margin: 0px;
}
</style>
