<template>
  <div class="no-bg">
    <div>
      <p class="title">CADASTRO DE PROJETO</p>
    </div>
    <div>
      <v-container id="container" class="mt-5 mb-16">
        <v-row id="navi" style="margin-top: 25px">
          <v-col>
            <div
              style="height: 0px; border: 1px solid #FF8D3F; width=100%; margin-right: 7%; margin-left: 7%"
            ></div>
          </v-col>
        </v-row>
        <v-row id="infoi" style="text-align: center">
          <v-col cols="2">
            <v-btn
              small
              outlined
              color="#FF8D3F"
              @click="currentTab = 0"
              width="50"
              height="50"
              :class="{
                'btn-design': currentTab != 0,
                'btn-design-active': currentTab == 0,
              }"
            >
              <span
                :class="{
                  'btn-text': currentTab != 0,
                  'btn-text-active': currentTab == 0,
                }"
              >
                1
              </span>
            </v-btn>
            <br />
            <span class="btn-footer"> Dados do projeto </span>
          </v-col>
          <v-col cols="2">
            <v-btn
              small
              outlined
              color="#FF8D3F"
              @click="currentTab = 1"
              width="50"
              height="50"
              :class="{
                'btn-design': currentTab != 1,
                'btn-design-active': currentTab == 1,
              }"
            >
              <span
                :class="{
                  'btn-text': currentTab != 1,
                  'btn-text-active': currentTab == 1,
                }"
              >
                2
              </span>
            </v-btn>
            <br />
            <span class="btn-footer"> Parcelas </span>
          </v-col>
          <v-col cols="2">
            <v-btn
              small
              outlined
              color="#FF8D3F"
              @click="currentTab = 2"
              width="50"
              height="50"
              :class="{
                'btn-design': currentTab != 2,
                'btn-design-active': currentTab == 2,
              }"
            >
              <span
                :class="{
                  'btn-text': currentTab != 2,
                  'btn-text-active': currentTab == 2,
                }"
              >
                3
              </span>
            </v-btn>
            <br />
            <span class="btn-footer"> Subprojetos </span>
          </v-col>
          <v-col cols="2">
            <v-btn
              small
              outlined
              color="#FF8D3F"
              @click="currentTab = 3"
              width="50"
              height="50"
              :class="{
                'btn-design': currentTab != 3,
                'btn-design-active': currentTab == 3,
              }"
            >
              <span
                :class="{
                  'btn-text': currentTab != 3,
                  'btn-text-active': currentTab == 3,
                }"
              >
                4
              </span>
            </v-btn>
            <br />
            <span class="btn-footer"> Metas e Atividades </span>
          </v-col>
          <v-col cols="2">
            <v-btn
              small
              outlined
              color="#FF8D3F"
              @click="currentTab = 4"
              width="50"
              height="50"
              :class="{
                'btn-design': currentTab != 4,
                'btn-design-active': currentTab == 4,
              }"
            >
              <span
                :class="{
                  'btn-text': currentTab != 4,
                  'btn-text-active': currentTab == 4,
                }"
              >
                5
              </span>
            </v-btn>
            <br />
            <span class="btn-footer"> Equipe </span>
          </v-col>
          <v-col cols="2">
            <v-btn
              small
              outlined
              color="#FF8D3F"
              @click="currentTab = 5"
              width="50"
              height="50"
              :class="{
                'btn-design': currentTab != 5,
                'btn-design-active': currentTab == 5,
              }"
            >
              <span
                :class="{
                  'btn-text': currentTab != 5,
                  'btn-text-active': currentTab == 5,
                }"
              >
                6
              </span>
            </v-btn>
            <br />
            <span class="btn-footer"> Itens </span>
          </v-col>
        </v-row>
      </v-container>

      <div v-if="currentTab == 0">
        <v-card-text style="padding-left: 50px; height: 80px">
          <br />
          <v-row>
            <v-col class="label-col" md="2">
              <p class="label">* Título:</p>
            </v-col>
            <v-col md="8">
              <v-text-field
                v-model="titulo"
                outlined
                dense
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text style="padding-left: 50px; height: 80px">
          <br />
          <v-row>
            <v-col class="label-col" md="2">
              <p class="label">* Código:</p>
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="codigo"
                outlined
                dense
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text style="padding-left: 50px; height: 80px">
          <br />
          <v-row>
            <v-col class="label-col" md="2">
              <p class="label">* Demanda:</p>
            </v-col>
            <v-col md="8">
              <v-text-field
                v-model="demanda"
                outlined
                dense
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text style="padding-left: 50px; height: 80px">
          <br />
          <v-row>
            <v-col class="label-col" md="2">
              <p class="label">* Convênio:</p>
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="convenio"
                outlined
                dense
                required
                v-mask="'############################'"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text style="padding-left: 50px; height: 80px">
          <br />
          <v-row>
            <v-col class="label-col" md="2">
              <p class="label">* Referência:</p>
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="referencia"
                outlined
                dense
                required
                v-mask="'############################'"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text style="padding-left: 50px; height: 80px">
          <br />
          <v-row>
            <v-col class="label-col" md="2">
              <p class="label">* SIG Fundação:</p>
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="sigFundacao"
                outlined
                dense
                required
                v-mask="'############################'"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text style="padding-left: 50px; height: 80px">
          <br />
          <v-row>
            <v-col class="label-col" md="2">
              <p class="label">* Vigência:</p>
            </v-col>
            <v-col md="4">
              <DatePicker v-model="inicioVigencia" />
            </v-col>
            <v-col md="4">
              <DatePicker v-model="fimVigencia" label="fim" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text style="padding-left: 50px; height: 80px">
          <br />
          <v-row>
            <v-col class="label-col" md="2">
              <p class="label">* Valor Total do Projeto:</p>
            </v-col>
            <v-col cols="3">
              <v-currency-field
                v-model="valorTotal"
                outlined
                dense
                required
                prefix="R$"
              ></v-currency-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="modal-footer" style="padding-top: 45px">
          <v-btn outlined width="190" color="#FF8D3F" @click="voltarNavegacao">
            Cancelar
          </v-btn>
          <v-btn
            depressed
            width="190"
            class="btn-controls"
            color="#FF8D3F"
            @click="isEditDadosGerais"
          >
            Avançar
          </v-btn>
        </v-card-actions>
      </div>
      <div v-if="currentTab == 1">
        <div class="content-title">
          <h1>Valor Total do Projeto:</h1>
          <h2 style="color: #1da7a3; padding-bottom: 25px">
            {{ valorTotal | currency }}
          </h2>
          <v-row class="cards-div">
            <v-col class="container-main">
              <h3 style="padding-bottom: 20px; padding-left: 30px">
                1ª PARCELA
              </h3>
              <v-row>
                <label class="label-text" style="padding-left: 60px;">Valor:</label>
                <v-currency-field
                  v-model="valorParcela1"
                  outlined
                  dense
                  required
                  @click="edit1Possible"
                  prefix="R$"
                  style="padding-left: 50px; padding-right: 50px"
                ></v-currency-field>
              </v-row>
              <v-row style="padding-left: 50px; padding-right: 50px">
                <label class="label-text">Data prevista de <br>desembolso:</label>
                <DatePicker v-model="dataParcela1" />
              </v-row>
            </v-col>
            <v-col class="container-main">
              <h3 style="padding-bottom: 20px; padding-left: 30px">
                2ª PARCELA
              </h3>
              <v-row>
                <label class="label-text" style="padding-left: 60px;">Valor:</label>
                <v-currency-field
                  v-model="valorParcela2"
                  outlined
                  dense
                  required
                  prefix="R$"
                  @click="edit2Possible"
                  style="padding-left: 50px; padding-right: 50px"
                ></v-currency-field>
              </v-row>
              <v-row style="padding-left: 50px; padding-right: 50px">
                <label class="label-text">Data prevista de <br>desembolso:</label>
                <DatePicker v-model="dataParcela2" />
              </v-row>
            </v-col>
            <v-col class="container-main">
              <h3 style="padding-bottom: 20px; padding-left: 30px">
                3ª PARCELA
              </h3>
              <v-row>
                <label class="label-text" style="padding-left: 60px;">Valor:</label>
                <v-currency-field
                  v-model="valorParcela3"
                  outlined
                  dense
                  required
                  prefix="R$"
                  @click="edit3Possible"
                  style="padding-left: 50px; padding-right: 50px"
                ></v-currency-field>
              </v-row>
              <v-row style="padding-left: 50px; padding-right: 50px">
                <label class="label-text">Data prevista de<br>desembolso:</label>
                <DatePicker v-model="dataParcela3" />
              </v-row>
            </v-col>
          </v-row>
          <v-card-actions class="modal-footer" style="padding-top: 45px">
            <v-btn outlined width="190" color="#FF8D3F" @click="voltarAba">
              VOLTAR
            </v-btn>
            <v-btn
              depressed
              width="190"
              class="btn-controls"
              color="#FF8D3F"
              @click="cadastrarParcelas"
            >
              Avançar
            </v-btn>
          </v-card-actions>
        </div>
      </div>
      <div v-if="currentTab == 2">
        <div
          v-for="(subprojeto, index) in subprojetos"
          :key="index"
          style="padding-bottom: 128px"
        >
          <v-row>
            <h3
              style="
                padding-top: 50px;
                padding-left: 60px;
                padding-bottom: 10px;
                color: #026873;
              "
            >
              SUBPROJETO {{ index + 1 }}
            </h3>
          </v-row>
          <v-card-text
            style="
              padding-left: 50px;
              height: 40px;
              padding-top: 0px;
              padding-bottom: 0px;
            "
          >
            <br />
            <v-row>
              <v-col class="label-col" md="2">
                <p class="label">Sigla:</p>
              </v-col>
              <v-col md="3">
                <v-text-field
                  v-model="subprojeto.codigo"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="label-col" md="2">
                <p class="label">Título:</p>
              </v-col>
              <v-col md="8">
                <v-text-field
                  v-model="subprojeto.descricao"
                  outlined
                  dense
                ></v-text-field>
              </v-col>
              <v-col v-if="index > 0" class="label-col" md="1">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      small
                      text
                      @click="deletarSubprojeto(subprojeto.id, index)"
                      class="option-hover"
                    >
                      <v-icon color="#ff8d3f" size="35"
                        >mdi-delete-outline</v-icon
                      >
                    </v-btn>
                  </template>
                  <span> REMOVER SUBPROJETO </span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-card-text>
        </div>
        <br />
        <v-row class="pt-2">
          <v-col offset="2" style="padding-left: 50px;">
            <a @click="adicionarSubprojeto" style="color: #026873">
              <v-icon color="#026873">mdi-plus</v-icon>
              Adicionar outro
            </a>
          </v-col>
        </v-row>
        <v-card-actions class="modal-footer" style="padding-top: 45px">
          <v-btn outlined width="190" color="#FF8D3F" @click="voltarAba">
            VOLTAR
          </v-btn>
          <v-btn
            depressed
            width="190"
            class="btn-controls"
            color="#FF8D3F"
            @click="cadastroSubprojeto"
          >
            Avançar
          </v-btn>
        </v-card-actions>
      </div>
      <div v-if="currentTab == 3">
        <v-container>
          <v-row>
            <v-col cols="6" offset="3">
              <v-img
                style="margin-left: auto; margin-right: auto"
                max-height="500PX"
                max-width="500PX"
                src="../../assets/metas-atividades.png"
              >
              </v-img>
              <v-card-actions class="modal-footer">
                <v-btn
                  outlined
                  width="50%"
                  height="80px"
                  color="#FF8D3F"
                  @click="goToMetas"
                >
                  IR PARA O CADASTRO DE<br />
                  METAS E ATIVIDADES
                </v-btn>
                <v-btn
                  depressed
                  width="50%"
                  height="80px"
                  class="btn-controls"
                  color="#FF8D3F"
                  @click="avancarAba"
                >
                  CONTINUAR O<br />CADASTRO DE PROJETO
                </v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div v-if="currentTab == 4">
        <v-container>
          <v-row>
            <v-col cols="6" offset="3">
              <v-img
                style="margin-left: auto; margin-right: auto"
                max-height="500PX"
                max-width="500PX"
                src="../../assets/equipe.png"
              >
              </v-img>
              <v-card-actions class="modal-footer">
                <v-btn
                  outlined
                  width="50%"
                  height="80px"
                  color="#FF8D3F"
                  @click="goToEquipe"
                >
                  IR PARA O CADASTRO<br />DE EQUIPE
                </v-btn>
                <v-btn
                  depressed
                  width="50%"
                  height="80px"
                  class="btn-controls"
                  color="#FF8D3F"
                  @click="avancarAba"
                >
                  CONTINUAR O<br />CADASTRO DE PROJETO
                </v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div v-if="currentTab == 5">
        <v-container>
          <v-row>
            <v-col cols="6" offset="3">
              <v-img
                style="margin-left: auto; margin-right: auto"
                max-height="500PX"
                max-width="500PX"
                src="../../assets/itens.png"
              >
              </v-img>
              <v-card-actions class="modal-footer">
                <v-btn
                  outlined
                  width="50%"
                  height="80px"
                  color="#FF8D3F"
                  @click="goToItens"
                >
                  IR PARA O CADASTRO <br />
                  DE ITENS
                </v-btn>
                <v-btn
                  depressed
                  width="50%"
                  height="80px"
                  class="btn-controls"
                  color="#FF8D3F"
                  @click="openFim"
                >
                  FINALIZAR O <br />
                  CADASTRO DE PROJETO
                </v-btn>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
    <ModalFinalizarCadastro
      :modalVisible="modalFim"
      @close="modalFim = !modalFim"
    />
  </div>
</template>

<script>
import ModalFinalizarCadastro from "./ModalFinalizarCadastro.vue";
import DatePicker from "./../DatePicker.vue";
import {
  adicionarDadosProjeto,
  adicionarParcelas,
  adicionarSubprojetos,
  editProjetoById,
  editarSubprojeto,
} from "./../../services/providers/CadastroProjeto";
import {
  getSubprojetosByProjetoApiSimple,
  deleteSubprojetoById,
} from "./../../services/providers/subProjetosProvider";

export default {
  name: "NovoCadastro",
  components: {
    ModalFinalizarCadastro,
    DatePicker,
  },
  props: {
    projeto: Object,
  },
  data: () => ({
    modalFim: false,
    idProjeto: "",
    currentTab: 0,
    titulo: "",
    demanda: "",
    convenio: "",
    referencia: "",
    sigFundacao: "",
    codigo: "",
    inicioVigencia: new Date().toLocaleDateString("en-CA"),
    fimVigencia: new Date().toLocaleDateString("en-CA"),
    valorTotal: "",
    valorParcela1: 0.0,
    dataParcela1: new Date().toLocaleDateString("en-CA"),
    valorParcela2: 0.0,
    dataParcela2: new Date().toLocaleDateString("en-CA"),
    valorParcela3: 0.0,
    dataParcela3: new Date().toLocaleDateString("en-CA"),
    subprojetos: [],
    dadosGeraisEdit: false,
    editParcelas: false,
    parcela1warning: false,
    parcela2warning: false,
    parcela3warning: false,
  }),
  created() {
    this.subprojetos.push(this.subprojetoFactory());
  },
  methods: {
    subprojetoFactory(codigo = "", descricao = "", id = null) {
      return {
        id: id,
        codigo: codigo,
        descricao: descricao,
        projeto: {
          id: this.idProjeto,
        },
      };
    },
    async buscarSubprojetos() {
      if (this.idProjeto) {
        let aux = await getSubprojetosByProjetoApiSimple(this.idProjeto);
        this.subprojetos = aux.map((subprojeto) => {
          return this.subprojetoFactory(
            subprojeto.codigo,
            subprojeto.descricao,
            subprojeto.id
          );
        });
        if (this.subprojetos.length == 0) {
          this.subprojetos.push(this.subprojetoFactory());
        }
      }
    },
    goToMetas() {
      this.$router.push({
        path: `/projetos-vigentes/${this.idProjeto}/metas`,
        query: { isCadastro: true },
      });
    },
    goToEquipe() {
      this.$router.push({
        path: `/projetos-vigentes/${this.idProjeto}/equipe`,
        query: { isCadastro: true },
      });
    },
    goToItens() {
      this.$router.push({
        path: `/projetos-vigentes/${this.idProjeto}/itens`,
        query: { isCadastro: true },
      });
    },
    adicionarSubprojeto() {
      if (this.subprojetos.length < 10)
        this.subprojetos.push(this.subprojetoFactory());
    },
    openFim() {
      this.modalFim = !this.modalFim;
    },
    voltarAba() {
      this.currentTab = this.currentTab - 1;
    },
    avancarAba() {
      this.currentTab = this.currentTab + 1;
    },
    voltarNavegacao() {
      this.$router.go(-1);
    },
    isEditDadosGerais() {
      if (!this.dadosGeraisEdit) {
        this.cadastrarDadosGerais();
      } else {
        this.editarDadosGerais();
      }
    },
    edit1Possible() {
      this.parcela1warning = true;
    },
    edit2Possible() {
      this.parcela2warning = true;
    },
    edit3Possible() {
      this.parcela3warning = true;
    },
    async deletarSubprojeto(id, index) {
      try {
        if (id) {
          await deleteSubprojetoById(id);
          this.$notify({
            title: "Subprojeto removido com Sucesso!",
            text: "Subprojeto removido com sucesso!",
            type: "success",
            duration: 5000,
          });
          this.buscarSubprojetos();
        } else {
          this.subprojetos.splice(index);
        }
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro ao remover Subprojeto",
          text: error.message,
          type: "error",
          duration: 15000,
        });
      }
    },
    async cadastrarDadosGerais() {
      try {
        const payload = {
          codigo: this.codigo,
          titulo: this.titulo,
          demanda: this.demanda,
          convenio: this.convenio,
          referencia: this.referencia,
          sigFundacao: this.sigFundacao,
          valorTotal: this.valorTotal,
          dataInicio: this.inicioVigencia + " 00:00:00",
          dataFim: this.fimVigencia + " 00:00:00",
        };
        const res = await adicionarDadosProjeto(payload);
        if (res) {
          this.idProjeto = res.id;
          this.$notify({
            title: "Dados do Projeto cadastrado com Sucesso!",
            text: "Dados do Projeto cadastrado com sucesso!",
            type: "success",
            duration: 5000,
          });
          this.currentTab = this.currentTab + 1;
          this.dadosGeraisEdit = true;
          this.subprojetos[0].projeto.id = this.idProjeto;
        }
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro ao cadastrar dados do projeto",
          text: error.message,
          type: "error",
          duration: 15000,
        });
      }
    },
    async editarDadosGerais() {
      try {
        const payload = {
          titulo: this.titulo,
          codigo: this.codigo,
          demanda: this.demanda,
          convenio: this.convenio,
          referencia: this.referencia,
          sigFundacao: this.sigFundacao,
          valorTotal: this.valorTotal,
          dataInicio: `${this.inicioVigencia} 00:00:00`,
          dataFim: `${this.fimVigencia} 00:00:00`,
        };
        const res = await editProjetoById(this.idProjeto, payload);
        if (res) {
          this.$notify({
            title: "Dados do Projeto cadastrado com Sucesso!",
            text: "Dados do Projeto cadastrado com sucesso!",
            type: "success",
            duration: 5000,
          });
          this.currentTab = this.currentTab + 1;
        }
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro ao cadastrar dados do projeto",
          text: error.message,
          type: "error",
          duration: 15000,
        });
      }
    },
    async cadastrarParcelas() {
      if (this.editParcelas) {
        this.avancarAba();
      } else {
        try {
          const payload1 = {
            parcelas: [
              {
                projeto: {
                  id: this.idProjeto,
                },
                valorAutorizado: this.valorParcela1,
                numero: 1,
                dataPrevisao: this.dataParcela1 + " 00:00:00",
              },
            ],
          };
          const payload2 = {
            parcelas: [
              {
                projeto: {
                  id: this.idProjeto,
                },
                valorAutorizado: this.valorParcela1,
                numero: 1,
                dataPrevisao: this.dataParcela1 + " 00:00:00",
              },
              {
                projeto: {
                  id: this.idProjeto,
                },
                valorAutorizado: this.valorParcela2,
                numero: 2,
                dataPrevisao: this.dataParcela2 + " 00:00:00",
              },
            ],
          };
          const payload3 = {
            parcelas: [
              {
                projeto: {
                  id: this.idProjeto,
                },
                valorAutorizado: this.valorParcela1,
                numero: 1,
                dataPrevisao: this.dataParcela1 + " 00:00:00",
              },
              {
                projeto: {
                  id: this.idProjeto,
                },
                valorAutorizado: this.valorParcela2,
                numero: 2,
                dataPrevisao: this.dataParcela2 + " 00:00:00",
              },
              {
                projeto: {
                  id: this.idProjeto,
                },
                valorAutorizado: this.valorParcela3,
                numero: 3,
                dataPrevisao: this.dataParcela3 + " 00:00:00",
              },
            ],
          };
          if (this.valorParcela3 != false && this.dataParcela3 != false) {
            const res = await adicionarParcelas(payload3);
            if (res) {
              this.$notify({
                title: "Parcela cadastrada com Sucesso!",
                text: "Parcela cadastrada com sucesso!",
                type: "success",
                duration: 5000,
              });
              this.currentTab = this.currentTab + 1;
              this.editParcelas = true;
            }
          } else if (
            this.valorParcela2 != false &&
            this.dataParcela2 != false
          ) {
            const res = await adicionarParcelas(payload2);
            if (res) {
              this.$notify({
                title: "Parcela cadastrada com Sucesso!",
                text: "Parcela cadastrada com Sucesso!",
                type: "success",
                duration: 5000,
              });
              this.currentTab = this.currentTab + 1;
              this.editParcelas = true;
            }
          } else {
            const res = await adicionarParcelas(payload1);
            if (res) {
              this.$notify({
                title: "Parcela cadastrada com Sucesso!",
                text: "Parcela cadastrada com Sucesso!",
                type: "Parcela cadastrada com Sucesso!",
                duration: 5000,
              });
              this.currentTab = this.currentTab + 1;
              this.editParcelas = true;
            }
          }
        } catch (error) {
          console.error(error);
          this.$notify({
            title: "Erro ao cadastrar parcelas do projeto",
            text: error.message,
            type: "error",
            duration: 15000,
          });
        }
      }
    },
    async cadastroSubprojeto() {
      try {
        let sucess = false;
        const payloadCadastro = this.subprojetos.filter(function (subprojeto) {
          return subprojeto.descricao && !subprojeto.id;
        });
        if (payloadCadastro.length > 0) {
          const enviar = {
            subprojetos: payloadCadastro,
          };
          const res = await adicionarSubprojetos(enviar);
          if (res) {
            this.subprojetos = res;
            this.$notify({
              title: "Subprojetos cadastrado com Sucesso!",
              text: "Subprojetos cadastrado com sucesso!",
              type: "success",
              duration: 5000,
            });
          }
          sucess = true;
        }

        const payloadEdit = this.subprojetos.filter(function (subprojeto) {
          return subprojeto.id;
        });
        if (payloadEdit.length > 0) {
          let res = true;
          payloadEdit.forEach(async function (subprojeto) {
            res = res && (await editarSubprojeto(subprojeto.id, subprojeto));
          });
          if (res) {
            this.$notify({
              title: "Subprojetos cadastrado com Sucesso!",
              text: "Subprojetos cadastrado com sucesso!",
              type: "success",
              duration: 5000,
            });
          }
          sucess = true;
        }
        
        if (sucess) {
          this.currentTab = this.currentTab + 1;
        }
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro ao cadastrar subprojeto",
          text: "Erro ao cadastrar subprojeto",
          type: "error",
          duration: 15000,
        });
      }
    },
  },
};
</script>

<style scoped>
#container {
  width: 100%;
  position: relative;
}
#navi,
#infoi {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
#infoi {
  z-index: 10;
}


.label-text {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  align-items: left;
  text-align: left;
  padding-bottom: 10px;
  color: #037F8C;
  padding-left: 10px;

}

.btn-design-active {
  border-radius: 100%;
  margin: 0px 0px 10px 0px;
  padding: 0px 0px 0px 0px !important;
  background-color: #ff8d3f;
}
.btn-text-active {
  font-size: 30px;
  color: white;
  margin: 0px 0px 0px 0px;
}

.btn-design {
  border-radius: 100%;
  margin: 0px 0px 10px 0px;
  padding: 0px 0px 0px 0px !important;
  background-color: white;
  border: 1px solid #ff8d3f;
}
.btn-text {
  font-size: 30px;
  color: #026873;
  margin: 0px 0px 0px 0px;
}
.btn-footer {
  font-size: 20x;
  color: #1da7a3;
}

.no-bg {
  background-color: #ffff;
}
.title {
  display: flex;
  color: #026873;
  text-transform: uppercase;
  justify-content: center;
  font-weight: bold !important;
  font-family: Raleway !important;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 0 !important;
}
.content-title {
  color: #026873;
  padding-left: 180px;
  padding-top: 20px;
}
.container-main {
  border-radius: 10px;
  border: 2px solid #55b4c2;
  padding-bottom: 30px;
  margin-right: 80px;
}
.cards-div {
  padding-right: 50px;
}
</style>