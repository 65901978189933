<template>
  <div v-if="projeto">
    <v-card
      class="card"
      min-width="350px"
      max-width="380px"
      height="600px"
      outlined
      style="border: none"
    >
      <v-card-text style="padding-bottom: 0px;">
        <!-- HEADER -->
        <div>
          <p
            id="card-title"
            style="color: #000; padding-top: 0px; font-weight: bold"
          >
            {{ projeto.codigo }}
          </p>
          <p id="card-title" style="padding-top: 0px; height: 70px">
            {{ projeto.descricao }}
          </p>
        </div>
        <br />

        <!-- LISTA DE PARCELAS -->
        <div v-if="parcelas.length" class="parcelas-container">
          <div v-for="parcela in parcelas" v-bind:key="parcela.id">
            <div v-if="parcela">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <div
                    v-on="on"
                    :id="'progress_bar_' + projeto.id + '_' + parcela.id"
                  >
                    <p class="progress_bar_desc">REALIZADO</p>
                  </div>
                </template>
                <span
                  style="text-align: left; text-transform: uppercase; font-size: 13px; font-weight: bold"
                >
                  Executado:
                  {{ parcela.valorExecutado | currency }}
                  <p>
                    Desembolsado: {{ parcela.valorDesembolsado | currency }}
                  </p>
                  <p>Previsto: {{ parcela.valorAlocado | currency }}</p>
                </span>
              </v-tooltip>
            </div>
            <p class="parcela-title">Parcela {{ parcela.id }}</p>
          </div>
        </div>
        <div v-else class="div-sem-parcelas">
          <p id="card-title" style="padding-top: 0px; height: 60px">
            Nenhuma parcela cadastrada!
          </p>
        </div>
        <br />

        <!-- LISTA DE METRICAS -->
        <div>
          <!-- FNDCT -->
          <div style="display: flex; justify-content: space-between">
            <span id="metrica-title">TOTAL EXECUTADO</span>
            <span
              v-if="projeto.metricas.valorAutorizado != null"
              id="ammount-text"
              class="metrica-ammount"
              >{{ projeto.metricas.valorAutorizado | currency }}</span
            >
            <span v-else id="ammount-text" class="metrica-ammount"
              >Não informado</span
            >
          </div>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <div
                v-on="on"
                :id="'progress_bar_line_' + projeto.id + '_execucao'"
              ></div>
            </template>
            <span
              v-if="projeto.metricas.valorExecutado != null"
              style="text-align: center; text-transform: uppercase; font-size: 13px"
            >
              Executado:
              <p>
                {{
                  parseInt(
                    projeto.metricas.percentualExecutadoPorContratado * 100
                  )
                }}%
              </p>
              {{ projeto.metricas.valorExecutado | currency }}
            </span>
            <span v-else>Não informado</span>
          </v-tooltip>
          <br />

          <!-- DESEMBOLSO -->
          <div style="display: flex; justify-content: space-between">
            <span id="metrica-title">TOTAL DESEMBOLSADO</span>
            <span
              v-if="projeto.metricas.valorDesembolsado != null"
              id="ammount-text"
              class="metrica-ammount"
              >{{ projeto.metricas.valorDesembolsado | currency }}</span
            >
            <span v-else id="ammount-text" class="metrica-ammount"
              >Não informado</span
            >
          </div>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <div
                v-on="on"
                :id="'progress_bar_line_' + projeto.id + '_desembolso'"
              ></div>
            </template>
            <span
              v-if="projeto.metricas.valorDesembolsado != null"
              style="text-align: center; text-transform: uppercase; font-size: 13px"
            >
              Desembolsado:
              <p>{{ parseInt(percentExecDesembolso * 100) }}%</p>
              {{ projeto.metricas.valorExecutado | currency }}
            </span>
            <span v-else>Não informado</span>
          </v-tooltip>
        </div>
        <br />

        <!-- FOOTER -->
        <v-row>
          <v-col>
            <div class="ammount-box">
              <p>
                <b>SALDO RENDIMENTO</b>
              </p>
              <p
                v-if="
                  projeto.metricasRendimento &&
                    projeto.metricasRendimento.saldoNaoExecutado != null
                "
              >
                {{ projeto.metricasRendimento.saldoNaoExecutado | currency }}
              </p>
              <p v-else>Não informado</p>
            </div>
          </v-col>
          <v-col>
            <div class="ammount-box">
              <p>
                <b>SALDO FNDCT</b>
              </p>
              <p
                v-if="
                  projeto.metricas && projeto.metricas.saldoNaoExecutado != null
                "
              >
                {{ projeto.metricas.saldoNaoExecutado | currency }}
              </p>
              <p v-else>Não informado</p>
            </div>
          </v-col>
        </v-row>
        <div v-if="projeto.reformulacao" class="reformulacao-status">
          <v-icon color="#FF8D3F">mdi-exclamation-thick</v-icon>
          <p>Projeto em reformulação</p>
        </div>
        <br v-else />
      </v-card-text>
      <v-card-actions class="card-actions">
        <v-btn
          depressed
          width="190"
          color="#037F8C"
          style="color: #fff; border-radius: 8px"
          @click="openProjetoPage"
          >Ver mais</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
var ProgressBar = require("progressbar.js");
import { optionsLine, optionsSemiCircle } from "../utils/progressBarOptions";
export default {
  name: "ProjectCard",
  props: {
    projeto: Object,
    isSubProjeto: Boolean,
  },
  data: () => ({
    colors: ["#DD4B39", "#2699FB", "#037F8C"],
    parcelas: [],
    percentExecDesembolso: 0,
  }),
  created() {
    if (this.projeto) {
      this.parcelas = this.projeto.metricasParcela
        .map((p) => ({
          ...p,
          id: p.parcela.numero,
        }))
        .sort((a, b) => (a.id > b.id ? 1 : -1));
    }
  },
  mounted() {
    if (this.projeto) {
      this.parcelas.forEach((parcela) => {
        const semiCircle = new ProgressBar.Circle(
          "#progress_bar_" + this.projeto.id + "_" + parcela.id,
          optionsSemiCircle
        );
        let percent = parcela.percentualExecucaoDesembolso;
        semiCircle.animate(percent > 1 ? 1 : percent);
      });

      const line_execucao = new ProgressBar.Line(
        "#progress_bar_line_" + this.projeto.id + "_execucao",
        optionsLine
      );
      line_execucao.animate(
        this.projeto.metricas.percentualExecutadoPorContratado > 1
          ? 1
          : this.projeto.metricas.percentualExecutadoPorContratado
      );

      const line_desembolso = new ProgressBar.Line(
        "#progress_bar_line_" + this.projeto.id + "_desembolso",
        optionsLine
      );
      this.percentExecDesembolso = this.projeto.percentualExecutadoDesembolsado;
      line_desembolso.animate(this.percentExecDesembolso);
    }
  },
  methods: {
    openProjetoPage() {
      this.$actions.setProjeto(this.projeto);
      this.$router.push({
        name: "financeiro",
        params: { id: this.projeto.id, projeto: this.projeto },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  background-color: $card-background !important;
}
.parcelas-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 120px !important;
  justify-content: space-around;
  align-content: center;
  align-items: center;
}
.parcela-title-div {
  padding-top: 10px;
}
.parcela-title {
  margin-left: 13px;
  text-transform: uppercase;
}
.div-sem-parcelas {
  height: 120px;
  display: flex;
  justify-content: space-around;
  align-content: center;
  align-items: center;
}
#card-title {
  padding: 10px;
  font-family: $title;
  text-align: center;
  color: $text-color;
  font-size: 16px;
}
#metrica-title {
  font-family: $subtitle;
  margin: 0;
  font-size: 16px;
  text-transform: uppercase;
  color: $text-color;
}
#ammount-text {
  font-family: $subtitle;
  text-align: right;
  font-size: 14px;
  font-weight: 500;
}
.metrica-ammount {
  font-size: 12px !important;
}
.progress_bar_desc {
  font-size: 10px;
  position: absolute;
  top: 50%;
  left: 23%;
  text-align: center;
  color: $secondary;
}
.alert-text {
  font-size: 14px;
  font-family: $subtitle;
}
.amount-column {
  padding-top: 0%;
  padding-bottom: 2px;
}
.ammount-box {
  border: 1px solid $primary;
  font-family: $subtitle;
  border-radius: 10px;
  color: $primary;
  font-style: normal;
  font-weight: normal;
  font-size: 0.9rem;
  line-height: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5px;
  p {
    margin-bottom: 5px;
  }
}
.card-actions {
  display: flex;
  flex: 1;
  justify-content: center;
}
p {
  margin-bottom: 0px;
}

@media only screen and (max-width: 600px) {
  .progress_bar_desc {
    font-size: 55%;
  }
}

.reformulacao-status {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;

  p {
    font-size: 14px;
    font-family: $subtitle;
    color: $secondary;
    font-weight: bold;
  }
}
</style>
