<template>
  <div class="container-div">
    <br />
    <v-row>
      <v-col style="display: flex; justify-content: space-between">
        <p
          v-if="!comentarios || !comentarios.length"
          style="font-family: Raleway; font-size: 18px"
        >
          Nenhum comentário cadastrado!
        </p>
        <div v-else></div>
        <div>
          <v-btn
            v-if="comentarios && comentarios.length"
            class="btn-controls"
            depressed
            color="#037F8C"
            style="margin-right: 20px"
            @click="gerarRelatorio"
          >
            EXPORTAR RELATÓRIO
          </v-btn>
          <v-btn
            outlined
            depressed
            color="#037F8C"
            @click="modalComentarioOpen = !modalComentarioOpen"
          >
            ADICIONAR COMENTÁRIO
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <div v-if="comentarios && comentarios.length">
      <v-row v-for="comentario in comentarios" :key="comentario.id">
        <v-col>
          <div>
            <v-row style="display: flex; justify-content: space-between">
              <v-col md="3" class="info-col">
                <p
                  v-if="comentario.usuarioCriacao"
                  class="comment-header-label"
                >
                  <v-icon style="margin-right: 10px" color="#037F8C"
                    >mdi-account</v-icon
                  >
                  {{ comentario.usuarioCriacao.login }}
                </p>
                <p v-if="comentario.dataEdicao" class="comment-header-label">
                  <v-icon style="margin-right: 10px" color="#037F8C"
                    >mdi-calendar</v-icon
                  >
                  {{
                    new Date(comentario.dataEdicao) | moment("DD/MM/YYYY HH:mm")
                  }}
                </p>
                <p v-else class="comment-header-label">
                  <v-icon style="margin-right: 10px" color="#037F8C"
                    >mdi-calendar</v-icon
                  >
                  {{
                    new Date(comentario.dataCriacao)
                      | moment("DD/MM/YYYY HH:mm")
                  }}
                </p>
              </v-col>
              <v-col md="9" class="actions-col">
                <v-btn
                  text
                  :disabled="usuario.id != comentario.usuarioCriacao.id"
                  @click="
                    (modalComentarioOpen = !modalComentarioOpen) &
                      (comentarioSelecionado = comentario)
                  "
                >
                  <v-icon color="#F39C12">mdi-pencil-box-outline</v-icon>
                </v-btn>
                <v-btn
                  text
                  :disabled="usuario.id != comentario.usuarioCriacao.id"
                  @click="
                    (modalExcluirComentarioOpen = !modalExcluirComentarioOpen) &
                      (comentarioSelecionado = comentario)
                  "
                >
                  <v-icon color="#F39C12">mdi-delete</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <p class="comment-text">{{ comentario.conteudo }}</p>
            <br />
            <div style="width: 100%; border: 1px solid #c4c4c4" />
          </div>
        </v-col>
      </v-row>
    </div>
    <ModalComentarioItem
      v-if="item"
      :key="
        String(comentarioSelecionado ? comentarioSelecionado.id : null) +
          Math.random()
      "
      :modalVisible="modalComentarioOpen"
      :comentarioItem="comentarioSelecionado"
      :item="item"
      @close="(modalComentarioOpen = !modalComentarioOpen) & getData()"
    />
    <ModalConfirmacao
      v-if="item"
      :modalVisible="modalExcluirComentarioOpen"
      :titulo="'Você realmente deseja excluir o comentário'"
      textoBotaoDireito="Excluir"
      :onConfirm="() => deletarComentario() & getData()"
      @close="
        (modalExcluirComentarioOpen = !modalExcluirComentarioOpen) & getData()
      "
    />
  </div>
</template>

<script>
import Auth from "../../services/auth";
import {
  deletarComentarioItem,
  listarComentariosItem,
} from "../../services/providers/itensProvider";
import ModalComentarioItem from "../modals/ModalComentarioItem";
import ModalConfirmacao from "../modals/ModalConfirmacao";
import { makePDFComentariosItem } from "../../utils/makePDFComentariosItem";

export default {
  name: "Comentarios",
  components: {
    ModalComentarioItem,
    ModalConfirmacao,
  },
  props: {
    itemProp: Object,
  },
  data: () => ({
    item: null,
    comentarios: [],
    modalComentarioOpen: false,
    modalExcluirComentarioOpen: false,
    comentarioSelecionado: null,
    usuario: Auth.getUser(),
  }),
  created() {
    this.item = this.itemProp;
    this.getData();
  },
  methods: {
    async getData() {
      try {
        this.comentarios = await listarComentariosItem(this.item.id);
        this.comentarioSelecionado = null;
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro interno!",
          text: error.message || error,
          type: "error",
          duration: 15000,
        });
      }
    },
    async deletarComentario() {
      if (this.comentarioSelecionado) {
        try {
          await deletarComentarioItem(this.comentarioSelecionado.id);
          this.$notify({
            title: "Comentário excluído!",
            text: "O comentário foi excluído com sucesso!",
            type: "success",
            duration: 5000,
          });
        } catch (error) {
          console.error(error);
          this.$notify({
            title: "Erro!",
            text: "Erro ao excluir o comentário!",
            type: "error",
            duration: 15000,
          });
        }
      }
    },
    gerarRelatorio() {
      makePDFComentariosItem(
        this.$store.projeto,
        this.itemProp,
        this.comentarios
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.info-col {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.actions-col {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.comment-header-label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: justify;
  text-transform: uppercase;
  color: #037f8c;
}
.comment-text {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  text-align: justify;
  color: #616161;
  white-space: pre-line;
}
</style>
