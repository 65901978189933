<template>
  <v-dialog v-model="modalVisible" persistent scrollable max-width="1000">
    <v-form ref="formEntregaItem">
      <v-card style="border-radius: 10px">
        <v-card-title class="modal-title">PROCESSO DE AQUISIÇÃO</v-card-title>
        <v-card-text style="padding-left: 30px; padding-right: 40px">
          <v-tabs
            background-color="white"
            color="#FF8D3F"
            center-active
            centered
            grow
            v-model="currentTab"
          >
            <v-tabs-slider></v-tabs-slider>
            <v-tab style="font-size: 18px">REQUISIÇÃO</v-tab>
            <v-tabs-slider></v-tabs-slider>
            <v-tab style="font-size: 18px">EMPENHO</v-tab>
            <v-tabs-slider></v-tabs-slider>
            <v-tab style="font-size: 18px">RECEBIMENTO</v-tab>

            <v-tab-item :key="'requisicao'">
              <v-card-text style="padding-left: 30px">
                <br />
                <v-row>
                  <v-col class="label-col" md="4">
                    <p class="label">N° de requisição:</p>
                  </v-col>
                  <v-col md="6">
                    <v-text-field
                      v-model="numRequisicao"
                      type="id"
                      outlined
                      dense
                      placeholder="000000000000000"
                      v-mask="'############################'"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="label-col" md="4">
                    <p class="label">Data de requisição:</p>
                  </v-col>
                  <v-col md="6">
                    <v-text-field
                      v-model="dataRequisicao"
                      type="text"
                      outlined
                      dense
                      :rules="rules"
                      placeholder="01/01/2020"
                      v-mask="'##/##/####'" append-icon="mdi-calendar"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-tab-item>

            <v-tab-item :key="'empenho'">
              <v-card-text style="padding-left: 30px">
                <br />
                <v-row>
                  <v-col class="label-col" md="4">
                    <p class="label">N° de empenho:</p>
                  </v-col>
                  <v-col md="6">
                    <v-text-field
                      v-model="numEmpenho"
                      type="id"
                      outlined
                      dense
                      placeholder="000000000000000"
                      :rules="[(v) => !!v || 'Campo obrigatório']"
                      v-mask="'############################'"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="label-col pb-0 pt-0" md="4">
                    <p class="label">Data do empenho:</p>
                  </v-col>
                  <v-col class="pb-0 pt-0" md="6">
                    <v-text-field
                      v-model="dataEmpenho"
                      type="text"
                      outlined
                      dense
                      :rules="rules"
                      placeholder="01/01/2020"
                      v-mask="'##/##/####'" append-icon="mdi-calendar"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="label-col  pb-0 pt-0" md="4">
                    <p class="label">Situação do empenho:</p>
                  </v-col>
                  <v-col class="pb-0 pt-0" md="6">
                    <v-text-field
                      v-model="situacaoEmpenho"
                      type="text"
                      outlined
                      dense
                      placeholder="PAGO"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="pb-10 pt-0 text-center">
                    <v-btn color="#026873" text small depressed @click="adicionarEmpenho">
                      <strong>
                        <v-icon size="20" md="2" color="#026873">
                        mdi-plus
                      </v-icon>
                      Adicionar Outro
                      </strong>
                    </v-btn>
                  </v-col>
                </v-row>

                <div v-if="empenhos && empenhos.length > 0">
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th rowspan="2" class="title-tab" style="width: 5%">
                            Nº
                          </th>
                          <th rowspan="2" class="title-tab" style="width: 30%">
                            EMPENHO
                          </th>
                          <th rowspan="2" class="title-tab" style="width: 60%">
                            DATA DO EMPENHO
                          </th>
                          <th rowspan="2" class="title-tab" style="width: 5%">
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(empenho, index) in empenhos" v-bind:key="empenho.id">
                          <td class="text-tab" width="5%">
                            {{ index + 1}}
                          </td>

                          <!-- Número da requisicao -->
                          <td v-if="empenho.numero" class="text-tab" width="30%">
                            {{ empenho.numero }}
                          </td>
                          <td v-else class="text-tab" width="30%">-</td>

                          <!-- Data da requisicao -->
                          <td class="text-tab" v-if="empenho.data" width="60%">
                            {{ readableDateFormat(empenho.data) }}
                          </td>
                          <td v-else class="text-tab" width="60%">-</td>

                          <td class="text-tab" width="5%">
                            <div style="display: flex; flex-direction: row">
                              <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                  <v-btn
                                    v-on="on"
                                    text
                                    small
                                    class="option-hover"
                                    @click="
                                      (empenhoSelecionado = empenho) &
                                        removerEmpenho()
                                    "
                                  >
                                    <v-icon color="#ff8d3f"
                                      >mdi-delete-outline</v-icon
                                    >
                                  </v-btn>
                                </template>
                                <span> Excluir </span>
                              </v-tooltip>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
              </v-card-text>
            </v-tab-item>

            <v-tab-item :key="'recebimento'">
              <v-row>
                <v-col class="label-col" md="2">
                  <p class="label">Nº de Nota Fiscal:</p>
                </v-col>
                <v-col md="4">
                  <v-text-field
                    v-model="notaFiscal"
                    outlined
                    dense
                    v-mask="'############################'"
                  ></v-text-field>
                </v-col>
                <v-col class="label-col" md="2">
                  <p class="label">Data da Nota Fiscal:</p>
                </v-col>
                <v-col md="4">
                  <v-text-field
                    v-model="dataNotaFiscal"
                    outlined
                    dense
                    v-mask="'##/##/####'"
                    placeholder="01/01/2022"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="label-col" md="2">
                  <p class="label">Credor:</p>
                </v-col>
                <v-col md="10">
                  <v-text-field v-model="credor" outlined dense></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="label-col" md="2">
                  <p class="label">Termo de responsabilidade:</p>
                </v-col>
                <v-col md="4">
                  <v-text-field
                    v-model="termoResponsabilidade"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col class="label-col" md="2">
                  <p class="label">Data de recebimento:</p>
                </v-col>
                <v-col md="4">
                  <v-text-field
                    v-model="dataRecebimento"
                    outlined
                    dense
                    v-mask="'##/##/####'"
                    placeholder="01/01/2022"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="label-col" md="2">
                  <p class="label">Responsável pelo recebimento:</p>
                </v-col>
                <v-col md="10">
                  <v-text-field
                    v-model="responsavel"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="label-col" md="2">
                  <p class="label">Local de instalação:</p>
                </v-col>
                <v-col md="10">
                  <v-text-field
                    v-model="localInstalacao"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="label-col" md="2">
                  <p class="label">Data da instalação:</p>
                </v-col>
                <v-col md="4">
                  <v-text-field
                    outlined
                    dense
                    v-model="dataInstalacao"
                    v-mask="'##/##/####'"
                    placeholder="01/01/2022"
                  ></v-text-field>
                </v-col>
                <v-col class="label-col" md="2">
                  <p class="label">Nº de tombo:</p>
                </v-col>
                <v-col md="4">
                  <v-text-field
                    outlined
                    dense
                    v-model="numeroTombo"
                    v-mask="'##########################'"
                    placeholder="00000000000000"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs>
        </v-card-text>
        <v-card-actions class="modal-footer">
          <v-btn outlined width="190" color="#FF8D3F" @click="closeModal(true)">
            Cancelar
          </v-btn>
          <v-btn
            depressed
            width="190"
            class="btn-controls"
            color="#FF8D3F"
            @click="qualAba"
          >
            {{ currentTab == "recebimento" ? "Salvar" : "Avançar" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>requisicao

<script>
import {
  cadastrarEntregaItem,
  editarEntregaItem,
  adicionarRequisicao,
  adicionarEmpenho,
  removerEmpenho
} from "../../services/providers/entregaItemProvider";
import { EventBus } from "../../services/event-bus";
import moment from 'moment';

function formatDate(date) {
  if (date == null)
        return null;
      console.log(date)
      
      const dateSplit = date.split("/");
      console.log(dateSplit)
      if (dateSplit.length != 3)
        return null;
      console.log(date)

      const data = dateSplit[2] + "-" + dateSplit[1] + '-' + dateSplit[0];
      return data;
}

export default {
  name: "ModalCrudEntregaItem",
  props: {
    item: Object,
    entrega: Object,
    modalVisible: Boolean,
  },
  data: () => ({
    dataRecebimento: "",
    dataInstalacao: "",
    dataNotaFiscal: "",
    localInstalacao: "",
    notaFiscal: "",
    responsavel: "",
    termoResponsabilidade: "",
    credor: "",
    currentTab: 0,
    numRequisicao: "",
    dataRequisicao: "",
    numEmpenho: "",
    dataEmpenho: "",
    situacaoEmpenho: "",
    numeroTombo: "",
    processoEntregaItemId: null,
    empenhos: [],
    empenhoSelecionado: {},
  }),
  mounted() {
    "requisicao";
    if (this.entrega) {
      this.dataRecebimento = new Date(
        this.entrega.dataRecebimento + " "
      ).toLocaleString();
      this.dataInstalacao = new Date(
        this.entrega.dataInstalacao + " "
      ).toLocaleString();
      this.dataNotaFiscal = new Date(
        this.entrega.dataNotaFiscal + " "
      ).toLocaleString();
      this.localInstalacao = this.entrega.localInstalacao;
      this.notaFiscal = this.entrega.notaFiscal;
      this.responsavel = this.entrega.responsavel;
      this.termoResponsabilidade = this.entrega.termoResponsabilidade;
      this.numeroTombo = this.entrega.numeroTombo;
    }
  },
  methods: {
    qualAba() {
      if (this.currentTab === 0) {
        this.cadastraReqi();
        this.currentTab = 1;
      } else if (this.currentTab === 1) {
        this.cadastrarEmpenho();
        this.currentTab = 2;
      } else {
        this.crudEntregaItem();
      }
    },
    closeModal(reload) {
      this.$refs.formEntregaItem.reset();
      this.dataNotaFiscal = "";
      this.dataRecebimento = "";
      this.dataInstalacao = "";
      if (reload) {
        EventBus.$emit("entrega-updated");
      }
      this.$emit("close");
    },
    async crudEntregaItem() {
      if (this.$refs.formEntregaItem.validate()) {
        try {
          const payload = {
            localInstalacao: this.localInstalacao,
            notaFiscal: this.notaFiscal,
            credor: this.credor,
            responsavel: this.responsavel,
            termoResponsabilidade: this.termoResponsabilidade,
            numeroTombo: this.numeroTombo,
            processoEntrega: {
              id: this.processoEntregaItemId,
            },
            dataRecebimento: "",
            dataInstalacao: "",
            dataNotaFiscal: "",
          };

          if (this.dataRecebimento) {
            let dataRecebimento = this.dataRecebimento.split("/");
            payload.dataRecebimento = new Date(
              dataRecebimento[2] +
                "/" +
                dataRecebimento[1] +
                "/" +
                dataRecebimento[0]
            )
              .toISOString()
              .slice(0, 10);
          }

          if (this.dataInstalacao) {
            let dataInstalacao = this.dataInstalacao.split("/");
            payload.dataInstalacao = new Date(
              dataInstalacao[2] +
                "/" +
                dataInstalacao[1] +
                "/" +
                dataInstalacao[0]
            )
              .toISOString()
              .slice(0, 10);
          }

          if (this.dataNotaFiscal) {
            let dataNotaFiscal = this.dataNotaFiscal.split("/");
            payload.dataNotaFiscal = new Date(
              dataNotaFiscal[2] +
                "/" +
                dataNotaFiscal[1] +
                "/" +
                dataNotaFiscal[0]
            )
              .toISOString()
              .slice(0, 10);
          }

          if (this.entrega) {
            const res = await editarEntregaItem(this.entrega.id, payload);
            if (res) {
              this.$notify({
                title: "Entrega de item editada",
                text: "Entrega de item editada com sucesso!",
                type: "success",
                duration: 5000,
              });
              this.closeModal(true);
            }
          } else {
            const res = await cadastrarEntregaItem(payload);
            if (res) {
              this.$notify({
                title: "Entrega de item concluída",
                text: "Entrega de item concluída com sucesso!",
                type: "success",
                duration: 5000,
              });
              this.closeModal(true);
            }
          }
        } catch (error) {
          console.error(error);
          this.$notify({
            title: "Erro ao cadastrar/editar entrega de item!",
            text: error.message,
            type: "error",
            duration: 15000,
          });
        }
      }
    },
    async cadastraReqi() {
      try {
        const payload1 = {
          itemId: this.item.id,
          numero: this.numRequisicao,
          processoEntregaItemId: this.processoEntregaItemId,
          data: formatDate(this.dataRequisicao),
        };
        const res1 = await adicionarRequisicao(payload1);
        if (res1) {
          this.processoEntregaItemId = res1.id;
          this.$notify({
            title: "Requisição cadastrada com ucesso!",
            text: "Requisição do Item cadastrada com sucesso!",
            type: "success",
            duration: 5000,
          });
        }
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro ao cadastrar requisição do item!",
          text: error.message,
          type: "error",
          duration: 15000,
        });
      }
    },
    async cadastrarEmpenho() {
      try {
        const payload2 = {
          itemId: this.item.id,
          numero: this.numEmpenho,
          processoEntregaItemId: this.processoEntregaItemId,
          data: formatDate(this.dataEmpenho),
          situacao: this.situacaoEmpenho,
        };
        const res2 = await adicionarEmpenho(payload2);
        if (res2) {
          this.processoEntregaItemId = res2.id;
          this.empenhos = res2.empenhos
          this.$notify({
            title: "Empenho cadastrado com ucesso!",
            text: "Empenho do Item cadastrado com sucesso!",
            type: "success",
            duration: 5000,
          });
        }
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro ao cadastrar empenho do item!",
          text: error.message,
          type: "error",
          duration: 15000,
        });
      }
    },
    async adicionarEmpenho() {
      await this.cadastrarEmpenho();
      this.numEmpenho = "";
      this.dataEmpenho = "";
      this.situacaoEmpenho = "";
    },
    async removerEmpenho() {

      let payload = {
        "numero": this.empenhoSelecionado.numero,
        "processoEntregaItemId": this.processoEntregaItemId,
        "itemId": this.item.id
      }

      try {
        const res = await removerEmpenho(payload);
        if (res.empenhos)
          this.empenhos = res.empenhos;
        else{
          this.processoEntregaItemId = null;
          this.empenhos = []
        }
        if (res) {
          this.processoEntregaItemId = res.id;
          this.empenhos = res.empenhos
          this.$notify({
            title: "Empenho Excluído!",
            text: "Empenho do Item excluído com sucesso!",
            type: "success",
            duration: 5000,
          });
        }
      } catch (error) {
        console.error(error);
          this.$notify({
            title: "Erro ao cadastrar/editar entrega de item!",
            text: error.message,
            type: "error",
            duration: 15000,
          });
      }
    },
    readableDateFormat(dateString) {
      if (!dateString)
        return ""
      const date = new Date(dateString + " ");
      const yyyy = date.getFullYear();
      let mm = date.getMonth() + 1; // Months start at 0!
      let dd = date.getDate();

      if (dd < 10) dd = '0' + dd;
      if (mm < 10) mm = '0' + mm;

      return `${dd}/${mm}/${yyyy}`;
    },
    validateDate() {
      this.$refs.formEntregaItem.validate();
    }
  },
  computed: {
    rules() {
      const rules = [];
      const rule = v => {
        return !v ? true : moment(v, 'DD/MM/YYYY', true).isValid() || 'Data inválida'
      };
      rules.push(rule);
      return rules;
    },
  },
  watch: {
    dataRequisicao: 'validateDate',
    dataEmpenho: 'validadeDate',
  }
};
</script>
<style lang="scss" scoped>
.modal-title {
  display: flex;
  font-size: 20px !important;
  text-transform: uppercase;
  justify-content: center;
  font-weight: bold;
  font-family: Raleway;
  color: $primary;
  margin: 20px;
  margin-bottom: 30px;
  border-radius: 10px;
  height: 65px;
}
.title-tab {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 16px;
  text-align: center !important;
  color: $primary !important;
  background-color: #e6f4f1;
  border: 0.899715px solid rgba(97, 97, 97, 0.3);
  height: 45px !important;
}
.text-tab {
  font-family: Roboto;
  font-style: normal;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 13px;
  text-align: center;
  color: #616161;
  border: 0.899715px solid rgba(97, 97, 97, 0.3);
  height: 45px !important;
}
</style>
