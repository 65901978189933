import api from "../api";

export const buscarDesembolsoPorParcela = async (idParcela) => {
  const res = await api.get(`/desembolsos/search?parcelaId=${idParcela}`);
  return res.data;
};

export const cadastrarDesembolso = async (payload) => {
  const res = await api.post(`/desembolsos`, payload);
  return res.data;
};

export const editarDesembolso = async (payload) => {
  const res = await api.put(`/desembolsos`, payload);
  return res.data;
};

export const solicitarDesembolso = async (payload) => {
  const res = await api.post(`/solicitacoes-desembolso`, payload);
  return res.data;
};

export const editarSolicitacaoDesembolso = async (idSolicitacao, payload) => {
  const res = await api.patch(`/solicitacoes-desembolso/${idSolicitacao}`, payload);
  return res.data;
}


export const excluirSolicitacaoDesembolso = async (idSolicitacao) => {
  const res = await api.delete(`/solicitacoes-desembolso/${idSolicitacao}`);
  return res.data;
}

export const listarSolicitacoesDesembolsoPorProjeto = async (idProjeto) => {
  const res = await api.get(
    `/solicitacoes-desembolso/search?projetos=${idProjeto}`
  );
  return res.data;
};
