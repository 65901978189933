<template>
  <div>
    <div v-if="visible">
      <p style="font-family: Raleway; font-size: 18px; margin-left: 20px">
        Nenhum relatorio cadastrado para este projeto!
      </p>
    </div>
    <div v-else>
      <RelatoriosTable />
    </div>
  </div>
</template>

<script>
import RelatoriosTable from "../tables/RelatoriosTable.vue";


export default {
  name: "Relatorios",
  components: {
    RelatoriosTable,
  },
  data: () => ({
    relatoriosList: [],
    visible: false,
  }),
};
</script>