<template>
  <v-dialog v-model="modalVisible" persistent max-width="800">
    <v-card style="border-radius: 10px">
      <br />
      <v-tooltip top>
        <template v-slot:activator="{ on }">
          <v-btn
            v-on="on"
            text
            small
            class="option-hover"
            @click="closeModal(false)"
          >
            <v-icon color="#ff8d3f">mdi-close</v-icon>
          </v-btn>
        </template>
        <span> Fechar </span>
      </v-tooltip>
      <v-card-title class="modal-title"
        >INFORMAÇÕES DO RECEBIMENTO</v-card-title
      >

      <v-card-text>
        <br />
        <v-row>
          <v-col class="t-label-col">
            <p class="t-label">Termo de responsbilidade:</p>
            <p v-if="processo && processo.termoResponsabilidade" class="item-t-label">
              {{ processo.termoResponsabilidade }}
            </p>
            <p v-else>-</p>
          </v-col>
        </v-row>
        <hr />
        <v-row class="t-label-col">
          <v-col class="t-label-col">
            <p class="t-label">Nº de Nota Fiscal:</p>
            <p v-if="processo && processo.notaFiscal" class="item-t-label">
              {{ processo.notaFiscal }}
            </p>
            <p v-else>-</p>
          </v-col>
          <v-col class="t-label-col">
            <p class="t-label">Data da Nota Fiscal:</p>
            <p v-if="processo && processo.dataNotaFiscal" class="item-t-label">
              {{
                new Date(processo.dataNotaFiscal + " 00:00:00")
                  | moment("DD/MM/YYYY")
              }}
            </p>
            <p v-else>-</p>
          </v-col>
        </v-row>
        <hr />
        <v-row class="t-label-col">
          <v-col class="t-label-col">
            <p class="t-label">Credor:</p>
            <p v-if="processo && processo.credor" class="item-t-label">
              {{ processo.credor }}
            </p>
            <p v-else>-</p>
          </v-col>
        </v-row>
        <hr />
        <v-row>
          <v-col class="t-label-col">
            <p class="t-label">Responsávelo pelo recebimento:</p>
            <p v-if="processo && processo.responsavel" class="item-t-label">
              {{ processo.responsavel }}
            </p>
            <p v-else>-</p>
          </v-col>
        </v-row>
        <hr />
        <v-row class="t-label-col">
          <v-col class="t-label-col">
            <p class="t-label">Data de recebimento:</p>
            <p v-if="processo && processo.dataRecebimento" class="item-t-label">
              {{
                new Date(processo.dataRecebimento + " 00:00:00")
                  | moment("DD/MM/YYYY")
              }}
            </p>
            <p v-else>-</p>
          </v-col>
        </v-row>
        <hr />
        <v-row>
          <v-col class="t-label-col">
            <p class="t-label">Local de instalação:</p>
            <p v-if="processo && processo.localInstalacao" class="item-t-label">
              {{ processo.localInstalacao }}
            </p>
            <p v-else>-</p>
          </v-col>
        </v-row>
        <hr />
        <v-row>
          <v-col class="t-label-col" md="6">
            <p class="t-label">Data da instalação:</p>
            <p v-if="processo && processo.dataInstalacao" class="item-t-label">
              {{
                new Date(processo.dataInstalacao + " 00:00:00")
                  | moment("DD/MM/YYYY")
              }}
            </p>
            <p v-else>-</p>
          </v-col>
          <v-col class="t-label-col">
            <p class="t-label">Número de tombo</p>
            <p v-if="processo && processo.numeroTombo" class="item-t-label">
              {{
                processo.numeroTombo
              }}
            </p>
            <p v-else>-</p>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { buscaPegarItem } from "../../services/providers/entregaItemProvider";
import { EventBus } from "../../services/event-bus";
export default {
  name: "ModalInfoRecebiComum",
  props: {
    modalVisible: Boolean,
    processoId: Object,
    item: Object,
  },
  data: () => ({
    dados: [],
    processo: null,
    isComum: true,
  }),

  computed: {
    isItemObra: function () {
      return this.item.rubrica.codigo == "44.00.51";
    },
  },

  async mounted() {
    this.obraOrComum();
  },

  beforeUpdate(){
    if (this.processoId.entregaItem)
      this.processo = this.processoId.entregaItem;
    else
      this.processo = {}
  },
  
  methods: {
    closeModal(reload) {
      if (reload) {
        EventBus.$emit("entrega-updated");
      }
      this.$emit("close");
    },
    obraOrComum() {
      this.processo = this.processoId.entregaItem;
    },
    limparDados() {
      this.processo = null;
    },
    async getData() {
      try {
        this.dados = await buscaPegarItem(this.processoId.id);
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro interno!",
          text: error.message || error,
          type: "error",
          duration: 15000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.add-recebido-label {
  background: #e6f4f1;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #037f8c;
  padding: 5px;
  border-radius: 10px;
  margin-right: 5px;
  margin-top: 10px;
}
.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 0.8px solid #037f8c;
  border-radius: 10px;
  padding-top: 50px;
  padding-bottom: 50px;
}
.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.t-label {
  color: #616161;
  font-size: 18px;
  text-align: left;
  font-weight: bold;
  margin: 0px;
  margin-right: 20px;
}
.t-label-col {
  display: flex;
  justify-content: flex-start;
}
.t-label-inline {
  padding-bottom: 0px;
  padding-top: 0px;
}
.item-t-label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #616161;
}
.no-pd {
  padding-left: 0px;
  padding-right: 0px;
}
.no-alg {
  justify-content: flex-start;
}
hr {
  background-color: #c4c4c4 !important;
}
p {
  margin: 0px;
}

.title-tab {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 16px;
  text-align: center !important;
  color: $primary !important;
  background-color: #e6f4f1;
  border: 0.899715px solid rgba(97, 97, 97, 0.3);
  height: 45px !important;
}
.text-tab {
  font-family: Roboto;
  font-style: normal;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 13px;
  text-align: center;
  color: #616161;
  border: 0.899715px solid rgba(97, 97, 97, 0.3);
  height: 45px !important;
}
.div-body {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 16px;
  text-align: center !important;
  color: $primary !important;
  padding: 0px;
}
.text-body {
  display: flex;
  height: 45px;
  padding: 10px;
  width: 100%;
  background-color: #e6f4f1;
  text-align: center;
  border: 0.899715px solid rgba(97, 97, 97, 0.3);
  border-left: none;
  border-right: none;
}
</style>
