<template>
  <v-card
    outlined
    :style="{
      border: 'none',
      backgroundColor: custom.color,
      borderRadius: '14px',
      height: '120px',
    }"
  >
    <v-card-title class="alerta-title">
      <div v-if="isNovo" class="novo">novo</div>
      <div v-else></div>
      <v-menu offset-y left open-on-hover>
        <template v-slot:activator="{ on, attrs }">
          <v-btn small rounded text v-bind="attrs" v-on="on">
            <v-icon color="white">mdi-dots-horizontal</v-icon>
          </v-btn>
        </template>
        <v-btn class="btn-alerta" text rounded @click="custom.onClick">
          Verificar alerta
        </v-btn>
      </v-menu>
    </v-card-title>
    <v-card-text style="color: white">
      <v-row>
        <v-col
          md="3"
          xl="2"
          sm="3"
          style="display: flex; justify-content: center;"
        >
          <v-icon x-large color="white">{{ custom.icon }}</v-icon>
        </v-col>
        <v-col md="9" xl="10" sm="9">
          <div class="titulo">
            {{ custom.titulo }}
          </div>
          <div class="menssagem" v-html="custom.menssagem"></div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "AlertaCard",
  props: {
    alerta: Object,
  },
  methods: {
    goToProject() {
      const { projeto } = this.alerta;
      if (projeto) {
        this.$router.push({
          path: `/projetos-vigentes/${projeto.id}/`,
        });
      }
    },
    goToItem() {
      const { item } = this.alerta;
      if (item) {
        const { subprojeto } = item;
        this.$router.push({
          path: `/projetos-vigentes/${subprojeto.projetoId}/subprojetos/${subprojeto.id}/itens/${item.id}`,
        });
      }
    },
  },
  computed: {
    isNovo: function() {
      const dt = new Date(this.alerta.dataCriacao);
      dt.setDate(dt.getDate() + 3);
      return new Date() <= dt;
    },
    custom: function() {
      const { tipoAlerta, projeto } = this.alerta;
      switch (tipoAlerta) {
        case "SOLICITACAO_DESEMBOLSO":
          return {
            icon: "mdi-cash-multiple",
            color: "rgba(243, 156, 18, 0.6)",
            titulo: "DESEMBOLSO",
            menssagem: `o projeto <b>${projeto.codigo}</b> atingiu 80% de execução, solicite um novo desembolso`,
            onClick: this.goToProject,
          };
        case "PRAZO_VIGENCIA":
          return {
            icon: "mdi-table-clock",
            color: "#1F77B4",
            titulo: "PRAZO DE VIGENCIA",
            menssagem: `solicitar a prorrogação do projeto <b>${projeto.codigo}</b>`,
            onClick: this.goToProject,
          };
        case "ATRASO_REQUISICAO":
          return {
            icon: "mdi-credit-card-clock",
            color: "#F66060",
            titulo: "REQUISIÇÃO",
            menssagem: `alguns itens do projeto <b>${projeto.codigo}</b> ainda não possuem solicitação de compra`,
            onClick: this.goToItem,
          };
        case "PENDENCIA_EMPENHO":
          return {
            icon: "mdi-information-outline",
            color: "#009B96",
            titulo: "EMPENHO",
            menssagem: `alguns itens do projeto <b>${projeto.codigo}</b> estão há muito tempo sem cadastro de empenho`,
            onClick: this.goToItem,
          };
        case "NECESSIDADE_REFORMULACAO":
          return {
            icon: "mdi-cog-outline",
            color: "#53CDE2",
            titulo: "REFORMULAÇÃO",
            menssagem: `O projeto <b>${projeto.codigo}</b> precisa de reformulação`,
            onClick: this.goToProject,
          };
        case "PRAZO_RELATORIO":
          return {
            icon: "mdi-file-multiple-outline",
            color: "#40A8C4",
            titulo: "RELATÓRIO",
            menssagem: `relatório de prestação de contas do <b>${projeto.codigo}</b> deve ser entregue em breve`,
            onClick: this.goToProject,
          };
        case "ENTREGA_ITEM":
          return {
            icon: "mdi-truck-outline",
            color: "#60C1C8",
            titulo: "PRAZO DE ENTREGA",
            menssagem: `alguns itens do <b>${projeto.codigo}</b> ainda não foram entregues`,
            onClick: this.goToItem,
          };
        default:
          return {
            icon: "",
            color: "",
            titulo: "",
            menssagem: "",
          };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.col {
  padding-top: 0px;
  padding-right: 0px;
}
.alerta-title {
  justify-content: space-between;
  padding: 0px;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 12px;
  line-height: 0px;
  color: white;
}
.titulo {
  font-family: Raleway;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;
  margin-bottom: 5px;
}
.menssagem {
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 0.78rem;
  line-height: 14px;
  padding-bottom: 20px;
}
.novo {
  background-color: white !important;
  color: #ff8d3f;
  font-family: Raleway;
  font-weight: bold;
  font-size: 10px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7px;
  padding-left: 8px;
  padding-right: 8px;
}
.btn-alerta {
  width: 150px;
  height: 30px !important;
  background: white;
  border-radius: 5px;
  font-family: Raleway;
  font-size: 13px;
  color: black;
  text-transform: none;
}
</style>
