import api from "../api"

export const buscarParcelasPorProjeto = async (idProjeto) => {
    const res = await api.get(`/parcelas/search?projetoId=${idProjeto}`);
    return res.data;
}

export const buscarParcelasPorSubProjeto = async (idProjeto) => {
    const res = await api.get(`/parcelas/search?projetoId=${idProjeto}`);
    return res.data;
}

export const cadastrarObservacaoParcela = async (payload) => {
    const res = await api.post(`/comentarios-parcelas`, payload);
    return res.data;
}

export const buscarObservacoesPorParcela = async (idParcela) => {
    const res = await api.get(`/comentarios-parcelas/search?parcelas=${idParcela}`);
    return res.data;
}

export const excluirObservacaoParcela = async (idObservacao) => {
    const res = await api.delete(`/comentarios-parcelas/${idObservacao}`);
    return res.data;
}

export const atualizarObservacaoParcela = async (payload) => {
    const res = await api.put(`/comentarios-parcelas`, payload);
    return res.data;
}