<template>
  <v-dialog v-model="modalVisible" persistent max-width="900">
    <v-form ref="formExecucao">
      <v-card style="border-radius: 10px">
        <br />
        <v-card-title v-if="execucao" class="modal-title"
          >Editar Despesa</v-card-title
        >
        <v-card-title v-else class="modal-title"
          >Adicionar Despesa</v-card-title
        >
        <v-card-text>
          <v-row style="display: flex; justify-content: center">
            <v-col md="6">
              <v-col class="box-ammount">
                <p class="title-ammount">Saldo FNDCT do item</p>
                <p class="text-ammount">{{ saldoFndct | currency }}</p>
              </v-col>
            </v-col>
            <v-col md="6">
              <v-col class="box-ammount">
                <p class="title-ammount">Saldo RENDIMENTO do item</p>
                <p class="text-ammount">
                  {{ saldoRendimento | currency }}
                </p>
              </v-col>
            </v-col>
          </v-row>
          <br />
          <v-row>
            <v-col class="label-col" md="2">
              <p class="label">Descrição:</p>
            </v-col>
            <v-col md="10">
              <v-text-field
                v-model="descricao"
                outlined
                dense
                :rules="[(v) => !!v || 'Descrição é obrigatória']"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="label-col" md="2">
              <p class="label">Quantidade:</p>
            </v-col>
            <v-col md="4">
              <v-text-field
                v-model.number="quantidade"
                :readonly="!execucao"
                :disabled="!execucao"
                min="1"
                outlined
                dense
                :rules="[
                  (v) => !!v || 'Quantidade é obrigatória',
                  (v) =>
                    !(parseFloat(v) <= 0) ||
                    'Quantidade deve ser maior que zero',
                ]"
                required
              ></v-text-field>
            </v-col>
            <v-col class="label-col" md="2">
              <p v-if="execucao" class="label">Valor unitário:</p>
              <p v-else class="label">Valor total:</p>
            </v-col>
            <v-col md="4">
              <v-currency-field
                prefix="R$"
                v-model="valorUnitario"
                outlined
                dense
                :rules="[
                  (v) => !!v || 'Valor Unitario é obrigatório',
                  (v) =>
                    !(parseFloat(String(v).replace(',', '.')) <= 0) ||
                    'Valor Unitario deve ser maior que zero',
                ]"
                required
              ></v-currency-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="modal-footer">
          <v-btn outlined width="190" color="#FF8D3F" @click="closeModal(false)"
            >Cancelar</v-btn
          >
          <v-btn
            depressed
            width="190"
            class="btn-controls"
            color="#FF8D3F"
            @click="validarForm"
            >Salvar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { EventBus } from "../../services/event-bus";
import {
  cadastrarExecucao,
  deletarEmpenho,
  deletarRequisicao,
  editarExecucao,
  getExecucaoPorId,
} from "../../services/providers/execucaoProvider";
import { getProjetoById } from "../../services/providers/projetosProvider";

export default {
  name: "ModalExecucao",
  props: {
    execucaoProp: Object,
    isEdit: Boolean,
    modalVisible: Boolean,
    item: Object
  },
  data: () => ({
    execucao: null,
    panel1: 0,
    panel2: 0,
    descricao: "",
    valorUnitario: null,
    quantidade: 1,
    saldoRendimento: 0,
    saldoFndct: 0,
    requisicaoSelecionada: null,
    empenhoSelecionado: null,
    modalRequisicaoOpen: false,
    modalEmpenhoOpen: false,
    modalExcluirRequisicaoOpen: false,
    modalExcluirEmpenhoOpen: false,
  }),
  mounted() {
    if (this.item) {
      this.saldoRendimento = this.item.recursos
        .filter((r) => (r.fonte ? r.fonte.codigo == "R" : false))
        .reduce((total, recurso) => (total += recurso.saldoNaoExecutado), 0);

      this.saldoFndct = this.item.recursos
        .filter((r) => (r.fonte ? r.fonte.codigo == "F" : false))
        .reduce((total, recurso) => (total += recurso.saldoNaoExecutado), 0);
    }
    this.getExecucao();
  },
  methods: {
    async getExecucao() {
      console.log(this.execucaoProp);
      if (!this.execucaoProp) {
        console.log(this.execucaoProp)
        return
      }
      this.execucao = await getExecucaoPorId(this.execucaoProp.id);
      if (this.execucao) {
        this.descricao = this.execucao.descricao;
        this.quantidade = this.execucao.quantidade;
        this.valorUnitario = this.execucao.valorUnitario;
        this.empenho = this.execucao.empenhoNumero;
        this.requisicao = this.execucao.requisicaoNumero;
      }
    },
    closeModal(reload) {
      this.quantidade = 1;
      this.descricao = "";
      this.valorUnitario = 0;
      this.requisicao = null;
      this.empenho = null;
      this.execucao = null;
      if (reload) {
        EventBus.$emit("execucao-updated");
      }
      this.$emit("close");
    },
    async validarForm() {
      if (this.$refs.formExecucao.validate()) {
        const payload = {
          itemId: this.item.id,
          descricao: this.descricao,
          quantidade: this.execucao ? this.quantidade : undefined,
          valor: !this.execucao ? this.valorUnitario : undefined,
          valorUnitario: this.execucao ? this.valorUnitario : undefined,
          empenhoNumero: this.empenho,
          requisicaoNumero: this.requisicao,
        };
        if (this.execucao) {
          try {
            delete payload.itemId;
            const res = await editarExecucao(this.execucao.id, payload);
            if (res) {
              this.$notify({
                title: "Execução editada!",
                text: "A execução foi editada com sucesso!",
                type: "success",
                duration: 5000,
              });
              const projeto = await getProjetoById(this.$store.projeto.id);
              if (projeto) {
                this.$actions.setProjeto(projeto);
              }
              this.closeModal(true);
            }
          } catch (error) {
            console.error(error);
            this.$notify({
              title: "Erro ao editar execução!",
              text: error.message,
              type: "error",
              duration: 15000,
            });
          }
        } else {
          try {
            const res = await cadastrarExecucao(payload);
            if (res) {
              this.$notify({
                title: "Execução cadastrada!",
                text: "A execução foi cadastrada com sucesso!",
                type: "success",
                duration: 5000,
              });
              const projeto = await getProjetoById(this.$store.projeto.id);
              if (projeto) {
                this.$actions.setProjeto(projeto);
              }
              this.closeModal(true);
            }
          } catch (error) {
            console.error(error);
            this.$notify({
              title: "Erro ao cadastrar execução!",
              text: error.message,
              type: "error",
              duration: 15000,
            });
          }
        }
      }
    },
    async excluirEmpenhoRequisicao(tipo) {
      try {
        if (tipo == "requisicao") {
          const payload = {
            execucaoId: this.execucao.id,
            numero: this.requisicaoSelecionada.numero,
          };
          await deletarRequisicao(payload);
          this.$notify({
            title: "Requisição excluída!",
            text: "Requisição excluída com sucesso!",
            type: "success",
            duration: 5000,
          });
          this.modalExcluirRequisicaoOpen = false;
          this.requisicaoSelecionada = false;
        } else {
          const payload = {
            execucaoId: this.execucao.id,
            numero: this.empenhoSelecionado.numero,
          };
          await deletarEmpenho(payload);
          this.$notify({
            title: "Empenho excluído!",
            text: "Empenho excluído com sucesso!",
            type: "success",
            duration: 5000,
          });
          this.modalExcluirEmpenhoOpen = false;
          this.empenhoSelecionado = false;
        }
        this.getExecucao();
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro excluir requisição/empenho!",
          text: error.message,
          type: "error",
          duration: 15000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-title {
  display: flex;
  justify-content: center;
  font-size: 24px !important;
  text-transform: uppercase;
  color: $primary;
}
.session-title {
  color: $primary;
  font-family: Roboto;
  font-size: 20px;
}
.no-margin {
  margin: 0px;
}
.box-ammount {
  border: 2px solid $primary;
  margin: 5px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 15px 1px 1px 1px;
}
.title-ammount {
  font-family: $title;
  text-transform: uppercase;
  text-align: center;
  font-size: 16px;
  color: $title-color;
  margin-bottom: 10px;
}
.text-ammount {
  font-family: $subtitle;
  text-transform: uppercase;
  text-align: center;
  color: $title-color;
  font-size: 22px;
}
.label-inline {
  padding-bottom: 0px;
  padding-top: 0px;
}
.tipo-despesa-title {
  color: $primary;
  font-family: Raleway;
  font-size: 22px;
  font-weight: 600;
}
.no-pd {
  padding-left: 0px;
  padding-right: 0px;
}
.no-alg {
  justify-content: flex-start;
}
.execucao-label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #616161;
}
hr {
  background-color: #c4c4c4 !important;
}
p {
  margin: 0px;
}
.title-tab {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 15px !important;
  line-height: 16px;
  text-align: center !important;
  color: #037f8c !important;
  border: 0.899715px solid rgba(97, 97, 97, 0.3);
  padding: 15px !important;
}
.text-tab {
  font-family: Roboto;
  font-style: normal;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  color: #616161 !important;
  border: 0.899715px solid rgba(97, 97, 97, 0.3);
  padding: 20px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}
</style>
