<template>
  <v-dialog v-model="modalVisible" persistent max-width="1000">
    <v-card style="border-radius: 10px">
      <br />
      <v-card-title v-if="isEdit" class="modal-title"
        >EDITAR ATIVIDADE</v-card-title
      >
      <v-card-title v-else class="modal-title"
        >CADASTRAR ATIVIDADE</v-card-title
      >
      <v-card-text>
        <div v-if="meta">
          <div class="session">{{ meta.subprojeto.codigo }}</div>
          <br />
          <p class="session-title">META: {{ meta.descricao }}</p>
          <br />
        </div>
        <v-form ref="formAtividade">
          <v-row>
            <v-col class="label-col" md="2">
              <p class="label">Nome da atividade:</p>
            </v-col>
            <v-col md="10">
              <v-textarea
                v-model="formFields.descricao"
                outlined
                dense
                rows="2"
                :rules="[(v) => !!v || 'Nome obrigatório']"
                required
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="label-col" md="2">
              <p class="label">Indicador físico de execução:</p>
            </v-col>
            <v-col md="10">
              <v-text-field
                v-model="formFields.indicador"
                outlined
                dense
                :rules="[(v) => !!v || 'Indicador obrigatório']"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="label-col" md="2">
              <p class="label">Duração prevista:</p>
            </v-col>
            <v-col md="2">
              <v-text-field
                v-model="formFields.duracaoInicio"
                label="Início:"
                outlined
                dense
                v-mask="'######'"
                :rules="[(v) => !!v || 'Inicio obrigatório']"
                required
              ></v-text-field>
            </v-col>
            <v-col md="2">
              <v-text-field
                v-model="formFields.duracaoFim"
                label="Fim:"
                outlined
                dense
                v-mask="'######'"
                :rules="[(v) => !!v || 'Fim obrigatório']"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <br />
        </v-form>
        <v-card-actions
          style="display: flex; justify-content: space-evenly; padding-bottom: 20px"
        >
          <v-btn outlined width="190" color="#FF8D3F" @click="closeModal">
            Cancelar
          </v-btn>
          <v-btn
            v-if="isEdit"
            depressed
            width="190"
            class="btn-controls"
            color="#FF8D3F"
            @click="crudAtividade"
          >
            Editar
          </v-btn>
          <v-btn
            v-else
            depressed
            width="190"
            class="btn-controls"
            color="#FF8D3F"
            @click="crudAtividade"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { EventBus } from "../../services/event-bus";
import {
  cadastrarAtividade,
  editarAtividade,
} from "../../services/providers/atividadesProvider";

const defaultForm = {
  descricao: null,
  indicador: null,
  duracaoInicio: 1,
  duracaoFim: 36,
};

export default {
  name: "ModalCrudAtividades",
  props: {
    modalVisible: Boolean,
    atividade: Object,
    meta: Object,
  },
  data: () => ({
    isEdit: false,
    formFields: { ...defaultForm },
  }),
  created() {
    this.isEdit = !!this.atividade;
    if (this.atividade) {
      this.formFields = {
        descricao: this.atividade.descricao,
        indicador: this.atividade.indicador,
        duracaoFim: this.atividade.fim,
        duracaoInicio: this.atividade.inicio,
      };
    }
  },
  methods: {
    closeModal(reload) {
      this.formFields = { ...defaultForm };
      if (reload) {
        EventBus.$emit("atividade-updated");
      }
      this.$emit("close");
    },
    async crudAtividade() {
      if (this.$refs.formAtividade.validate()) {
        try {
          const payload = {
            meta: {
              id: this.meta.id,
            },
            descricao: this.formFields.descricao,
            indicador: this.formFields.indicador,
            fim: this.formFields.duracaoFim,
            inicio: this.formFields.duracaoInicio,
          };
          if (this.isEdit) {
            await editarAtividade(this.atividade.id, payload);
          } else {
            await cadastrarAtividade(payload);
          }
          this.closeModal(true);
          this.$notify({
            title: "Atividade cadastrada/editada!",
            text: "Atividade cadastrada/editada com sucesso!",
            type: "success",
            duration: 5000,
          });
        } catch (error) {
          console.error(error);
          this.$notify({
            title: "Erro interno!",
            text: error.message || error,
            type: "error",
            duration: 15000,
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.session-title {
  font-size: 22px !important;
  padding-left: 20px;
  color: $primary;
  font-weight: 500;
}
.session {
  width: 100%;
  padding: 15px;
  padding-left: 25px;
  background-color: #e6f4f1;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  display: flex;
  color: #037f8c;
}
</style>
