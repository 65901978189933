import api from "../api";

export const getExecucaoPorId = async (idExecucao) => {
  const res = await api.get(`/execucoes/${idExecucao}`);
  return res.data;
};

export const cadastrarExecucao = async (payload) => {
  const res = await api.post(`/execucoes/cadastrar`, payload);
  return res.data;
};

export const editarExecucao = async (idExecucao, payload) => {
  const res = await api.patch(`/execucoes/${idExecucao}`, payload);
  return res.data;
};

export const deletarExecucao = async (idExecucao) => {
  const res = await api.delete(`/execucoes/${idExecucao}`);
  return res.data;
};

export const cadastrarRequisicao = async (payload) => {
  const res = await api.post(`/execucoes/adicionar-requisicao`, payload);
  return res.data;
};

export const editarRequisicao = async (idRequisicao, payload) => {
  const res = await api.patch(`/requisicoes/${idRequisicao}`, payload);
  return res.data;
};

export const deletarRequisicao = async (payload) => {
  const res = await api.delete(`/execucoes/remover-requisicao`, {
    data: payload,
  });
  return res.data;
};

export const cadastrarEmpenho = async (payload) => {
  const res = await api.post(`/execucoes/adicionar-empenho`, payload);
  return res.data;
};

export const editarEmpenho = async (idEmpenho, payload) => {
  const res = await api.patch(`/empenhos/${idEmpenho}`, payload);
  return res.data;
};

export const deletarEmpenho = async (payload) => {
  const res = await api.delete(`/execucoes/remover-empenho`, { data: payload });
  return res.data;
};
