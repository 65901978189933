<template>
  <div class="container_div">
    <p class="title">ÁREA EM CONSTRUÇÃO</p>
    <p class="desc">Disponível em breve</p>
    <br>
    <img height="520" src="../assets/area_contrucao.png" />
  </div>
</template>

<script>
export default {
  name: "AreaConstrucao",
};
</script>

<style lang="scss" scoped>
.container_div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.title {
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 35px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #026873;
}
.desc {
  font-family: Raleway;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #026873;
}
</style>