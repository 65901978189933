<template>
  <v-col>
    <div class="header">
      <PageTitle title="<b>DETALHAMENTO DAS DESPESAS</b>" />
      <v-btn
        outlined
        depressed
        color="#037F8C"
        @click="
          (modalExecucaoOpen = !modalExecucaoOpen) &
            (execucaoSelecionada = null)
        "
        >ADICIONAR DESPESA
      </v-btn>
    </div>
    <br />
    <div>
      <v-simple-table dense v-if="execucoes.length" :key="execucoes.length">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="title-tab" style="width: 35%">
                Descrição
              </th>
              <th class="title-tab" style="width: 5%">QTD</th>
              <th class="title-tab">Valor Unitário</th>
              <th class="title-tab">Valor Total</th>
              <th class="title-tab">Fonte</th>
              <th class="title-tab" style="width: 160px"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(execucao, idx) in execucoes" v-bind:key="idx">
              <td
                v-if="execucao.descricao"
                style="width: 35%; text-align: left"
                class="text-tab"
              >
                {{ execucao.descricao }}
              </td>
              <td v-else style="width: 30%; text-align: left" class="text-tab">
                -
              </td>
              <td v-if="execucao.quantidade" class="text-tab">
                {{ execucao.quantidade }}
              </td>
              <td v-else class="text-tab">-</td>
              <td v-if="execucao.valorUnitario" class="text-tab">
                {{ execucao.valorUnitario | currency }}
              </td>
              <td v-else class="text-tab">-</td>
              <td v-if="execucao.valorTotal" class="text-tab">
                {{ execucao.valorTotal | currency }}
              </td>
              <td v-else class="text-tab">-</td>
              <td v-if="execucao.recurso.fonte" class="text-tab">
                {{ execucao.recurso.fonte.descricao }}
              </td>
              <td v-else class="text-tab">-</td>
              <td class="text-tab" style="padding: 0px !important">
                <v-btn
                  small
                  text
                  @click="
                    (modalVerExecucaoOpen = !modalVerExecucaoOpen) &
                      (execucaoSelecionada = execucao)
                  "
                >
                  <v-icon color="#037F8C">mdi-eye-outline</v-icon>
                </v-btn>
                <v-btn
                  small
                  text
                  @click="
                    (modalExecucaoOpen = !modalExecucaoOpen) &
                      (execucaoSelecionada = execucao)
                  "
                >
                  <v-icon color="#037F8C">mdi-pencil-box-outline</v-icon>
                </v-btn>
                <v-btn
                  small
                  text
                  @click="
                    (modalExcluirExecucao = !modalExcluirExecucao) &
                      (execucaoSelecionada = execucao)
                  "
                >
                  <v-icon color="#037F8C">mdi-delete-outline</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <p v-else style="font-family: Raleway; font-size: 18px">
        Nenhuma despesa realizada!
      </p>

      <ModalExecucao
        :key="modalExecucaoOpen"
        :modalVisible="modalExecucaoOpen"
        :isEdit="!!execucaoSelecionada"
        :execucaoProp="execucaoSelecionada"
        :item="item"
        @close="modalExecucaoOpen = !modalExecucaoOpen"
      />
      <ModalVerExecucao
        :modalVisible="modalVerExecucaoOpen"
        :execucao="execucaoSelecionada"
        @close="modalVerExecucaoOpen = !modalVerExecucaoOpen"
      />
      <ModalConfirmacao
        :modalVisible="modalExcluirExecucao"
        v-if="execucaoSelecionada"
        :titulo="'Você realmente deseja excluir a execução'"
        :item="execucaoSelecionada.descricao"
        textoBotaoDireito="Excluir"
        :onConfirm="excluirExecucao"
        @close="
          (modalExcluirExecucao = !modalExcluirExecucao) &
            (execucaoSelecionada = null)
        "
      />
    </div>
  </v-col>
</template>

<script>
import { EventBus } from "../../../services/event-bus";
import { deletarExecucao } from "../../../services/providers/execucaoProvider";
import { listarExecucoesItem } from "../../../services/providers/itensProvider";
import { getProjetoById } from "../../../services/providers/projetosProvider";
import ModalExecucao from "../../modals/ModalExecucao";
import ModalConfirmacao from "../../modals/ModalConfirmacao";
import ModalVerExecucao from "../../modals/ModalVerExecucao";
import PageTitle from "../../PageTitle.vue";

export default {
  name: "DetalhamentoDespesas",
  props: {
    item: Object,
  },
  components: {
    ModalExecucao,
    ModalVerExecucao,
    ModalConfirmacao,
    PageTitle,
  },
  data: () => ({
    execucoes: [],
    modalExecucaoOpen: false,
    modalVerExecucaoOpen: false,
    modalExcluirExecucao: false,
    execucaoSelecionada: null,
  }),
  async mounted() {
    try {
      this.execucoes = await listarExecucoesItem(this.item.id);
    } catch (error) {
      console.error(error);
      this.$notify({
        title: "Erro interno!",
        text: error.message || error,
        type: "error",
        duration: 15000,
      });
    }
  },
  methods: {
    getStatusEmpenho(execucao) {
      if (execucao.empenhos && execucao.empenhos.length) {
        if (execucao.empenhos.length > 1) {
          return `${execucao.empenhos[0].situacao ||
            "Nenhum"}, entre outros...`;
        }
        return execucao.empenhos[0].situacao || "Nenhum";
      }
      return "Nenhum";
    },
    async excluirExecucao() {
      if (this.execucaoSelecionada) {
        try {
          await deletarExecucao(this.execucaoSelecionada.id);
          this.$notify({
            title: "Execução escluída!",
            text: "A execução foi excluída com sucesso!",
            type: "success",
            duration: 5000,
          });
          const projeto = await getProjetoById(this.item.subprojeto.projetoId);
          if (projeto) {
            this.$actions.setProjeto(projeto);
          }
          EventBus.$emit("execucao-updated");
        } catch (error) {
          console.error(error);
          this.$notify({
            title: "Erro ao excluir a execução!",
            text: error.message,
            type: "error",
            duration: 15000,
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
hr {
  background-color: #c4c4c4 !important;
}
p {
  margin: 0px;
}
.title-tab {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 15px !important;
  line-height: 16px;
  text-align: center !important;
  color: #037f8c !important;
  border: 0.899715px solid rgba(97, 97, 97, 0.3);
  padding: 15px !important;
}
.text-tab {
  font-family: Roboto;
  font-style: normal;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  color: #616161 !important;
  border: 0.899715px solid rgba(97, 97, 97, 0.3);
  padding: 20px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}
</style>
