<template>
  <div>
    <div class="container-div">
      <div class="header">
        <div>
          <v-btn
            class="header-button"
            large
            color="white"
            text
            @click="$router.go(-1)"
          >
            <v-icon large color="#037F8C">mdi-arrow-left-circle-outline</v-icon>
            <PageTitle :title="'<b>REFORMULAÇÃO</b>'" size="18" />
          </v-btn>
        </div>
      </div>
    </div>
    <br />
    <div v-if="$store.projeto.reformulacao && reformulacaoAtiva" class="container-div">
      <div class="reformulacao-div">
        <v-row>
          <v-col md="2">
            <p class="reformulacao-title">Projeto em reformulação</p>
          </v-col>
          <v-col md="7" style="display: flex; justify-content: center">
            <div
              style="display: flex; width: 60%; justify-content: space-between"
            >
              <div>
                <p class="label-title">Iniciado em:</p>
                <p v-if="reformulacaoAtiva.dataCriacao">
                  {{
                    new Date(reformulacaoAtiva.dataCriacao)
                      | moment("DD/MM/YYYY HH:mm")
                  }}
                </p>
                <p v-else>-</p>
              </div>
              <div>
                <p class="label-title">Por:</p>
                <p v-if="reformulacaoAtiva.usuarioCriacao">
                  <v-icon style="margin-right: 5px" color="#FFF"
                    >mdi-account</v-icon
                  >
                  {{ reformulacaoAtiva.usuarioCriacao.login }}
                </p>
                <p v-else>-</p>
              </div>
              <div>
                <p class="label-title">Última atualização:</p>
                <p v-if="reformulacaoAtiva.ultimaAlteracao">
                  {{
                    new Date(reformulacaoAtiva.ultimaAlteracao)
                      | moment("DD/MM/YYYY HH:mm")
                  }}
                </p>
                <p v-else>-</p>
              </div>
            </div>
          </v-col>
          <v-col
            md="3"
            style="display: flex; justify-content: center; align-items: center"
          >
            <v-btn
              class="btn-controls"
              depressed
              color="#FF8D3F"
              width="250"
              style="margin-right: 10px"
              large
              @click="
                modalReformulacaoOpen =
                  !modalReformulacaoOpen & (isFinish = true)
              "
            >
              FINALIZAR REFORMULAÇÃO
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div
        v-if="
          reformulacaoAtiva && Object.keys(reformulacaoAtiva.alteracoes).length
        "
      >
        <br />
        <br />
        <p class="item-title">Alterações</p>
        <v-row
          v-for="key in Object.keys(reformulacaoAtiva.alteracoes)"
          :key="key"
        >
          <v-col>
            <v-expansion-panels style="padding: 0px !important">
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <p
                    class="item-title"
                    style="border-bottom: 1px solid #037F8C; padding-bottom: 10px"
                  >
                    {{ key }}
                  </p>
                </v-expansion-panel-header>
                <v-expansion-panel-content style="display: flex">
                  <div
                    v-for="(alteracao, idx) in reformulacaoAtiva.alteracoes[
                      key
                    ]"
                    :key="idx"
                  >
                    <div class="item-div">{{ alteracao.item.descricao }}</div>
                    <Historico
                      :itemProp="alteracao.item"
                      :historicoProp="alteracao.historico"
                    />
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </div>
    </div>
    <div v-else class="container-div">
      <div style="display: flex; justify-content: space-between">
      <div>
        <p class="item-title">Reformulações</p>
      </div>
        <v-btn
          class="btn-controls"
          depressed
          color="#FF8D3F"
          width="250"
          style="margin-right: 10px"
          @click="modalReformulacaoOpen = !modalReformulacaoOpen"
        >
          REFORMULAR PROJETO
        </v-btn>
      </div>

      <br>

      <span
          v-if="!reformulacoes.length"
          style="font-family: Raleway; font-size: 18px"
        >
          Este projeto ainda não sofreu reformulação...
      </span>

      <div v-else>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="title-tab">Nº</th>
                <th class="title-tab">INICIADO EM</th>
                <th class="title-tab">FINALIZADO EM</th>
                <th class="title-tab">REALIZADO POR</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(reformulacao, idx) in reformulacoes" :key="idx">
                <td class="text-tab">
                  {{ idx + 1 }}
                </td>
                <td v-if="reformulacao.dataCriacao" class="text-tab">
                  {{
                    new Date(reformulacao.dataCriacao)
                      | moment("DD/MM/YYYY HH:mm")
                  }}
                </td>
                <td v-else class="text-tab">-</td>
                <td v-if="reformulacao.dataEdicao" class="text-tab">
                  {{
                    new Date(reformulacao.dataEdicao)
                      | moment("DD/MM/YYYY HH:mm")
                  }}
                </td>
                <td v-else class="text-tab">-</td>
                <td v-if="reformulacao.usuarioCriacao" class="text-tab">
                  {{ reformulacao.usuarioCriacao.login }}
                </td>
                <td v-else class="text-tab">-</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </div>
    </div>
    <ModalReformulacaoProjeto
      :modalVisible="modalReformulacaoOpen"
      :isFinish="isFinish"
      @close="
        (modalReformulacaoOpen = !modalReformulacaoOpen) &
          (isFinish = !isFinish)
      "
      @reload="listarReformulacoes"
    />
  </div>
</template>

<script>
import ModalReformulacaoProjeto from "../../components/modals/ModalReformulacaoProjeto.vue";
import { listarReformulacoesProjeto } from "../../services/providers/reformulacaoProvider";
import Historico from "../../components/itens/Historico.vue";
import PageTitle from "../../components/PageTitle";

export default {
  name: "ReformulacaoProjeto",
  components: {
    ModalReformulacaoProjeto,
    Historico,
    PageTitle,
  },
  data: () => ({
    modalReformulacaoOpen: false,
    isFinish: false,
    reformulacoes: [],
    reformulacaoAtiva: null,
  }),
  mounted() {
    this.listarReformulacoes();
  },
  methods: {
    async listarReformulacoes() {
      try {
        const reformulacoes = await listarReformulacoesProjeto(
          this.$store.projeto.id
        );
        reformulacoes.forEach((r) => {
          const formatted = {};
          r.alteracoes.forEach((alteracao) => {
            const key = alteracao.item.subprojeto.codigo;
            formatted[key] = [...(formatted[key] || []), alteracao];
          });
          r.alteracoes = formatted;
        });
        this.reformulacaoAtiva = reformulacoes
          .reverse()
          .filter((r) => r.ativo)[0];
        this.reformulacoes = reformulacoes;
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro ao buscar reformulações do projeto!",
          text: error.message,
          type: "error",
          duration: 15000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.reformulacao-div {
  background-color: #1da7a3;
  width: 100%;
  display: flex;
  padding: 40px;
  border-radius: 20px;
}
.reformulacao-title {
  font-family: Raleway;
  font-weight: bold;
  font-size: 28px;
  color: #f2f2f2;
}
p {
  color: white;
  font-size: 18px;
}
.label-title {
  font-size: 18px !important;
  font-weight: bold !important;
}
.item-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  color: #037f8c;
  text-transform: uppercase;
}
.item-div {
  height: 50px;
  width: 100%;
  background: #e6f4f1;
  border: 0.787547px solid rgba(97, 97, 97, 0.3);
  display: flex;
  align-items: center;
  padding-left: 40px;
  color: #037f8c;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: bold;
  text-overflow: ellipsis;
}
.title-tab {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 15px !important;
  line-height: 16px;
  text-align: center !important;
  color: #037f8c !important;
  border: 0.899715px solid rgba(97, 97, 97, 0.3);
  padding: 10px !important;
}
.text-tab {
  font-family: Roboto;
  font-style: normal;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  color: #616161 !important;
  border: 0.899715px solid rgba(97, 97, 97, 0.3);
  padding: 10px !important;
  vertical-align: middle !important;
}
</style>
