<template>
  <v-dialog v-model="modalVisible" persistent max-width="650">
    <v-form ref="formJustificativa">
      <v-card style="border-radius: 10px">
        <br />
        <v-card-title class="modal-title">Justificativa</v-card-title>
        <v-card-text>
          <v-textarea
            v-model="justificativa"
            autofocus
            outlined
            rows="7"
            placeholder="Adicione uma justificativa para seguir com as alterações feitas..."
            :rules="[(v) => !!v || 'A justificativa é obrigatória']"
            required
          ></v-textarea>
        </v-card-text>
        <v-card-actions
          style="display: flex; justify-content: space-evenly; padding-bottom: 20px"
        >
          <v-btn outlined width="190" color="#FF8D3F" @click="closeModal(false)"
            >Cancelar</v-btn
          >
          <v-btn
            depressed
            width="190"
            class="btn-controls"
            color="#FF8D3F"
            @click="confirm"
            >Salvar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
export default {
  name: "ModalJustificativa",
  props: {
    modalVisible: Boolean,
  },
  data: () => ({
    justificativa: "",
  }),
  methods: {
    closeModal() {
      this.justificativa = "";
      this.$emit("close");
    },
    confirm() {
      if (this.$refs.formJustificativa.validate()) {
        this.$emit("confirm", this.justificativa);
        this.$refs.formJustificativa.reset();
      } else {
        this.$notify({
          title: "Erro!",
          text: "Preencha todas as informações obrigatórias",
          type: "error",
          duration: 15000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
