export const convertCurrencyToNumber = (string) => {
  return typeof string === "string"
    ? parseFloat(
        parseFloat(
          String(string)
            .replaceAll(".", "")
            .replaceAll(",", ".")
        ).toFixed(2)
      )
    : string;
};

export const convertNumberToCurrency = (number) => {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(number);
};

export const decimalAdjust = (num, dec) => {
  if (typeof num !== "number" || typeof dec !== "number") return false;
  var num_sign = num >= 0 ? 1 : -1;
  return +(
    Math.round(num * Math.pow(10, dec) + num_sign * 0.0001) / Math.pow(10, dec)
  ).toFixed(dec);
};
