var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.projeto)?_c('div',[_c('v-card',{staticClass:"card",staticStyle:{"border":"none"},attrs:{"min-width":"350px","max-width":"380px","height":"600px","outlined":""}},[_c('v-card-text',{staticStyle:{"padding-bottom":"0px"}},[_c('div',[_c('p',{staticStyle:{"color":"#000","padding-top":"0px","font-weight":"bold"},attrs:{"id":"card-title"}},[_vm._v(" "+_vm._s(_vm.projeto.codigo)+" ")]),_c('p',{staticStyle:{"padding-top":"0px","height":"70px"},attrs:{"id":"card-title"}},[_vm._v(" "+_vm._s(_vm.projeto.descricao)+" ")])]),_c('br'),(_vm.parcelas.length)?_c('div',{staticClass:"parcelas-container"},_vm._l((_vm.parcelas),function(parcela){return _c('div',{key:parcela.id},[(parcela)?_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({attrs:{"id":'progress_bar_' + _vm.projeto.id + '_' + parcela.id}},on),[_c('p',{staticClass:"progress_bar_desc"},[_vm._v("REALIZADO")])])]}}],null,true)},[_c('span',{staticStyle:{"text-align":"left","text-transform":"uppercase","font-size":"13px","font-weight":"bold"}},[_vm._v(" Executado: "+_vm._s(_vm._f("currency")(parcela.valorExecutado))+" "),_c('p',[_vm._v(" Desembolsado: "+_vm._s(_vm._f("currency")(parcela.valorDesembolsado))+" ")]),_c('p',[_vm._v("Previsto: "+_vm._s(_vm._f("currency")(parcela.valorAlocado)))])])])],1):_vm._e(),_c('p',{staticClass:"parcela-title"},[_vm._v("Parcela "+_vm._s(parcela.id))])])}),0):_c('div',{staticClass:"div-sem-parcelas"},[_c('p',{staticStyle:{"padding-top":"0px","height":"60px"},attrs:{"id":"card-title"}},[_vm._v(" Nenhuma parcela cadastrada! ")])]),_c('br'),_c('div',[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('span',{attrs:{"id":"metrica-title"}},[_vm._v("TOTAL EXECUTADO")]),(_vm.projeto.metricas.valorAutorizado != null)?_c('span',{staticClass:"metrica-ammount",attrs:{"id":"ammount-text"}},[_vm._v(_vm._s(_vm._f("currency")(_vm.projeto.metricas.valorAutorizado)))]):_c('span',{staticClass:"metrica-ammount",attrs:{"id":"ammount-text"}},[_vm._v("Não informado")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({attrs:{"id":'progress_bar_line_' + _vm.projeto.id + '_execucao'}},on))]}}],null,false,3709055735)},[(_vm.projeto.metricas.valorExecutado != null)?_c('span',{staticStyle:{"text-align":"center","text-transform":"uppercase","font-size":"13px"}},[_vm._v(" Executado: "),_c('p',[_vm._v(" "+_vm._s(parseInt( _vm.projeto.metricas.percentualExecutadoPorContratado * 100 ))+"% ")]),_vm._v(" "+_vm._s(_vm._f("currency")(_vm.projeto.metricas.valorExecutado))+" ")]):_c('span',[_vm._v("Não informado")])]),_c('br'),_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('span',{attrs:{"id":"metrica-title"}},[_vm._v("TOTAL DESEMBOLSADO")]),(_vm.projeto.metricas.valorDesembolsado != null)?_c('span',{staticClass:"metrica-ammount",attrs:{"id":"ammount-text"}},[_vm._v(_vm._s(_vm._f("currency")(_vm.projeto.metricas.valorDesembolsado)))]):_c('span',{staticClass:"metrica-ammount",attrs:{"id":"ammount-text"}},[_vm._v("Não informado")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({attrs:{"id":'progress_bar_line_' + _vm.projeto.id + '_desembolso'}},on))]}}],null,false,982276531)},[(_vm.projeto.metricas.valorDesembolsado != null)?_c('span',{staticStyle:{"text-align":"center","text-transform":"uppercase","font-size":"13px"}},[_vm._v(" Desembolsado: "),_c('p',[_vm._v(_vm._s(parseInt(_vm.percentExecDesembolso * 100))+"%")]),_vm._v(" "+_vm._s(_vm._f("currency")(_vm.projeto.metricas.valorExecutado))+" ")]):_c('span',[_vm._v("Não informado")])])],1),_c('br'),_c('v-row',[_c('v-col',[_c('div',{staticClass:"ammount-box"},[_c('p',[_c('b',[_vm._v("SALDO RENDIMENTO")])]),(
                _vm.projeto.metricasRendimento &&
                  _vm.projeto.metricasRendimento.saldoNaoExecutado != null
              )?_c('p',[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.projeto.metricasRendimento.saldoNaoExecutado))+" ")]):_c('p',[_vm._v("Não informado")])])]),_c('v-col',[_c('div',{staticClass:"ammount-box"},[_c('p',[_c('b',[_vm._v("SALDO FNDCT")])]),(
                _vm.projeto.metricas && _vm.projeto.metricas.saldoNaoExecutado != null
              )?_c('p',[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.projeto.metricas.saldoNaoExecutado))+" ")]):_c('p',[_vm._v("Não informado")])])])],1),(_vm.projeto.reformulacao)?_c('div',{staticClass:"reformulacao-status"},[_c('v-icon',{attrs:{"color":"#FF8D3F"}},[_vm._v("mdi-exclamation-thick")]),_c('p',[_vm._v("Projeto em reformulação")])],1):_c('br')],1),_c('v-card-actions',{staticClass:"card-actions"},[_c('v-btn',{staticStyle:{"color":"#fff","border-radius":"8px"},attrs:{"depressed":"","width":"190","color":"#037F8C"},on:{"click":_vm.openProjetoPage}},[_vm._v("Ver mais")])],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }