<template>
  <div>
    <div v-if="(desembolsos) && (desembolsos.length > 0)">
      <v-simple-table dense>
        <template>
          <thead>
            <tr>
              <th class=" title-tab" style="width: 5%">Nº</th>
              <th class=" title-tab" style="width: 30%">Nº DE OFICIO</th>
              <th class=" title-tab" style="width: 25%">PARCELA</th>
              <th class=" title-tab" style="width: 25%">DATA DO DESEMBOLSO</th>
              <th class=" title-tab" style="width: 15%"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(desembolso, key) in desembolsos" :key="key">
              <td class="text-tab" style="width: 5%">{{ key + 1 }}</td>
              <td class="text-tab" style="width: 30%">{{ desembolso.numeroOficio }}</td>
              <td class="text-tab" style="width: 25%">{{ desembolso.parcela.numero }}</td>
              <td class="text-tab" style="width: 25%">{{ castDate(desembolso.data) }}</td>
              <td class="text-tab" style="width: 15%">
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      text
                      small
                      class="option-hover"
                      @click="
                        (modalEditarOpen = !modalEditarOpen) &
                          (solicitacaoSelecionada = desembolso)
                      "
                    >
                      <v-icon color="#ff8d3f">mdi-pencil-box-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>
                    Editar
                  </span>
                </v-tooltip>
                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      v-on="on"
                      text
                      small
                      class="option-hover"
                      @click="
                        (modalExcluirOpen = !modalExcluirOpen) &
                          (solicitacaoSelecionada = desembolso)
                      "
                    >
                      <v-icon color="#ff8d3f">mdi-delete-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>
                    Excluir
                  </span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
        </template>

      </v-simple-table>
    </div>

    <p v-else style="font-family: Raleway; font-size: 18px">
      Nenhum desembolso solicitado para este projeto!
    </p>
    <ModalSolicitacaoDesembolso
      v-if="modalEditarOpen"
      :modalVisible="modalEditarOpen"
      @close="modalEditarOpen = !modalEditarOpen"
      :solicitacao="solicitacaoSelecionada"
    />
    <ModalConfirmacao
      v-if="modalExcluirOpen"
      :modalVisible="modalExcluirOpen"
      :titulo="'Você realmente deseja excluir essa solicitação de desembolso'"
      textoBotaoDireito="Excluir"
      :onConfirm="excluirSolicitacao"
      @close="modalExcluirOpen = !modalExcluirOpen"
    />
  </div>
  
</template>



<script>

import {excluirSolicitacaoDesembolso } from "../../services/providers/desembolsoProvider";
import ModalConfirmacao from "../../components/modals/ModalConfirmacao";
import ModalSolicitacaoDesembolso from "../modals/ModalSolicitacaoDesembolso.vue";

export default {
  name: "DesembolsoTable",
  props: ["desembolsos"],
  components: {ModalConfirmacao, ModalSolicitacaoDesembolso},
  data() {
    return {
      solicitacaoSelecionada: null,
      modalEditarOpen: false,
      modalExcluirOpen: false,
    }
  },
  methods: {
    async excluirSolicitacao() {
      try {
        if (this.solicitacaoSelecionada) {
          let idSolicitacao = this.solicitacaoSelecionada.id;
          await excluirSolicitacaoDesembolso(idSolicitacao);
          this.$notify({
            title: "Solicitação Excluída",
            text: `Solicitação Excluída com sucesso!`,
            type: "success",
            duration: 5000,
          });
          this.$emit("update-solicitacoes");
        }
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro!",
          text: error.message,
          type: "error",
          duration: 15000,
        });
      }
    },
    castDate(dateString) {
      let date = new Date(dateString.split("-"));
      return date.toLocaleDateString("pt-Br")
    }
  }

};
</script>

<style lang="scss" scoped>
  .title-tab {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 16px;
    text-align: center !important;
    color: $primary !important;
    background-color: #e6f4f1;
    border: 0.899715px solid rgba(97, 97, 97, 0.3);
    height: 45px !important;
  }

  .text-tab {
    font-family: Roboto;
    font-style: normal;
    text-transform: uppercase;
    font-size: 13px;
    line-height: 13px;
    text-align: center;
    color: #616161;
    border: 0.899715px solid rgba(97, 97, 97, 0.3);
    height: 45px !important;
  }


</style>
