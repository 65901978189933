<template>
  <v-dialog v-model="modalVisible" persistent max-width="800">
    <v-card style="border-radius: 10px">
      <br />
      <v-card-title class="modal-title"
        >REGISTRAR ENTREGA DE RELATÓRIO</v-card-title
      >
      <v-card-text>
        <br />
        <v-row>
          <v-col md="12">
            <div class="session">
              {{ $store.projeto ? $store.projeto.codigo : "-" }}
            </div>
          </v-col>
        </v-row>
        <v-row style="margin-bottom: 10px">
          <v-col class="label-col" md="5">
            <p class="label" style="margin-top: 0px">Nº de Ofício:</p>
          </v-col>
          <v-col md="4">
            <v-text-field
             v-model="numeroOficio"
              outlined
              dense
              v-mask="'#####################'"
           ></v-text-field>
          </v-col>
        </v-row>
        <v-row style="margin-bottom: 10px">
          <v-col class="label-col" md="5">
            <p class="label" style="margin-top: 0px">Data do último envio:</p>
          </v-col>
          <v-col md="4">
            <DatePicker v-model="date" :key="date"/>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions
        style="display: flex; justify-content: center; padding-bottom: 20px"
      >
        <v-btn
          depressed
          outlined
          width="190"
          color="#FF8D3F"
          @click="closeModal()"
          >CANCELAR</v-btn
        >
        <v-btn
          v-if="!relatorioEdit"
          depressed
          class="btn-controls"
          width="190"
          color="#FF8D3F"
          @click="cadastrarNotificacaoRelatorio"
          >REGISTRAR</v-btn
        >
        <v-btn
          v-else
          depressed
          class="btn-controls"
          width="190"
          color="#FF8D3F"
          @click="editarRelatorio"
          >ALTERAR</v-btn
        >
      </v-card-actions>
    </v-card>
    <ModalSuccess
      :modalVisible="modalSuccessOpen"
      @close="modalSuccessOpen = !modalSuccessOpen"
    >
      <p class="success-title">Notificação cadastrada com sucesso!</p>
      <p class="success-title">
        O relatório do <b>{{ $store.projeto.codigo }}</b> deve ser entregue no
        dia <b>{{ new Date(date + " 00:00:00") | moment("DD/MM/YYYY") }}</b>
      </p>
    </ModalSuccess>
  </v-dialog>
</template>

<script>
import DatePicker from "../DatePicker";
import ModalSuccess from "./ModalSuccess";
import {
  cadastrarRelatorioPrestacaoContas,
  editarRelatorio,
} from "../../services/providers/RelatorioPrestContasProvider";
import { EventBus } from "../../services/event-bus";

export default {
  name: "ModalNotificarEntregaRelatorio",
  props: {
     relatorioEdit: Object,
     modalVisible: Boolean,
  },
  components: {
    DatePicker,
    ModalSuccess,
  },
  data: () => ({
    date: new Date().toISOString().slice(0, 10),
    modalSuccessOpen: false,
    editId: 0,
    numeroOficio: '',
  }),
  mounted() {
    if (this.relatorioEdit) {
      this.editId = this.relatorioEdit.id;
      this.date = this.relatorioEdit.dataExecucao;
      this.numeroOficio = this.relatorioEdit.numeroOficio;
    }
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async cadastrarNotificacaoRelatorio() {
      try {
        const payload = {
          projetoId: this.$store.projeto.id,
          dataExecucao: this.date,
          titulo: this.$store.projeto.codigo + " " + this.date,
          numeroOficio: this.numeroOficio,
        };
        await cadastrarRelatorioPrestacaoContas(payload);
        this.$notify({
          title: "Notificação cadastrada!",
          text: "Notificação cadastrada com sucesso!",
          type: "success",
          duration: 5000,
        });
        this.closeModal(true);
        this.modalSuccessOpen = !this.modalSuccessOpen;
        EventBus.$emit("RelatoriosTable-updated");
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro ao cadastrar notificação!",
          text: error.message,
          type: "error",
          duration: 15000,
        });
      }
    },
    async editarRelatorio() {
      if (this.relatorioEdit.id) {
        try {
          const payload = {
            dataExecucao: this.date,
            numeroOficio: this.numeroOficio,
          };
          await editarRelatorio(this.relatorioEdit.id, payload);
          this.closeModal(true);
          this.modalSuccessOpen = !this.modalSuccessOpen;
          EventBus.$emit("RelatoriosTable-updated");
        } catch (error) {
          console.error(error);
          this.$notify({
            title: "Erro!",
            text: "Erro ao editar o Relatorio!",
            type: "error",
            duration: 15000,
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.success-title {
  font-family: Raleway;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #037f8c;
}
.session {
  width: 100%;
  padding: 15px;
  padding-left: 25px;
  background-color: #e6f4f1;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  display: flex;
  color: #037f8c;
}
</style>
