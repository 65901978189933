<template>
  <div v-if="item" class="container-div">
    <div class="header">
      <v-btn class="header-button" color="white" text @click="$router.go(-1)">
        <v-icon large color="#037F8C">mdi-arrow-left-circle-outline</v-icon>
        <PageTitle
          :title="
            `<b>SUBPROJETOS/${$store.subProjeto.codigo}/Verficar Itens</b>/${nomeItem}`
          "
          size="18"
        />
      </v-btn>
      <div>
        <v-btn
          v-if="item && item.situacaoItem.codigo == 'E'"
          text
          @click="openModalRestaurarItem"
        >
          <v-icon color="#ff8d3f">mdi-restore</v-icon>
        </v-btn>
        <v-btn v-else text @click="openModalExcluirItem" :disabled="!itemEditavel">
          <v-icon large color="#ff8d3f">mdi-delete-outline</v-icon>
        </v-btn>
        <v-btn disabled text>
          <v-icon large color="#ff8d3f">mdi-download</v-icon>
        </v-btn>
      </div>
    </div>
    <br />
    <v-tabs
      background-color="white"
      color="#FF8D3F"
      center-active
      centered
      grow
    >
      <v-tabs-slider></v-tabs-slider>
      <v-tab @click="currentTab = 'info'" style="font-size: 18px"
        >INFORMAÇÕES GERAIS</v-tab
      >
      <v-tab @click="currentTab = 'historico'" style="font-size: 18px"
        >HISTÓRICO DO ITEM</v-tab
      >
      <v-tab @click="currentTab = 'comentarios'" style="font-size: 18px"
        >COMENTÁRIOS</v-tab
      >
      <v-tab-item :key="currentTab + 1">
        <InformacoesGerais :key="JSON.stringify(item)" :itemProp="item" />
      </v-tab-item>
      <v-tab-item :key="currentTab + 2">
        <div class="container-div">
          <br />
          <Historico :key="JSON.stringify(item)" :itemProp="item" />
        </div>
      </v-tab-item>
      <v-tab-item :key="currentTab + 3">
        <Comentarios :key="JSON.stringify(item)" :itemProp="item" />
      </v-tab-item>
    </v-tabs>

    <ModalConfirmacao
      v-if="item"
      :modalVisible="modalExcluirOpen"
      :titulo="'Você realmente deseja excluir o item'"
      :item="item.descricao"
      textoBotaoDireito="Excluir"
      :onConfirm="openModalJustificativaExcluir"
      @close="closeModalExcluirItem"
    />
    <ModalConfirmacao
      v-if="item"
      :modalVisible="modalRestaurarOpen"
      :titulo="'Você realmente deseja restaurar o item'"
      :item="item.descricao"
      texto="Obs.: Os valores atribuidos ateriormente a esse item aparecerão zerados."
      textoBotaoDireito="Restaurar"
      :onConfirm="openModalJustificativaRestaurar"
      @close="closeModalRestaurarItem"
    />
    <ModalJustificativa
      :modalVisible="modalJustificativaExcluirOpen"
      :item="item"
      @close="closeModalJustificativa"
      @confirm="excluirItem"
    />
    <ModalJustificativa
      :modalVisible="modalJustificativaRestaurarOpen"
      :item="item"
      @close="closeModalJustificativa"
      @confirm="restaurarItemFn"
    />
  </div>
</template>

<script>
import {
  deletarItem,
  getItem,
  restaurarItem,
} from "../../services/providers/itensProvider";
import PageTitle from "../PageTitle";
import Comentarios from "./Comentarios";
import Historico from "./Historico";
import InformacoesGerais from "./InformacoesGerais/InformacoesGerais";
import ModalConfirmacao from "../modals/ModalConfirmacao";
import ModalJustificativa from "../modals/ModalJustificativa";
import { getProjetoById } from "../../services/providers/projetosProvider";

export default {
  name: "VerItem",
  components: {
    PageTitle,
    InformacoesGerais,
    Historico,
    Comentarios,
    ModalConfirmacao,
    ModalJustificativa,
  },
  data: () => ({
    item: null,
    modalExcluirOpen: false,
    modalRestaurarOpen: false,
    justificativa: "",
    modalJustificativaExcluirOpen: false,
    modalJustificativaRestaurarOpen: false,
    currentTab: "info",
  }),
  mounted() {
    window.scroll(0,0);
    this.getData();
  },
  computed: {
    nomeItem: function() {
      return this.item.descricao.slice(0, 40) + "...";
    },
    itemEditavel() {

      const projetoEmReformulacao = this.$store.projeto.reformulacao ? true : false;
      const projetoEmCadastro = this.$store.projeto.estadoCadastroProjeto != 'FINALIZADO';

      return projetoEmReformulacao || projetoEmCadastro;
    },
  },
  methods: {
    async getData() {
      try {
        this.item = await getItem(this.$route.params.iditem);
        this.closeModalJustificativa();
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro interno!",
          text: error.message || error,
          type: "error",
          duration: 15000,
        });
      }
    },
    async excluirItem(justificativa) {
      try {
        const payload = {
          justificativa,
        };
        await deletarItem(this.item.id, payload);
        this.$notify({
          title: "Item exlcuído!",
          text: `Item exlcuído com sucesso!`,
          type: "success",
          duration: 5000,
        });
        const projeto = await getProjetoById(this.item.subprojeto.projetoId);
        if (projeto) {
          this.$actions.setProjeto(projeto);
        }
        this.$router.go(-1);
      } catch (error) {
        this.closeModalJustificativa();
        console.error(error);
        this.$notify({
          title: "Erro!",
          text: error.message,
          type: "error",
          duration: 15000,
        });
      }
    },
    closeModalExcluirItem() {
      this.modalExcluirOpen = false;
    },
    openModalExcluirItem() {
      this.modalExcluirOpen = !this.modalExcluirOpen;
    },
    async restaurarItemFn(justificativa) {
      try {
        const payload = {
          justificativa,
        };
        const res = await restaurarItem(this.item.id, payload);
        this.$notify({
          title: "Item restaurado!",
          text: `Item restaurado com sucesso!`,
          type: "success",
          duration: 5000,
        });
        const projeto = await getProjetoById(res.subprojeto.projetoId);
        if (projeto) {
          this.$actions.setProjeto(projeto);
        }
        await this.getData();
      } catch (error) {
        console.error(error);
        this.closeModalJustificativa();
        this.$notify({
          title: "Erro!",
          text: "Erro ao restaurar o item!",
          type: "error",
          duration: 15000,
        });
      }
    },
    closeModalRestaurarItem() {
      this.modalRestaurarOpen = false;
    },
    openModalRestaurarItem() {
      this.modalRestaurarOpen = !this.modalRestaurarOpen;
    },
    closeModalJustificativa() {
      this.modalJustificativaExcluirOpen = false;
      this.modalJustificativaRestaurarOpen = false;
    },
    openModalJustificativaExcluir() {
      this.modalJustificativaExcluirOpen = !this.modalJustificativaExcluirOpen;
    },
    openModalJustificativaRestaurar() {
      this.modalJustificativaRestaurarOpen = !this
        .modalJustificativaRestaurarOpen;
    },
  },
};
</script>
