<template>
  <v-container style="max-width: 1450px">
    <PageTitle title="<b>Projetos Finalizados</b>" />
    <AreaConstrucao />
  </v-container>
</template>

<script>
import PageTitle from "../components/PageTitle";
import AreaConstrucao from "../components/AreaContrucao";
export default {
  name: "ProjetosFinalizados",
  components: {
    PageTitle,
    AreaConstrucao,
  },
};
</script>

<style lang="scss" scoped></style>
