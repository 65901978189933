<template>
  <v-dialog v-model="modalVisible" persistent max-width="700">
    <v-card class="confirm-content">
      <br />
      <v-card-title style="display: flex; justify-content: center">
        <v-icon color="#FF8D3F" style="font-size: 80px !important"
          >mdi-exclamation-thick</v-icon
        >
      </v-card-title>
      <v-card-text
        style="display: flex; justify-content: center; flex-direction: column"
      >
        <br />
        <p class="alert-title">ATENÇÃO</p>
        <br />
        <p style="font-size: 18px; text-align: center">
          Ao finalizar o cadastro, as informações serão aplicadas ao projeto.
          Para realizar alterações será necessário editar no próprio projeto.
        </p>
        <br />
      </v-card-text>
      <v-card-actions
        style="
          display: flex;
          justify-content: space-evenly;
          padding-bottom: 20px;
        "
      >
        <v-btn outlined width="190" color="#FF8D3F" @click="closeModal(false)">
          CANCELAR
        </v-btn>
        <v-btn depressed class="btn-controls" color="#FF8D3F" @click="finalizar">
          FINALIZAR CADASTRO
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { finalizarProjeto } from "./../../services/providers/CadastroProjeto";
export default {
  name: "ModalFinalizarCadastro",
  props: {
    modalVisible: Boolean,
    idProjeto: Number
  },
  methods: {
    closeModal(reload) {
      this.$emit("close");
      if (reload) {
        this.$emit("reload");
      }
    },
    async finalizar() {
      try {
        await finalizarProjeto(this.idProjeto);
          this.$notify({
            title: "Dados do Projeto cadastrado com Sucesso!",
            text: "Dados do Projeto cadastrado com sucesso!",
            type: "success",
            duration: 5000,
          });
          this.$router.push({
             name: "cadastro-finalizado",
             params: { id: this.idProjeto},
          });
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro ao cadastrar dados do projeto",
          text: error.message,
          type: "error",
          duration: 15000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-controls {
  border-radius: 8px;
  color: #fff;
}
.confirm-content {
  border: 2px solid #ff8d3f;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
}
.alert-title {
  font-family: Raleway;
  font-size: 42px;
  color: #ff8d3f;
  text-align: center;
  font-weight: bold;
}
</style>