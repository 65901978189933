<template>
  <v-dialog v-model="modalVisible" persistent max-width="800">
    <v-card style="border-radius: 10px">
      <br />
      <div style="width: 100%; display: flex; justify-content: flex-end">
        <v-btn text large @click="closeModal()">
          <v-icon large color="#037F8C">mdi-close-circle-outline</v-icon>
        </v-btn>
      </div>
      <v-card-title v-if="isHistorico" class="modal-title"
        >GERAR RELATÓRIO DE HISTÓRICO DE ITENS:</v-card-title
      >
      <v-card-title v-else class="modal-title">GERAR RELATÓRIO</v-card-title>
      <v-card-text>
        <v-row v-if="isHistorico">
          <v-col md="12">
            <v-row>
              <v-col md="2"></v-col>
              <v-col
                class="label-col date"
                style="text-align: left !important"
                md="10"
                >Relatório referente ao periodo:</v-col
              >
            </v-row>
            <v-row>
              <v-col md="2"></v-col>
              <v-col md="4">
                <DatePicker v-model="date" />
              </v-col>
              <v-col
                md="1"
                class="label-col date"
                style="text-align: center; margin-top: 7px"
                >até</v-col
              >
              <v-col md="4">
                <DatePicker v-model="dateFim" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col md="8">
            <v-row>
              <v-col
                class="label-col date"
                style="
                  text-align: ritght !important;
                  justify-content: flex-end !important;
                "
                md="3"
                >Relatório referente a:</v-col
              >
              <v-col md="6">
                <DatePicker v-model="date" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="label-col label" md="2">Subprojeto:</v-col>
          <v-col>
            <v-select
              class="select-form"
              outlined
              hide-details
              clearable
              small-chips
              multiple
              dense
              color="#037F8C"
              item-color="#037F8C"
              :items="subprojetos"
              item-text="label"
              item-value="value"
              label="Todos os subprojetos"
              solo
              v-model="subProjetoSelecionados"
              ><template v-slot:prepend-item>
                <v-list-item
                  ripple
                  @click="
                    subProjetoSelecionados = selecionarTodasOpcoes(
                      subProjetoSelecionados,
                      subprojetos
                    )
                  "
                >
                  <v-list-item-action>
                    <v-icon>
                      {{
                        subProjetoSelecionados.length == subprojetos.length
                          ? "mdi-close-box"
                          : "mdi-checkbox-blank-outline"
                      }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title> Selecionar todos </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="label-col label" md="2">Rubrica:</v-col>
          <v-col>
            <v-select
              class="select-form"
              outlined
              hide-details
              clearable
              small-chips
              multiple
              dense
              label="Todas as rubricas"
              solo
              color="#037F8C"
              item-color="#037F8C"
              :items="rubricasFilter"
              item-text="label"
              item-value="value"
              v-model="rubricaFilterSelecionada"
              ><template v-slot:prepend-item>
                <v-list-item
                  ripple
                  @click="
                    rubricaFilterSelecionada = selecionarTodasOpcoes(
                      rubricaFilterSelecionada,
                      rubricasFilter
                    )
                  "
                >
                  <v-list-item-action>
                    <v-icon>
                      {{
                        rubricaFilterSelecionada.length == rubricasFilter.length
                          ? "mdi-close-box"
                          : "mdi-checkbox-blank-outline"
                      }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title> Selecionar todas </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row v-if="!isHistorico">
          <v-col class="label-col label" md="2">Status:</v-col>
          <v-col>
            <v-select
              class="select-form"
              outlined
              hide-details
              clearable
              chips
              multiple
              dense
              label="Todos os status"
              solo
              color="#037F8C"
              item-color="#037F8C"
              :items="status"
              item-text="label"
              item-value="value"
              v-model="statusSelecionado"
            >
              <template v-slot:prepend-item>
                <v-list-item
                  ripple
                  @click="
                    statusSelecionado = selecionarTodasOpcoes(
                      statusSelecionado,
                      status
                    )
                  "
                >
                  <v-list-item-action>
                    <v-icon>
                      {{
                        statusSelecionado.length == status.length
                          ? "mdi-close-box"
                          : "mdi-checkbox-blank-outline"
                      }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title> Selecionar todos </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="label-col label" md="2">Parcela:</v-col>
          <v-col>
            <v-select
              outlined
              chips
              hide-details
              clearable
              multiple
              dense
              label="Todas as parcelas"
              solo
              :items="parcelas"
              color="#037F8C"
              item-color="#037F8C"
              item-text="label"
              item-value="value"
              v-model="parcelaSelecionada"
            >
              <template v-slot:prepend-item>
                <v-list-item
                  ripple
                  @click="
                    parcelaSelecionada = selecionarTodasOpcoes(
                      parcelaSelecionada,
                      parcelas
                    )
                  "
                >
                  <v-list-item-action>
                    <v-icon>
                      {{
                        parcelaSelecionada.length == parcelas.length
                          ? "mdi-close-box"
                          : "mdi-checkbox-blank-outline"
                      }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title> Selecionar todas </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-select>
          </v-col>
          <v-col class="label-col label" md="2">Recurso:</v-col>
          <v-col>
            <v-select
              class="select-form"
              outlined
              hide-details
              clearable
              chips
              multiple
              dense
              label="Todos os recursos"
              solo
              color="#037F8C"
              item-color="#037F8C"
              :items="recursos"
              item-text="label"
              item-value="value"
              v-model="recursoSelecionado"
            >
              <template v-slot:prepend-item>
                <v-list-item
                  ripple
                  @click="
                    recursoSelecionado = selecionarTodasOpcoes(
                      recursoSelecionado,
                      recursos
                    )
                  "
                >
                  <v-list-item-action>
                    <v-icon>
                      {{
                        recursoSelecionado.length == recursos.length
                          ? "mdi-close-box"
                          : "mdi-checkbox-blank-outline"
                      }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title> Selecionar todos </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="label-col label" md="2">Saldo:</v-col>
          <v-col>
            <v-select
              class="select-form"
              outlined
              hide-details
              clearable
              chips
              multiple
              dense
              label="Todos os saldos"
              solo
              color="#037F8C"
              item-color="#037F8C"
              :items="tiposSaldos"
              item-text="label"
              item-value="value"
              v-model="tiposSaldoSelecionado"
            >
              <template v-slot:prepend-item>
                <v-list-item
                  ripple
                  @click="
                    tiposSaldoSelecionado = selecionarTodasOpcoes(
                      tiposSaldoSelecionado,
                      tiposSaldos
                    )
                  "
                >
                  <v-list-item-action>
                    <v-icon>
                      {{
                        tiposSaldoSelecionado.length == tiposSaldos.length
                          ? "mdi-close-box"
                          : "mdi-checkbox-blank-outline"
                      }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title> Selecionar todos </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-select>
          </v-col>
          <v-col class="label-col label" md="2">Execução:</v-col>
          <v-col>
            <v-select
              class="select-form"
              outlined
              hide-details
              clearable
              dense
              chips
              multiple
              label="Todas as execuções"
              solo
              color="#037F8C"
              item-color="#037F8C"
              :items="tipoExecucaos"
              item-text="label"
              item-value="value"
              v-model="tipoExecucaoSelecionada"
            >
              <template v-slot:prepend-item>
                <v-list-item
                  ripple
                  @click="
                    tipoExecucaoSelecionada = selecionarTodasOpcoes(
                      tipoExecucaoSelecionada,
                      tipoExecucaos
                    )
                  "
                >
                  <v-list-item-action>
                    <v-icon>
                      {{
                        tipoExecucaoSelecionada.length == tipoExecucaos.length
                          ? "mdi-close-box"
                          : "mdi-checkbox-blank-outline"
                      }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title> Selecionar todas </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <v-row v-if="isHistorico">
          <v-col class="label-col label" md="2">Status:</v-col>
          <v-col>
            <v-select
              class="select-form small"
              outlined
              hide-details
              clearable
              chips
              multiple
              dense
              label="Todos os status"
              solo
              color="#037F8C"
              item-color="#037F8C"
              :items="status"
              item-text="label"
              item-value="value"
              v-model="statusSelecionado"
            >
              <template v-slot:prepend-item>
                <v-list-item
                  ripple
                  @click="
                    statusSelecionado = selecionarTodasOpcoes(
                      statusSelecionado,
                      status
                    )
                  "
                >
                  <v-list-item-action>
                    <v-icon>
                      {{
                        statusSelecionado.length == status.length
                          ? "mdi-close-box"
                          : "mdi-checkbox-blank-outline"
                      }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title> Selecionar todos </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-select>
          </v-col>
          <v-col class="label-col label" md="2">Modificações:</v-col>
          <v-col>
            <v-select
              class="select-form small"
              outlined
              hide-details
              clearable
              chips
              multiple
              dense
              label="Todas as modificações"
              solo
              color="#037F8C"
              item-color="#037F8C"
              :items="modificacoes"
              item-text="label"
              item-value="value"
              v-model="modificacaoSelecionada"
            >
              <template v-slot:prepend-item>
                <v-list-item
                  ripple
                  @click="
                    modificacaoSelecionada = selecionarTodasOpcoes(
                      modificacaoSelecionada,
                      modificacoes
                    )
                  "
                >
                  <v-list-item-action>
                    <v-icon>
                      {{
                        modificacaoSelecionada.length == modificacoes.length
                          ? "mdi-close-box"
                          : "mdi-checkbox-blank-outline"
                      }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title> Selecionar todas </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-select>
          </v-col>
        </v-row>
        <br />
      </v-card-text>
      <v-card-actions
        style="
          display: flex;
          justify-content: space-between;
          padding-bottom: 20px;
          margin-left: 20px;
          margin-right: 20px;
        "
      >
        <div>
          <v-btn outlined width="190" color="#FF8D3F" @click="closeModal(false)"
            >Cancelar</v-btn
          >
        </div>
        <div>
          <v-btn
            v-if="!isHistorico"
            depressed
            width="190"
            class="btn-controls"
            style="margin-right: 10px"
            color="#FF8D3F"
            @click="exportarCSV"
            >Exportar CSV</v-btn
          >
          <v-btn
            depressed
            width="190"
            class="btn-controls"
            color="#FF8D3F"
            @click="exportarPDF"
            >Exportar PDF</v-btn
          >
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DatePicker from "../DatePicker";
import {
  relatorioItens,
  listarSituacoesItem,
  relatorioItensHistorico,
} from "../../services/providers/itensProvider";
import {
  formatItensHistoricoPorRubrica,
  formatItensPorRubrica,
} from "../../services/providers/rubricaProvider";
import { getSubprojetosByProjetoApi } from "../../services/providers/subProjetosProvider";
import { makePDF } from "../../utils/makePDFRelatorioItens";
import { makePDFHistorico } from "../../utils/makePDFHistoricoItens";
import { makeCSV } from "../../utils/makeCSVRelatorioItens";

export default {
  name: "ModalGerarRelatorioProjeto",
  props: {
    modalVisible: Boolean,
    isHistorico: Boolean,
  },
  components: {
    DatePicker,
  },
  data: () => ({
    rubricas: [],
    subprojetos: [],
    subProjetoSelecionados: [],
    rubricasFilter: [],
    rubricaFilterSelecionada: [],
    date: new Date().toISOString().substr(0, 10),
    dateFim: new Date().toISOString().substr(0, 10),
    parcelas: [
      { label: "1º parcela", value: 1 },
      { label: "2º parcela", value: 2 },
      { label: "3º parcela", value: 3 },
      { label: "Sem parcela", value: null },
    ],
    parcelaSelecionada: [],
    status: [],
    statusSelecionado: [],
    recursos: [
      { label: "FNDCT", value: "SOMENTE_FNDCT" },
      { label: "Rendimento", value: "SOMENTE_RENDIMENTO" },
      { label: "Complementação", value: "AMBOS" },
    ],
    recursoSelecionado: [],
    tiposSaldos: [
      { label: "Saldo positivo", value: 1 },
      { label: "Saldo zero", value: 0 },
      { label: "Saldo negativo", value: -1 },
    ],
    tiposSaldoSelecionado: [],
    tipoExecucaos: [
      { label: "Executado", value: 1 },
      { label: "Não executado", value: -1 },
    ],
    tipoExecucaoSelecionada: [],
    modificacoes: [
      { label: "Modificado", value: "MODIFICADO" },
      { label: "Não modificado", value: "NAO_MODIFICADO" },
      { label: "Ambos", value: "AMBOS" },
    ],
    modificacaoSelecionada: [],
  }),
  async mounted() {
    try {
      if (this.$store.projeto && this.$store.projeto.metricasRubrica) {
        let subprojetos = await getSubprojetosByProjetoApi(
          this.$store.projeto.id
        );
        this.subprojetos = subprojetos.map((o) => ({
          label: o.codigo,
          value: o.id,
        }));
        if (this.$store.subProjeto) {
          this.subProjetoSelecionados.push({
            label: this.$store.subProjeto.codigo,
            value: this.$store.subProjeto.id,
          });
        }
        this.rubricasFilter = this.$store?.projeto?.metricasRubrica
          .map((mr) => mr.rubrica)
          .map((o) => ({
            label: o.descricaoCompleta,
            value: o.id,
          }));
        let status = await listarSituacoesItem();
        this.status = status.map((o) => ({ label: o.descricao, value: o.id }));
      }
    } catch (error) {
      console.error(error);
      this.$notify({
        title: "Erro interno!",
        text: error.message || error,
        type: "error",
        duration: 15000,
      });
    }
  },
  methods: {
    closeModal() {
      this.limparFiltros();
      this.$emit("close");
    },
    getValueObjetoSelecao(selecoes) {
      return selecoes.map((selecao) => {
        if (typeof selecao === "object") {
          return selecao ? selecao.value : null;
        } else {
          return selecao || null;
        }
      });
    },
    async filtrarItens() {
      try {
        const params = {
          data: this.date + " 23:59:00",
          rubricas: this.rubricaFilterSelecionada.length
            ? this.getValueObjetoSelecao(this.rubricaFilterSelecionada)
            : undefined,
          situacoes: this.statusSelecionado.length
            ? this.getValueObjetoSelecao(this.statusSelecionado)
            : undefined,
          parcelas: this.parcelaSelecionada.length
            ? this.getValueObjetoSelecao(this.parcelaSelecionada)
            : undefined,
          fontes: this.recursoSelecionado.length
            ? this.getValueObjetoSelecao(this.recursoSelecionado)
            : undefined,
          saldos: this.tiposSaldoSelecionado.length
            ? this.getValueObjetoSelecao(this.tiposSaldoSelecionado)
            : undefined,
          executados: this.tipoExecucaoSelecionada.length
            ? this.tipoExecucaoSelecionada.length == 2
              ? null
              : this.getValueObjetoSelecao(this.tipoExecucaoSelecionada)[0]
            : undefined,
          subprojetos: this.subProjetoSelecionados.length
            ? this.getValueObjetoSelecao(this.subProjetoSelecionados)
            : undefined,
          projetos: [this.$store.projeto.id],
        };
        if (this.isHistorico) {
          delete params.data;
          params.dataInicio = this.date + " 00:00:00";
          params.dataFim = this.dateFim + " 23:59:00";
          params.modificacao = this.modificacaoSelecionada.length
            ? this.getValueObjetoSelecao(this.modificacaoSelecionada)
            : undefined;
        }
        const itens = await (this.isHistorico
          ? relatorioItensHistorico(params)
          : relatorioItens(params));
        this.rubricas = await (this.isHistorico
          ? formatItensHistoricoPorRubrica(itens)
          : formatItensPorRubrica(itens));

        return this.rubricas;
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro interno!",
          text: error.message || error,
          type: "error",
          duration: 15000,
        });
      }
    },
    limparCamposFiltro() {
      this.descricaoFilter = "";
      this.statusSelecionado = [];
      this.recursoSelecionado = [];
      this.tiposSaldoSelecionado = [];
      this.tipoExecucaoSelecionada = [];
      this.rubricaFilterSelecionada = [];
      this.parcelaSelecionada = [];
      this.panel = [1];
    },
    limparFiltros() {
      this.limparCamposFiltro();
    },
    selecionarTodasOpcoes(arrModel, arrComparacao) {
      if (arrModel.length == arrComparacao.length) {
        arrModel = [];
      } else {
        arrModel = arrComparacao;
      }
      return arrModel;
    },
    async exportarPDF() {
      const rubricas = await this.filtrarItens();
      if (rubricas.length) {
        if (this.isHistorico) {
          makePDFHistorico(
            this.$store.projeto,
            null,
            rubricas,
            this.date,
            this.dateFim
          );
        } else {
          makePDF(this.$store.projeto, null, rubricas, this.date);
        }
      } else {
        this.$notify({
          title: "Aviso!",
          text: "Nenhum item encontrado",
          type: "warning",
          duration: 15000,
        });
      }
    },
    async exportarCSV() {
      const rubricas = await this.filtrarItens();
      if (rubricas.length) {
        let csvContent = makeCSV(
          this.$store.projeto,
          null,
          rubricas,
          this.date
        );
        const data = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", data);
        link.setAttribute("download", "relatorio.csv");
        link.click();
      } else {
        this.$notify({
          title: "Aviso!",
          text: "Nenhum item encontrado",
          type: "warning",
          duration: 15000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.session-title {
  color: $primary;
  font-family: Roboto;
  font-size: 20px;
}
.label {
  margin-top: 8px !important;
}
.date {
  margin-top: -15px !important;
  padding-top: 0px !important;
  font-family: roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  display: flex;
  align-items: center;
  color: #037f8c;
  justify-content: flex-start;
}
</style>
