import Vue from "vue";

/* Create a reactive store */
export const store = Vue.observable({
  projeto: null,
  subProjeto: null,
  globalLoadingActive: false,
  drawerOpen: false,
  disableGlobalLoader: false,
  itemSelecionado: null,
});

/* Create centralized actions for updating the store */
export const actions = {
  setProjeto(value) {
    store.projeto = value;
  },
  setSubProjeto(value) {
    store.subProjeto = value;
  },
  setGlobalLoadingActive(value) {
    store.globalLoadingActive = value;
  },
  setDrawerOpen(value) {
    store.drawerOpen = value;
  },
  setDisableGlobalLoader(value) {
    store.disableGlobalLoader = value;
  },
  setItemSelecionado(value) {
    store.itemSelecionado = value;
  }
};
