import api from "../api";

function formatMetricasByFonte(subprojeto) {
  const metricasFndct = subprojeto.metricasFonte.find(
    (fp) => fp.fonte.codigo == "F"
  );
  if (metricasFndct) {
    subprojeto.metricas = metricasFndct;
  }
  const metricasRendimento = subprojeto.metricasFonte.find(
    (fp) => fp.fonte.codigo == "R"
  );
  if (metricasRendimento) {
    metricasRendimento.valorDesembolsado = subprojeto.valorDesembolsado;
    subprojeto.metricasRendimento = metricasRendimento;
  }
}

export const getSubprojetosApi = async () => {
  const res = await api.get("/subprojetos");
  const subprojetos = res.data;
  subprojetos.forEach((subprojeto) => {
    formatMetricasByFonte(subprojeto);
  });
  return subprojetos;
};

export const getSubprojetoById = async (id) => {
  const res = await api.get(`/subprojetos/${id}`);
  const subprojeto = res.data;
  formatMetricasByFonte(subprojeto);
  return subprojeto;
};

export const getSubprojetosByProjetoApi = async (idProjeto) => {
  const res = await api.get(`/subprojetos/search?projetoId=${idProjeto}`);
  const subprojetos = res.data;
  subprojetos.forEach((subprojeto) => {
    formatMetricasByFonte(subprojeto);
  });
  return subprojetos;
};

export const buscarSubProjetos = async (query) => {
  return await getSubprojetosApi().filter((p) => p.codigo.startsWith(query));
};

export const calcularExecucaoRubricaPorSubProjeto = async (idSubProjeto) => {
  const rubricasPorCodigo = [];
  const itensRes = await api.get(`/itens/search?subprojetos=${idSubProjeto}`);
  const itens = itensRes.data;
  if (itens) {
    const rubricasRes = await api.get(`/subprojetos/${idSubProjeto}/rubricas`);
    let rubricas = rubricasRes.data;
    if (rubricas) {
      rubricas.forEach(
        (rubrica) =>
          (rubrica.codigo = rubrica.codigo + (rubrica.importado ? "-I" : "-N"))
      );
      let rubricasFiltered = [];
      itens.forEach((item) => {
        rubricas.forEach((rubrica) => {
          if (item.rubrica.id == rubrica.id) {
            item.codigoRubrica = rubrica.codigo;
            rubricasFiltered.push(rubrica);
          }
        });
      });
      rubricas = rubricasFiltered;
      rubricas.forEach((rubrica) => {
        if (!rubricasPorCodigo.some((r) => r.codigo == rubrica.codigo)) {
          rubricasPorCodigo.push({
            codigo: rubrica.codigo,
            descricao: rubrica.descricaoCompleta,
          });
        }
      });

      rubricasPorCodigo.forEach((rubrica) => {
        rubrica.valorExecutado = itens
          .filter((item) => item.codigoRubrica == rubrica.codigo)
          .reduce((total, item) => (total += item.valorExecutado), 0);
        rubrica.valorAlocado = itens
          .filter((item) => item.codigoRubrica == rubrica.codigo)
          .reduce((total, item) => (total += item.valorAlocado), 0);
      });
    }
  }
  return rubricasPorCodigo;
};

export const adicionarResponsavelSubProjeto = async (payload) => {
  const res = await api.patch(`/subprojetos/adicionar-responsavel`, payload);
  return res.data;
};

export const getSubprojetosByProjetoApiSimple = async (idProjeto) => {
  const res = await api.get(`/subprojetos/search?projetoId=${idProjeto}`);
  return res.data;
};

export const deleteSubprojetoById = async (idSubProjeto) => {
  const res = await api.delete(`/subprojetos/${idSubProjeto}`);
  return res.data;
};