<template>
  <div id="tool-bar">
    <v-menu offset-y transition="slide-y-transition">
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on">
          <v-badge
            v-if="qtdNotificacoesNaoLidas"
            color="#F28705"
            :content="qtdNotificacoesNaoLidas"
          >
            <v-icon color="white" large>mdi-bell</v-icon>
          </v-badge>
          <v-icon v-else color="white" large>mdi-bell</v-icon>
        </v-btn>
      </template>
      <v-list dense class="mx-auto" width="500" style="margin-top: 10px">
        <div style="margin-top: 5px; padding: 0px">
          <p class="notificacao-title">Notificações</p>
        </div>
        <v-divider></v-divider>
        <NotificationCard
          v-for="(item, index) in notificacoes"
          v-bind:key="index"
          :notificacao="item"
          :withTempoEstimado="true"
        />
        <p
          v-if="!notificacoes.length"
          style="text-align: center; font-family: Raleway"
        >
          Nenhuma notificação
        </p>
        <v-divider></v-divider>
        <div class="list-footer">
          <router-link to="/notificacoes"
            >Visualizar TODAS AS Notificações</router-link
          >
        </div>
      </v-list>
    </v-menu>
    <v-menu offset-y transition="slide-y-transition">
      <template v-slot:activator="{ on }">
        <v-btn icon v-on="on">
          <v-icon color="white" large>mdi-account</v-icon>
        </v-btn>
      </template>
      <v-card class="mx-auto" max-width="450" style="margin-top: 10px">
        <div class="card-header">
          <v-row>
            <v-col align="center" justify="center">
              <v-avatar color="white" size="64">
                <v-icon color="#c4c4c4" size="64">mdi-account</v-icon>
              </v-avatar>
            </v-col>
          </v-row>
          <v-row>
            <v-col align="center" justify="center">
              <p class="card-title">{{ user.nome }}</p>
              <p class="card-subtitle">{{ user.matricula }}</p>
            </v-col>
          </v-row>
        </div>
        <v-card-actions class="card-body">
          <v-row>
            <v-col md="6">
              <v-btn
                outlined
                block
                color="#F28705"
                @click="modalRedefinirSenhaOpen = !modalRedefinirSenhaOpen"
                >Redefinir senha</v-btn
              >
            </v-col>
            <v-col md="6">
              <v-btn
                to="/logout"
                block
                depressed
                color="#F28705"
                class="btn-controls"
                >Sair</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-menu>
    <ModalRedefinirSenha
      :modalVisible="modalRedefinirSenhaOpen"
      @close="modalRedefinirSenhaOpen = false"
    />
  </div>
</template>

<script>
import auth from "../services/auth";
import ModalRedefinirSenha from "../components/modals/ModalRedefinirSenha";
import { listarNotificacoes } from "../services/providers/notificacoesProvider";
import NotificationCard from "./NotificationCard";
import { EventBus } from "../services/event-bus.js";

export default {
  name: "ToolsBar",
  components: {
    ModalRedefinirSenha,
    NotificationCard,
  },
  data: () => ({
    user: null,
    modalRedefinirSenhaOpen: false,
    notificacoes: [],
    qtdNotificacoesNaoLidas: 0,
  }),
  created() {
    this.user = auth.getUser();

    EventBus.$on("read-notification", async () => {
      await this.listarNotificacoes();
    });
  },
  async mounted() {
    await this.listarNotificacoes();
  },
  methods: {
    async listarNotificacoes() {
      try {
        this.notificacoes = await listarNotificacoes();
        this.getQtdNotificacoesNaoLidas(this.notificacoes);
        if (Array.isArray(this.notificacoes)) {
          this.notificacoes = this.notificacoes
            .sort((a, b) => (a.lido < b.lido ? -1 : 1))
            .slice(0, 5);
        }
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro interno!",
          text: error.message || error,
          type: "error",
          duration: 15000,
        });
      }
    },
    getQtdNotificacoesNaoLidas: function(notificacoes = []) {
      this.qtdNotificacoesNaoLidas =
        notificacoes.filter((a) => !a.lido).length || 0;
    },
  },
};
</script>

<style lang="scss" scoped>
#tool-bar {
  margin-top: 5px;
  margin-right: 10px;
  float: right;
  display: flex;
  justify-content: space-between;
  z-index: 9999999;
}
.card-header {
  width: 400px;
  background-color: $primary-dark;
}
.card-title {
  font-size: 15px;
  font-weight: bold;
  color: white;
  font-family: $title;
}
.card-subtitle {
  font-size: 12px;
  font-weight: bold;
  color: white;
  font-family: $title;
}
.card-body {
  background-color: $card-background;
  display: flex;
  justify-content: space-between;
}
.card-btn {
  color: $secondary;
}
.btn-controls {
  border-radius: 8px;
  color: #fff;
}
.notificacao-title {
  margin-left: 25px;
  margin-bottom: 10px;
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #616161;
}
.list-footer {
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  a {
    text-decoration: none;
    text-align: center;
    font-family: Raleway;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    text-transform: uppercase;
    color: #616161;
  }
}
</style>
