<template>
  <v-dialog v-model="modalVisible" persistent max-width="1100">
    <v-card style="border-radius: 10px">
      <br />
      <div style="width: 100%; display: flex; justify-content: flex-end">
        <v-btn text large @click="closeModal()">
          <v-icon large color="#037F8C">mdi-close-circle-outline</v-icon>
        </v-btn>
      </div>
      <v-card-text>
        <br />
        <p class="session-title">Justificativa</p>
        <br /><br />
        <p v-if="justificativa" class="justificativa-label">
          {{ `${justificativa}` }}
        </p>
        <br />
        <br />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ModalLerJustificativaInteira",
  props: {
    modalVisible: Boolean,
    justificativa: String,
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.modal-title {
  font-size: 24px !important;
  text-transform: uppercase;
  color: $primary;
  font-weight: 500;
}
.session-title {
  text-align: center !important;
  font-size: 24px !important;
  text-transform: uppercase;
  color: $primary;
  font-weight: 500;
}
.justificativa-label {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #616161;
  text-align: justify;
  white-space: pre-line;
}
p {
  margin: 0px;
}
</style>
