<template>
  <div class="container-main">
    <div class="container-login">
      <div class="content-login">
        <img width="309" height="231" src="../assets/logo-full.png" />
        <br />
        <v-card elevation="0" width="80%" style="border-radius: 10px">
          <div v-if="!token">
            <v-card-text>
              <div class="form-div-title">
                <p class="subtitle">
                  Um email contendo o link para alteração de senha será enviado
                  para seu email em breve. Isso pode levar alguns minutos.
                  Lembre-se de verificar a caixa de spam do seu email.
                </p>
              </div>
              <v-text-field
                v-model="email"
                color="#037F8C"
                label="E-mail"
                :rules="[
                  (v) => !!v || 'E-mail é obrigatório!',
                  (v) => validateEmail(v) || 'E-mail inválido!',
                ]"
                class="form-input"
                required
              >
                <v-icon slot="append" class="input-icon">mdi-email</v-icon>
              </v-text-field>
              <router-link :to="{ path: 'login' }" class="form-link"
                >Voltar para página de login</router-link
              >
            </v-card-text>
            <v-card-actions class="form-buttom">
              <v-btn
                :disabled="!email"
                depressed
                @click="solicitarRecuperacaoDeSenha"
                width="40%"
                color="#037F8C"
                style="color: #fff; border-radius: 10px"
                >ENVIAR</v-btn
              >
            </v-card-actions>
          </div>
          <div v-else>
            <div v-if="!success">
              <v-card-text>
                <div class="form-div-title">
                  <p class="subtitle">Defina uma nova senha</p>
                </div>
                <v-text-field
                  v-model="senha1"
                  color="#037F8C"
                  label="Senha"
                  class="form-input"
                  :rules="[(v) => !!v || 'Senha é obrigatória!']"
                  @keyup.enter="login"
                  :append-icon="passVisible1 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="() => (passVisible1 = !passVisible1)"
                  :type="passVisible1 ? 'password' : 'text'"
                  required
                >
                  <v-icon slot="prepend" class="input-icon">mdi-lock</v-icon>
                </v-text-field>
                <br />
                <v-text-field
                  v-model="senha2"
                  color="#037F8C"
                  label="Confirmar senha"
                  class="form-input"
                  :rules="[(v) => !!v || 'Senha é obrigatória!']"
                  @keyup.enter="login"
                  :append-icon="passVisible2 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="() => (passVisible2 = !passVisible2)"
                  :type="passVisible2 ? 'password' : 'text'"
                  required
                >
                  <v-icon slot="prepend" class="input-icon">mdi-lock</v-icon>
                </v-text-field>
              </v-card-text>
              <v-card-actions class="form-buttom">
                <v-btn
                  :disabled="!senha1 || !senha2"
                  depressed
                  @click="alterarSenha"
                  width="40%"
                  color="#037F8C"
                  style="color: #fff; border-radius: 10px"
                  >SALVAR</v-btn
                >
              </v-card-actions>
            </div>
            <div v-else>
              <v-card-text>
                <div class="form-div-title">
                  <p
                    class="subtitle"
                    style="font-size: 24px; font-weight: normal"
                  >
                    Senha redefinida com sucesso!
                  </p>
                </div>
              </v-card-text>
              <v-card-actions class="form-buttom">
                <v-btn
                  depressed
                  :to="'/'"
                  width="50%"
                  color="#037F8C"
                  style="color: #fff; border-radius: 10px"
                  >IR PARA O SISTEMA</v-btn
                >
              </v-card-actions>
            </div>
          </div>
          <br />
        </v-card>
        <br />
      </div>
    </div>
    <div class="copyright">2021 Copyright Projeto Sapiencia</div>
  </div>
</template>

<script>
import {
  redefinirSenha,
  solicitarRecuperacaoDeSenha,
} from "../services/providers/usuarioProvider";
import { validateEmail } from "../utils/validators";
export default {
  name: "RecuperarSenha",
  data: () => ({
    email: "",
    senha1: "",
    senha2: "",
    passVisible1: true,
    passVisible2: true,
    token: null,
    success: null,
  }),
  mounted() {
    const { token } = this.$route.query;
    this.token = token;
  },
  methods: {
    async solicitarRecuperacaoDeSenha() {
      try {
        await solicitarRecuperacaoDeSenha(this.email.trim());
        this.$notify({
          title: "E-mail enviado!!",
          text:
            "Um e-mail contendo o link para recuperação de senha foi enviado para você!",
          type: "success",
          duration: 5000,
        });
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro interno!",
          text: error.message || error,
          type: "error",
          duration: 15000,
        });
      }
    },
    async alterarSenha() {
      if (this.token) {
        if (this.senha1 != this.senha2) {
          this.$notify({
            title: "As senhas devem ser iguais!",
            type: "error",
            duration: 15000,
          });
          return;
        }
        try {
          await redefinirSenha({
            token: this.token,
            senha: this.senha1,
          });
          this.success = true;
          this.$notify({
            title: "Senha alterada!",
            text: "Senha alterada com sucesso!",
            type: "success",
            duration: 5000,
          });
        } catch (error) {
          console.error(error);
          this.$notify({
            title: "Erro interno!",
            text: error.message || error,
            type: "error",
            duration: 15000,
          });
        }
      }
    },
    validateEmail: validateEmail,
  },
};
</script>

<style lang="scss" scoped>
@media only screen and (max-width: 480px) {
  .content-login {
    width: 100% !important;
  }
}
.container-login {
  width: 100%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}
.container-main {
  width: 100%;
  height: 100%;
  background-color: $card-background;
  background-image: url("../assets/malha.png");
  background-size: cover;
}
.content-login {
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  border-radius: 30px;
}
.form {
  padding: 5%;
}
.form-buttom {
  padding-left: 7%;
  padding-right: 7%;
  width: 100%;
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
}
.form-div-title {
  padding: 5%;
  padding-top: 7%;
  padding-bottom: 3%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.input-icon {
  color: $primary;
}
.form-bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.title {
  font-size: 36px !important;
  color: $secondary;
  font-family: $title;
}
.subtitle {
  font-size: 16px;
  color: #037f8c;
  font-family: $title;
}
.form-checkbox {
  font-family: $title;
  position: relative;
  top: -20px;
}
.form-input {
  font-family: $title;
}
.form-link {
  color: #7d7d7d;
  font-family: $title;
}
.error-form {
  padding-left: 5%;
  font-family: $title;
  color: red;
}
.copyright {
  background: #55b4c2;
  height: 70px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  color: #e6f4f1;
}
</style>
