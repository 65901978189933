<template>
  <v-dialog v-model="modalVisible" persistent max-width="650">
    <v-card style="border-radius: 10px">
      <br />
      <v-card-title></v-card-title>
      <v-card-text>
        <v-textarea
          v-model="comentario"
          autofocus
          outlined
          rows="7"
          placeholder="Adicione um comentário..."
          required
        ></v-textarea>
      </v-card-text>
      <v-card-actions
        style="display: flex; justify-content: space-evenly; padding-bottom: 20px"
      >
        <v-btn outlined width="190" color="#FF8D3F" @click="closeModal(false)"
          >Cancelar</v-btn
        >
        <v-btn
          v-if="isEdit"
          depressed
          width="190"
          class="btn-controls"
          color="#FF8D3F"
          @click="editarComentario"
          >Salvar</v-btn
        >
        <v-btn
          v-else
          depressed
          width="190"
          class="btn-controls"
          color="#FF8D3F"
          @click="addComentario"
          >Salvar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  addComentarioItem,
  editarComentarioItem,
} from "../../services/providers/itensProvider";
export default {
  name: "ModalComentarioItem",
  props: {
    comentarioItem: Object,
    item: Object,
    modalVisible: Boolean,
  },
  data: () => ({
    comentario: "",
    isEdit: false,
  }),
  mounted() {
    if (this.comentarioItem) {
      this.comentario = this.comentarioItem.conteudo;
      this.isEdit = true;
    }
  },
  methods: {
    closeModal() {
      this.comentario = "";
      this.isEdit = false;
      this.$emit("close");
    },
    async addComentario() {
      try {
        const payload = {
          conteudo: this.comentario,
          itemId: this.item.id,
        };
        const res = await addComentarioItem(payload);
        if (res) {
          this.$notify({
            title: "Comentário adicionado!",
            text: "O comentário foi cadastrado com sucesso!",
            type: "success",
            duration: 5000,
          });
          this.closeModal(true);
        }
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro ao cadastrar o comentário!",
          text: error.message,
          type: "error",
          duration: 15000,
        });
      }
    },
    async editarComentario() {
      try {
        const payload = {
          conteudo: this.comentario,
          itemId: this.item.id,
          id: this.comentarioItem.id,
        };
        const res = await editarComentarioItem(payload);
        if (res) {
          this.$notify({
            title: "Comentário editado!",
            text: "O comentário foi editado com sucesso!",
            type: "success",
            duration: 5000,
          });
          this.closeModal(true);
        }
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro ao editar o comentário!",
          text: error.message,
          type: "error",
          duration: 15000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
