export const optionsLine = {
    strokeWidth: 2,
    color: "#037F8C",
    trailColor: "#C4C4C4",
    trailWidth: 2,
    easing: "easeInOut",
    duration: 1200,
    text: {
        value: "",
        alignToBottom: true,
        style: {
            color: "#f00",
            position: "absolute",
            left: "0%",
            top: "90%",
            padding: 0,
            margin: 0,
            lineHeight: 1,
            textAlign: "justify"
        }
    },
    svgStyle: null,
    from: { color: "#87CEFA" },
    to: { color: "#1E90FF" },/* 
    step: (state, bar) => {
        var value = Math.round(bar.value() * 100);
        bar.setText(`${value}%`);
        bar.text.style.color = state.color;
        bar.text.style.fontSize = "0.6rem";
    } */
};

export const optionsSemiCircle = {
    strokeWidth: 10,
    trailColor: "#C4C4C4",
    trailWidth: 10,
    easing: "easeInOut",
    duration: 1200,
    svgStyle: {
        width: '100px',
        height: '100px'
    },
    text: {
        value: "",
        alignToBottom: true,
        style: {
            color: "#f00",
            position: "absolute",
            left: "25%",
            width: '50px',
            padding: 0,
            margin: 0,
            lineHeight: 1,
            textAlign: "center"
        }
    },
    from: { color: "#75babf" },
    to: { color: "#75babf" },
    step: (state, bar) => {
        bar.path.setAttribute("stroke", state.color);
        var value = Math.round(bar.value() * 100);
        bar.setText(`${value}%`);
        bar.text.style.color = state.color;
        bar.text.style.fontSize = "16px";
        bar.text.style.color = "#000";
        bar.text.style.top = "35%";
        bar.text.style.textAlign = "center";
    }
};

export const optionsSemiCircleChart = {
    strokeWidth: 5,
    trailColor: "#C4C4C4",
    trailWidth: 5,
    easing: "easeInOut",
    duration: 1200,
    svgStyle: {
        width: '85%'
    },
    text: {
        value: "",
        alignToBottom: true,
        style: {
            color: "#f00",
            position: "absolute",
            left: "50%",
            padding: 0,
            margin: 0,
            lineHeight: 1,
            textAlign: "justify"
        }
    },
    from: { color: "#009B96" },
    to: { color: "#009B96" },
    step: (state, bar) => {
        bar.path.setAttribute("stroke", state.color);
        var value = Math.round(bar.value() * 100);
        bar.setText(`${value}%`);
        bar.text.style.color = state.color;
        bar.text.style.fontSize = "42px";
        bar.text.style.color = "#000";
        bar.text.style.top = "60%";
        bar.text.style.textAlign = "center";
    }
};