<template>
  <v-dialog v-model="modalVisible" persistent max-width="700">
    <v-card class="confirm-content">
      <br />
      <v-card-title style="display: flex; justify-content: center;">
        <v-icon color="#FF8D3F" style="font-size: 80px !important"
          >mdi-alert-circle-outline</v-icon
        >
      </v-card-title>
      <v-card-text
        style="display: flex; justify-content: center; flex-direction: column"
      >
        <br />
        <p class="alert-title">
          ATENÇÃO
        </p>
        <br />
        <p v-if="isFinish" style="font-size: 18px; text-align: center">
          Ao finalizar a reformulação as alterações feitas serão aplicadas ao
          projeto. Para realizar outras alterações será necessário realizar uma
          nova reformulação.
        </p>
        <p v-else style="font-size: 18px; text-align: center">
          O estado de Reformulação serve para quando deseja fazer alterações em todo o projeto. <br/>
          Ao ser ativado, ele tira as validações dos itens podendo seus valores superarem o valor total do projeto.<br/>
          As alterações feitas na reformulação podem ser definitivas.<br/>
          <strong>Você deseja realmente reformular este projeto?</strong>
        </p>
        <br />
      </v-card-text>
      <v-card-actions
        style="display: flex; justify-content: space-evenly; padding-bottom: 20px"
      >
        <v-btn outlined width="190" color="#FF8D3F" @click="closeModal(false)">
          Cancelar
        </v-btn>
        <v-btn
          v-if="isFinish"
          depressed
          class="btn-controls"
          color="#FF8D3F"
          @click="desativarReformulacao"
        >
          FINALIZAR REFORMULAÇÃO
        </v-btn>
        <v-btn
          v-else
          depressed
          class="btn-controls"
          color="#FF8D3F"
          @click="ativarReformulacao"
        >
          REFORMULAR PROJETO
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  ativarEstadoReformulacao,
  desativarEstadoReformulacao,
  getProjetoById,
} from "../../services/providers/projetosProvider";
export default {
  name: "ModalReformulacaoProjeto",
  props: {
    modalVisible: Boolean,
    isFinish: Boolean,
  },
  emits: ['reload', 'close'],
  methods: {
    closeModal(reload) {
      this.$emit("close");
      if (reload) {
        this.$emit("reload");
      }
    },
    async ativarReformulacao() {
      try {
        await ativarEstadoReformulacao(this.$store.projeto.id);
        const projeto = await getProjetoById(this.$store.projeto.id);
        if (projeto) {
          this.$actions.setProjeto(projeto);
        }
        this.closeModal(true);
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro ao ativar reformulação!",
          text: error.message,
          type: "error",
          duration: 15000,
        });
      }
    },
    async desativarReformulacao() {
      try {
        await desativarEstadoReformulacao(this.$store.projeto.id);
        const projeto = await getProjetoById(this.$store.projeto.id);
        if (projeto) {
          this.$actions.setProjeto(projeto);
        }
        this.closeModal(true);
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro ao desativar reformulação!",
          text: error.message,
          type: "error",
          duration: 15000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-controls {
  border-radius: 8px;
  color: #fff;
}
.confirm-content {
  border: 2px solid #ff8d3f;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
}
.alert-title {
  font-family: Raleway;
  font-size: 42px;
  color: #ff8d3f;
  text-align: center;
  font-weight: bold;
}
</style>
