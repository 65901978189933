

<template>
  <v-dialog v-model="modalVisible" persistent scrollable max-width="1000">
    <v-form ref="formEntregaItem">
      <v-card style="border-radius: 10px">
        <v-card-title class="modal-title">PROCESSO DE AQUISIÇÃO</v-card-title>
        <v-card-text style="padding-left: 30px; padding-right: 40px">
          <v-tabs background-color="white" color="#FF8D3F" center-active centered grow v-model="currentTab">
            <v-tabs-slider></v-tabs-slider>
            <v-tab style="font-size: 18px">REQUISIÇÃO</v-tab>
            <v-tabs-slider></v-tabs-slider>
            <v-tab style="font-size: 18px">EMPENHO</v-tab>
            <v-tabs-slider></v-tabs-slider>
            <v-tab style="font-size: 18px">RECEBIMENTO</v-tab>
            <v-tab-item :key="'requisicao'">
              <v-card-text style="padding-left: 30px">
                <br />
                <v-row>
                  <v-col class="label-col" md="4">
                    <p class="label">N° de requisição:</p>
                  </v-col>
                  <v-col md="6">
                    <v-text-field v-model="numRequisicao" type="id" outlined dense placeholder="000000000000000" v-mask="'#######################'">
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="label-col" md="4">
                    <p class="label">Data de requisição:</p>
                  </v-col>
                  <v-col md="6">
                    <v-text-field v-model="dataRequisicao" type="text" outlined dense :rules="rules"
                      placeholder="01/01/2020" v-mask="'##/##/####'" append-icon="mdi-calendar"></v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-tab-item>

            <v-tab-item :key="'empenho'">
              <v-card-text style="padding-left: 30px">
                <br />
                <v-row>
                  <v-col class="label-col pb-0 pt-0" md="4">
                    <p class="label">N° de empenho:</p>
                  </v-col>
                  <v-col class="pb-0 pt-0" md="6">
                    <v-text-field :rules="[(v) => !!v || 'Campo obrigatório']" v-model="numEmpenho" type="id" outlined
                      dense placeholder="000000000000000" v-mask="'#######################'"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="label-col pb-0 pt-0" md="4">
                    <p class="label">Data do empenho:</p>
                  </v-col>
                  <v-col class="pb-0 pt-0" md="6">
                    <v-text-field :rules="rules" v-model="dataEmpenho" type="text" outlined dense
                      placeholder="01/01/2020" v-mask="'##/##/####'" append-icon="mdi-calendar"></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="label-col  pb-0 pt-0" md="4">
                    <p class="label">Situação do empenho:</p>
                  </v-col>
                  <v-col class="pb-0 pt-0" md="6">
                    <v-text-field v-model="situacaoEmpenho" type="text" outlined dense placeholder="PAGO">
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col class="pb-10 pt-0 text-center">
                    <v-btn color="#026873" text small depressed @click="adicionarEmpenho">
                      <strong>
                        <v-icon size="20" md="2" color="#026873">
                          mdi-plus
                        </v-icon>
                        Adicionar Outro
                      </strong>
                    </v-btn>
                  </v-col>
                </v-row>

                <div v-if="empenhos && empenhos.length > 0">
                  <v-simple-table dense>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th rowspan="2" class="title-tab" style="width: 5%">
                            Nº
                          </th>
                          <th rowspan="2" class="title-tab" style="width: 30%">
                            EMPENHO
                          </th>
                          <th rowspan="2" class="title-tab" style="width: 60%">
                            DATA DO EMPENHO
                          </th>
                          <th rowspan="2" class="title-tab" style="width: 5%">
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(empenho, index) in empenhos" v-bind:key="empenho.id">
                          <td class="text-tab" width="5%">
                            {{ index + 1}}
                          </td>

                          <!-- Número da requisicao -->
                          <td v-if="empenho.numero" class="text-tab" width="30%">
                            {{ empenho.numero }}
                          </td>
                          <td v-else class="text-tab" width="30%">-</td>

                          <!-- Data da requisicao -->
                          <td class="text-tab" v-if="empenho.data" width="60%">
                            {{ readableDateFormat(empenho.data) }}
                          </td>
                          <td v-else class="text-tab" width="60%">-</td>

                          <td class="text-tab" width="5%">
                            <div style="display: flex; flex-direction: row">
                              <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                  <v-btn v-on="on" text small class="option-hover" @click="
                                    (empenhoSelecionado = empenho) &
                                      removerEmpenho()
                                  ">
                                    <v-icon color="#ff8d3f">mdi-delete-outline</v-icon>
                                  </v-btn>
                                </template>
                                <span> Excluir </span>
                              </v-tooltip>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
              </v-card-text>
            </v-tab-item>

            <v-tab-item :key="'recebimento'">
              <v-row>
                <v-col class="label-col" md="2">
                  <p class="label">Nº de Nota Fiscal:</p>
                </v-col>
                <v-col md="4">
                  <v-text-field v-model="notaFiscal" outlined dense v-mask="'############################'">
                  </v-text-field>
                </v-col>
                <v-col class="label-col" md="2">
                  <p class="label">Data da Nota Fiscal:</p>
                </v-col>
                <v-col md="4">
                  <v-text-field outlined dense v-mask="'##/##/####'" v-model="dataNotaFiscal" placeholder="01/01/2022">
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="label-col" md="2">
                  <p class="label">Credor:</p>
                </v-col>
                <v-col md="10">
                  <v-text-field v-model="credor" outlined dense></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="label-col" md="2">
                  <p class="label">Termo de responsabilidade:</p>
                </v-col>
                <v-col md="4">
                  <v-text-field v-model="termoResponsabilidade" outlined dense></v-text-field>
                </v-col>
                <v-col class="label-col" md="2">
                  <p class="label">Data de recebimento:</p>
                </v-col>
                <v-col md="4">
                  <v-text-field outlined dense v-model="dataRecebimento" v-mask="'##/##/####'" placeholder="01/01/2022">
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="label-col" md="2">
                  <p class="label">Responsável pelo recebimento:</p>
                </v-col>
                <v-col md="10">
                  <v-text-field v-model="responsavel" outlined dense></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col class="label-col" md="2">
                  <p class="label">Local de instalação:</p>
                </v-col>
                <v-col md="10">
                  <v-text-field v-model="localInstalacao" outlined dense></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="label-col" md="2">
                  <p class="label">Data da instalação:</p>
                </v-col>
                <v-col md="4">
                  <v-text-field outlined dense v-model="dataInstalacao" v-mask="'##/##/####'" placeholder="01/01/2022">
                  </v-text-field>
                </v-col>
                <v-col class="label-col" md="2">
                  <p class="label">Nº de tombo:</p>
                </v-col>
                <v-col md="4">
                  <v-text-field outlined dense v-model="numeroTombo" v-mask="'##########################'"
                    placeholder="00000000000000"></v-text-field>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs>
        </v-card-text>
        <v-card-actions class="modal-footer">
          <v-btn outlined width="190" color="#FF8D3F" @click="closeModal(true)">
            Cancelar
          </v-btn>
          <v-btn depressed width="190" class="btn-controls" color="#FF8D3F" @click="qualAba()">
            {{ currentTab != "recebimento" ? "Avançar" : "Salvar" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import {
  cadastrarEntregaItem,
  editarEntregaItem,
  editarRequisicao,
  editarEmpenho,
  removerEmpenho,
  adicionarEmpenho
} from "../../services/providers/entregaItemProvider";
import { EventBus } from "../../services/event-bus";
import moment from 'moment';

function formatDate(date) {
  if (date == null)
    return null;
  console.log(date)

  const dateSplit = date.split("/");
  console.log(dateSplit)
  if (dateSplit.length != 3)
    return null;
  console.log(date)

  const data = dateSplit[2] + "-" + dateSplit[1] + '-' + dateSplit[0];
  return data;
}

export default {
  name: "ModalEntregaItemEditar",
  props: {
    item: Object,
    entrega: Object,
    modalVisible: Boolean,
    processoId: Object,
  },
  data: () => ({
    dataRecebimento: "",
    dataInstalacao: "",
    dataNotaFiscal: "",
    localInstalacao: "",
    notaFiscal: "",
    responsavel: "",
    termoResponsabilidade: "",
    credor: "",
    currentTab: 0,
    reqId: "",
    numRequisicao: "",
    dataRequisicao: "",
    empenhoId: "",
    numEmpenho: "",
    dataEmpenho: "",
    situacaoEmpenho: "",
    numeroTombo: "",
    processoEntregaItemId: null,
    entregaId: "",
    empenhos: [],
  }),
  beforeMount() {
    this.processoEntregaItemId = this.processoId.id;
    if (this.processoId.requisicao) {
      this.reqId = this.processoId.requisicao.id;
      this.numRequisicao = this.processoId.requisicao.numero;
      this.dataRequisicao = this.processoId.requisicao.data
        ? new Date(
          this.processoId.requisicao.data + " "
        ).toLocaleString("pt-BR")
        : "";
    }
    if (this.processoId.empenhos) {
      this.empenhos = this.processoId.empenhos;
    }
    if (this.processoId.entregaItem) {
      this.entregaId = this.processoId.entregaItem.id;
      this.credor = this.processoId.entregaItem.credor;
      this.termoResponsabilidade =
        this.processoId.entregaItem.termoResponsabilidade;
      this.localInstalacao = this.processoId.entregaItem.localInstalacao;
      this.responsavel = this.processoId.entregaItem.responsavel;
      this.notaFiscal = this.processoId.entregaItem.notaFiscal;
      this.numeroTombo = this.processoId.entregaItem.numeroTombo;
      this.dataRecebimento = this.processoId.entregaItem.dataRecebimento
        ? new Date(
          this.processoId.entregaItem.dataRecebimento + " "
        ).toLocaleString("pt-BR")
        : "";
      this.dataInstalacao = this.processoId.entregaItem.dataInstalacao
        ? new Date(
          this.processoId.entregaItem.dataInstalacao + " "
        ).toLocaleString("pt-BR")
        : "";
      this.dataNotaFiscal = this.processoId.entregaItem.dataNotaFiscal
        ? new Date(
          this.processoId.entregaItem.dataNotaFiscal + " "
        ).toLocaleString("pt-BR")
        : "";
    }
  },
  mounted() {
    if (this.entrega) {
      this.dataRecebimento = this.entrega.dataRecebimento
        ? new Date(this.entrega.dataRecebimento + " ").toLocaleString("pt-BR")
        : "";
      this.dataInstalacao = this.entrega.dataInstalacao
        ? new Date(this.entrega.dataInstalacao + " ").toLocaleString("pt-BR")
        : "";
      this.dataNotaFiscal = this.entrega.dataNotaFiscal
        ? new Date(this.entrega.dataNotaFiscal + " ").toLocaleString("pt-BR")
        : "";
      this.localInstalacao = this.entrega.localInstalacao;
      this.notaFiscal = this.entrega.notaFiscal;
      this.responsavel = this.entrega.responsavel;
      this.termoResponsabilidade = this.entrega.termoResponsabilidade;
    }
  },
  methods: {
    qualAba() {
      if (this.currentTab === 0) {
        this.editarReqi();
        this.currentTab += 1;
      } else if (this.currentTab === 1) {
        this.currentTab += 1;
      } else {
        this.crudEntregaItem();
      }
    },
    closeModal(reload) {
      this.$refs.formEntregaItem.reset();
      this.dataNotaFiscal = "";
      this.dataRecebimento = "";
      this.dataInstalacao = "";
      if (reload) {
        EventBus.$emit("entrega-updated");
      }
      this.$emit("close");
    },
    async adicionarEmpenho() {
      await this.cadastrarEmpenho();
      this.numEmpenho = ""
      this.situacaoEmpenho = ""
      this.dataEmpenho = ""
    },
    async crudEntregaItem() {
      if (this.$refs.formEntregaItem.validate()) {
        try {
          const payload = {
            localInstalacao: this.localInstalacao,
            notaFiscal: this.notaFiscal,
            credor: this.credor,
            responsavel: this.responsavel,
            termoResponsabilidade: this.termoResponsabilidade,
            numeroTombo: this.numeroTombo,
            dataRecebimento: "",
            dataInstalacao: "",
            dataNotaFiscal: "",
          };

          if (this.dataRecebimento) {
            let dataRecebimento = this.dataRecebimento.split("/");
            payload.dataRecebimento = new Date(
              dataRecebimento[2] +
              "/" +
              dataRecebimento[1] +
              "/" +
              dataRecebimento[0]
            )
              .toISOString()
              .slice(0, 10);
          }

          if (this.dataInstalacao) {
            let dataInstalacao = this.dataInstalacao.split("/");
            payload.dataInstalacao = new Date(
              dataInstalacao[2] +
              "/" +
              dataInstalacao[1] +
              "/" +
              dataInstalacao[0]
            )
              .toISOString()
              .slice(0, 10);
          }

          if (this.dataNotaFiscal) {
            let dataNotaFiscal = this.dataNotaFiscal.split("/");
            payload.dataNotaFiscal = new Date(
              dataNotaFiscal[2] +
              "/" +
              dataNotaFiscal[1] +
              "/" +
              dataNotaFiscal[0]
            )
              .toISOString()
              .slice(0, 10);
          }

          if (this.processoId.entregaItem) {
            const res = await editarEntregaItem(
              this.processoId.entregaItem.id,
              payload
            );
            if (res) {
              this.$notify({
                title: "Entrega de item editada",
                text: "Entrega de item editada com sucesso!",
                type: "success",
                duration: 5000,
              });
              this.closeModal(true);
            }
          } else {
            payload.processoEntregaId = this.processoEntregaItemId;
            const res = await cadastrarEntregaItem(payload);
            if (res) {
              this.$notify({
                title: "Entrega de item cadastrada",
                text: "Entrega de item cadastrada com sucesso!",
                type: "success",
                duration: 5000,
              });
              this.closeModal(true);
            }
          }
        } catch (error) {
          console.error(error);
          this.$notify({
            title: "Erro ao cadastrar/editar entrega de item!",
            text: error.message,
            type: "error",
            duration: 15000,
          });
        }
      }
    },
    async editarReqi() {
      try {
        const payload1 = {
          numero: this.numRequisicao,
          processoEntregaItemId: this.processoEntregaItemId,
          data: formatDate(this.dataRequisicao)
        };
        const res1 = await editarRequisicao(this.reqId, payload1);
        if (res1) {
          this.$notify({
            title: "Requisição editada com sucesso!",
            text: "Requisição editada com sucesso!",
            type: "success",
            duration: 5000,
          });
        }
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro ao editar requisição do item!",
          text: error.message,
          type: "error",
          duration: 15000,
        });
      }
    },
    async editarEmpenho() {
      try {
        const payload2 = {
          numero: this.numEmpenho,
          processoEntregaItemId: this.processoEntregaItemId,
        };
        const res2 = await editarEmpenho(this.empenhoId, payload2);
        if (res2) {
          this.$notify({
            title: "Empenho editado com sucesso!",
            text: "Empenho do Item editado com sucesso!",
            type: "success",
            duration: 5000,
          });
        }
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro ao editado empenho do item!",
          text: error.message,
          type: "error",
          duration: 15000,
        });
      }
    },
    async cadastrarEmpenho() {
      try {
        const payload2 = {
          itemId: this.item.id,
          numero: this.numEmpenho,
          processoEntregaItemId: this.processoEntregaItemId,
          situacao: this.situacaoEmpenho,
          data: formatDate(this.dataEmpenho),
        };
        const res2 = await adicionarEmpenho(payload2);
        if (res2) {
          this.processoEntregaItemId = res2.id;
          this.empenhos = res2.empenhos
          this.$notify({
            title: "Empenho cadastrado com sucesso!",
            text: "Empenho do Item cadastrado com sucesso!",
            type: "success",
            duration: 5000,
          });
        }
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro ao cadastrar empenho do item!",
          text: error.message,
          type: "error",
          duration: 15000,
        });
      }
    },
    async removerEmpenho() {

      let payload = {
        "numero": this.empenhoSelecionado.numero,
        "processoEntregaItemId": this.processoEntregaItemId,
        "itemId": this.item.id
      }

      try {
        const res = await removerEmpenho(payload);
        if (res.empenhos)
          this.empenhos = res.empenhos;

        else {
          this.processoEntregaItemId = null;
          this.empenhos = []
        }
        if (res) {
          this.processoEntregaItemId = res.id;
          this.empenhos = res.empenhos
          this.$notify({
            title: "Empenho Excluído!",
            text: "Empenho do Item excluído com sucesso!",
            type: "success",
            duration: 5000,
          });
        }
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro ao cadastrar/editar entrega de item!",
          text: error.message,
          type: "error",
          duration: 15000,
        });
      }
    },
    readableDateFormat(dateString) {
      if (!dateString)
        return ""
      const date = new Date(dateString + " ");
      const yyyy = date.getFullYear();
      let mm = date.getMonth() + 1; // Months start at 0!
      let dd = date.getDate();

      if (dd < 10) dd = '0' + dd;
      if (mm < 10) mm = '0' + mm;

      return `${dd}/${mm}/${yyyy}`;
    },
    validateDate() {
      this.$refs.formEntregaItem.validate();
    }
  },
  computed: {
    rules() {
      const rules = [];
      const rule = v => {
        return !v ? true : moment(v, 'DD/MM/YYYY', true).isValid() || 'Data inválida'
      };
      rules.push(rule);
      return rules;
    },
  },
  watch: {
    dataRequisicao: 'validateDate',
    dataEmpenho: 'validadeDate',
  }
};
</script>
<style lang="scss" scoped>
.modal-title {
  display: flex;
  font-size: 20px !important;
  text-transform: uppercase;
  justify-content: center;
  font-weight: bold;
  font-family: Raleway;
  color: $primary;
  margin: 20px;
  margin-bottom: 30px;
  border-radius: 10px;
  height: 65px;
}

.title-tab {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 16px;
  text-align: center !important;
  color: $primary !important;
  background-color: #e6f4f1;
  border: 0.899715px solid rgba(97, 97, 97, 0.3);
  height: 45px !important;
}

.text-tab {
  font-family: Roboto;
  font-style: normal;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 13px;
  text-align: center;
  color: #616161;
  border: 0.899715px solid rgba(97, 97, 97, 0.3);
  height: 45px !important;
}
</style>
