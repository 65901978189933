var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('br'),_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"title-tab",staticStyle:{"width":"5%"},attrs:{"rowspan":"2"}},[_vm._v("Nº")]),_c('th',{staticClass:"title-tab",attrs:{"rowspan":"2"}},[_vm._v("Nº DE OFÍCIO")]),_c('th',{staticClass:"title-tab",attrs:{"rowspan":"2"}},[_vm._v("DATA DO ENVIO")]),_c('th',{staticClass:"title-tab",staticStyle:{"width":"30%"},attrs:{"rowspan":"2"}},[_vm._v(" ENVIADO POR ")]),_c('th',{staticClass:"title-tab",attrs:{"rowspan":"2"}})])]),_c('tbody',[_vm._l((_vm.relatorios),function(item,idx){return _c('tr',{key:item.id},[_c('td',{staticClass:"text-tab"},[_vm._v(_vm._s(idx + 1))]),(item.numeroOficio)?_c('td',{staticClass:"text-tab"},[_vm._v(" "+_vm._s(item.numeroOficio)+" ")]):_c('td',{staticClass:"text-tab"},[_vm._v("-")]),(item.dataExecucao)?_c('td',{staticClass:"text-tab"},[_vm._v(" "+_vm._s(_vm._f("moment")(new Date(item.dataExecucao).toISOString().slice(0,10).replace(/-/g,"/"),"DD/MM/YYYY"))+" ")]):_c('td',{staticClass:"text-tab"},[_vm._v("-")]),(item.usuarioCriacao.login)?_c('td',{staticClass:"text-tab"},[_vm._v(" "+_vm._s(item.usuarioCriacao.login)+" ")]):_c('td',{staticClass:"text-tab"},[_vm._v("-")]),_c('td',{staticClass:"text-tab",staticStyle:{"width":"10%","padding-left":"0px","padding-right":"0px"}},[_c('div',{staticStyle:{"display":"flex","flex-direction":"row"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"option-hover",attrs:{"text":"","small":""},on:{"click":function($event){(_vm.relatorioSelecionado = item) &
                      (_vm.modalNotificarEntregaRelatorio =
                        !_vm.modalNotificarEntregaRelatorio)}}},on),[_c('v-icon',{attrs:{"color":"#ff8d3f"}},[_vm._v("mdi-pencil-box-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(" Editar ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"option-hover",attrs:{"text":"","small":""},on:{"click":function($event){(_vm.modalExcluirOpen = !_vm.modalExcluirOpen) &
                        (_vm.relatorioSelecionado = item)}}},on),[_c('v-icon',{attrs:{"color":"#ff8d3f"}},[_vm._v("mdi-delete-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(" Excluir ")])])],1)])])}),_c('td',{staticClass:"div-body",attrs:{"colspan":"6"}},[_c('div',{staticClass:"text-body",staticStyle:{"border-right":"0.899715px solid rgba(97, 97, 97, 0.3)"}},[_vm._v(" TOTAL ENVIADO: "+_vm._s(_vm.relatorios.length)+" ")])])],2)]},proxy:true}])}),(_vm.relatorioSelecionado)?_c('ModalNotificarEntregaRelatorio',{attrs:{"modalVisible":_vm.modalNotificarEntregaRelatorio,"relatorioEdit":_vm.relatorioSelecionado},on:{"close":function($event){(_vm.modalNotificarEntregaRelatorio = !_vm.modalNotificarEntregaRelatorio) &
        _vm.getData() & (_vm.relatorioSelecionado = null)}}}):_vm._e(),(_vm.relatorioSelecionado)?_c('ModalConfirmacao',{attrs:{"modalVisible":_vm.modalExcluirOpen,"texto":'Você realmente deseja excluir este registro de envio de relatório?',"textoBotaoDireito":"Excluir","onConfirm":function () { return _vm.excluirRelatorio() & _vm.getData(); }},on:{"close":function($event){(_vm.modalExcluirOpen = !_vm.modalExcluirOpen) & _vm.getData() & (_vm.relatorioSelecionado = null)}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }