<template>
  <div>
    <br />
    <div
      style="display: flex; flex-direction: row; justify-content: space-between"
    >
      <p class="session-title">CRONOGRAMAS</p>
      <v-btn
        depressed
        color="#FF8D3F"
        class="btn-controls"
        style="margin-top: 12px; margin-right: 15px"
        @click="salvarCronogramas"
        >SALVAR CRONOGRAMAS</v-btn
      >
    </div>
    <div ref="divCronogramaDesembolso" class="container-main">
      <div class="container-header">
        <p class="session-subtitle">CRONOGRAMA DE DESEMBOLSO</p>
        <div v-show="!onPrint">
          <v-btn
            class="btn-detalhes"
            outlined
            color="#037F8C"
            @click="
              (modalVisible = !modalVisible) & (tipoCronograma = 'projeto')
            "
            >VISUALIZAR CRONOGRAMAS ANTERIORES</v-btn
          >
          <v-btn text @click="printCronograma('divCronogramaDesembolso')">
            <v-icon color="#F39C12">mdi-download</v-icon>
          </v-btn>
        </div>
      </div>
      <CronogramaDesembolsoTable
        :key="JSON.stringify(projeto)"
        :metricas="projeto.metricasCronograma"
      />
    </div>
    <br />
    <div ref="divCronogramaSubprojeto" class="container-main">
      <div class="container-header">
        <p class="session-subtitle">CRONOGRAMA POR SUBPROJETO</p>
        <div v-show="!onPrint">
          <v-btn
            class="btn-detalhes"
            outlined
            color="#037F8C"
            @click="
              (modalVisible = !modalVisible) & (tipoCronograma = 'subprojeto')
            "
            >VISUALIZAR CRONOGRAMAS ANTERIORES</v-btn
          >
          <v-btn text @click="printCronograma('divCronogramaSubprojeto')">
            <v-icon color="#F39C12">mdi-download</v-icon>
          </v-btn>
        </div>
      </div>
      <CronogramaSubProjetoTable
        :key="JSON.stringify(subprojetos)"
        :metricasSubprojetos="subprojetos"
      />
      <SnapshotCronogramas
        v-if="tipoCronograma"
        :key="String(modalVisible)"
        :modalVisible="modalVisible"
        @close="modalVisible = !modalVisible"
        :tipo="tipoCronograma"
      />
    </div>
  </div>
</template>

<script>
import CronogramaDesembolsoTable from "./tables/CronogramaDesembolsoTable";
import CronogramaSubProjetoTable from "./tables/CronogramaSubProjetoTable";
import SnapshotCronogramas from "./modals/SnapshotCronogramas";
import { getSubprojetosByProjetoApi } from "../services/providers/subProjetosProvider";
import {
  salvarSnapshotCronogramasProjeto,
  salvarSnapshotCronogramasSubProjeto,
} from "../services/providers/cronogramaProvider";

export default {
  name: "CronogramaSession",
  props: {
    projeto: Object,
  },
  components: {
    CronogramaDesembolsoTable,
    CronogramaSubProjetoTable,
    SnapshotCronogramas,
  },
  data: () => ({
    modalVisible: false,
    onPrint: false,
    tipoCronograma: null,
    subprojetos: [],
  }),
  async mounted() {
    try {
      this.subprojetos = await getSubprojetosByProjetoApi(this.projeto.id);
    } catch (error) {
      console.error(error);
      this.$notify({
        title: "Erro interno!",
        text: error.message || error,
        type: "error",
        duration: 15000,
      });
    }
  },
  methods: {
    async printCronograma(id) {
      this.onPrint = !this.onPrint;
      const el = this.$refs[id];
      const options = {
        type: "dataURL",
      };
      const output = await this.$html2canvas(el, options);
      this.download(
        `cronograma_desembolso_${this.tipoCronograma}_${
          this.$store.projeto.codigo
        }_${new Date().toLocaleDateString("pt-BR")}.png`,
        output
      );
      this.onPrint = !this.onPrint;
    },
    download(filename, link) {
      const element = document.createElement("a");
      element.setAttribute("href", link);
      element.setAttribute("download", filename);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    },
    async salvarCronogramas() {
      try {
        const subprojetos = await getSubprojetosByProjetoApi(
          this.$store.projeto.id
        );
        await Promise.all([
          salvarSnapshotCronogramasProjeto({
            titulo:
              "CRONOGRAMA DESENBOLSO " +
              Math.random()
                .toString(36)
                .substring(6),
            entityId: this.$store.projeto.id,
          }),
          ...subprojetos.map(async (s) => {
            await salvarSnapshotCronogramasSubProjeto({
              titulo:
                "CRONOGRAMA DESENBOLSO " +
                Math.random()
                  .toString(36)
                  .substring(6),
              entityId: s.id,
            });
          }),
        ]);
        this.$notify({
          title: "Cronogramas salvos!",
          text: "Os cronogramas foram salvos com sucesso!",
          type: "success",
          duration: 5000,
        });
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro ao salvar cronogramas!",
          text: error.message,
          type: "error",
          duration: 15000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.session-title {
  margin: 20px;
  text-transform: uppercase;
  font-family: Raleway;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #037f8c;
}
.session-subtitle {
  text-transform: uppercase;
  font-family: Raleway;
  font-style: normal;
  font-size: 20px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #55b4c2;
}
.container-main {
  border-radius: 10px;
  border: 0.5px solid #55b4c2;
  padding-bottom: 30px;
}
.container-header {
  display: flex;
  justify-content: space-between;
  padding: 25px;
}
.btn-controls {
  border-radius: 8px;
  color: #fff;
}
</style>
