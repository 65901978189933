import api from "../api";

function formatMetricasByFonte(projeto) {
  const metricasFndct = projeto.fontes.find((fp) => fp.fonte.codigo == "F");
  if (metricasFndct) {
    metricasFndct.valorDesembolsado = projeto.valorDesembolsado;
    projeto.metricas = metricasFndct;
  }
  const metricasRendimento = projeto.fontes.find(
    (fp) => fp.fonte.codigo == "R"
  );
  if (metricasRendimento) {
    metricasRendimento.valorDesembolsado = projeto.valorDesembolsado;
    projeto.metricasRendimento = metricasRendimento;
  }
  const metricasParcela = projeto.metricasFonteParcela.filter(
    (fp) => fp.fonte.codigo == "F"
  );
  metricasParcela.forEach((m) => {
    m.percentualExecucaoDesembolso = m.parcela.percentualExecucaoDesembolso;
    m.valorDesembolsado = m.parcela.valorDesembolsado;
    m.valorAutorizado = m.parcela.valorAutorizado;
  });
  projeto.metricasParcela = metricasParcela;
}

export const getProjetosApi = async () => {
  const res = await api.get("/projetos");
  const projetos = res.data;
  projetos.forEach((projeto) => {
    formatMetricasByFonte(projeto);
  });
  return projetos;
};

export const getProjetoById = async (idProjeto) => {
  const res = await api.get(`/projetos/${idProjeto}`);
  const projeto = res.data;
  formatMetricasByFonte(projeto);
  return projeto;
};

export const buscarProjetosApi = async (query) => {
  const res = await api.get(`/projetos/search?camposTextuais=${query}`);
  const projetos = res.data;
  projetos.forEach((projeto) => {
    formatMetricasByFonte(projeto);
  });
  return projetos;
};

export const atualizarRendimentosProjeto = async (payload) => {
  const res = await api.post(`/projetos/atualizar-rendimentos`, payload);
  return res.data;
};

export const calcularExecucaoRubricaPorProjeto = async (idProjeto) => {
  const rubricasPorCodigo = [];
  const itensRes = await api.get(`/itens/search?projetos=${idProjeto}`);
  const itens = itensRes.data;
  if (itens) {
    const rubricasRes = await api.get(`rubricas`);
    let rubricas = rubricasRes.data;
    if (rubricas) {
      rubricas.forEach(
        (rubrica) =>
          (rubrica.codigo = rubrica.codigo + (rubrica.importado ? "-I" : "-N"))
      );
      let rubricasFiltered = [];
      itens.forEach((item) => {
        rubricas.forEach((rubrica) => {
          if (item.rubrica.id == rubrica.id) {
            item.codigoRubrica = rubrica.codigo;
            rubricasFiltered.push(rubrica);
          }
        });
      });
      rubricas = rubricasFiltered;
      rubricas.forEach((rubrica) => {
        if (!rubricasPorCodigo.some((r) => r.codigo == rubrica.codigo)) {
          rubricasPorCodigo.push({
            codigo: rubrica.codigo,
            descricao: rubrica.descricaoCompleta,
          });
        }
      });

      rubricasPorCodigo.forEach((rubrica) => {
        rubrica.valorExecutado = itens
          .filter((item) => item.codigoRubrica == rubrica.codigo)
          .reduce((total, item) => (total += item.valorExecutado), 0);
        rubrica.valorAlocado = itens
          .filter((item) => item.codigoRubrica == rubrica.codigo)
          .reduce((total, item) => (total += item.valorAlocado), 0);
      });
    }
  }
  return rubricasPorCodigo;
};

export const ativarEstadoReformulacao = async (idProjeto) => {
  const res = await api.get(
    `/projetos/ativar-estado-reformulacao?projetoId=${idProjeto}`
  );
  return res.data;
};

export const desativarEstadoReformulacao = async (idProjeto) => {
  const res = await api.get(
    `/projetos/desativar-estado-reformulacao?projetoId=${idProjeto}`
  );
  return res.data;
};

export const excluirProjeto = async (idProjeto) => {
  const res = await api.delete(
    `/projetos/${idProjeto}`
  );
  return res.data;
}