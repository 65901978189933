<template>
  <v-dialog v-model="modalVisible" persistent max-width="650">
    <v-card style="border-radius: 10px">
      <br />
      <v-card-title></v-card-title>
      <v-card-text>
        <v-textarea
          v-model="conteudo"
          autofocus
          outlined
          rows="7"
          placeholder="Escreva um comentário."
          required
        ></v-textarea>
      </v-card-text>
      <v-card-actions
        style="
          display: flex;
          justify-content: space-evenly;
          padding-bottom: 20px;
        "
      >
        <v-btn outlined width="190" color="#FF8D3F" @click="closeModal(false)"
          >Cancelar</v-btn
        >
        <v-btn
          depressed
          width="190"
          class="btn-controls"
          color="#FF8D3F"
          @click="isEdit ? attObservacao() : addObservacao()"
          >Salvar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  atualizarObservacaoParcela,
  cadastrarObservacaoParcela,
} from "../../services/providers/parcelaProvider";
export default {
  name: "ModalAddObservacaoParcela",
  props: {
    parcela: Object,
    modalVisible: Boolean,
    observacao: Object,
  },
  data: () => ({
    conteudo: "",
    isEdit: false,
  }),
  mounted() {
    if (this.observacao) {
      this.isEdit = true;
      this.conteudo = this.observacao.conteudo;
    }
  },
  methods: {
    closeModal() {
      this.conteudo = "";
      this.$emit("close");
    },
    async addObservacao() {
      try {
        const payload = {
          conteudo: this.conteudo,
          parcelaId: this.parcela.id,
        };
        const res = await cadastrarObservacaoParcela(payload);
        if (res) {
          this.$notify({
            title: "Observação adicionada!",
            text: "A observação foi cadastrada com sucesso!",
            type: "success",
            duration: 5000,
          });
          this.closeModal(true);
        }
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro ao cadastrar a observação!",
          text: error.message,
          type: "error",
          duration: 15000,
        });
      }
    },
    async attObservacao() {
      try {
        const payload = {
          id: this.observacao.id,
          conteudo: this.conteudo,
          parcelaId: this.parcela.id,
        };
        const res = await atualizarObservacaoParcela(payload);
        if (res) {
          this.$notify({
            title: "Observação atualizada!",
            text: "A observação foi atualizada com sucesso!",
            type: "success",
            duration: 5000,
          });
          this.closeModal(true);
        }
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro ao atualizada a observação!",
          text: error.message,
          type: "error",
          duration: 15000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
