<template>
  <v-dialog v-model="modalVisible" persistent scrollable max-width="800">
    <v-form ref="formSolicitarDesembolso" lazy-validation>
      <v-card style="border-radius: 10px">
        <br />
        <v-card-title class="modal-title">
          SOLICITAÇÃO DE DESEMBOLSO
        </v-card-title>
        <v-card-text style="padding-left: 30px">
          <br />
          <v-row>
            <v-col class="label-col" md="4">
              <p class="label">Parcela:</p>
            </v-col>
            <v-col md="6">
              <v-select
                :items="parcelasFormatted"
                v-model="parcelaSelecionada"
                item-text="label"
                item-value="value"
                label="Selecione a parcela"
                outlined
                clearable
                dense
                class="select-form"
                color="#037F8C"
                item-color="#037F8C"
                :rules="[(v) => !!v || 'Selecione uma parcela']"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="label-col" md="4">
              <p class="label">N° de Ofício:</p>
            </v-col>
            <v-col md="6">
              <v-text-field
                v-model="oficio"
                outlined
                dense
                :rules="[(v) => !!v || 'N° de Ofício é obrigatória']"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="label-col" md="4">
              <p class="label">Data de desembolso:</p>
            </v-col>
            <v-col md="6">
              <DatePicker v-model="data" :key="data"/>
            </v-col>
          </v-row>
          <br />
        </v-card-text>
        <v-card-actions class="modal-footer">
          <v-btn outlined width="190" color="#FF8D3F" @click="closeModal(false)"
            >Cancelar</v-btn
          >
          <v-btn
            depressed
            width="190"
            class="btn-controls"
            color="#FF8D3F"
            @click="solicitarDesembolso"
            >SOLICITAR</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { EventBus } from "../../services/event-bus";
import { solicitarDesembolso, editarSolicitacaoDesembolso } from "../../services/providers/desembolsoProvider";
import DatePicker from "../DatePicker.vue";
export default {
  name: "ModalSolicitacaoDesembolso",
  components: {
    DatePicker,
  },
  props: {
    modalVisible: Boolean,
    solicitacao: Object,
  },
  data: () => ({
    id: null,
    parcelasFormatted: [],
    parcelaSelecionada: null,
    oficio: null,
    data: new Date().toISOString().slice(0, 10),
  }),
  async mounted() {
    if (this.$store.projeto) {
      const parcelas = this.$store.projeto.parcelas;
      if (parcelas) {
        this.parcelasFormatted = parcelas.map((p) => ({
          label: p.numero + "º Parcela",
          value: p.id,
        }));
      }
    }
    if (this.solicitacao) {
      // console.log(this.solicitacao);
      this.oficio = this.solicitacao.numeroOficio;
      this.parcelaSelecionada = this.solicitacao.parcelaId;
      this.data = this.solicitacao.data;
      this.id = this.solicitacao.id;
    }
  },
  methods: {
    closeModal(reload) {
      if (reload) {
        EventBus.$emit("desembolso-updated");
      }
      this.$emit("close");
    },
    async solicitarDesembolso() {
      if (this.$refs.formSolicitarDesembolso.validate()) {
        if (this.id) {
          try {
            const payload = {
              parcelaId: this.parcelaSelecionada,
              numeroOficio: this.oficio,
              data: this.data,
            };
            const res = await editarSolicitacaoDesembolso(this.id, payload);
            if (res) {
              this.closeModal(true);
              this.$notify({
                title: "Solicitação editada",
                text: "Solicitação editada com sucesso!",
                type: "success",
                duration: 5000,
              });
            }
          } catch (error) {
            console.error(error);
            this.$notify({
              title: "Erro ao solicitar desembolso!",
              text: error.message,
              type: "error",
              duration: 15000,
            });
          }
        } else {
          try {
          const payload = {
            parcelaId: this.parcelaSelecionada,
            numeroOficio: this.oficio,
            data: this.data,
          };
          const res = await solicitarDesembolso(payload);
          if (res) {
            this.closeModal(true);
            this.$notify({
              title: "Desembolso solicitado",
              text: "Desembolso solicitado com sucesso!",
              type: "success",
              duration: 5000,
            });
          }
        } catch (error) {
          console.error(error);
          this.$notify({
            title: "Erro ao solicitar desembolso!",
            text: error.message,
            type: "error",
            duration: 15000,
          });
        }
        }
        
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
