<template>
  <v-row>
    <v-col md="12">
      <v-row style="margin-bottom: 0; margin-top: 0px">
        <v-col md="2">
          <p class="t-label">Título:</p>
        </v-col>
        <v-col md="8">
          <p v-if="projeto && projeto.titulo">{{ projeto.titulo }}</p>
          <p v-else>-</p>
        </v-col>
      </v-row>
      <hr>
      <v-row style="margin-bottom: 0; margin-top: 0px">
        <v-col md="2">
          <p class="t-label">Código:</p>
        </v-col>
        <v-col md="8">
          <p v-if="projeto && projeto.codigo">{{ projeto.codigo }}</p>
          <p v-else>-</p>
        </v-col>
      </v-row>
      <hr>
      <v-row>
        <v-col md="2">
          <p class="t-label">Demanda:</p>
        </v-col>
        <v-col md="8">
          <p v-if="projeto && projeto.demanda">{{ projeto.demanda }}</p>
          <p v-else>-</p>
        </v-col>
      </v-row>
      <hr>
      <v-row>
        <v-col md="2">
          <p class="t-label">Convênio:</p>
        </v-col>
        <v-col md="8">
          <p v-if="projeto && projeto.convenio">{{ projeto.convenio }}</p>
          <p v-else>-</p>
        </v-col>
      </v-row>
      <hr>
      <v-row>
        <v-col md="2">
          <p class="t-label">Referência:</p>
        </v-col>
        <v-col md="8">
          <p v-if="projeto && projeto.referencia">
            {{ projeto.referencia }}
          </p>
          <p v-else>-</p>
        </v-col>
      </v-row>
      <hr>
      <v-row>
        <v-col md="2">
          <p class="t-label">SIGFundação:</p>
        </v-col>
        <v-col md="8">
          <p v-if="projeto && projeto.sigFundacao">
            {{ projeto.sigFundacao }}
          </p>
          <p v-else>-</p>
        </v-col>
      </v-row>
      <hr>
      <v-row>
        <v-col md="2">
          <p class="t-label">Vigência:</p>
        </v-col>
        <v-col md="8">
          <p v-if="projeto && projeto.dataInicio">
            {{ new Date(projeto.dataInicio) | moment("DD/MM/YYYY") }} até
            {{ new Date(projeto.dataFim) | moment("DD/MM/YYYY") }}
          </p>
          <p v-else>-</p>
        </v-col>
      </v-row>
      <hr>
      <v-row>
        <v-col md="2">
          <p class="t-label">Valor Total do Projeto:</p>
        </v-col>
        <v-col md="8">
          <p v-if="projeto">
            {{ valorTotalProjeto | currency }}
          </p>
          <p v-else>-</p>
        </v-col>
      </v-row>
      <hr>         
    </v-col>
  </v-row>
</template>

<script>
import { getProjetoById } from "../../services/providers/projetosProvider";

export default {
  name: "InfoBasicasProjeto",
  data: () => ({
    pageTitle: null,
    projeto: null,
  }),
  async created() {
    if (!this.$store.projeto) {
      try {
        const projeto = await getProjetoById(this.$route.params.id);
        if (projeto) {
          this.$actions.setProjeto(projeto);
          this.projeto = projeto;
          this.pageTitle = projeto.codigo;
        } else {
          this.$router.push("/projetos-vigentes");
        }
      } catch (error) {
        console.error(error);
        this.$notify({
          title: "Erro interno!",
          text: error.message || error,
          type: "error",
          duration: 15000,
        });
      }
    }
    this.pageTitle = this.$store.projeto.codigo;
    this.projeto = this.$store.projeto;
  },
  computed: {
    valorTotalProjeto() {
      if (this.projeto.valorTotal){
        return this.projeto.valorTotal;
      }
      else {
        const fontes = this.projeto.fontes;
        const fonteFNDCT = fontes.filter(fonte => fonte.fonte.codigo === "F");
        return fonteFNDCT[0].valorAutorizado;
      }
    },
  },
  methods: {
    recarregarInformacoes() {
    }
  }
};
</script>

<style lang="scss" scoped>
.v-application .title {
  font-family: $title !important;
  font-weight: bold !important;
}
.v-application p {
  margin-bottom: 0px;
}
.t-label {
  font-size: 18px !important;
  font-family: $subtitle !important;
  position: relative;
  text-align: left;
  font-weight: 500;
  top: -3.6px;
}
.t-label-box {
  font-size: 15px !important;
  font-family: $subtitle !important;
  position: relative;
  text-align: left;
  font-weight: 500;
}
.description {
  font-family: $title !important;
  font-size: 18px !important;
}
.container .col {
  padding-bottom: 0px;
}
.container-ammount {
  background-color: $card-background;
  justify-content: center;
  width: 100%;
  padding-left: 20px;
  padding-top: 30px;
  padding-bottom: 10px;
  border-radius: 20px;
}
.v-tab {
  color: $primary !important;
  font-weight: bold !important;
}
.v-tab--active {
  color: #ff8d3f !important;
}
.alert-box {
  margin-top: 10px;
  margin-bottom: 15px;
  text-align: center;
}
</style>
